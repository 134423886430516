import { Typography } from '@mui/material';

const Item = ({ title, description, image }) => (
  <div className="landing-page-coach-item-main-container-item">
    <div
      style={{
        backgroundImage: `url(${image})`,
      }}
      className="landing-page-coach-item-main-container-item-top-container"
    />
    <div className="landing-page-coach-item-main-container-item-bottom-container">
      <Typography variant="titleSnippet" gutterBottom>
        {title}
      </Typography>
      <Typography color="primary.main">{description}</Typography>
    </div>
  </div>
);

export default Item;
