/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import Excel from 'exceljs';

import { getAllQuestionnaireUsers } from '../api';
import { downloadReport } from './';

export const getAllianzUserBreakdown = async () => {
  try {
    const questionnaireUsers = await getAllQuestionnaireUsers();
    const { headers, excelData } =
      getUserByClustersAndUnits(questionnaireUsers);
    const workbook = createAllianzUserBreakdownWorkBook({ headers, excelData });
    const buffer = await workbook.xlsx.writeBuffer();
    downloadReport(buffer, 'AllianzUserBreakdown');
  } catch (err) {
    Sentry.captureException(err);
  }
};

const getUserByClustersAndUnits = (questionnaireUsers) => {
  const topTable = {};
  const middleTable = {};
  const bottomTable = {};
  questionnaireUsers.forEach((itemData) => {
    getInfo(topTable, itemData);

    if (Array.isArray(itemData.answers)) {
      getInfo(middleTable, itemData);
    }

    const hasGoals = itemData.goals && itemData.goals.length > 0;
    const hasSavedOffers =
      itemData.savedOffers && itemData.savedOffers.length > 0;
    const hasContextNotes =
      itemData.contextNotes && itemData.contextNotes.length > 0;
    const hasTeamNotes = itemData.teamNotes && itemData.teamNotes.length > 0;
    const hasSelfNotes = itemData.selfNotes && itemData.selfNotes.length > 0;
    const hasTeamIdeas = itemData.teamIdeas && itemData.teamIdeas.length > 0;
    const hasSelfIdeas = itemData.selfIdeas && itemData.selfIdeas.length > 0;

    if (
      hasGoals ||
      hasSavedOffers ||
      hasContextNotes ||
      hasTeamNotes ||
      hasSelfNotes ||
      hasTeamIdeas ||
      hasSelfIdeas
    ) {
      getInfo(bottomTable, itemData);
    }
  });

  const headerClusters = getHeaders(topTable, middleTable, bottomTable);

  const rows = Object.keys(
    Object.values(topTable).reduce((result, obj) => {
      return Object.assign(result, obj);
    }, {}),
  ).sort();
  rows.push('Gesamt');

  return {
    headers: [{ header: '', key: '' }].concat(headerClusters),
    excelData: getExcelData(
      rows,
      headerClusters,
      topTable,
      middleTable,
      bottomTable,
    ),
  };
};

const CLUSTERS = {
  AGE: 'AGE (ehemals E0)',
  ASE: 'ASE (ehemals E1)',
  AE: 'AE (ehemals E2)',
  ASA: 'ASA',
};

const AGE_ASE_CLUSTERS = 'AGE (ehemals E0)/ASE (ehemals E1)';

const getInfo = (table, itemData) => {
  if (itemData.cluster === CLUSTERS.ASE || itemData.cluster === CLUSTERS.AGE) {
    if (!table[AGE_ASE_CLUSTERS]) {
      table[AGE_ASE_CLUSTERS] = {};
      table[AGE_ASE_CLUSTERS][itemData.unit] = 1;
    } else if (table[AGE_ASE_CLUSTERS][itemData.unit]) {
      table[AGE_ASE_CLUSTERS][itemData.unit]++;
    } else {
      table[AGE_ASE_CLUSTERS][itemData.unit] = 1;
    }
    return;
  }

  if (table[itemData.cluster]) {
    if (table[itemData.cluster][itemData.unit]) {
      table[itemData.cluster][itemData.unit]++;
    } else {
      table[itemData.cluster][itemData.unit] = 1;
    }
  } else {
    table[itemData.cluster] = {};
    table[itemData.cluster][itemData.unit] = 1;
  }
};

const getHeaders = (topTable, middleTable, bottomTable) => {
  const topHeaders = Object.keys(topTable).map((item, i) => {
    return {
      header: item,
      key: `top${i}`,
    };
  });
  const middleHeaders = Object.keys(middleTable).map((item, i) => {
    return {
      header: item,
      key: `middle${i}`,
    };
  });
  const bottomHeaders = Object.keys(bottomTable).map((item, i) => {
    return {
      header: item,
      key: `bottom${i}`,
    };
  });
  topHeaders.push({
    header: 'Gesamt',
    key: `top${topHeaders.length}`,
  });
  middleHeaders.push({
    header: 'Gesamt',
    key: `middle${middleHeaders.length}`,
  });
  bottomHeaders.push({
    header: 'Gesamt',
    key: `bottom${bottomHeaders.length}`,
  });
  return topHeaders.concat(middleHeaders).concat(bottomHeaders);
};

const getExcelData = (
  rows,
  headerClusters,
  topTable,
  middleTable,
  bottomTable,
) => {
  let verticalTopSum = 0;
  let verticalMiddleSum = 0;
  let verticalBottomSum = 0;
  return rows.map((row) => {
    const t = {};
    let sumOfTop = 0;
    let sumOfMiddle = 0;
    let sumOfBottom = 0;
    t.empty = row;
    headerClusters.forEach((header) => {
      if (header.header === 'Gesamt') {
        t[header.key] = '';
        return;
      }
      if (header.key.includes('top') && row !== 'Gesamt') {
        if (topTable[header.header][row]) {
          t[header.key] = topTable[header.header][row];
          sumOfTop++;
        } else {
          t[header.key] = 0;
        }
      }
      if (header.key.includes('middle') && row !== 'Gesamt') {
        if (middleTable[header.header][row]) {
          t[header.key] = middleTable[header.header][row];
          sumOfMiddle++;
        } else {
          t[header.key] = 0;
        }
      }
      if (header.key.includes('bottom') && row !== 'Gesamt') {
        if (bottomTable[header.header][row]) {
          t[header.key] = bottomTable[header.header][row];
          sumOfBottom++;
        } else {
          t[header.key] = 0;
        }
      }
    });
    if (row !== 'Gesamt') {
      const emptyKeys = Object.keys(t).filter((key) => t[key] === '');
      t[emptyKeys[0]] = sumOfTop;
      t[emptyKeys[1]] = sumOfMiddle;
      t[emptyKeys[2]] = sumOfBottom;
      verticalTopSum += sumOfTop;
      verticalMiddleSum += sumOfMiddle;
      verticalBottomSum += sumOfBottom;
    } else {
      const emptyKeys = Object.keys(t).filter((key) => t[key] === '');
      t[emptyKeys[0]] = verticalTopSum;
      t[emptyKeys[1]] = verticalMiddleSum;
      t[emptyKeys[2]] = verticalBottomSum;
    }
    return t;
  });
};

const createAllianzUserBreakdownWorkBook = ({ headers, excelData }) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Nutzer');

  worksheet.getRow(2).values = headers.map((header) => header.header);

  // Add headers
  worksheet.columns = headers.map((item, key) => {
    if (key === 0) {
      return {
        header: '',
        key: 'empty',
      };
    }
    if (item.key === 'top0') {
      return {
        header: 'In #lead registriert',
        key: 'top0',
      };
    }

    if (item.key === 'middle0') {
      return {
        header: 'Standortbestimmung ausgefüllt',
        key: 'middle0',
      };
    }

    if (item.key === 'bottom0') {
      return {
        header: 'Inklusive Detailauswertung\t\t',
        key: 'bottom0',
      };
    }

    return {
      header: '',
      key: item.key,
    };
  });

  for (let i = 0; i < worksheet.columns.length; i += 1) {
    let dataMax = 0;
    const column = worksheet.columns[i];
    for (let j = 1; j < column.values.length; j += 1) {
      const columnLength = column.values[j].length;
      if (columnLength > dataMax) {
        dataMax = columnLength;
      }
    }
    column.width = dataMax < 10 ? 10 : dataMax;
  }

  // Make the header bold.
  // Note: in Excel the rows are 1 based, meaning the first row is 1 instead of 0.
  worksheet.getRow(1).font = { bold: true };
  excelData.forEach((d) => {
    worksheet.addRow(d);
  });

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const rowValues = worksheet.getRow(2).values;
  const cellsToMerge = [2].concat(
    rowValues.map((r, i) => (r === 'Gesamt' ? i : '')).filter(String),
  );

  excelData.forEach((d, i) => {
    if (d.empty === 'Gesamt') {
      const overallFirstFormula = Array.from({ length: i }, (v, k) => k)
        .map((item) => {
          return `${alphabet[cellsToMerge[1] - 1]}${item + 3}`;
        })
        .toString()
        .split(',')
        .join('+');

      const overallSecondFormula = Array.from({ length: i }, (v, k) => k)
        .map((item) => {
          return `${alphabet[cellsToMerge[2] - 1]}${item + 3}`;
        })
        .toString()
        .split(',')
        .join('+');

      const overallThirdFormula = Array.from({ length: i }, (v, k) => k)
        .map((item) => {
          return `${alphabet[cellsToMerge[3] - 1]}${item + 3}`;
        })
        .toString()
        .split(',')
        .join('+');

      worksheet.getCell(i + 3, cellsToMerge[1]).value = {
        formula: overallFirstFormula,
      };
      worksheet.getCell(i + 3, cellsToMerge[2]).value = {
        formula: overallSecondFormula,
      };
      worksheet.getCell(i + 3, cellsToMerge[3]).value = {
        formula: overallThirdFormula,
      };
      return;
    }

    const formulaFirst = alphabet
      .slice(cellsToMerge[0] - 1, cellsToMerge[1] - 1)
      .map((item) => {
        return item + (i + 3);
      })
      .toString()
      .split(',')
      .join('+');

    const formulaSecond = alphabet
      .slice(cellsToMerge[1], cellsToMerge[2] - 1)
      .map((item) => {
        return item + (i + 3);
      })
      .toString()
      .split(',')
      .join('+');

    const formulaThird = alphabet
      .slice(cellsToMerge[2], cellsToMerge[3] - 1)
      .map((item) => {
        return item + (i + 3);
      })
      .toString()
      .split(',')
      .join('+');

    worksheet.getCell(i + 3, cellsToMerge[1]).value = { formula: formulaFirst };
    worksheet.getCell(i + 3, cellsToMerge[2]).value = {
      formula: formulaSecond,
    };
    worksheet.getCell(i + 3, cellsToMerge[3]).value = { formula: formulaThird };
  });

  worksheet.mergeCells(1, cellsToMerge[0], 1, cellsToMerge[1]);
  worksheet.mergeCells(1, cellsToMerge[1] + 1, 1, cellsToMerge[2]);
  worksheet.mergeCells(1, cellsToMerge[2] + 1, 1, cellsToMerge[3]);

  return workbook;
};
