import { Box, CircularProgress, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  getCurrentUser,
  onAuthStateChanged,
  usePostSignInHook,
  useGetPublicDataByCustomPathQuery,
} from '../../api';
import bgWelcome from '../../assets/images/bg-welcome.webp';
import Button from '../../components/Button';
import ButtonResendConfirmationEmail from '../../components/ButtonResendConfirmationEmail/ButtonResendConfirmationEmail';
import LoadingWrapper from '../../components/LoadingWrapper';
import SAMLSignInButton from '../../components/SAMLSignInButton';
import SupportPart from '../../components/SupportPart';
import { KeyCodes } from '../../constants/KeyCodes';
import PagePath from '../../constants/PagePath';
import { useGlobalState } from '../../hooks';
import NewPasswordModal from '../../modals/NewPasswordModal';
import ResetPasswordModal from '../../modals/ResetPasswordModal';
import { getCompanyLogo, getLink, Monitoring } from '../../utils';
import Login from '../Login';
import SignUp from '../SignUp';
import './Welcome.scss';

const Welcome = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');

  const initialState = {
    signupShow: false,
    loginShow: false,
    showResetPasswordModal: false,
    showNewPasswordModal: !!oobCode,
  };

  const [state, setState] = useState(initialState);
  const [{ company, user }] = useGlobalState();

  const onEnterPressSubmitSignUp = (event) => {
    if (event.keyCode === KeyCodes.ENTER) {
      const button = document.getElementsByClassName('sign-up-modal-button');
      button[0]?.click();
    }
  };

  const onEnterPressSubmitLogin = (event) => {
    if (event.keyCode === KeyCodes.ENTER) {
      const button = document.getElementsByClassName('login-modal-button');
      button[0]?.click();
    }
  };

  const handleSignupClose = () => {
    setState({ ...state, signupShow: false });
    window.removeEventListener('keypress', onEnterPressSubmitSignUp);
  };

  const handleSignupShow = () => {
    Monitoring.trackEvent('Open Register Form');
    setState({ ...state, signupShow: true });
    window.addEventListener('keypress', onEnterPressSubmitSignUp);
  };

  const handleLoginClose = () => {
    setState((state) => ({ ...state, loginShow: false }));
    window.removeEventListener('keypress', onEnterPressSubmitLogin);
  };

  const handleLoginShow = () => {
    Monitoring.trackEvent('Open Login Form');
    setState({ ...state, loginShow: true });
    window.addEventListener('keypress', onEnterPressSubmitLogin);
  };

  const openResetPasswordModal = () => {
    setState({ ...state, showResetPasswordModal: true });
    handleLoginClose();
  };

  const closeResetPasswordModal = () => {
    setState({ ...state, showResetPasswordModal: false });
  };

  const closeNewPasswordModal = () => {
    setState({ ...state, showNewPasswordModal: false });
  };

  const submitNewPasswordModal = () => {
    setState({ ...state, showNewPasswordModal: false, loginShow: true });
  };

  // If we have a custom path, we need to load company data to update logo and login method

  const {
    isFetching,
    isSuccess,
    data: publicCompanyData,
  } = useGetPublicDataByCustomPathQuery(params.customPath, {
    enabled: !!params.customPath,
    onError: (error) => {
      if (error.code === 'functions/not-found') {
        // If the custom path is invalid,
        navigate(getLink(company, PagePath.ANMELDEN)); // we redirect to a page without custom path
      } else {
        Sentry.captureException(error);
      }
    },
    initialData: {},
  });

  const postSignInHook = usePostSignInHook();

  useEffect(() => {
    Monitoring.trackEvent('Visit Login Page');

    const unsubscribeFromOnAuthStateChanged = onAuthStateChanged((authUser) => {
      if (authUser) {
        postSignInHook(authUser);
      }
    });

    return () => unsubscribeFromOnAuthStateChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    signupShow,
    loginShow,
    showResetPasswordModal,
    showNewPasswordModal,
  } = state;

  const Actions = () => {
    if (user && company) {
      if (!getCurrentUser()?.emailVerified) {
        return (
          <>
            <Typography mb={2}>
              {t('Welcome.confirmEmailAddressNotice', { email: user.email })}
            </Typography>
            <ButtonResendConfirmationEmail email={user.email} />
          </>
        );
      } else {
        return <CircularProgress />;
      }
    } else {
      if (publicCompanyData.samlProviderId) {
        return (
          <SAMLSignInButton samlProviderId={publicCompanyData.samlProviderId} />
        );
      } else {
        return (
          <>
            <div className="signupPart">
              <Button size="big" variant="main" onClick={handleSignupShow}>
                {t('NEU REGISTRIEREN')}
              </Button>
            </div>
            <div className="loginPart">
              <p className="primary-text">
                {t('Sie haben sich bereits registriert?')}
              </p>
              <Button size="big" variant="main" onClick={handleLoginShow}>
                {t('Welcome.SignIn')}
              </Button>
            </div>
          </>
        );
      }
    }
  };

  return (
    <Box className="Container" sx={{ backgroundImage: `url(${bgWelcome})` }}>
      <div className="Wrapper">
        <LoadingWrapper isLoading={isFetching} isSuccess={isSuccess}>
          <Box
            component="img"
            src={publicCompanyData?.logo_url || getCompanyLogo(company)}
            alt="Logo"
            sx={{
              height: { xxs: '72px', md: '84px' },
              marginBottom: { xxs: 4, md: 6 },
            }}
          />

          <Typography variant="titleSection" marginBottom={4}>
            {t('Herzlich Willkommen')}
          </Typography>
          <Actions />
        </LoadingWrapper>
      </div>
      <SupportPart />
      <SignUp show={signupShow} onHide={handleSignupClose} />
      {showNewPasswordModal && (
        <NewPasswordModal
          show={showNewPasswordModal}
          onHide={closeNewPasswordModal}
          oobCode={oobCode}
          onSubmit={submitNewPasswordModal}
        />
      )}
      <ResetPasswordModal
        show={showResetPasswordModal}
        onHide={closeResetPasswordModal}
      />
      <Login
        openResetPasswordModal={openResetPasswordModal}
        show={loginShow}
        onHide={handleLoginClose}
      />
    </Box>
  );
};

export default Welcome;
