import * as Sentry from '@sentry/react';
import {
  serverTimestamp,
  deleteField,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  deleteDoc,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';

import projectStates from '../constants/ProjectStates';
import { db } from '../firebaseConfig';
import { GET_ALL_PROJECTS } from '../hooks/useGlobalState';

const COLLECTION_NAME = 'projects';

/**
 * @method getAllProjects
 * @param {function} dispatch - function that dispatch action
 * @param {string} uid - id of user
 * @summary get all projects by id for user
 */
export const getAllProjects = async (dispatch, uid) => {
  try {
    const projectsRef = collection(db, COLLECTION_NAME);
    const q = query(projectsRef, where('user_id', '==', uid));
    const projectsSnap = await getDocs(q);
    if (projectsSnap.empty) {
      dispatch({ type: GET_ALL_PROJECTS, payload: [] });
    }
    const array = projectsSnap.docs.map((doc) => doc.data());
    dispatch({ type: GET_ALL_PROJECTS, payload: array });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method createNewProject
 * @param {object} projectData - data about project (type, comments...)
 * @summary create new project with projectData and default state
 */
export const createNewProject = async (projectData) => {
  try {
    const projectsRef = collection(db, COLLECTION_NAME);
    const projectRef = await addDoc(projectsRef, {
      ...projectData,
      created_at: serverTimestamp(),
      state: projectStates.findCoaches,
    });
    await updateDoc(projectRef, { id: projectRef.id });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method updateProjectStateToProjectRunning
 * @param {object} projectData - data about project (id, type, comments...)
 * @param {object} coach - coach that assigned to project
 * @summary change state of project, assign coach to project and delete suggested_coaches
 */
export const updateProjectStateToProjectRunning = async (
  projectData,
  coach,
) => {
  try {
    const coachRef = doc(db, 'coaches', coach.id);
    const projectRef = doc(db, COLLECTION_NAME, projectData.id);
    await updateDoc(projectRef, {
      selected_coach: coachRef,
      state: projectStates.projectRunning,
      suggested_coaches: deleteField(),
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method getProjectById
 * @param {string} id - id of project
 * @summary get project by id
 * @returns {Promise<Object>} project data
 */
export const getProjectById = async (id) => {
  try {
    const projectRef = doc(db, COLLECTION_NAME, id);
    const projectSnap = await getDoc(projectRef);
    if (!projectSnap.exists()) {
      return {};
    }
    return projectSnap.data();
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method confirmProjectRequest
 * @param {string} id - id of project
 * @param {array} coaches - array of suggested_coaches
 * @summary update state of project to bookCoach and assign suggested_coaches to project
 */
export const confirmProjectRequest = async (id, coaches) => {
  try {
    const suggestedCoachesRefs = coaches.map((coach) =>
      doc(db, 'coaches', coach.id),
    );
    const projectRef = doc(db, COLLECTION_NAME, id);
    await updateDoc(projectRef, {
      state: projectStates.bookCoach,
      suggested_coaches: suggestedCoachesRefs,
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method updateSuggestedCoaches
 * @param {string} projectId - id of project
 * @param {array} coaches - new array of suggested_coaches
 * @summary find project by id and update suggested_coaches
 */
export const updateSuggestedCoaches = async (projectId, coaches) => {
  try {
    const suggestedCoachesRefs = coaches.map((coach) =>
      doc(db, 'coaches', coach.id),
    );
    const projectRef = doc(db, COLLECTION_NAME, projectId);
    await updateDoc(projectRef, { suggested_coaches: suggestedCoachesRefs });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method deleteProject
 * @param {string} projectId - id of project
 */
export const deleteProject = async (id) => {
  try {
    const projectRef = doc(db, COLLECTION_NAME, id);
    await deleteDoc(projectRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function onUserProjectsUpdate
 * @param {String} userId - id of projects owner
 * @param {Function} callback - function, that we will call on update
 * @returns {Function} unsubscribe function
 */
export const onUserProjectsUpdate = (userId, callback) => {
  try {
    const projectsRef = collection(db, COLLECTION_NAME);
    const q = query(projectsRef, where('user_id', '==', userId));
    return onSnapshot(q, (projectsSnap) => {
      if (projectsSnap.empty) {
        callback([]);
        return;
      }
      const projects = projectsSnap.docs.map((doc) => doc.data());
      callback(projects);
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function onProjectsUpdate
 * @param {Function} callback - function, that we will call on update
 * @returns {Function} unsubscribe function
 */
export const onProjectsUpdate = (callback) => {
  try {
    const projectsRef = collection(db, COLLECTION_NAME);
    const q = query(projectsRef);
    return onSnapshot(q, (projectsSnap) => {
      if (projectsSnap.empty) {
        callback([]);
        return;
      }
      const projects = projectsSnap.docs.map((doc) => doc.data());
      callback(projects);
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function onProjectsByStateUpdate
 * @param {String} state - state of project
 * @param {Function} callback - function, that we will call on update
 * @returns {Function} unsubscribe function
 */
export const onProjectsByStateUpdate = (state, callback) => {
  try {
    const projectsRef = collection(db, COLLECTION_NAME);
    const q = query(
      projectsRef,
      where('state', '==', state),
      orderBy('created_at', 'asc'),
    );
    return onSnapshot(q, (projectsSnap) => {
      if (projectsSnap.empty) {
        callback([]);
        return;
      }
      const projects = projectsSnap.docs.map((doc) => doc.data());
      callback(projects);
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};
