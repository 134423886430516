import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { useGetCompanyById } from '../../../../api';

const initialFormValues = {
  name: '',
  custom_path: '',
  domains: [],
  redirect_after_login_to: '',
  available_products: [],
  logo: null,
  logo_url: null,
};

const useAdminCompanyForm = () => {
  const { companyId } = useParams();

  const form = useForm({
    defaultValues: initialFormValues,
    resolver: yupResolver(adminCompanyFormSchema()),
  });

  const companyByIdQuery = useGetCompanyById(companyId ?? null, {
    enabled: !!companyId,
    onSuccess: (company) => {
      if (company) {
        form.setValue('name', company.name || '');
        form.setValue('custom_path', company.custom_path || '');
        form.setValue('domains', company.domain || []);
        form.setValue(
          'redirect_after_login_to',
          company.redirect_after_login_to,
        );
        form.setValue('logo_url', company.logo_url || '');
        form.setValue('available_products', company.available_products || []);
        form.setValue('samlGroups', company.samlGroups || []);
      }
    },
  });

  const handleInvalidateQueries = useCallback(() => {
    companyByIdQuery.remove();
  }, [companyByIdQuery]);

  return {
    form,
    handleInvalidateQueries,
  };
};

const adminCompanyFormSchema = () =>
  yup.object().shape({
    name: yup.string().required('Bitte einen Firmennamen angeben'),
    custom_path: yup.string(),
    domains: yup.array().of(yup.string()).min(1),
    redirect_after_login_to: yup.string(),
    available_products: yup.mixed(),
    logo: yup.mixed(),
    logo_url: yup.string().nullable(),
  });

export default useAdminCompanyForm;
