import * as yup from 'yup';

const loginSchema = (t) =>
  yup.object({
    email: yup
      .string()
      .email(t('Ihr Unternehmen wurde noch nicht freigeschaltet'))
      .required(t('Bitte eine E-Mail-Adresse angeben')),
    password: yup
      .string()
      .min(6, t('Bitte geben Sie ein Passwort mit mindestens 6 Zeichen ein'))
      .required(t('Bitte geben Sie ein Passwort ein')),
  });

export default loginSchema;
