/**
 * @enum ProjectTypes {'coach' | 'moderator' | 'elearning'}
 */
const ProjectTypes = {
  COACH: 'coach',
  MODERATOR: 'moderator',
  ELEARNING: 'elearning',
};

export default ProjectTypes;
