import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import ButtonPdfProfile from '../../components/ButtonPdfProfile';
import FgiModal from '../../components/FgiModal';
import ProjectTypes from '../../constants/ProjectTypes';
import { useGlobalState } from '../../hooks';
import { getCoachesTranslatedData } from '../../utils';
import './ReviewCoachesModal.scss';

const ReviewCoachesModal = ({
  onHide,
  show,
  coaches,
  onSofortBuchenClick,
  project,
}) => {
  const [t] = useTranslation();
  const [{ locale }] = useGlobalState();

  const getColorByProjectType = (type) => {
    switch (type) {
      case ProjectTypes.MODERATOR:
        return 'green';
      case ProjectTypes.COACH:
        return 'yellow';
      default:
        return '';
    }
  };

  const getTitleByProjectType = (type) => {
    switch (type) {
      case ProjectTypes.MODERATOR:
        return t('Moderator buchen');
      case ProjectTypes.COACH:
        return t('Coach buchen');
      default:
        return '';
    }
  };

  return (
    <FgiModal
      title={getTitleByProjectType(project.type)}
      color={getColorByProjectType(project.type)}
      description={t(
        'Bitte buchen Sie Ihren Coach über „Sofort buchen“. Möchten Sie einen Coach in einem Chemistry Call kennenlernen, so kontaktieren Sie den Coach bitte per E-Mail.',
      )}
      onHide={onHide}
      show={show}
      className="review-coaches-modal"
    >
      <div className="review-coaches-modal-coaches-container">
        {coaches.map((coach) => {
          return (
            <div
              className="review-coaches-modal-coaches-container-item"
              key={coach.id}
            >
              <Avatar
                image={coach.id}
                bigSize="152px"
                smallSize="152px"
                smallStyle={{ marginLeft: '-50px' }}
              />
              <div className="review-coaches-modal-coaches-container-item-info">
                <div className="review-coaches-modal-coaches-container-item-info-text title-snippet">
                  {`${coach.firstname} ${coach.lastname}`}
                </div>
                <div className="review-coaches-modal-coaches-container-item-info-text">
                  <div className="review-coaches-modal-coaches-container-item-info-text-bio-container">
                    <div className="review-coaches-modal-coaches-container-item-info-text-bio-label form-label">
                      {t('Kontakt')}
                    </div>
                    <a
                      href={`mailto:${coach.email}`}
                      className="review-coaches-modal-coaches-container-item-info-text-bio-email primary-text"
                    >
                      {coach.email}
                    </a>
                    <a
                      href={`tel:${coach.phone}`}
                      className="review-coaches-modal-coaches-container-item-info-text-bio-email primary-text"
                    >
                      {coach.phone}
                    </a>
                  </div>
                  <div className="review-coaches-modal-coaches-container-item-info-text-bio-container">
                    <div className="review-coaches-modal-coaches-container-item-info-text-bio-label form-label">
                      {t('Standort')}
                    </div>
                    <div className="review-coaches-modal-coaches-container-item-info-text-bio-value primary-text">
                      {coach.bio_location}
                    </div>
                  </div>
                  <div className="review-coaches-modal-coaches-container-item-info-text-bio-container">
                    <div className="review-coaches-modal-coaches-container-item-info-text-bio-label form-label">
                      {t('Sprache(n)')}
                    </div>
                    <div className="review-coaches-modal-coaches-container-item-info-text-bio-value primary-text">
                      {getCoachesTranslatedData(coach.bio_language, locale)}
                    </div>
                    <ButtonPdfProfile coachId={coach.id} />
                  </div>
                </div>
                <div className="review-coaches-modal-coaches-container-item-info-buttons-container">
                  <a
                    className="review-coaches-modal-coaches-container-item-info-buttons-container-email text-button-small"
                    href={`tel:+${coach.phone}`}
                  >
                    {t('Anrufen')}
                  </a>
                  <ButtonPdfProfile coachId={coach.id} />
                </div>
                <Button
                  onClick={onSofortBuchenClick(coach, project)}
                  variant={getColorByProjectType(project.type)}
                  size="medium"
                >
                  {t('Sofort Buchen')}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </FgiModal>
  );
};

ReviewCoachesModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSofortBuchenClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  coaches: PropTypes.arrayOf(
    PropTypes.shape({
      bio_language: PropTypes.shape({
        de: PropTypes.string.isRequired,
      }).isRequired,
      bio_location: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    }),
  ).isRequired,
  project: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
};

export default ReviewCoachesModal;
