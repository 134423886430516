import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';

export const DeleteProjectModal = ({ show, onHide, confirmDeleteProject }) => {
  const [t] = useTranslation();

  return (
    <FgiModal
      color="orange"
      className="small-modal"
      show={show}
      onHide={onHide}
      title={t('Projekt Löschen?')}
      description={t(`Möchten Sie dieses Projekt löschen?`)}
      showCloseButton={false}
    >
      <div>
        <span className="sub-description">
          Das Projekt wird aus allen Ansichten und allen Reportings aller Nutzer
          entfernt.
        </span>
        <div className="modal-footer-button">
          <Button onClick={onHide} size="small" variant="light" arrow={false}>
            {t('Abbrechen')}
          </Button>
          <Button
            onClick={confirmDeleteProject}
            style={{ marginLeft: '24px' }}
            size="medium"
            variant="main-dark"
            arrow={false}
          >
            {t('Löschen')}
          </Button>
        </div>
      </div>
    </FgiModal>
  );
};

DeleteProjectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  confirmDeleteProject: PropTypes.func.isRequired,
};

export default DeleteProjectModal;
