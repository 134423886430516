import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useUpdateUserByIdMutation } from '../../../../../../api';
import Button from '../../../../../../components/Button';
import { useGlobalState } from '../../../../../../hooks';

/**
 * SwitchCompany
 * @param companyId {string | undefined}
 * @return {JSX.Element}
 */
const SwitchCompany = ({ companyId = undefined }) => {
  const [{ user }] = useGlobalState();
  const updateUserByIdMutation = useUpdateUserByIdMutation();

  const handleSwitchCompany = () => {
    updateUserByIdMutation.mutate({
      id: user.uid,
      data: { company_id: companyId },
    });
  };

  if (!companyId) {
    return (
      <>
        <Typography
          component={'h3'}
          variant={'primaryText'}
          sx={{ color: '#000', marginBottom: '8px', fontWeight: 'bold' }}
        >
          Als Nutzer zu diesem Unternehmen wechseln
        </Typography>
        <Typography
          component={'p'}
          variant={'primaryText'}
          sx={{ color: '#000', marginBottom: '8px' }}
        >
          Speichern Sie dieses Unternehmen, um dessen Konfiguration aus Sicht
          eines Nutzers erleben zu können.
        </Typography>
      </>
    );
  }

  if (typeof user?.company_id === 'string' && user?.company_id === companyId) {
    return (
      <>
        <Typography
          component={'h3'}
          variant={'primaryText'}
          sx={{ color: '#000', marginBottom: '8px', fontWeight: 'bold' }}
        >
          Sie sind Teil dieses Unternehmens
        </Typography>
        <Typography
          component={'p'}
          variant={'primaryText'}
          sx={{ color: '#000', marginBottom: '8px' }}
        >
          Wenn Sie die Sicht eines Nutzers aufrufen, erleben Sie die
          Konfiguration aus Sicht der Nutzer dieses Unternehmens.
        </Typography>
        <Typography
          component={'p'}
          variant={'primaryText'}
          sx={{ color: '#000', marginBottom: '8px' }}
        >
          Wechseln Sie zu einem anderen Unternehmen, um eine andere
          Konfiguration zu erleben.
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography
        component={'h3'}
        variant={'primaryText'}
        sx={{ color: '#000', marginBottom: '8px', fontWeight: 'bold' }}
      >
        Als Nutzer zu diesem Unternehmen wechseln
      </Typography>
      <Typography
        component={'p'}
        variant={'primaryText'}
        sx={{ color: '#000', marginBottom: '8px' }}
      >
        Erleben Sie die Konfiguration dieses Unternehmens aus Sicht eines
        Nutzers.
      </Typography>
      <Button
        type={'button'}
        variant={'light'}
        size={'small'}
        arrow={false}
        onClick={handleSwitchCompany}
        load={updateUserByIdMutation.isLoading}
      >
        WECHSELN
      </Button>
    </>
  );
};

SwitchCompany.propTypes = {
  /**
   * The id of the company to switch to
   */
  companyId: PropTypes.string,
};

export default SwitchCompany;
