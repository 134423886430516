import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './Hero.scss';

class Hero extends Component {
  state = {
    bigImage: true,
  };

  componentDidMount() {
    window.addEventListener('resize', this.changeBackground);
    this.changeBackground();
  }

  changeBackground = () => {
    this.setState({ bigImage: window.innerWidth >= 992 });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeBackground);
  }

  render() {
    const { bigBackground, smallBackground, children, paddingBottom } =
      this.props;
    const { bigImage } = this.state;
    return (
      <div
        className="hero"
        style={{
          paddingBottom: !bigImage && paddingBottom,
          backgroundImage: bigImage
            ? `url(${bigBackground})`
            : `url(${smallBackground})`,
        }}
      >
        {children}
      </div>
    );
  }
}

Hero.defaultProps = {
  style: {},
};

Hero.propTypes = {
  /** It is an optional string property that represents the padding at the bottom of the hero section*/
  paddingBottom: PropTypes.string,
  /**It is a required node property that represents the main background element for the hero section */
  bigBackground: PropTypes.node.isRequired,
  /**It is a required node property that represents the secondary background element for the hero section */
  smallBackground: PropTypes.node.isRequired,
  /**t is a required node property that represents the content of the hero section */
  children: PropTypes.node.isRequired,
};

export default Hero;
