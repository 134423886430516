import {
  collection,
  query,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  deleteDoc,
} from '@firebase/firestore';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { db } from '../firebaseConfig';
import { Locales } from '../i18n';

const COLLECTION_NAME = 'products';

/**
 * @function getAvailableProducts
 * @description return available products from refs list
 * @param {Array} productRefs - list of products refs
 * @returns {Promise<Array<Product>>}
 */
export const getAvailableProducts = async (productRefs) => {
  try {
    return await Promise.all(
      productRefs.map(async (productRef) => {
        const productSnap = await getDoc(productRef);
        if (!productSnap.exists()) {
          return {};
        }
        return { ...productSnap.data(), id: productSnap.id };
      }),
    );
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @typedef {Object} Product
 * @property {String} id - id of product
 * @property {ProjectTypes} type - type of product
 * @property {string} image - image of product
 */

/**
 * @function getAllProducts
 * @description - fetch all products
 * @returns {Promise<Array<Product>>} - list of products
 */
export const getAllProducts = async () => {
  try {
    const productsRef = collection(db, COLLECTION_NAME);
    const productsSnap = await getDocs(query(productsRef));
    if (productsSnap.empty) {
      return [];
    }
    return productsSnap.docs.map((product) => product.data());
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function useGetAllProducts
 * @return {import('@tanstack/react-query').DefinedUseQueryResult<Product[], unknown>}
 */
export const useGetAllProducts = () => {
  return useQuery({
    queryKey: ['products'],
    queryFn: getAllProducts,
    initialData: [],
  });
};

/**
 * @function createProduct
 * @description - create new product
 * @param {Object} data - new product data
 */
export const createProduct = async (data) => {
  try {
    const productsRef = collection(db, COLLECTION_NAME);
    const productRef = await addDoc(productsRef, data);
    await updateDoc(productRef, { id: productRef.id });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function updateProduct
 * @description - update product with provided data by id
 * @param {String} id - id of updated product
 * @param {Object} data - new data of product
 */
export const updateProduct = async (id, data) => {
  try {
    const productRef = doc(db, COLLECTION_NAME, id);
    await updateDoc(productRef, data);
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function getProductById
 * @description fetch product by id
 * @param {String} id - id of product
 * @returns {Promise<Object>}
 */
export const getProductById = async (id) => {
  try {
    const productRef = doc(db, COLLECTION_NAME, id);
    const productSnap = await getDoc(productRef);
    if (!productSnap.exists()) {
      return {};
    }
    return productSnap.data();
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function deleteProductById
 * @description delete product with provided id
 * @param {String} id - id of deleted product
 */
export const deleteProductById = async (id) => {
  try {
    const productRef = doc(db, COLLECTION_NAME, id);
    await deleteDoc(productRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * Returns a Firestore document reference based on the product's id.
 *
 * @param {String} id - The ID of the product.
 */
export const getProductRef = (id) => {
  return doc(db, COLLECTION_NAME, id);
};

/**
 * Returns false if the product provided has no missing translations otherwise returns true.
 *
 * @param {Object} product The product object as it comes out of the database.
 */
export const hasMissingTranslations = (product) => {
  for (const locale of Object.values(Locales)) {
    if (
      !product.description?.[locale] ||
      !product.pdfUrl?.[locale] ||
      !product.title?.[locale]
    ) {
      return true;
    }
  }
  return false;
};
