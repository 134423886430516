import React from 'react';

import { useGlobalState } from '../../../hooks';
import WorkshopItem from '../WorkshopItem';

const WorkshopsList = () => {
  const [{ events }] = useGlobalState();
  return (
    <>
      {events?.map((item) => (
        <WorkshopItem key={item.id} item={item} />
      ))}
    </>
  );
};

export default WorkshopsList;
