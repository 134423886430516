import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';

import closeIcon from '../../assets/svgs/Close.svg';
import './LoginError.scss';

const LoginError = ({ show, onHide, title, description }) => (
  <Modal
    id="loginErrorModalbox"
    show={show}
    onHide={onHide}
    dialogClassName="loginErrorModal"
  >
    <ModalBody>
      <div>
        <p className="firstItem">{title}</p>
        <p className="secondItem">{description}</p>
      </div>
      <span className="closeButton" onClick={onHide}>
        <img src={closeIcon} className="closeIcon" alt="Close" />
      </span>
    </ModalBody>
  </Modal>
);

LoginError.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default LoginError;
