import { useTranslation, Trans } from 'react-i18next';

import Image1 from '../../../../assets/images/How-to-Illustration-1.png';
import Image2 from '../../../../assets/images/How-to-Illustration-2.png';
import Image3 from '../../../../assets/images/How-to-Illustration-3.png';
import Title from '../../../../components/PageTitle';
import './CoachItem.scss';
import Item from './components/Item';

const CoachItem = () => {
  const [t] = useTranslation();

  return (
    <div id="Coach-Finden" className="landing-page-coach-item">
      <div className="landing-page-coach-item-title">
        <Title
          color="yellow"
          width="27%"
          text={t('SO FINDEN SIE IHREN COACH')}
        />
      </div>
      <div className="landing-page-coach-item-main-container">
        <Item
          title={t('coachFinder.coachItem.ItemLeft.title')}
          description={
            <Trans i18nKey="coachFinder.coachItem.ItemLeft.description" />
          }
          image={Image1}
        />
        <Item
          title={t('coachFinder.coachItem.ItemMiddle.title')}
          description={
            <Trans i18nKey="coachFinder.coachItem.ItemMiddle.description" />
          }
          image={Image2}
        />
        <Item
          title={t('coachFinder.coachItem.ItemRight.title')}
          description={
            <Trans i18nKey="coachFinder.coachItem.ItemRight.description" />
          }
          image={Image3}
        />
      </div>
    </div>
  );
};

export default CoachItem;
