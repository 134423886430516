import { Typography, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';

const CancelBookingModal = ({ show, onHide, onCancelBooking }) => {
  const [t] = useTranslation();
  return (
    <FgiModal
      color="dark-orchid"
      className="small-modal"
      show={show}
      onHide={onHide}
      title={t('Buchung Stornieren?')}
    >
      <Typography
        sx={{ textAlign: 'center' }}
        component="div"
        variant="primaryText"
      >
        {t('Möchten Sie diese Buchung stornieren?')}
      </Typography>
      <Typography
        sx={{ textAlign: 'center', margin: '24px 0' }}
        component="div"
        variant="primaryText"
      >
        {t(
          'Der Teilnehmer erhält eine E-Mail mit einer Bestätigung der Stornierung und die Buchung wird aus allen Berichten gelöscht.',
        )}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button onClick={onHide} size="medium" arrow={false} variant="light">
          {t('Abbrechen')}
        </Button>
        <Button
          onClick={onCancelBooking}
          size="medium"
          arrow={false}
          variant="dark-orchid"
        >
          {t('Stornieren')}
        </Button>
      </Box>
    </FgiModal>
  );
};

export default CancelBookingModal;
