import React from 'react';
import { Navigate } from 'react-router-dom';

import PagePath from '../constants/PagePath';
import useAvailableContent from '../hooks/useAvailableContent';
import { useGlobalState } from '../hooks/useGlobalState';
import { getLink } from '../utils';

const withRequiredProduct = (ComposedComponent, productType) => (props) => {
  const [{ company }] = useGlobalState();
  const isAvailable = useAvailableContent();

  if (isAvailable(productType)) {
    return <ComposedComponent {...props} />;
  }

  return <Navigate to={getLink(company, PagePath.ANMELDEN)} />;
};

export default withRequiredProduct;
