import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ProjectTypes from '../../../../constants/ProjectTypes';
export const ProjectInfo = ({ project }) => {
  const [t] = useTranslation();

  return project.type === ProjectTypes.COACH ? (
    <Form.Group as={Col}>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Businesssituation')}</Form.Label>
          <div className="primary-text">{project.business_situation}</div>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{t('Coachinganliegen/Thema')}</Form.Label>
          <div className="primary-text">{project.coaching_topic}</div>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Führungsebene')}</Form.Label>
          <div className="primary-text">{project.leadership_level}</div>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{t('Anschlussmaßnahmen')}</Form.Label>
          <div className="primary-text">{project.follow_on_action}</div>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Ort des Coachings')}</Form.Label>
          <div className="primary-text">
            {project.location || t('Virtuell')}
          </div>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Erwartungen')}</Form.Label>
          <div className="primary-text">{project.expectations}</div>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Anmerkungen')}</Form.Label>
          <div className="primary-text">{project.comments}</div>
        </Form.Group>
      </Row>
    </Form.Group>
  ) : (
    <Form.Group as={Col}>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Teilnehmerzahl')}</Form.Label>
          <div className="primary-text">{project.numParticipants}</div>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{t('Bevorzugte Sprache der Coachings')}</Form.Label>
          <div className="primary-text">{t(project.language)}</div>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Ort des Workshops')}</Form.Label>
          <div className="primary-text">
            {project.location || t('Virtuell')}
          </div>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{t('Startdatum')}</Form.Label>
          <div className="primary-text">{moment(project.date).format('L')}</div>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Anmerkungen')}</Form.Label>
          <div className="primary-text">{project.comments}</div>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{t('Führungsebene')}</Form.Label>
          <div className="primary-text">{project.leadership_level}</div>
        </Form.Group>
      </Row>
    </Form.Group>
  );
};

ProjectInfo.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectInfo;
