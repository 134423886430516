import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

import NotFoundImage from '../assets/images/not-found.png';
import { storage } from '../firebaseConfig';

export const uploadProductImage = async (file) => {
  if (!file.type || !file.blob) throw new Error('Invalid file');
  const metadata = {
    contentType: file.type,
  };
  const fileRef = ref(storage, `products_pictures/${file.name}`);
  await uploadBytes(fileRef, file.blob, metadata);
  return await getDownloadURL(fileRef);
};

export const useUploadProductImageMutation = () => {
  return useMutation(uploadProductImage);
};

/**
 * @function uploadCompanyLogo
 * @description Uploads a company logo to firebase storage
 * @param file {File}
 * @return {Promise<string>}
 */
export const uploadCompanyLogo = async (file) => {
  let result;
  try {
    const metadata = {
      contentType: file.type,
    };
    const fileRef = ref(storage, `company_logos/${file.name}`);
    await uploadBytes(fileRef, file.blob, metadata);
    result = await getDownloadURL(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }
  return result;
};

/**
 * @function useUploadProductImageMutation
 * @description React query mutation to upload a product image
 * @return {import('@tanstack/react-query').UseMutationResult<string, unknown, File, unknown>}
 */
export const useUploadCompanyLogoMutation = () => {
  return useMutation(uploadCompanyLogo);
};

export const deleteProductImage = async (fileUrl) => {
  try {
    const fileName = fileUrl.match(/%2F(.*?)\?alt/)[1];
    const fileRef = ref(
      storage,
      `products_pictures/${decodeURIComponent(fileName)}`,
    );
    await deleteObject(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * Returns a download link for a given coach profile image.
 * If no images was found, it returns the link to a not found image.
 */
export const getCoachProfilePictureURL = async (userId) => {
  try {
    const fileRef = ref(storage, `profile_pictures/coaches/${userId}.png`);
    return await getDownloadURL(fileRef);
  } catch (e) {
    switch (e.code) {
      case 'storage/object-not-found':
        return NotFoundImage;
      default:
        Sentry.captureException(e);
    }
  }
};

export const getCoachProfilePdfURL = async (userId) => {
  let result;
  try {
    const fileRef = ref(storage, `coach_profiles/${userId}.pdf`);
    result = await getDownloadURL(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }

  return result;
};

export const deleteCompanyLogo = async (imageURL) => {
  try {
    const fileName = imageURL.match(/%2F(.*?)\?alt/)[1];
    const fileRef = ref(
      storage,
      `company_logos/${decodeURIComponent(fileName)}`,
    );
    await deleteObject(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const uploadOpenWorkshopPDF = async (file) => {
  let result;
  try {
    const metadata = {
      contentType: file.type,
    };
    const fileRef = ref(storage, `open_workshop_files/pdf/${file.name}`);
    await uploadBytes(fileRef, file.blob, metadata);
    result = await getDownloadURL(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }
  return result;
};

export const uploadOpenWorkshopImage = async (file) => {
  const metadata = {
    contentType: file.type,
  };
  const fileRef = ref(storage, `open_workshop_files/images/${file.name}`);
  await uploadBytes(fileRef, file.blob, metadata);
  return await getDownloadURL(fileRef);
};

export const useOpenWorkshopImageMutation = () => {
  return useMutation(uploadOpenWorkshopImage);
};

export const deleteOpenWorkshopPDF = async (fileUrl) => {
  try {
    const fileName = fileUrl.match(/%2..*%2F(.*?)\?alt/)[1];
    const fileRef = ref(
      storage,
      `open_workshop_files/pdf/${decodeURIComponent(fileName)}`,
    );
    await deleteObject(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const deleteOpenWorkshopImage = async (fileUrl) => {
  try {
    const fileName = fileUrl.match(/%2..*%2F(.*?)\?alt/)[1];
    const fileRef = ref(
      storage,
      `open_workshop_files/images/${decodeURIComponent(fileName)}`,
    );
    await deleteObject(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const uploadProductPdf = async (file) => {
  let result;
  try {
    const metadata = {
      contentType: file.type,
    };
    const fileRef = ref(storage, `products_pdfs/${file.name}`);
    await uploadBytes(fileRef, file.blob, metadata);
    result = await getDownloadURL(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }
  return result;
};

export const deleteProductPdf = async (fileUrl) => {
  try {
    const fileName = fileUrl.match(/%2F(.*?)\?alt/)[1];
    const fileRef = ref(
      storage,
      `products_pdfs/${decodeURIComponent(fileName)}`,
    );
    await deleteObject(fileRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};
