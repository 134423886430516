import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';

import './AdminCheckbox.scss';

const AdminCheckbox = React.forwardRef(
  ({ checked, onChange, name, label, description, disabled, sx }, ref) => (
    <Box className="admin-checkbox" sx={sx}>
      <label className="admin-checkbox-container">
        <input
          ref={ref}
          name={name}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        <span className="admin-checkbox-checkmark" />
      </label>
      <div className="admin-checkbox-text-container">
        <div className="primary-text">{label}</div>
        <div className="text-small-1">{description}</div>
      </div>
    </Box>
  ),
);

/**
 * The react nodes that should be rendered within the AdminCheckbox.
 */

AdminCheckbox.propTypes = {
  /** Shows user make selection */
  checked: PropTypes.bool,
  /** function for  */
  onChange: PropTypes.func.isRequired,
  /** shows name of checkbox */
  name: PropTypes.string,
  /** shows label of checkbox */
  label: PropTypes.string,
  /**show description */
  description: PropTypes.string,
  /**Sets a  description */
  disabled: PropTypes.bool,
};

export default AdminCheckbox;
