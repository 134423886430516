import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, query, getDoc } from 'firebase/firestore';

import { db } from '../firebaseConfig';

const COLLECTION_NAME = 'coaches';

/**
 * @method getAllCoaches
 * @summary get all coaches for projects page
 * @returns {Promise<Array>} coaches array or empty array
 */
/* eslint-disable import/prefer-default-export */
export const getAllCoaches = async () => {
  try {
    const coachesRef = collection(db, COLLECTION_NAME);
    const coachesSnap = await getDocs(query(coachesRef));
    if (coachesSnap.empty) {
      return [];
    }
    const coaches = coachesSnap.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return coaches;
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function useGetAllCoaches
 * @return {import('@tanstack/react-query').DefinedUseQueryResult<Product[], unknown>}
 */
export const useGetAllCoaches = () => {
  return useQuery({
    queryKey: ['coaches'],
    queryFn: getAllCoaches,
    initialData: [],
  });
};

/**
 * @function getCoachByRef
 * @description - fetch coach by ref and return his firstname and lastname
 * @param {*} ref - reference of coach to db
 * @returns {Promise<Object>} - coach firstname and lastname
 */
export const getCoachByRef = async (ref) => {
  try {
    const coachSnap = await getDoc(ref);
    if (coachSnap.exists()) {
      const coach = coachSnap.data();
      return {
        firstname: coach.firstname,
        lastname: coach.lastname,
      };
    }
    return { firstname: '', lastname: '' };
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function getCoachesByRef
 * @description - fetch coaches by ref and return a list of their firstname and lastname
 * @param {Array} coaches - array of coaches ref
 * @returns {Promise<Array>} List of coaches, that consists of their firstname and lastname
 */
export const getCoachesByRef = async (coaches) => {
  try {
    return await Promise.all(
      coaches.map(async (coachRef) => {
        const coachSnap = await getDoc(coachRef);
        if (!coachSnap.exists()) {
          return {
            firstname: '',
            lastname: '',
          };
        }
        return { ...coachSnap.data(), id: coachSnap.id };
      }),
    );
  } catch (err) {
    Sentry.captureException(err);
  }
};
