import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

/**
 * This is a simple form text input element.
 *
 * It currently uses react-bootstrap which is outdated and should be replaced with MUI.
 */
const InputText = ({
  name,
  label,
  onChange,
  value,
  isInvalid = false,
  isDisabled = false,
  errorMessage = '',
  isRequired = false,
}) => {
  return (
    <>
      {label && (
        <Form.Label>
          {label}
          {isRequired ? ' *' : ''}
        </Form.Label>
      )}
      <Form.Control
        name={name}
        as="input"
        onChange={onChange}
        value={value}
        isInvalid={isInvalid}
        disabled={isDisabled}
      />
      {errorMessage && (
        <Typography variant="formError">{errorMessage}</Typography>
      )}
    </>
  );
};

InputText.propTypes = {
  /**
   * The function that is called when the value changes.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The name of the component.
   */
  name: PropTypes.string.isRequired,

  /**
   * The label of the component.
   */
  label: PropTypes.string,

  /**
   * The value of the component.
   */
  value: PropTypes.string.isRequired,

  /**
   * Whether the component should display an invalid state.
   */
  isInvalid: PropTypes.bool,

  /**
   * Whether the component is disabled or not.
   */
  isDisabled: PropTypes.bool,

  /**
   * If set, the component will display an error message.
   */
  errorMessage: PropTypes.string,

  /**
   * Is the component required in the form it is implemented in?
   */
  isRequired: PropTypes.bool,
};

export default InputText;
