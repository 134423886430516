import PageContainer from '../../components/PageContainer';
import { Title, Headline, Text, UL } from './components';

const PrivacyPolicyDe = () => {
  return (
    <PageContainer>
      <Title text="Datenschutz" />

      <Headline>1. Einleitung</Headline>
      <Text>
        Wir bei fgi Fischer Group International nehmen den Schutz Ihrer
        persönlichen Daten sehr ernst. Diese Datenschutzrichtlinie erklärt, wie
        wir Ihre persönlichen Daten erheben, verwenden, speichern und schützen.
      </Text>

      <Headline>2. Verantwortliche Stelle</Headline>
      <Text>
        Verantwortlich für die Datenverarbeitung ist: fgi Fischer Group
        International GmbH
        <br />
        Brodschrangen 3-5
        <br />
        20457 Hamburg
        <br />
        Germany
        <br />
        E-Mail: digital.solutions@fgi-mail.com
        <br />
        Internet:{' '}
        <a href="http://www.fgi-digitalsolutions.de">
          www.fgi-digitalsolutions.de
        </a>
      </Text>

      <Headline>3. Datenschutzerklärung</Headline>
      <Text>
        Personenbezogene Daten werden auf dieser Webseite nur im technisch
        notwendigen Umfang erhoben. In keinem Fall werden die erhobenen Daten
        verkauft. Die nachfolgende Erklärung gibt einen Überblick darüber, wie
        fgi Fischer Group International GmbH, nachfolgend fgi genannt, diesen
        Schutz gewährleistet und welche Art von Daten zu welchem Zweck erhoben
        werden.
      </Text>

      <Headline>4. Erhebung und Nutzung von Daten</Headline>
      <Headline>4.1. Welche Daten wir erheben</Headline>
      <Text>
        Wir erheben verschiedene Arten von personenbezogenen Daten, die für die
        Bereitstellung unserer Dienstleistungen notwendig sind. Dazu gehören
        insbesondere:
        <UL
          items={[
            <>
              <strong>Nutzerinformationen:</strong> Vorname, Nachname,
              E-Mail-Adresse, Geburtsdatum, Nationalität, Standort, IP-Adresse,
              bevorzugte Sprache in Projekten.
            </>,
            <>
              <strong>Coach-Informationen:</strong> Vorname, Nachname,
              E-Mail-Adresse, Standort, Telefonnummer, Sprachen, Foto,
              Biografie.
            </>,
            <>
              <strong>Projektbezogene Daten:</strong> Kategorie, Produkt,
              Kostenstelle, Business Situation, Coachinganliegen/Thema,
              Führungssituation, Führungsebene, Anschlussmaßnahmen,
              Teilnehmerzahl, Datum, Ort, Sprache, Erwartungen, Anmerkungen.
            </>,
            <>
              <strong>E-Learnings:</strong> Datum des ersten Starts eines
              Moduls, Fortschritt innerhalb des Moduls, gespeicherte Daten des
              Nutzers, Ergebnis/Erfolg/Abschluss eines Moduls.
            </>,
          ]}
        />
      </Text>

      <Headline>5. Rechtsgrundlage der Verarbeitung</Headline>
      <Text>
        Die Verarbeitung Ihrer Daten erfolgt auf der Grundlage folgender
        Rechtsgrundlagen:
        <UL
          items={[
            'Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)',
            'Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO)',
            'Erfüllung rechtlicher Verpflichtungen (Art. 6 Abs. 1 lit. c DSGVO)',
            'Berechtigte Interessen (Art. 6 Abs. 1 lit. f DSGVO)',
          ]}
        />
      </Text>

      <Headline>6. Weitergabe von Daten</Headline>
      <Headline>6.1. Dienstleister</Headline>
      <Text>
        Wir geben Ihre persönlichen Daten nur in den folgenden Fällen weiter:
        <UL
          items={[
            'Mit Ihrer Einwilligung',
            <>
              An Dienstleister, die zur Erbringung unserer Dienstleistung
              notwendig sind. Diese umfassen:
              <UL
                items={[
                  <>
                    <strong>Google Cloud EMEA Limited</strong>
                    <br />
                    Website:{' '}
                    <a href="https://cloud.google.com/">
                      https://cloud.google.com/
                    </a>
                    <br />
                    Datenschutz:{' '}
                    <a href="https://policies.google.com/privacy">
                      https://policies.google.com/privacy
                    </a>
                    <br />
                    Zweck: IT-Hosting und -Infrastruktur
                    <br />
                    Notwendig für Betrieb: Ja
                    <br />
                    Datenschutz: Unterliegt dem europäischen Datenschutzrecht
                    (DSGVO)
                  </>,
                  <>
                    <strong>Rustici Software LLC</strong>
                    <br />
                    Website: <a href="https://scorm.com/">https://scorm.com/</a>
                    <br />
                    Datenschutz:{' '}
                    <a href="https://ltgplc.com/privacy-notice/">
                      https://ltgplc.com/privacy-notice/
                    </a>
                    <br />
                    Zweck: Bereitstellung von E-Learning-Infrastruktur
                    <br />
                    Notwendig für Betrieb: Ja
                    <br />
                    Datenschutz: Unterliegt dem europäischen Datenschutzrecht
                    (DSGVO)
                  </>,
                  <>
                    <strong>Mailgun Technologies, Inc.</strong>
                    <br />
                    Website:{' '}
                    <a href="https://www.mailgun.com/">
                      https://www.mailgun.com/
                    </a>
                    <br />
                    Datenschutz:{' '}
                    <a href="https://www.mailgun.com/gdpr/">
                      https://www.mailgun.com/gdpr/
                    </a>
                    <br />
                    Zweck: Versenden von E-Mails
                    <br />
                    Notwendig für Betrieb: Ja
                    <br />
                    Datenschutz: Unterliegt dem europäischen Datenschutzrecht
                    (DSGVO)
                  </>,
                  <>
                    <strong>Amplitude, Inc.</strong>
                    <br />
                    Website:{' '}
                    <a href="https://amplitude.com/">https://amplitude.com/</a>
                    <br />
                    Datenschutz:{' '}
                    <a href="https://amplitude.com/privacy">
                      https://amplitude.com/privacy
                    </a>
                    <br />
                    Zweck: Tracking von Nutzerverhalten
                    <br />
                    Notwendig für Betrieb: Nein
                    <br />
                    Datenschutz: Unterliegt dem europäischen Datenschutzrecht
                    (DSGVO)
                  </>,
                ]}
              />
            </>,
            'Wenn es gesetzlich vorgeschrieben ist oder zur Durchsetzung unserer Nutzungsbedingungen.',
          ]}
        />
      </Text>

      <Headline>7. Datenübermittlung in Drittländer</Headline>
      <Text>
        Eine Übermittlung Ihrer Daten in Drittländer (außerhalb der EU/des EWR)
        findet nur statt, wenn dies gesetzlich erlaubt ist oder Sie in die
        Datenübermittlung eingewilligt haben. Dabei stellen wir sicher, dass die
        jeweiligen Dienstleister den europäischen Datenschutzstandards
        unterliegen.
      </Text>

      <Headline>8. Speicherung und Schutz von Daten</Headline>
      <Headline>8.1. Datenspeicherung</Headline>
      <Text>
        Wir speichern Ihre persönlichen Daten nur so lange, wie es für die
        Zwecke, für die sie erhoben wurden, erforderlich ist oder wie es
        gesetzlich vorgeschrieben ist. Im Allgemeinen werden die Daten bei
        Beendigung der Zusammenarbeit gelöscht, es sei denn, es bestehen
        gesetzliche Aufbewahrungspflichten.
      </Text>

      <Headline>8.2. Datensicherheit</Headline>
      <Text>
        Wir setzen angemessene technische und organisatorische Maßnahmen ein, um
        Ihre persönlichen Daten vor unbefugtem Zugriff, Verlust oder Zerstörung
        zu schützen.
      </Text>

      <Headline>9. Ihre Rechte</Headline>
      <Text>
        Sie haben das Recht:
        <UL
          items={[
            'Auskunft über Ihre bei uns gespeicherten Daten zu verlangen',
            'Berichtigung unrichtiger Daten zu verlangen',
            'Löschung Ihrer Daten zu verlangen, sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen',
            'Die Einschränkung der Verarbeitung Ihrer Daten zu verlangen',
            'Der Verarbeitung Ihrer Daten zu widersprechen',
            'Datenübertragbarkeit zu verlangen',
          ]}
        />
      </Text>

      <Headline>9.1. Auskunftsrecht</Headline>
      <Text>
        Besucher haben jederzeit das Recht auf Auskunft über die bezüglich ihrer
        Person gespeicherten Daten, deren Herkunft und Empfänger sowie den Zweck
        der Datenverarbeitung. Die Anfrage zur Auskunftserteilung ist an unsere
        im Impressum der Webseite angegebene Adresse zu richten oder kann an
        folgende Adressen gerichtet werden:
        <UL
          items={[
            'Mail: digital.solutions@fgi-mail.com',
            'Fon: +49 (0)40 65 68 90 0',
            'Fax: +49 (0)40 65 68 90 80',
          ]}
        />
      </Text>

      <Headline>9.2. Löschung</Headline>
      <Text>
        Die Löschung der gespeicherten personenbezogenen Daten erfolgt, wenn der
        Besucher der Anwendung die Einwilligung zur Speicherung widerruft, wenn
        ihre Kenntnis zur Erfüllung des mit der Speicherung verfolgten Zwecks
        nicht mehr erforderlich ist oder wenn ihre Speicherung aus sonstigen
        gesetzlichen Gründen unzulässig ist. Daten für Abrechnungszwecke und
        buchhalterische Zwecke werden von einem Löschungsverlangen nicht
        berührt.
      </Text>

      <Headline>10. Haftungshinweis</Headline>
      <Text>
        Alle Inhalte auf unserer Website haben wir sorgfältig zusammengestellt
        und geprüft. Trotz aller Sorgfalt kann eine Haftung oder Garantie für
        die Richtigkeit, Vollständigkeit und Aktualität der Daten nicht
        übernommen werden. Dies gilt auch für alle anderen Websites, auf die mit
        Hyperlinks verwiesen wird. Wir sind für den Inhalt der Websites, die
        aufgrund einer solchen Verbindung erreicht werden, nicht verantwortlich.
        Wir behalten uns das Recht vor, Aktualisierungen, Änderungen oder
        Ergänzungen an den bereitgestellten Informationen und Daten vorzunehmen.
      </Text>

      <Headline>11. Änderungen dieser Datenschutzrichtlinie</Headline>
      <Text>
        Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren.
        Die aktuelle Version wird immer auf unserer Website verfügbar sein.
        Wesentliche Änderungen werden wir Ihnen per E-Mail mitteilen.
      </Text>

      <Headline>12. Kontakt</Headline>
      <Text>
        Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben oder Ihre Rechte
        ausüben möchten, kontaktieren Sie uns bitte unter:
        <br />
        fgi Fischer Group International GmbH
        <br />
        Brodschrangen 3-5
        <br />
        20457 Hamburg
        <br />
        Germany
        <br />
        E-Mail: digital.solutions@fgi-mail.com
        <br />
        Internet:{' '}
        <a href="http://www.fgi-digitalsolutions.de">
          www.fgi-digitalsolutions.de
        </a>
      </Text>
    </PageContainer>
  );
};

export default PrivacyPolicyDe;
