import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import OkSVG from '../../assets/svgs/ok';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import ProjectTypes from '../../constants/ProjectTypes';

export const CoachModeratorBuchenSuccessModal = ({
  show,
  onHide,
  coach,
  project,
}) => {
  const [t] = useTranslation();

  const getColorByProjectType = (type) => {
    switch (type) {
      case ProjectTypes.MODERATOR:
        return 'green';
      case ProjectTypes.COACH:
        return 'yellow';
      default:
        return '';
    }
  };

  const onCloseModal = () => {
    onHide();
  };

  const description = (
    <>
      <div className="small-modal__ok-icon">
        <OkSVG
          color={
            project.type === ProjectTypes.MODERATOR ? '#89C959' : '#F4DA55'
          }
        />
      </div>
      <div>
        {t('Sie erhalten in Kürze eine Bestätigung ihrer Buchung per E-Mail.')}
      </div>
      <br />
      <div>
        <b>
          {coach.firstname} {coach.lastname}
        </b>{' '}
        {t('wird Sie für die weiteren Schritte bald kontaktieren.')}
      </div>
    </>
  );

  return (
    <FgiModal
      title="Buchung erfolgreich"
      description={description}
      onHide={onCloseModal}
      show={show}
      color={getColorByProjectType(project.type)}
      showCloseButton={false}
      className="small-modal"
    >
      <div className="modal-footer-button">
        <Button
          size="medium"
          onClick={onCloseModal}
          variant={getColorByProjectType(project.type)}
        >
          {t('Fertig')}
        </Button>
      </div>
    </FgiModal>
  );
};

CoachModeratorBuchenSuccessModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  coach: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

export default CoachModeratorBuchenSuccessModal;
