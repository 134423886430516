import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  TableHead,
  TableBody,
  Table,
  TableCell,
  TableRow,
  Box,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../components/Button';
import PagePath from '../../../../constants/PagePath';
import { useGlobalState } from '../../../../hooks';
import { getLink } from '../../../../utils';

const AdminTrainingsRow = ({ workshop }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [{ locale, company }] = useGlobalState();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleEditTraining = () => {
    navigate(getLink(company, `${PagePath.ADMIN_TRAININGS}/${workshop.id}`));
  };
  const navigateToParticipants = (id) => () => {
    navigate(
      getLink(company, `${PagePath.ADMIN_TRAININGS_PARTICIPANTS}/${id}`),
    );
  };

  const formatDate = (d) => {
    const minutes = d.getMinutes();
    return `${d.getDate()}.${
      d.getMonth() + 1
    }.${d.getFullYear()} ${d.getHours()}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
  };

  const tableBody = (workshop) => [
    workshop.title[locale],
    `${workshop.events.length} ${t('Termine')}`,
    `${workshop.noticePeriodInDays} ${t('Tage')}`,
    `${t('Maximal')} ${workshop.maxParticipants} `,
  ];

  const tableEventHeads = [t('Start'), t('Ende'), t('Ort'), t('Teilnehmer')];

  const tableEventBody = (event) => [
    {
      width: '25%',
      label: `${formatDate(event.start.toDate())} ${t('Uhr')}`,
    },
    {
      width: '25%',
      label: `${formatDate(event.end.toDate())} ${t('Uhr')}`,
    },
    {
      width: '15%',
      label: event.location,
    },
    {
      width: '10%',
      label: `${event.numBookings}/${workshop.maxParticipants}`,
    },
  ];

  return (
    <React.Fragment>
      <TableRow sx={{ height: '100px', '>td': { padding: '16px 0' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography
            sx={{ color: 'primary.main' }}
            variant="primaryTextMedium"
          >
            {workshop.company.name}
          </Typography>
        </TableCell>
        {tableBody(workshop).map((body, index) => (
          <TableCell key={index}>
            <Typography variant="primaryText">{body}</Typography>
          </TableCell>
        ))}
        <TableCell align="right">
          <Button onClick={handleEditTraining} size="medium" variant="main">
            {t('Bearbeiten')}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: '0px 5%' }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '16px 0' }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ '>th': { padding: '4px 0' } }}>
                    {tableEventHeads.map((head, index) => (
                      <TableCell key={index}>
                        <Typography
                          sx={{ color: 'primary.main' }}
                          variant="secondaryText"
                        >
                          {head}
                        </Typography>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workshop.events.map((event) => (
                    <TableRow
                      key={event.id}
                      sx={{ '>td': { padding: '4px 0' } }}
                    >
                      {tableEventBody(event).map((body, index) => (
                        <TableCell width={body.width} key={index}>
                          <Typography
                            sx={{ color: 'primary.main' }}
                            variant="secondaryText"
                          >
                            {body.label}
                          </Typography>
                        </TableCell>
                      ))}
                      <TableCell
                        width="25%"
                        align="right"
                        sx={{ paddingRight: 0 }}
                      >
                        <Button
                          size="medium"
                          variant="light"
                          onClick={navigateToParticipants(event.id)}
                        >
                          {t('Teilnehmer')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default AdminTrainingsRow;
