import * as amplitude from '@amplitude/analytics-browser';

const IS_DEBUG = process.env.NODE_ENV === 'development' ? true : false;

/**
 * @method initAmplitude
 * @param {string} userId - user id
 * @summary init amplitude app
 * @returns nothing
 */
export const initAmplitude = (userId) => {
  if (IS_DEBUG) {
    // eslint-disable-next-line no-console
    console.log(
      "initAmplitude: Data wasn't passed to amplitude because we're in development.",
      { userId },
    );
  } else {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, userId);
  }
};

/**
 * @method setAmplitudeUserId
 * @param {string} userId - user id
 * @summary send id of user to amplitude
 * @returns nothing
 */
export const setAmplitudeUserId = (userId) => {
  if (IS_DEBUG) {
    // eslint-disable-next-line no-console
    console.log(
      "setAmplitudeUserId: Data wasn't passed to amplitude because we're in development.",
      { userId },
    );
  } else {
    amplitude.setUserId(userId);
  }
};

/**
 * @method setAmplitudeUserProperties
 * @param {object} properties - company name and user role
 * @summary send user role and company to amplitude
 * @returns nothing
 */
export const setAmplitudeUserProperties = (properties) => {
  if (IS_DEBUG) {
    // eslint-disable-next-line no-console
    console.log(
      "setAmplitudeUserProperties: Data wasn't passed to amplitude because we're in development.",
      { properties },
    );
  } else {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set(properties);
    amplitude.identify(identifyEvent);
  }
};

/**
 * @method sendAmplitudeData
 * @param {string} eventType - type of event
 * @param {object} eventProperties - event properties
 * @summary send to amplitude data about users events and events properties
 * @returns void
 */
export const sendAmplitudeData = (eventType, eventProperties) => {
  if (IS_DEBUG) {
    // eslint-disable-next-line no-console
    console.log(
      "sendAmplitudeData: Data wasn't passed to amplitude because we're in development.",
      { eventType, eventProperties },
    );
  } else {
    amplitude.track(eventType, eventProperties);
  }
};
