import * as yup from 'yup';

const ProfileSecondaryDataUpdateModalSchema = yup.object({
  date_of_birth: yup.string(),
  nationality: yup.string(),
  language: yup.string(),
  location: yup.string(),
});

export default ProfileSecondaryDataUpdateModalSchema;
