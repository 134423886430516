import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import Footer from '../components/Footer';
import Header from '../components/Header';

const MainLayout = ({ children }) => (
  <>
    <Header />
    <Container className="container-with-margins">{children}</Container>
    <Footer />
  </>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
