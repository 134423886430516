import * as yup from 'yup';

const ResetPasswordModalSchema = (t) =>
  yup.object({
    email: yup
      .string()
      .email(t('Ihr Unternehmen wurde noch nicht freigeschaltet'))
      .required(t('Bitte eine E-Mail-Adresse angeben')),
  });

export default ResetPasswordModalSchema;
