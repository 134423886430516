import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import emailIcon from '../../assets/images/Mail@3x.png';
import ButtonResendConfirmationEmail from '../../components/ButtonResendConfirmationEmail/ButtonResendConfirmationEmail';
import FgiModal from '../../components/FgiModal';
import './Confirm.scss';

const Confirm = ({ show, onHide, email }) => {
  const [t] = useTranslation();

  return (
    <FgiModal
      id="confirmModalbox"
      title={t('E-Mail bestätigen')}
      onHide={onHide}
      show={show}
      showCloseButton={false}
    >
      <>
        <img src={emailIcon} alt="email" className="emailLogo" />

        <div className="confirmEmailWrapper">
          <p>
            {t(
              'Sollte die E-Mail in 5 Minuten nicht eingetroffen sein, dann klicken Sie bitte hier:',
            )}
          </p>
          <p>
            {t(
              'Wir haben Ihnen eine E-Mail an {{email}} gesendet. Bitte klicken Sie auf den Link in dieser E-Mail.',
              { email },
            )}
          </p>
        </div>
        <ButtonResendConfirmationEmail email={email} />
      </>
    </FgiModal>
  );
};

Confirm.propTypes = {
  email: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default Confirm;
