import PageContainer from '../../components/PageContainer';
import { Title, Headline, Text, UL } from './components';

const PrivacyPolicyFr = () => {
  return (
    <PageContainer>
      <Title text="Politique de Confidentialité" />

      <Headline>1. Introduction</Headline>
      <Text>
        Chez fgi Fischer Group International, nous prenons la protection de vos
        données personnelles très au sérieux. Cette politique de confidentialité
        explique comment nous collectons, utilisons, stockons et protégeons vos
        données personnelles.
      </Text>

      <Headline>2. Responsable du traitement</Headline>
      <Text>
        Le responsable du traitement des données est : fgi Fischer Group
        International GmbH
        <br />
        Brodschrangen 3-5
        <br />
        20457 Hambourg
        <br />
        Allemagne
        <br />
        E-mail : digital.solutions@fgi-mail.com
        <br />
        Internet :{' '}
        <a href="http://www.fgi-digitalsolutions.de">
          www.fgi-digitalsolutions.de
        </a>
      </Text>

      <Headline>3. Déclaration de Confidentialité</Headline>
      <Text>
        Les données personnelles sont collectées sur ce site web uniquement dans
        la mesure nécessaire d'un point de vue technique. En aucun cas, les
        données collectées ne seront vendues. La déclaration suivante donne un
        aperçu de la manière dont fgi Fischer Group International GmbH, ci-après
        dénommée fgi, garantit cette protection et du type de données collectées
        ainsi que de l'objectif de cette collecte.
      </Text>

      <Headline>4. Collecte et utilisation des données</Headline>
      <Headline>4.1. Quelles données nous collectons</Headline>
      <Text>
        Nous collectons différents types de données personnelles nécessaires à
        la fourniture de nos services. Cela inclut notamment :
        <UL
          items={[
            <>
              <strong>Informations utilisateur :</strong> Prénom, nom, adresse
              e-mail, date de naissance, nationalité, localisation, adresse IP,
              langue préférée dans les projets.
            </>,
            <>
              <strong>Informations sur les coachs :</strong> Prénom, nom,
              adresse e-mail, localisation, numéro de téléphone, langues, photo,
              biographie.
            </>,
            <>
              <strong>Données relatives aux projets :</strong> Catégorie,
              produit, centre de coût, situation professionnelle, sujet/objectif
              du coaching, situation de leadership, niveau de leadership,
              mesures de suivi, nombre de participants, date, lieu, langue,
              attentes, remarques.
            </>,
            <>
              <strong>E-Learning :</strong> Date de la première ouverture d'un
              module, progression dans le module, données enregistrées par
              l'utilisateur, résultat/succès/achèvement d'un module.
            </>,
          ]}
        />
      </Text>

      <Headline>5. Base légale du traitement</Headline>
      <Text>
        Le traitement de vos données est basé sur les fondements juridiques
        suivants :
        <UL
          items={[
            'Consentement (art. 6, par. 1, let. a du RGPD)',
            'Exécution du contrat (art. 6, par. 1, let. b du RGPD)',
            'Respect des obligations légales (art. 6, par. 1, let. c du RGPD)',
            'Intérêts légitimes (art. 6, par. 1, let. f du RGPD)',
          ]}
        />
      </Text>

      <Headline>6. Transmission des données</Headline>
      <Headline>6.1. Prestataires de services</Headline>
      <Text>
        Nous ne partageons vos données personnelles que dans les cas suivants :
        <UL
          items={[
            'Avec votre consentement',
            <>
              Aux prestataires de services nécessaires à la fourniture de nos
              services. Ceux-ci comprennent :
              <UL
                items={[
                  <>
                    <strong>Google Cloud EMEA Limited</strong>
                    <br />
                    Site web :{' '}
                    <a href="https://cloud.google.com/">
                      https://cloud.google.com/
                    </a>
                    <br />
                    Politique de confidentialité :{' '}
                    <a href="https://policies.google.com/privacy">
                      https://policies.google.com/privacy
                    </a>
                    <br />
                    Objectif : Hébergement et infrastructure IT
                    <br />
                    Nécessaire au fonctionnement : Oui
                    <br />
                    Confidentialité : Conforme au droit européen de la
                    protection des données (RGPD)
                  </>,
                  <>
                    <strong>Rustici Software LLC</strong>
                    <br />
                    Site web :{' '}
                    <a href="https://scorm.com/">https://scorm.com/</a>
                    <br />
                    Politique de confidentialité :{' '}
                    <a href="https://ltgplc.com/privacy-notice/">
                      https://ltgplc.com/privacy-notice/
                    </a>
                    <br />
                    Objectif : Fourniture de l'infrastructure d'E-Learning
                    <br />
                    Nécessaire au fonctionnement : Oui
                    <br />
                    Confidentialité : Conforme au droit européen de la
                    protection des données (RGPD)
                  </>,
                  <>
                    <strong>Mailgun Technologies, Inc.</strong>
                    <br />
                    Site web :{' '}
                    <a href="https://www.mailgun.com/">
                      https://www.mailgun.com/
                    </a>
                    <br />
                    Politique de confidentialité :{' '}
                    <a href="https://www.mailgun.com/gdpr/">
                      https://www.mailgun.com/gdpr/
                    </a>
                    <br />
                    Objectif : Envoi d'e-mails
                    <br />
                    Nécessaire au fonctionnement : Oui
                    <br />
                    Confidentialité : Conforme au droit européen de la
                    protection des données (RGPD)
                  </>,
                  <>
                    <strong>Amplitude, Inc.</strong>
                    <br />
                    Site web :{' '}
                    <a href="https://amplitude.com/">https://amplitude.com/</a>
                    <br />
                    Politique de confidentialité :{' '}
                    <a href="https://amplitude.com/privacy">
                      https://amplitude.com/privacy
                    </a>
                    <br />
                    Objectif : Suivi du comportement des utilisateurs
                    <br />
                    Nécessaire au fonctionnement : Non
                    <br />
                    Confidentialité : Conforme au droit européen de la
                    protection des données (RGPD)
                  </>,
                ]}
              />
            </>,
            'Lorsque cela est requis par la loi ou pour faire respecter nos conditions d’utilisation.',
          ]}
        />
      </Text>

      <Headline>7. Transfert de données vers des pays tiers</Headline>
      <Text>
        Un transfert de vos données vers des pays tiers (en dehors de l'UE/EEE)
        n'a lieu que si cela est légalement permis ou si vous avez consenti au
        transfert de données. Nous veillons à ce que les prestataires de
        services respectifs soient soumis aux normes européennes de protection
        des données.
      </Text>

      <Headline>8. Stockage et protection des données</Headline>
      <Headline>8.1. Stockage des données</Headline>
      <Text>
        Nous conservons vos données personnelles uniquement aussi longtemps que
        nécessaire pour les fins pour lesquelles elles ont été collectées ou
        conformément aux obligations légales. En général, les données sont
        supprimées à la fin de la collaboration, sauf s'il existe des
        obligations légales de conservation.
      </Text>

      <Headline>8.2. Sécurité des données</Headline>
      <Text>
        Nous mettons en œuvre des mesures techniques et organisationnelles
        appropriées pour protéger vos données personnelles contre tout accès non
        autorisé, perte ou destruction.
      </Text>

      <Headline>9. Vos droits</Headline>
      <Text>
        Vous avez le droit de :
        <UL
          items={[
            'Demander des informations sur les données vous concernant que nous avons stockées',
            'Demander la correction des données inexactes',
            "Demander la suppression de vos données, sauf s'il existe des obligations légales de conservation",
            'Demander la limitation du traitement de vos données',
            'Vous opposer au traitement de vos données',
            'Demander la portabilité des données',
          ]}
        />
      </Text>

      <Headline>9.1. Droit d'accès</Headline>
      <Text>
        Les visiteurs ont le droit à tout moment de demander des informations
        sur les données les concernant que nous avons stockées, leur origine et
        leur destinataire, ainsi que l'objectif du traitement des données. La
        demande d'informations doit être adressée à l'adresse indiquée dans les
        mentions légales du site ou peut être envoyée aux adresses suivantes :
        <UL
          items={[
            'Mail : digital.solutions@fgi-mail.com',
            'Téléphone : +49 (0)40 65 68 90 0',
            'Fax : +49 (0)40 65 68 90 80',
          ]}
        />
      </Text>

      <Headline>9.2. Suppression</Headline>
      <Text>
        La suppression des données personnelles enregistrées a lieu lorsque le
        visiteur de l'application révoque son consentement à la conservation,
        lorsque la connaissance de ces données n'est plus nécessaire à la
        réalisation de l'objectif poursuivi par la conservation ou lorsque leur
        conservation est interdite pour d'autres raisons légales. Les données
        destinées à des fins de facturation et de comptabilité ne sont pas
        affectées par une demande de suppression.
      </Text>

      <Headline>10. Clause de non-responsabilité</Headline>
      <Text>
        Tous les contenus de notre site web ont été soigneusement compilés et
        vérifiés. Malgré tout le soin apporté, aucune responsabilité ou garantie
        ne peut être assumée quant à l'exactitude, l'exhaustivité et l'actualité
        des informations. Cela s'applique également à tous les autres sites web
        auxquels il est fait référence par des hyperliens. Nous ne sommes pas
        responsables du contenu des sites web accessibles par ce type de lien.
        Nous nous réservons le droit d'apporter des modifications, mises à jour
        ou ajouts aux informations et données fournies.
      </Text>

      <Headline>
        11. Modifications de cette politique de confidentialité
      </Headline>
      <Text>
        Nous pouvons mettre à jour cette politique de confidentialité de temps
        en temps. La version actuelle sera toujours disponible sur notre site
        web. Nous vous informerons par e-mail des modifications importantes.
      </Text>

      <Headline>12. Contact</Headline>
      <Text>
        Si vous avez des questions concernant cette politique de confidentialité
        ou si vous souhaitez exercer vos droits, veuillez nous contacter à
        l'adresse suivante :
        <br />
        fgi Fischer Group International GmbH
        <br />
        Brodschrangen 3-5
        <br />
        20457 Hambourg
        <br />
        Allemagne
        <br />
        E-mail : digital.solutions@fgi-mail.com
        <br />
        Internet :{' '}
        <a href="http://www.fgi-digitalsolutions.de">
          www.fgi-digitalsolutions.de
        </a>
      </Text>
    </PageContainer>
  );
};

export default PrivacyPolicyFr;
