import { Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  deleteProductById,
  deleteProductImage,
  deleteProductPdf,
  useGetAllProducts,
  hasMissingTranslations,
} from '../../api';
import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks';
import DeleteProductModal from '../../modals/DeleteProductModal';
import { getLink } from '../../utils';
import './AdminProducts.scss';

const AdminProducts = () => {
  const navigate = useNavigate();

  const [{ company }] = useGlobalState();
  const { data: products, refetch: refetchProducts } = useGetAllProducts();
  const [removableProduct, setRemovableProduct] = useState({});
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);

  const redirectToNewProduct = () => {
    navigate(getLink(company, PagePath.ADMIN_PRODUCTS_NEW));
  };

  const editProduct = (id) => () => {
    const url = `${PagePath.ADMIN_PRODUCTS}/${id}`;
    navigate(getLink(company, url));
  };

  const onDeleteProductClick = (product) => () => {
    setRemovableProduct(product);
    setShowDeleteProductModal(true);
  };

  const closeDeleteProductModal = () => {
    setRemovableProduct({});
    setShowDeleteProductModal(false);
  };

  const deleteProduct = async () => {
    await Promise.all([
      deleteProductById(removableProduct.id),
      deleteProductImage(removableProduct.image),
      ...Object.values(removableProduct.pdfUrl).map((pdfUrl) =>
        deleteProductPdf(pdfUrl),
      ),
    ]);
    await refetchProducts();
    closeDeleteProductModal();
  };

  return (
    <AdminLayout>
      <div className="admin-products-title-wrapper">
        <Title text="Produkte" color="green" width="13%" />
        <Button
          className="admin-products-title-wrapper-button"
          variant="light"
          onClick={redirectToNewProduct}
          size="small"
        >
          Hinzufügen
        </Button>
      </div>
      <div className="main-container">
        <div className="admin-products-products-list">
          <table className="admin-products-table">
            <thead className="secondary-text">
              <tr className="admin-products-table-head-row">
                <th width="25%">Name</th>
                <th width="30%">Typ</th>
                <th width="15%" />
                <th width="15%" />
                <th width="15%" />
              </tr>
            </thead>
            <tbody className="primary-text">
              {products.map((product) => (
                <tr key={product.id} className="admin-products-table-body-row">
                  <td>{product.title['de']}</td>
                  <td>{product.type} </td>
                  <td>
                    {hasMissingTranslations(product) && (
                      <Typography variant="formError">
                        Fehlende Übersetzungen
                      </Typography>
                    )}
                  </td>
                  <td>
                    <Button
                      onClick={onDeleteProductClick(product)}
                      arrow={false}
                      size="small"
                      variant="light"
                    >
                      Entfernen
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={editProduct(product.id)}
                      size="medium"
                      variant="main"
                    >
                      Bearbeiten
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <DeleteProductModal
        productName={removableProduct.title?.['de']}
        deleteProduct={deleteProduct}
        onHide={closeDeleteProductModal}
        show={showDeleteProductModal}
      />
    </AdminLayout>
  );
};

export default AdminProducts;
