import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../../../amplitude';
import bigBackground from '../../../../assets/images/moderator-finder-background.png';
import smallBackground from '../../../../assets/images/moderator-finder-small-background.png';
import Button from '../../../../components/Button';
import Hero from '../../../../components/Hero';
import Teaser from '../../../../components/Teaser';
import ProjectTypes from '../../../../constants/ProjectTypes';
import ModeratorFindenModal from '../../../../modals/ModeratorFindenModal';
import './TopItem.scss';

export const TopItem = ({
  moderatorFindenItems,
  openFosterCollaborationModal,
}) => {
  const [moderatorModalShow, setModeratorModalShow] = useState(false);
  const [t] = useTranslation();

  const openModeratorModalShow = () => {
    sendAmplitudeData('New Project – Start', { type: ProjectTypes.MODERATOR });
    setModeratorModalShow(true);
  };

  const closeModeratorModal = () => {
    setModeratorModalShow(false);
  };

  const onCategoryClick = (category) => () => {
    setModeratorModalShow(false);
    openFosterCollaborationModal(category)();
  };

  return (
    <div>
      <Hero
        paddingBottom="45px"
        bigBackground={bigBackground}
        smallBackground={smallBackground}
      >
        <Teaser
          type="green"
          title={t('Workshops unterstützen Sie bei strategischen Fragestellungen')} // prettier-ignore
          description={t('Arbeiten Sie mit erfahrenen Workshop-Moderatoren zielgerichtet an strategischen Themen')} // prettier-ignore
        />
        <div className="moderator-finder-top-item-button-wrapper">
          <Button onClick={openModeratorModalShow} size="big" variant="green">
            {t('Finden Sie Ihren Moderator')}
          </Button>
        </div>
      </Hero>
      <ModeratorFindenModal
        moderatorFindenItems={moderatorFindenItems}
        onHide={closeModeratorModal}
        show={moderatorModalShow}
        onCategoryClick={onCategoryClick}
      />
    </div>
  );
};

TopItem.propTypes = {
  openFosterCollaborationModal: PropTypes.func.isRequired,
  moderatorFindenItems: PropTypes.array.isRequired,
};

export default TopItem;
