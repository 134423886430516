import * as yup from 'yup';

export const NewCoachingFormSchema = (t) =>
  yup.object({
    business_situation: yup
      .string()
      .required(t('Bitte wählen Sie eine Businesssituation')),
    coaching_topic: yup
      .string()
      .required(t('Bitte wählen Sie eine Coachinganliegen/Thema')),
    leadership_level: yup
      .string()
      .required(t('Bitte wählen Sie eine Führungsebene')),
    follow_on_action: yup
      .string()
      .required(t('Bitte wählen SIe eine Anschlussmaßnahme')),
    location: yup
      .string()
      .required(t('Bitte geben Sie den Ort des Coachings an')),
    expectations: yup
      .string()
      .required(t('Bitte geben Sie Ihre Erwartungen an')),
    comments: yup.string(),
  });

export const NewCoachingFormSchemaWithoutLocation = (t) =>
  yup.object({
    business_situation: yup
      .string()
      .required(t('Bitte wählen Sie eine Businesssituation')),
    coaching_topic: yup
      .string()
      .required(t('Bitte wählen Sie eine Coachinganliegen/Thema')),
    leadership_level: yup
      .string()
      .required(t('Bitte wählen Sie eine Führungsebene')),
    follow_on_action: yup
      .string()
      .required(t('Bitte wählen SIe eine Anschlussmaßnahme')),
    expectations: yup
      .string()
      .required(t('Bitte geben Sie Ihre Erwartungen an')),
    comments: yup.string(),
  });
