import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks';
import { getLink } from '../../utils';
import './AdminCompaniesNew.scss';
import AdminCompaniesNewForm from './components/AdminCompaniesNewForm';

export const AdminCompaniesNew = () => {
  const [{ company }] = useGlobalState();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const redirectToCompanies = () => {
    navigate(getLink(company, PagePath.ADMIN_COMPANIES));
  };

  return (
    <AdminLayout>
      <div className="admin-companies-new-title-wrapper">
        <Title text={t('Unternehmen hinzufügen')} color="yellow" width="13%" />
        <Button
          className="admin-companies-new-title-wrapper-button"
          variant="light"
          size="small"
          onClick={redirectToCompanies}
          arrow="back"
        >
          {t('Zurück')}
        </Button>
      </div>
      <AdminCompaniesNewForm />
    </AdminLayout>
  );
};

export default AdminCompaniesNew;
