import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FgiModal from '../../components/FgiModal';
import ProjectTypes from '../../constants/ProjectTypes';
import './ProjectDetailsModal.scss';

export const ProjectDetailsModal = ({ show, onHide, project, index }) => {
  const [t] = useTranslation();

  const getColorByProject = (type) => {
    switch (type) {
      case ProjectTypes.COACH:
        return 'yellow';
      case ProjectTypes.MODERATOR:
        return 'green';
      default:
        return '';
    }
  };

  return (
    <FgiModal
      show={show}
      onHide={onHide}
      title={`#${index + 1} ${project.category}`}
      description={
        project.created_at &&
        `${t('Projektdetails vom')} ${moment
          .unix(project.created_at.seconds)
          .format('LL')}`
      }
      color={getColorByProject(project.type)}
    >
      {project.type === ProjectTypes.COACH && (
        <Form noValidate>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>{t('Businesssituation')}</Form.Label>
              <div className="description">{project.business_situation}</div>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{t('Coachinganliegen/Thema')}</Form.Label>
              <div className="description">{project.coaching_topic}</div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>{t('Führungsebene')}</Form.Label>
              <div className="description">{project.leadership_level}</div>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{t('Anschlussmaßnahmen')}</Form.Label>
              <div className="description">{project.follow_on_action}</div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>{t('Ort des Coachings')}</Form.Label>
              <div className="description">
                {project.location || t('Virtuell')}
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>{t('Erwartungen')}</Form.Label>
              <div className="description">{project.expectations}</div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>{t('Anmerkungen')}</Form.Label>
              <div className="description">{project.comments}</div>
            </Form.Group>
          </Row>
        </Form>
      )}
      {project.type === ProjectTypes.MODERATOR && (
        <Form noValidate>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>{t('Teilnehmerzahl')}</Form.Label>
              <div className="description">{project.numParticipants}</div>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{t('Bevorzugte Sprache der Coachings')}</Form.Label>
              <div className="description">{project.language}</div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>{t('Ort des Workshops')}</Form.Label>
              <div className="description">
                {project.location || t('Virtuell')}
              </div>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>{t('Startdatum')}</Form.Label>
              <div className="description">
                {moment(project.date).format('L')}
              </div>
            </Form.Group>
          </Row>
          {project.leadership_level ? (
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Führungsebene')}</Form.Label>
                <div className="description">{project.leadership_level}</div>
              </Form.Group>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Form.Group as={Col}>
              <Form.Label>{t('Anmerkungen')}</Form.Label>
              <div className="description">{project.comments}</div>
            </Form.Group>
          </Row>
        </Form>
      )}
    </FgiModal>
  );
};

ProjectDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

export default ProjectDetailsModal;
