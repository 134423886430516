import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NoProjectsIcon from '../../../../assets/icons/NoProjectsIcon.png';

const AdminProjectsNoItems = () => {
  const [t] = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'common.white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '788px',
      }}
    >
      <img alt="" src={NoProjectsIcon} />
      <Box
        sx={{
          width: '311px',
          textAlign: 'center',
          marginTop: '30px',
          color: 'grey.light2',
        }}
      >
        <Typography variant="titleProNr">
          {t('Derzeit gibt es keine derartigen Projekte')}
        </Typography>
      </Box>
    </Box>
  );
};

export default AdminProjectsNoItems;
