import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import OkSVG from '../../assets/svgs/ok';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import PagePath from '../../constants/PagePath';
import { useGlobalState } from '../../hooks';
import { getLink } from '../../utils';

export const NewCoachingSuccessModal = ({ show, onHide }) => {
  const [{ company }] = useGlobalState();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const description = (
    <>
      <div className="small-modal__ok-icon">
        <OkSVG />
      </div>
      <div>
        {t(
          'Unsere Coaching-Experten werden innerhalb von zwei Werktagen eine geeignete Vorauswahl an Coaches für Sie treffen.',
        )}
      </div>
      <br />
      <div>
        {t(
          'Sobald die Vorauswahl abgeschlossen ist, werden wir Sie per E-Mail informieren.',
        )}
      </div>
    </>
  );

  const onCloseModal = () => {
    onHide();
    navigate(getLink(company, PagePath.BOOKINGS));
  };

  return (
    <FgiModal
      title={t('Anfrage erfolgreich')}
      description={description}
      onHide={onHide}
      show={show}
      showCloseButton={false}
      className="small-modal"
    >
      <div className="modal-footer-button">
        <Button
          variant="main"
          size="medium"
          onClick={onCloseModal}
          style={{ minWidth: '110px' }}
        >
          {t('Fertig')}
        </Button>
      </div>
    </FgiModal>
  );
};

NewCoachingSuccessModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default NewCoachingSuccessModal;
