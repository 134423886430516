import PropTypes from 'prop-types';

import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import './DeleteProductModal.scss';

const DeleteProductModal = ({ show, onHide, deleteProduct, productName }) => {
  return (
    <FgiModal
      color="green"
      className="small-modal delete-product-modal"
      show={show}
      onHide={onHide}
      title="Produkt entfernen?"
    >
      <div className="delete-product-modal-text primary-text">
        Sind Sie sicher das Sie
        <br /> <b>{productName}</b> löschen möchten?
      </div>
      <Button onClick={deleteProduct} size="medium" arrow={false} variant="red">
        Entfernen
      </Button>
    </FgiModal>
  );
};

DeleteProductModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  productName: PropTypes.string,
};

export default DeleteProductModal;
