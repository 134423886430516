import React from 'react';
import { useTranslation } from 'react-i18next';

import './MiddleItem.scss';

export const MiddleItem = () => {
  const [t] = useTranslation();

  return (
    <div className="leadership-middle-item">
      <div className="leadership-middle-item-text">
        <div className="title-snippet">
          {t('Komprimiertes Wissen, das einen Mehrwert stiftet')}
        </div>
        {/* prettier-ignore */}
        <div className="leadership-middle-item-text-description description">
          {t('Nutzen Sie E-Learnings und Dokumente in Ihrem Arbeitsalltag. Sie finden darin hilfreiche Tipps, interaktive Tools und strukturierte Leitfäden.')}
        </div>
      </div>
      <div className="leadership-middle-item-image" />
    </div>
  );
};

export default MiddleItem;
