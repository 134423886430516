import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  deleteOpenWorkshopImage,
  deleteOpenWorkshopPDF,
  deleteWorkshopById,
  useGetWorkshopByIdQuery,
  uploadOpenWorkshopPDF,
  useGetAllCompaniesQuery,
  useUpdateWorkshopMutation,
  useCreateOpenWorkshopMutation,
  useOpenWorkshopImageMutation,
} from '../../../../api';
import AdminEventRow from '../../../../components/AdminForms/fields/AdminEventRow';
import AdminFocusTopicInput from '../../../../components/AdminForms/fields/AdminFocusTopicInput';
import AdminFocusTopicsArray from '../../../../components/AdminForms/fields/AdminFocusTopicsArray';
import AdminFormImage from '../../../../components/AdminForms/fields/AdminFormImage';
import AdminFormPdf from '../../../../components/AdminForms/fields/AdminFormPdf';
import AdminFormSelect from '../../../../components/AdminForms/fields/AdminFormSelect';
import AdminFormText from '../../../../components/AdminForms/fields/AdminFormText';
import { useLanguageSwitch } from '../../../../components/AdminForms/LanguageSwitch';
import Button from '../../../../components/Button';
import PagePath from '../../../../constants/PagePath';
import { useGlobalState } from '../../../../hooks';
import { Locales } from '../../../../i18n';
import DeleteTrainingModal from '../../../../modals/DeleteTrainingModal/DeleteTrainingModal';
import { getLink } from '../../../../utils';
import { openWorkshopSchema } from './AdminTrainingsNewFormSchema';

const AdminTrainingsNewForm = ({ isEdit }) => {
  const navigate = useNavigate();
  const [{ company }] = useGlobalState();
  const { id } = useParams();

  const { selectedLanguage, LanguageSwitchComponent } = useLanguageSwitch();

  const form = useForm({
    resolver: yupResolver(openWorkshopSchema),
    defaultValues: defaultFormValues(Object.values(Locales)),
    mode: 'onSubmit',
    criteriaMode: 'all',
  });

  const getTrainingQuery = useGetWorkshopByIdQuery(id, {
    enabled: !!id,
    onSuccess: (payload) => {
      form.reset({
        ...defaultFormValues(Object.values(Locales)),
        ...payload,
        imageUrl: {
          name: payload.imageUrl,
        },
        focusTopics: payload.focusTopics.map((focusTopic) => ({
          name: focusTopic,
        })),
        events: payload.events.map((e) => ({
          id: e.id,
          location: e.location,
          // TODO: Replace moment with date-fns
          startTime: moment(e.start.toDate()).format('HH:mm'),
          endTime: moment(e.end.toDate()).format('HH:mm'),
          startDate: moment(e.start.toDate()).format('YYYY-MM-DD'),
          endDate: moment(e.end.toDate()).format('YYYY-MM-DD'),
        })),
      });
    },
  });

  const deleteTrainingMutation = useMutation(
    async (training) => {
      if (
        training.companyId &&
        training.id &&
        training.imageUrl &&
        (training.pdfUrl || training.urlToPdf)
      ) {
        await Promise.all([
          deleteWorkshopById(training.companyId, training.id),
          deleteOpenWorkshopImage(training.imageUrl),
          (() => {
            if (training.urlToPdf) {
              return deleteOpenWorkshopPDF(training.urlToPdf);
            }
            return Promise.resolve();
          })(),
          ...Object.values(Locales).map((locale) => {
            if (training.pdfUrl?.[locale]) {
              return deleteOpenWorkshopPDF(training.pdfUrl[locale]);
            }
            return Promise.resolve();
          }),
        ]);
      } else {
        // eslint-disable-next-line no-console
        console.error('Missing data for deleteTrainingMutation', training);
      }
      // TODO: Invalidate getAllWorkshops query when getAllWorkshopsQuery is implemented
    },
    {
      onSuccess: () => {
        navigate(getLink(company, PagePath.ADMIN_TRAININGS));
      },
    },
  );

  const getAllCompaniesQuery = useGetAllCompaniesQuery();

  const [isDeleteTrainingModalOpen, setIsDeleteTrainingModalOpen] =
    useState(false);

  const openDeleteTrainingModal = (event) => {
    event.preventDefault();
    setIsDeleteTrainingModalOpen(true);
  };

  const closeDeleteTrainingModal = () => {
    setIsDeleteTrainingModalOpen(false);
  };

  const createWorkshopMutation = useCreateOpenWorkshopMutation();
  const updateWorkshopMutation = useUpdateWorkshopMutation();
  const uploadOpenWorkshopImageMutation = useOpenWorkshopImageMutation();

  const submitMutation = useMutation(async (payload) => {
    const newPayload = {
      ...payload,
      focusTopics: payload.focusTopics.map((focusTopic) => focusTopic.name),
      imageUrl: payload.imageUrl.name,
    };
    if (payload.imageUrl.name !== getTrainingQuery.data?.imageUrl) {
      newPayload.imageUrl = await uploadOpenWorkshopImageMutation.mutateAsync(
        payload.imageUrl,
      );
      if (getTrainingQuery.data?.imageUrl) {
        await deleteOpenWorkshopImage(getTrainingQuery.data?.imageUrl);
      }
    }
    await Promise.all(
      Object.values(Locales).map(async (locale) => {
        const pdfFile = payload.pdfUrl[locale];
        const initialPdfUrl = getTrainingQuery.data?.pdfUrl?.[locale];

        // Is our file a new file that needs uploading?
        if (pdfFile?.blob) {
          if (initialPdfUrl) {
            // If yes, we delete the old file,
            await deleteOpenWorkshopPDF(initialPdfUrl);
          }

          // upload the new one and set it's url as our pdfUrl.
          newPayload.pdfUrl[locale] = await uploadOpenWorkshopPDF(pdfFile);
        } else {
          /**
           * If we don't have a pdfFile now but there was an initialPdfUrl,
           * this means the user has removed the file and hence, we can delete it.
           * */

          if (!pdfFile && initialPdfUrl) {
            await deleteOpenWorkshopPDF(initialPdfUrl);
          }

          newPayload.pdfUrl[locale] = pdfFile;
        }
      }),
    );

    if (isEdit) {
      if (getTrainingQuery.data?.events?.length > 0) {
        newPayload.events.forEach((event) => {
          if (event.id) {
            const oldEvent = getTrainingQuery.data.events.find(
              (e) => e.id === event.id,
            );
            if (oldEvent && oldEvent.numBookings > 0) {
              event.numBookings = oldEvent.numBookings;
            }
          }
        });
      }
      await updateWorkshopMutation.mutateAsync({
        ...newPayload,
        id: getTrainingQuery.data.id,
      });
    } else {
      await createWorkshopMutation.mutateAsync({
        ...newPayload,
      });
    }

    navigate(getLink(company, PagePath.ADMIN_TRAININGS));
  });

  const eventsFieldArray = useFieldArray({
    control: form.control,
    name: 'events',
  });

  return (
    <form onSubmit={form.handleSubmit(submitMutation.mutate)}>
      <FormProvider {...form}>
        <Box
          sx={{
            backgroundColor: 'common.white',
            marginTop: '24px',
            padding: '37px 40px 44px 40px',
            display: 'grid',
            gridTemplateColumns: '1fr 3fr',
            gridTemplateAreas: "'files inputs''files events''. submit'",
          }}
        >
          <Box
            sx={{
              gridArea: 'files',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '24px',
            }}
          >
            <AdminFormImage label={'Produktbild'} name={'imageUrl'} />
            {Object.values(Locales).map((locale) => {
              // TODO: This is a workaround,
              //  because InputFile component is not updating its value when changed.
              return (
                <div
                  key={locale}
                  style={{
                    display: selectedLanguage === locale ? 'block' : 'none',
                  }}
                >
                  <AdminFormPdf label={'PDF'} name={'pdfUrl'} locale={locale} />
                </div>
              );
            })}
            {LanguageSwitchComponent}
          </Box>
          <Box
            sx={{
              gridArea: 'inputs',
              display: 'grid',
              gridTemplateColumns: 'repeat(8, 1fr)',
              gridTemplateAreas: `
                'title title title title title companyId companyId companyId'
                'focusTopicInput focusTopicInput focusTopicInput focusTopicInput maxParticipants maxParticipants noticePeriodInDays noticePeriodInDays'
                'focusTopics focusTopics focusTopics focusTopics focusTopics focusTopics focusTopics focusTopics'
                'description description description description description description description description'
              `,
              rowGap: '20px',
              columnGap: '40px',
            }}
          >
            <AdminFormText
              label={'Name des Trainings *'}
              name={'title'}
              selectedLanguage={selectedLanguage}
              sx={{
                gridArea: 'title',
              }}
            />
            <AdminFormSelect
              label={'Unternehmen *'}
              name={'companyId'}
              options={getAllCompaniesQuery.data.map((company) => ({
                value: company.id,
                label: company.name,
              }))}
              sx={{
                gridArea: 'companyId',
              }}
            />
            <AdminFocusTopicInput sx={{ gridArea: 'focusTopicInput' }} />
            <AdminFocusTopicsArray sx={{ gridArea: 'focusTopics' }} />
            <AdminFormText
              label={'Max. Teilnehmeranzahl *'}
              name={'maxParticipants'}
              type={'number'}
              placeholder={'Personen'}
              sx={{
                gridArea: 'maxParticipants',
              }}
            />
            <AdminFormText
              label={'Anmeldefrist in Tagen *'}
              name={'noticePeriodInDays'}
              type={'number'}
              placeholder={'Tage'}
              sx={{
                gridArea: 'noticePeriodInDays',
              }}
            />
            <AdminFormText
              label={'Beschreibung *'}
              name={`text`}
              as={'textarea'}
              selectedLanguage={selectedLanguage}
              sx={{
                gridArea: 'description',
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: '20px',
              gridArea: 'events',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr auto',
                columnGap: '8px',
                rowGap: '8px',
              }}
            >
              <Form.Label>Startdatum *</Form.Label>
              <Form.Label>Startzeit * </Form.Label>
              <Form.Label>Enddatum * </Form.Label>
              <Form.Label>Endzeit * </Form.Label>
              <Form.Label>Ort * </Form.Label>
              <div />
              {eventsFieldArray.fields.map((event, index) => (
                <AdminEventRow
                  key={event.id}
                  index={index}
                  handleDelete={eventsFieldArray.remove}
                />
              ))}
            </Box>
            <Button
              type="button"
              onClick={() => eventsFieldArray.append({})}
              size="medium"
              variant="light"
              arrow={false}
              style={{ marginTop: '24px' }}
            >
              Termin Hinzufügen
            </Button>
          </Box>
          <Box sx={{ gridArea: 'submit' }}>
            <Box
              component="hr"
              sx={{ margin: '70px 0 57px 0', width: '100%' }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {isEdit && getTrainingQuery.isFetched ? (
                <>
                  <Box
                    component={Button}
                    sx={{ marginRight: '24px' }}
                    onClick={openDeleteTrainingModal}
                    variant="light"
                    size="medium"
                    arrow={false}
                    load={deleteTrainingMutation.isLoading}
                  >
                    Löschen
                  </Box>
                  <Button
                    type="submit"
                    size="medium"
                    variant="dark-orchid"
                    load={submitMutation.isLoading}
                    role={'submit'}
                  >
                    Speichern
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  size="medium"
                  variant="dark-orchid"
                  load={submitMutation.isLoading}
                  role={'submit'}
                >
                  Training Hinzufügen
                </Button>
              )}
            </Box>
            <DeleteTrainingModal
              show={isDeleteTrainingModalOpen}
              onHide={closeDeleteTrainingModal}
              onDeleteTraining={() =>
                deleteTrainingMutation.mutate(getTrainingQuery.data)
              }
            />
          </Box>
        </Box>
      </FormProvider>
    </form>
  );
};

/**
 * @param {TLocale[]} locales
 */
const defaultFormValues = (locales) => {
  const values = {
    focusTopics: [],
    maxParticipants: null,
    noticePeriodInDays: null,
    text: {},
    title: {},
    pdfUrl: {},
    companyId: null,
    events: [
      {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        location: null,
      },
    ],
  };

  locales.forEach((locale) => {
    values.title[locale] = null;
    values.text[locale] = null;
    values.pdfUrl[locale] = null;
  });

  return values;
};

export default AdminTrainingsNewForm;
