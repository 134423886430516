import React from 'react';
import { Navigate } from 'react-router-dom';

import PagePath from '../constants/PagePath';
import { useGlobalState } from '../hooks/useGlobalState';
import { getLink } from '../utils';

const withRequiredAuth = (ComposedComponent) => (props) => {
  const [{ company, emailVerified }] = useGlobalState();

  if (!emailVerified) {
    return <Navigate to={getLink(company, PagePath.ANMELDEN)} />;
  }

  return <ComposedComponent {...props} />;
};

export default withRequiredAuth;
