import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import { isSAMLProvider } from '../../api';
import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import GENDER_TYPES from '../../constants/SexTypes';
import MainLayout from '../../HOC/MainLayout';
import { useGlobalState } from '../../hooks/useGlobalState';
import ProfileMainDataUpdateModal from '../../modals/ProfileMainDataUpdateModal';
import ProfilePasswordUpdateModal from '../../modals/ProfilePasswordUpdateModal';
import ProfileSecondaryDataUpdateModal from '../../modals/ProfileSecondaryDataUpdateModal';
import './Settings.scss';

export const Settings = () => {
  const [t] = useTranslation();
  const [{ user, company }] = useGlobalState();
  const userIsSignedInWithSAML = isSAMLProvider();

  useEffect(() => {
    sendAmplitudeData('Visit Settings');
  }, []);

  const [showProfileMainDataUpdateModal, setShowProfileMainDataUpdateModal] =
    useState(false);

  const [
    showProfileSecondaryDataUpdateModal,
    setShowProfileSecondaryDataUpdateModal,
  ] = useState(false);

  const [showProfilePasswordUpdateModal, setShowProfilePasswordUpdateModal] =
    useState(false);

  const openProfileMainDataUpdateModal = () => {
    setShowProfileMainDataUpdateModal(true);
  };

  const closeProfileMainDataUpdateModal = () => {
    setShowProfileMainDataUpdateModal(false);
  };

  const openProfileSecondaryDataUpdateModal = () => {
    setShowProfileSecondaryDataUpdateModal(true);
  };

  const closeProfileSecondaryDataUpdateModal = () => {
    setShowProfileSecondaryDataUpdateModal(false);
  };

  const openProfilePasswordUpdateModal = () => {
    setShowProfilePasswordUpdateModal(true);
  };

  const closeProfilePasswordUpdateModal = () => {
    setShowProfilePasswordUpdateModal(false);
  };

  const getSexLabel = (sex) => {
    switch (sex) {
      case GENDER_TYPES.male:
        return t('Herr');
      case GENDER_TYPES.female:
        return t('Frau');
      case GENDER_TYPES.other:
        return t('Divers');
      default:
        return '';
    }
  };

  return (
    <MainLayout>
      <ProfileMainDataUpdateModal
        show={showProfileMainDataUpdateModal}
        onHide={closeProfileMainDataUpdateModal}
      />
      <ProfileSecondaryDataUpdateModal
        show={showProfileSecondaryDataUpdateModal}
        onHide={closeProfileSecondaryDataUpdateModal}
      />
      <ProfilePasswordUpdateModal
        show={showProfilePasswordUpdateModal}
        onHide={closeProfilePasswordUpdateModal}
      />
      <Title color="yellow" text={t('Einstellungen')} />
      <div className="settings">
        <div className="settings-info-container">
          <div className="settings-main-info-container">
            <Row className="settings-mobile-column">
              <Form.Group as={Col}>
                <Form.Label>{getSexLabel(user.sex)}</Form.Label>
                <div className="primary-text">
                  {user.firstname} {user.lastname}
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('E-Mail')}</Form.Label>
                <div className="primary-text">{user.email}</div>
              </Form.Group>
              <Form.Group className="settings-info-container-button" as={Col}>
                <Button
                  onClick={openProfileMainDataUpdateModal}
                  variant="light"
                  arrow={false}
                  size="small"
                >
                  {t('Daten Ändern')}
                </Button>
              </Form.Group>
            </Row>
          </div>
          <hr className="settings-info-container-hr" />
          <div className="settings-secondary-info-container">
            <Row className="settings-mobile-column">
              <Form.Group as={Col}>
                <Form.Label>{t('Geburtsdatum')}</Form.Label>
                <div className="primary-text">
                  {moment(user.date_of_birth).format('L')}
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('Nationalität')}</Form.Label>
                <div className="primary-text">{t(user.nationality)}</div>
              </Form.Group>
              <Form.Group className="settings-info-container-button" as={Col}>
                <Button
                  variant="light"
                  arrow={false}
                  size="small"
                  onClick={openProfileSecondaryDataUpdateModal}
                >
                  {t('Daten Ändern')}
                </Button>
              </Form.Group>
            </Row>
            <Row className="settings-mobile-column">
              <Form.Group as={Col}>
                <Form.Label>{t('Bevorzugte Sprache der Coachings')}</Form.Label>
                <div className="primary-text">{t(user.language)}</div>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('Standort')}</Form.Label>
                <div className="primary-text">{t(user.location)}</div>
              </Form.Group>
              <Form.Group as={Col} />
            </Row>
          </div>
          {userIsSignedInWithSAML && (
            <>
              <hr className="settings-info-container-hr" />
              <div className="settings-main-info-container">
                <Row className="settings-mobile-column">
                  <Form.Group as={Col}>
                    <Typography variant="formLabel">
                      {t('Settings.SAMLgroups.label')}
                    </Typography>
                    <Tooltip
                      sx={{ marginLeft: 1 }}
                      title={t('Settings.SAMLgroups.tooltip', {
                        company,
                      })}
                    >
                      <HelpOutlineOutlinedIcon fontSize="14px" />
                    </Tooltip>
                    {Array.isArray(user.groups) && user.groups.length > 0 ? (
                      user.groups.map((userGroup) => {
                        // Find matching samlGroup by id from list of company saml groups
                        const samlGroup = company.samlGroups?.find(
                          (samlGroup) => samlGroup.id === userGroup,
                        );

                        // Display the groups' name or fall back to displaying the groups id
                        return (
                          <Typography>
                            {samlGroup ? samlGroup.name : userGroup}
                          </Typography>
                        );
                      })
                    ) : (
                      <Typography>
                        {t('Settings.SAMLgroups.noGroupAssigned')}
                      </Typography>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </>
          )}
        </div>
        <div className="settings-password-container">
          {!userIsSignedInWithSAML && (
            <Button
              onClick={openProfilePasswordUpdateModal}
              variant="main"
              size="big"
            >
              {t('Passwort Ändern')}
            </Button>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default Settings;
