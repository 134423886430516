import { Stack, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import supportUrl from '../../assets/icons/Support@3x.png';
import FgiInfo from '../../constants/FgiInfo';
import { KeyCodes } from '../../constants/KeyCodes';
import PagePath from '../../constants/PagePath';
import LanguageSwitcher from '../LanguageSwitcher';
import './SupportPart.scss';

export const SupportPart = ({ withoutText, className }) => {
  const [t] = useTranslation();

  const supportItemList = (t) => [
    {
      // eslint-disable-next-line global-require
      handleOnClick: () => {
        window.location.href = `mailto:${FgiInfo.email}`;
      },
      icon: require('../../assets/icons/Mail@3x.png'),
      desc: FgiInfo.email,
    },
    {
      // eslint-disable-next-line global-require
      handleOnClick: () => {},
      icon: require('../../assets/icons/Call@3x.png'),
      desc: FgiInfo.phone,
    },
  ];

  const onEnterPress = (item) => (event) => {
    if (event.keyCode === KeyCodes.ENTER) {
      item.handleOnClick();
    }
  };

  return (
    <div className={`supportPart ${className || ''}`}>
      <div className="titleWrapper">
        <img src={supportUrl} alt="" className="supportIcon-box" />
        <span className="title-section">{t('SUPPORT & HILFE')}</span>
      </div>
      {supportItemList(t).map((item, index) => (
        <div
          tabIndex="0"
          onKeyDown={onEnterPress(item)}
          onClick={item.handleOnClick}
          key={index}
          className="itemSupportWrapper"
        >
          <img src={item.icon} alt="" />
          <span className="primary-text">{item.desc}</span>
        </div>
      ))}

      {!withoutText && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <LanguageSwitcher />
          <Link
            className="secondary-text"
            to={PagePath.IMPRINT}
            target="_blank"
          >
            {t('Impressum')}
          </Link>
          <Link
            className="secondary-text"
            to={PagePath.PRIVACY_POLICY}
            target="_blank"
          >
            {t('Datenschutz')}
          </Link>
        </Stack>
      )}
    </div>
  );
};

SupportPart.propTypes = {
  /** It is an optional boolean property that determines whether to display text  */
  withoutText: PropTypes.bool,
  /**  It is an optional string property that allows you to add custom CSS classes*/
  className: PropTypes.string,
};

export default SupportPart;
