import { Grid } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdminMainContainer from '../../components/AdminMainContainer';
import AdminReportCard from '../../components/AdminReportCard/AdminReportCard';
import Loader from '../../components/Loader';
import Title from '../../components/PageTitle';
import { functions } from '../../firebaseConfig';
import AdminLayout from '../../HOC/AdminLayout';
import {
  getAllianzUserReport,
  getAllianzUserBreakdown,
  getBillingReport,
  Monitoring,
} from '../../utils';

const AdminReports = () => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const downloadReport = (getReport) => async () => {
    try {
      setIsLoading(true);
      await getReport();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const reportCards = [
    {
      title: 'LISTE ALLER #LEAD-NUTZER',
      text: 'Lädt eine Excel-Datei mit allen Nutzern der #lead-Plattform herunter.',
      onButtonClick: downloadReport(getAllianzUserReport),
    },
    {
      title: 'ZUSAMMENFASSUNG ALLER #LEAD-NUTZER',
      text: 'Lädt eine Excel-Datei mit einer Zusammenfassung aller Nutzer der #lead-Plattform nach Bereich herunter.',
      onButtonClick: downloadReport(getAllianzUserBreakdown),
    },
    {
      title: 'Liste aller E-Learning-Registrierungen',
      text: 'Lädt eine Excel-Datei mit allen E-Learning-Registrierungen herunter.',

      onButtonClick: async (resolve = () => {}, reject = () => {}) => {
        try {
          const createReport = httpsCallable(
            functions,
            'reports-elearning-uiEndpoint',
          );

          await createReport();
          resolve();
        } catch (err) {
          Monitoring.captureException(err);
          reject();
        }
      },
    },
    {
      title: 'ALLIANZ BILLING REPORT',
      text: 'Die Auflistung aller Buchungen für die Abrechnung bei der Alllianz.',
      onButtonClick: downloadReport(getBillingReport),
    },
  ];

  return (
    <AdminLayout>
      <Title color="yellow" text={t('Berichte')} width="14%" />
      <AdminMainContainer>
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{
            backgroundColor: 'common.white',
          }}
        >
          {reportCards.map(({ onButtonClick, title, text }, index) => (
            <Grid item xs={4} key={index}>
              <AdminReportCard
                onButtonClick={onButtonClick}
                title={title}
                text={text}
              />
            </Grid>
          ))}
        </Grid>
      </AdminMainContainer>
      <Loader show={isLoading} />
    </AdminLayout>
  );
};

export default AdminReports;
