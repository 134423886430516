import DataTable from 'react-data-table-component';

import { useGetAllCoaches } from '../../api';
import AdminMainContainer from '../../components/AdminMainContainer';
import Avatar from '../../components/Avatar';
import ButtonPdfProfile from '../../components/ButtonPdfProfile';
import Title from '../../components/PageTitle';
import AdminLayout from '../../HOC/AdminLayout';

const AdminCoaches = () => {
  const columns = [
    {
      selector: (row) => (
        <Avatar bigSize="40px" smallSize="40px" image={row.id} />
      ),
      width: '40px', // 40px width of image plus 16px padding each side.
      compact: true,
    },
    {
      name: 'Vorname',
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: 'Nachname',
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: 'E-Mail-Adresse',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Telefon',
      selector: (row) => row.phone,
    },
    {
      name: 'Ort',
      selector: (row) => row.bio_location,
      sortable: true,
    },
    {
      name: 'Sprache',
      selector: (row) => (
        <div>
          {Object.entries(row.bio_language).map(([k, v], i) => (
            <div key={i}>{`${k}: ${v}\n`}</div>
          ))}
        </div>
      ),
    },
    {
      selector: (row) => <ButtonPdfProfile coachId={row.id} />,
    },
  ];

  const { data, isLoading } = useGetAllCoaches();

  return (
    <AdminLayout>
      <Title text="Coaches" color="yellow" />
      <AdminMainContainer>
        <DataTable
          columns={columns}
          data={data}
          progressPending={isLoading}
          dense
        />
      </AdminMainContainer>
    </AdminLayout>
  );
};

export default AdminCoaches;
