import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getCoachProfilePictureURL } from '../../api';
import './Avatar.scss';

const Avatar = ({ bigSize, image, smallSize, smallStyle, ...style }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [picture, setPicture] = useState('');

  const changeSize = () => {
    setIsMobile(window.innerWidth <= 992);
  };

  useEffect(() => {
    window.addEventListener('resize', changeSize);
    changeSize();
    return () => {
      window.removeEventListener('resize', changeSize);
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (image) {
        const link = await getCoachProfilePictureURL(image);
        setPicture(link);
      }
    };
    fetch();
  }, [image]);

  const size = isMobile ? smallSize : bigSize;
  const mobileStyles = isMobile ? smallStyle : {};
  return (
    <img
      className="fgi-avatar"
      style={{
        height: size,
        minWidth: size,
        width: size,
        ...mobileStyles,
        ...style,
      }}
      src={picture}
      alt=""
    />
  );
};

Avatar.propTypes = {
  /** Sets the style `height`, `width` and `minWidth` for non-mobile deveices. */
  bigSize: PropTypes.string.isRequired,

  /** Sets the style `height`, `width` and `minWidth` for mobile deveices. */
  smallSize: PropTypes.string.isRequired,

  /** The file name for the coach profile picture. The component gets the public file path and renders it. */
  image: PropTypes.string,

  /** Use to set special styles for mobile devices. */
  smallStyle: PropTypes.object,
};

export default Avatar;
