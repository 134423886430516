import { Box, Typography, Link } from '@mui/material';

import PageTitle from '../../components/PageTitle';

export const Title = ({ text }) => (
  <Box
    sx={{
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <PageTitle width="30%" text={text} color="yellow" />
  </Box>
);

export const Headline = ({ children }) => (
  <Typography variant="titleSection" marginTop={3} gutterBottom>
    {children}
  </Typography>
);

export const Text = ({ children }) => (
  <Typography gutterBottom>{children}</Typography>
);

const List = ({ items, component }) => (
  <Typography gutterBottom>
    <Box
      component={component}
      sx={{
        marginBlockStart: 1,
        marginBlockEnd: 1,
        marginInlineStart: 0,
        marginInlineEnd: 0,
        paddingInlineStart: 5,
      }}
    >
      {items.map((item) => (
        <li>{item}</li>
      ))}
    </Box>
  </Typography>
);

export const OL = ({ items }) => List({ items, component: 'ol' });
export const UL = ({ items }) => List({ items, component: 'ul' });

export const LinkToGooglePrivacyPolicy = ({ children }) => (
  <Link href="http://gogle.de" target="_blank">
    {children}
  </Link>
);
