import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { KeyCodes } from '../../constants/KeyCodes';
import './Checkbox.scss';

const Checkbox = ({ onClick, checked, isError, label }) => {
  const changeCheckedOnEnter = (event) => {
    if (event.keyCode === KeyCodes.SPACE) {
      onClick();
    }
  };

  return (
    <div
      className={classNames({
        'checkbox-container': true,
        'checkbox-container-error': isError,
      })}
    >
      <div
        tabIndex="0"
        onKeyDown={changeCheckedOnEnter}
        className="checkbox"
        onClick={onClick}
      >
        {checked && <div className="checkinner" />}
      </div>
      <span onClick={onClick} className="checkbox-label secondary-text">
        {label}
      </span>
    </div>
  );
};

Checkbox.propTypes = {
  /** whether there is an error associated with the checkbox*/
  isError: PropTypes.bool,
  /**This prop is required and of type function */
  onClick: PropTypes.func.isRequired,
  /**This prop is required and of type boolean */
  checked: PropTypes.bool.isRequired,
};

export default Checkbox;
