import { Typography, Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Controller, FormProvider } from 'react-hook-form';
import { useLocation, useParams, generatePath } from 'react-router-dom';

import { useGetAllProducts, useUpdateCompanyMutation } from '../../../../api';
import AdminMainContainer from '../../../../components/AdminMainContainer';
import AdminNavigation from '../../../../components/AdminNavigation';
import InputAdminCompanCustomPath from '../../../../components/InputAdminCompanCustomPath';
import InputAdminCompanyRedirect from '../../../../components/InputAdminCompanyRedirect';
import InputFile from '../../../../components/InputFile';
import InputText from '../../../../components/InputText';
import PagePath from '../../../../constants/PagePath';
import { useGlobalState } from '../../../../hooks';
import AdminCompanyDomains from './AdminCompanyDomains';
import AdminCompanyGroups from './components/AdminCompanyGroups/AdminCompanyGroups';
import AdminCompanyProductsTabs from './components/AdminCompanyProductsTabs';
import SwitchCompany from './components/SwitchCompany';
import useAdminActions from './components/useAdminActions';
import useAdminCompanyForm from './useAdminCompanyForm';

const AdminCompanyForm = () => {
  const { companyId, groupId } = useParams();
  const [{ locale }] = useGlobalState();
  const { pathname: path } = useLocation();
  const getAllProductsQuery = useGetAllProducts();
  const { form, handleInvalidateQueries, control } = useAdminCompanyForm();

  const { onSaveSuccess, AdminActions } = useAdminActions();
  const updateCompanyMutation = useUpdateCompanyMutation();
  const samlGroups = form.watch('samlGroups');

  const submitMutation = useMutation(
    /**
     * @param {AdminCompanyFormFields} payload
     * @return {Promise<void>}
     */
    async (payload) => {
      const companyData = {
        name: payload.name,
        available_products: payload.available_products,
        domain: payload.domains,
        logo: payload.logo,
        logo_url: payload.logo_url,
        custom_path: payload.custom_path,
        samlGroups: payload.samlGroups,
      };
      await updateCompanyMutation.mutateAsync({
        id: companyId,
        data: companyData,
      });
    },
    {
      onSuccess: () => {
        handleInvalidateQueries();
        onSaveSuccess();
      },
    },
  );

  const navigationColectionEdit = [
    {
      label: 'Allgemein',
      to: generatePath(PagePath.ADMIN_COMPANY, { companyId }),
      key: 'general',
    },
    {
      label: 'Produkte',
      to: generatePath(PagePath.ADMIN_COMPANY_PRODUCTS, {
        companyId,
        groupId: undefined,
      }),
      key: 'product',
      submenu: [
        {
          key: 'product',
          label: 'Alle Nutzer',
          to: generatePath(PagePath.ADMIN_COMPANY_PRODUCTS, {
            companyId,
            groupId: undefined,
          }),
        },
        ...(samlGroups
          ? samlGroups.map(({ id: groupId, name }) => ({
              key: 'product',
              label: name,
              to: generatePath(PagePath.ADMIN_COMPANY_PRODUCTS, {
                companyId,
                groupId,
              }),
            }))
          : []),
      ],
    },
    {
      label: 'Weiterleitung',
      to: generatePath(PagePath.ADMIN_COMPANY_REDIRECT, { companyId }),
      key: 'redirect',
    },
    {
      label: 'SSO-Gruppen',
      to: generatePath(PagePath.ADMIN_COMPANY_GROUPS, { companyId }),
      key: 'groups',
    },
  ];

  const getForm = (key) => {
    const componentCollection = [
      {
        component: (
          <>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateAreas: `'name domains'
                                    'customPath domains'`,
                gridTemplateRows: 'auto auto',
                gridColumnGap: '32px',
                gridRowGap: '32px',
              }}
            >
              <Box flexGrow={1} sx={{ gridArea: 'name' }}>
                <Controller
                  name={'name'}
                  render={({ field, fieldState }) => (
                    <InputText
                      style={{ width: '335px' }}
                      label="Name"
                      name={field.name}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      isInvalid={fieldState.invalid}
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box flexGrow={1} sx={{ gridArea: 'domains' }}>
                <Controller
                  name={'domains'}
                  render={({ field, fieldState }) => (
                    <AdminCompanyDomains
                      domains={field.value}
                      invalid={fieldState.invalid}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box sx={{ gridArea: 'customPath', alignSelf: 'start' }}>
                <Controller
                  name={'custom_path'}
                  render={({ field, fieldState }) => (
                    <InputAdminCompanCustomPath
                      value={field.value}
                      name={field.name}
                      onChange={(e) => field.onChange(e.target.value)}
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <AdminActions isSaving={submitMutation.isLoading} />
          </>
        ),
        key: 'general',
      },
      {
        component: (
          <>
            {groupId ? (
              <Controller
                name="samlGroups"
                render={function ({ field: { value, onChange } }) {
                  const group = value[value.findIndex((v) => v.id === groupId)];
                  return (
                    <>
                      <Typography variant="titleSection" gutterBottom>
                        Produkte für {group.name} freischalten
                      </Typography>
                      <Typography sx={{ marginBottom: 2 }}>
                        Alle Nutzer der Gruppe '{group.name}' sehen diese
                        Produkte und können sie nutzen.
                      </Typography>
                      <AdminCompanyProductsTabs
                        allProducts={getAllProductsQuery.data}
                        locale={locale}
                        selectedProducts={group.products}
                        isLoading={getAllProductsQuery.isLoading}
                        onChange={(newValue) => {
                          value[
                            value.findIndex((v) => v.id === groupId)
                          ].products = newValue;
                          onChange(value);
                        }}
                      />
                    </>
                  );
                }}
              />
            ) : (
              <>
                <Typography variant="titleSection" gutterBottom>
                  Produkte für alle Nutzer freischalten
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                  Alle Nutzer dieses Unternehmens sehen diese Produkte und
                  können sie nutzen.
                </Typography>
                <Controller
                  name="available_products"
                  render={function ({ field: { value, onChange } }) {
                    return (
                      <AdminCompanyProductsTabs
                        allProducts={getAllProductsQuery.data}
                        locale={locale}
                        selectedProducts={value}
                        isLoading={getAllProductsQuery.isLoading}
                        onChange={(v) => onChange(v)}
                      />
                    );
                  }}
                />
              </>
            )}

            <AdminActions isSaving={submitMutation.isLoading} />
          </>
        ),
        key: 'product',
      },
      {
        component: (
          <Box maxWidth={'50%'}>
            <Controller
              name={'redirect_after_login_to'}
              render={({ field }) => {
                return (
                  <InputAdminCompanyRedirect
                    label="Automatische Weiterleitung nach Login"
                    value={field.value}
                    name={field.name}
                  />
                );
              }}
            />
          </Box>
        ),
        key: 'redirect',
      },
      {
        component: (
          <Box>
            <Typography mb={4}>
              Wenn sich Nutzer per Single-Sign-On anmelden, wird unter Umständen
              auch die Zugehörigkeit zu Gruppen übermittelt. Anhand dieser
              Gruppenzugehörigkeit lassen sich einzelne Produkte für Gruppen
              sichtbar machen. Manchmal werden allerdings nur Gruppen-IDs
              übermittelt.
              <br />
              <br />
              In dieser Tabelle lassen sich Gruppen-IDs mit verständlichen Namen
              hinterlegen, die dann in der Anwendung angezeigt werden.
            </Typography>

            <Controller
              name="samlGroups"
              control={control}
              render={({ field: { value, onChange } }) => (
                <AdminCompanyGroups value={value} onChange={onChange} />
              )}
            />

            <AdminActions isSaving={submitMutation.isLoading} />
          </Box>
        ),
        key: 'groups',
      },
    ];

    const componentFind = componentCollection.find(
      ({ key: formKey }) => formKey === key,
    )?.component;
    if (!componentFind) return null;

    return componentFind;
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitMutation.mutate)}>
        <AdminMainContainer
          sidebarElements={[
            <Box>
              <Controller
                name={'logo'}
                render={({ field, fieldState }) => (
                  <InputFile
                    accept={'.png, .jpeg, .bmp, .tiff'}
                    title="Logo"
                    removePicture={() => {
                      field.onChange(null);
                      form.setValue('logo_url', null);
                    }}
                    onSubmitFile={(file) => field.onChange(file)}
                    pictureName={form.watch('logo_url')}
                    content="logo"
                    isFormSubmit={fieldState.invalid}
                  />
                )}
              />
            </Box>,
            <AdminNavigation items={navigationColectionEdit} />,
            <Box>
              <SwitchCompany companyId={companyId} />
            </Box>,
          ]}
        >
          <Box flexGrow={1}>
            {getForm(
              navigationColectionEdit.find(
                (item) =>
                  encodeURI(item.to) === path ||
                  item.submenu?.find(
                    (subItem) => encodeURI(subItem.to) === path,
                  ),
              )?.key,
            )}
          </Box>
        </AdminMainContainer>
      </form>
    </FormProvider>
  );
};

export default AdminCompanyForm;
