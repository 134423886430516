import { Box } from '@mui/material';
import React, { useCallback } from 'react';

import TrashIcon from '../../../assets/svgs/Trash.svg';
import AdminFormText from './AdminFormText';

const AdminEventRow = ({ index, handleDelete }) => {
  const onClickHandleDelete = useCallback(() => {
    handleDelete(index);
  }, [index, handleDelete]);

  return (
    <React.Fragment>
      <AdminFormText
        as="input"
        type="date"
        placeholder="TT.MM.JJJJ"
        name={`events.${index}.startDate`}
      />
      <AdminFormText
        as="input"
        type="text"
        placeholder="14:00 Uhr"
        name={`events.${index}.startTime`}
      />
      <AdminFormText
        as="input"
        type="date"
        placeholder="TT.MM.JJJJ"
        name={`events.${index}.endDate`}
      />
      <AdminFormText
        as="input"
        type="text"
        placeholder="14:00 Uhr"
        name={`events.${index}.endTime`}
      />
      <AdminFormText
        as="input"
        type="text"
        placeholder="Bsp. Berlin"
        name={`events.${index}.location`}
      />
      <Box
        onClick={onClickHandleDelete}
        sx={{
          height: '38px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box component="img" src={TrashIcon} alt="delete icon" />
      </Box>
    </React.Fragment>
  );
};

export default AdminEventRow;
