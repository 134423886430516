import { Paper, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import AdminSidebar from '../AdminSidebar';

/**
 * Use this component as the white background container that is used on almost all admin pages.
 *
 * It automatically sets the correct color, padding, size and top margin.
 *
 * You can also add an [`AdminSidebar`](http://localhost:6006/?path=/docs/components-adminsidebar--docs) by setting `sidebarElements` as an array of elements.
 */
const AdminMainContainer = ({ sidebarElements = [], children }) => {
  return (
    <Stack
      direction="row"
      sx={{
        marginTop: 3,
      }}
    >
      {sidebarElements.length > 0 && (
        <AdminSidebar elements={sidebarElements} />
      )}
      <Paper elevation={0} square sx={{ padding: 4, width: '100%' }}>
        {children}
      </Paper>
    </Stack>
  );
};

AdminMainContainer.propTypes = {
  /** The element that should be displayed in the main content area. */
  children: PropTypes.node.isRequired,

  /** An array of individual components that should be shown in the [`AdminSidebar`](http://localhost:6006/?path=/docs/components-adminsidebar--docs). */
  sidebarElements: PropTypes.arrayOf(PropTypes.node),
};

export default AdminMainContainer;
