import React from 'react';

const MailSVG = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.8038 9.96093L21.5962 16.0499C21.55 16.0943 21.55 16.161 21.5962 16.2054L25.9404 20.6611C26.2346 20.9444 26.2346 21.3999 25.9404 21.6833C25.7962 21.8222 25.6 21.8944 25.4096 21.8944C25.2192 21.8944 25.0231 21.8222 24.8788 21.6833L20.5519 17.2443C20.5058 17.1999 20.4308 17.1999 20.3846 17.2443L19.3288 18.2777C18.4462 19.1388 17.275 19.6166 16.0173 19.6222C14.7423 19.6277 13.5308 19.111 12.6365 18.2388L11.6212 17.2443C11.575 17.1999 11.5 17.1999 11.4538 17.2443L7.12692 21.6833C6.98269 21.8222 6.78654 21.8944 6.59615 21.8944C6.40577 21.8944 6.20962 21.8222 6.06538 21.6833C5.77115 21.3999 5.77115 20.9444 6.06538 20.6611L10.4096 16.2054C10.45 16.161 10.45 16.0943 10.4096 16.0499L4.19615 9.96093C4.12115 9.8887 4 9.9387 4 10.0387V22.2222C4 23.2 4.83077 24 5.84615 24H26.1538C27.1692 24 28 23.2 28 22.2222V10.0387C28 9.9387 27.8731 9.89426 27.8038 9.96093Z"
      fill={color === 'yellow' ? '#f4da55' : '#CECECE'}
    />
    <path
      d="M16.0002 18.1723C16.854 18.1723 17.6559 17.8501 18.2559 17.2612L27.3079 8.38889C26.9905 8.14445 26.5982 8 26.1656 8H5.84055C5.40786 8 5.00978 8.14445 4.69824 8.38889L13.7502 17.2612C14.3444 17.8446 15.1463 18.1723 16.0002 18.1723Z"
      fill={color === 'yellow' ? '#f4da55' : '#CECECE'}
    />
  </svg>
);

export default MailSVG;
