import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import { useGlobalState } from '../../hooks';
import { getProductTranslatedData } from '../../utils';

export const NeuesCoachingModal = ({
  show,
  onHide,
  onCategoryClick,
  coachFinderItems,
}) => {
  const [{ locale }] = useGlobalState();
  const [t] = useTranslation();

  return (
    <FgiModal
      color="yellow"
      title={t('coaching.new.title')}
      description={t('Wobei dürfen wir Sie unterstützen?')}
      onHide={onHide}
      show={show}
    >
      <div className="modal-list-container">
        {coachFinderItems.map((item) => (
          <div key={item.id} className="modal-list-container-item">
            <div className="modal-list-container-item-title title-article">
              {getProductTranslatedData(item.title, locale)}
            </div>
            <div className="modal-list-container-item-description description">
              {getProductTranslatedData(item.description, locale)}
            </div>
            <Button
              onClick={onCategoryClick(item)}
              size="medium"
              variant="main"
            >
              {t('Wählen')}
            </Button>
          </div>
        ))}
      </div>
    </FgiModal>
  );
};

NeuesCoachingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onCategoryClick: PropTypes.func.isRequired,
  coachFinderItems: PropTypes.array.isRequired,
};

export default NeuesCoachingModal;
