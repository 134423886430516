import ContentTypes from '../constants/ContentTypes';
import ProjectTypes from '../constants/ProjectTypes';
import { useGlobalState } from './useGlobalState';

/**
 * @function useAvailableContent
 * @description hook for any place in the code, where based on the type of content we need to check user access to it
 * @returns {Function} isAvailable
 */

const useAvailableContent = () => {
  const [{ available_products, events, user }] = useGlobalState();
  /**
   * @function isAvailable
   * @description function, that can be called with contentType to find out if the user has access or not
   * @param {String} contentType - the type of the content
   * @param {Object} [state] - used to overwrite data from globalState
   * @returns {Boolean}
   */
  const isAvailable = (contentType, state) => {
    const currentUser = state?.user || user;
    const availableProducts = state?.available_products || available_products;
    const userEvents = state?.events || events;
    if (
      (currentUser?.productVisibility &&
        !currentUser.productVisibility.includes(contentType)) ||
      !contentType ||
      !Object.values(ContentTypes).includes(contentType)
    ) {
      return false;
    }
    if (Object.values(ProjectTypes).includes(contentType)) {
      return availableProducts?.some((i) => i.type === contentType);
    }
    if (contentType === ContentTypes.OPEN_WORKSHOP) {
      return !!userEvents?.length;
    }
    return false;
  };
  return isAvailable;
};

export default useAvailableContent;
