import { Typography, Stack, Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Button from '../Button';

const LOADING = 'loading';
const SUCCESS = 'success';
const ERROR = 'error';

const AdminReportCard = ({ onButtonClick, title, text }) => {
  const [state, setState] = useState();

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Typography variant="titleSection">{title}</Typography>
      <Typography variant="primaryText">{text}</Typography>
      {(() => {
        switch (state) {
          case SUCCESS:
            return (
              <Alert severity="success">
                Der Report wird im Hintergrund erstellt und Ihnen per E-Mail
                gesendet.
              </Alert>
            );
          case ERROR:
            return (
              <Alert severity="error">
                Es ist ein Fehler aufgetreten. Wir arbeiten daran.
              </Alert>
            );
          default:
            return (
              <Button
                onClick={() => {
                  setState(LOADING);
                  onButtonClick(
                    () => setState(SUCCESS),
                    () => setState(ERROR),
                  );
                }}
                isLoading={state === LOADING}
                variant="light"
                size="medium"
              >
                Herunterladen
              </Button>
            );
        }
      })()}
    </Stack>
  );
};

AdminReportCard.propTypes = {
  /** parameter is a function that is required . It is used to handle the button click  */
  onButtonClick: PropTypes.func.isRequired,
  /** shows text by card */
  text: PropTypes.string.isRequired,
  /**shows a title by card */
  title: PropTypes.string.isRequired,
};

export default AdminReportCard;
