import { CircularProgress, Alert } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * A simple wrapper to render a loading spinner, display an error message or display content that has
 * been loeaded. Use it if content is not available at first render and only becomes available later on.
 */

const LoadingWrapper = ({
  children,
  isLoading = false,
  isError = false,
  errorMessage = 'Loading failed',
}) => {
  if (isLoading) {
    return <CircularProgress />;
  } else if (isError) {
    return <Alert severity="error">{errorMessage}</Alert>;
  } else {
    return children;
  }
};

LoadingWrapper.propTypes = {
  /** The content that should be rendered when loading as succeeded */
  children: PropTypes.node.isRequired,

  /** Indicates if loading is still in progress */
  isLoading: PropTypes.bool,

  /** Indicates if loading has failed */
  isError: PropTypes.bool,

  /** If loading has failed, this will be the error message that will be displayed */
  errorMessage: PropTypes.string,
};

export default LoadingWrapper;
