import * as yup from 'yup';

const firstCoachingFormSchema = (t) =>
  yup.object({
    date_of_birth: yup
      .string()
      .required(t('Bitte geben Sie Ihr Geburtsdatum an')),
    nationality: yup.string().required(t('Bitte wählen Sie Ihre Nationalität')),
    language: yup
      .string()
      .required(t('Bitte geben Sie Ihre bevorzugte Sprache an')),
    location: yup.string().required(t('Bitte geben Sie Ihren Standort an')),
  });

export default firstCoachingFormSchema;
