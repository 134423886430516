import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { EmailAuthProvider } from 'firebase/auth';
import {
  getAuth,
  signInWithEmailAndPassword as signIn,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  query,
  collection,
  collectionGroup,
  getDocs,
  addDoc,
  where,
  deleteDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';

import { setAmplitudeUserProperties } from '../amplitude';
import { db, functions } from '../firebaseConfig';
import {
  SET_AVAILABLE_PRODUCTS,
  SET_COMPANY,
  SET_USER,
  useGlobalState,
  useAvailablePage,
} from '../hooks';
import { getLink, getRedirectToLink, Monitoring } from '../utils';
import {
  loadEventsList,
  getAvailableProducts,
  getCustomToken,
  getCompanyById,
  getAllProjects,
} from './';

const COLLECTION_NAME = 'users';

/**
 * @method createUser
 * @param {string} email - email for new user
 * @param {string} password - password for new user
 * @summary create new user with email and password
 * @returns {Promise<Object>} new user
 */
export const createUser = (user) => {
  const cloudFunction = httpsCallable(functions, 'users-create');
  return cloudFunction(user);
};

/**
 * @method getUserById
 * @param {string} id - user id
 * @summary get user by id
 * @returns {Promise<Object || null>} user data or null
 */
export const getUserById = async (id) => {
  const docRef = doc(db, COLLECTION_NAME, id);

  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    return null;
  }
  return { uid: docSnap.id, ...docSnap.data() };
};

/**
 * @method signInWithEmailAndPassword
 * @param {string} email - user email
 * @param {string} password - user password
 * @summary sign in user with email and password
 * @returns {Promise<Object>} user data
 */
export const signInWithEmailAndPassword = async (email, password) => {
  try {
    const auth = getAuth();
    return await signIn(auth, email, password);
  } catch (err) {
    throw new Error(err);
  }
};

export const useLoadUserAndCompany = () => {
  const [, dispatch] = useGlobalState();

  /** Takes a [Firebase User object](https://firebase.google.com/docs/reference/js/auth.user.md#user_interface) */
  return async ({ uid, emailVerified }) => {
    const user = await getUserById(uid);

    dispatch({ type: SET_USER, payload: { ...user, emailVerified } });

    const company = await getCompanyById(user.company_id);

    /**
     * Load available products
     */

    // Get generic products for all users from company
    const availableProductRefs = company.available_products;

    // If we have groups set for both users and company, ...
    if (user.groups?.length > 0 && company.samlGroups?.length > 0) {
      // We cycle through each of the user's groups and...
      user.groups.forEach((userGroup) => {
        // get the corresponding group from the company's groups
        const samlGroup = company.samlGroups.find(
          (samlGroup) => samlGroup.id === userGroup,
        );

        // and finally push products of that group to our list of available products
        if (samlGroup?.products) {
          availableProductRefs.push(...samlGroup.products);
        }
      });
    }

    // Get actual products from database
    const available_products = await getAvailableProducts(availableProductRefs);

    // Save products to global state
    dispatch({ type: SET_COMPANY, payload: company });

    const role = user.admin ? 'Admin' : 'User';
    setAmplitudeUserProperties({ company: company.name, role });
    Monitoring.trackEvent('Login approved');

    getAllProjects(dispatch, uid);

    // Set user product visibility
    if (localStorage.getItem('productVisibility')) {
      const productVisibility = localStorage.getItem('productVisibility');
      const updatedUser = await updateUserById(user.uid, {
        productVisibility,
      });
      dispatch({ type: SET_USER, payload: updatedUser });
      localStorage.removeItem('productVisibility');
    }

    dispatch({
      type: SET_AVAILABLE_PRODUCTS,
      payload: available_products,
    });

    const events = await loadEventsList({
      company_id: user.company_id,
      user_id: user.uid,
      dispatch,
    });

    return { events, company, user, available_products };
  };
};

export const usePostSignInHook = () => {
  const getAvailablePage = useAvailablePage();
  const navigate = useNavigate();
  const loadUserAndCompany = useLoadUserAndCompany();

  /** Takes a [Firebase User object](https://firebase.google.com/docs/reference/js/auth.user.md#user_interface) */
  return async (authUser) => {
    const { events, company, user, available_products } =
      await loadUserAndCompany(authUser);

    if (authUser.emailVerified) {
      // Redirect
      if (company.redirect_after_login_to) {
        const token = await getCustomToken();
        return window.open(
          getRedirectToLink(token, company.redirect_after_login_to),
          '_self',
        );
      }

      const availablePage = getAvailablePage({
        events,
        available_products,
        user,
      });

      navigate(getLink(company, availablePage));
    }
  };
};

/**
 * @method saveUserById
 * @param {string} id - user id
 * @param {object} data - user data (gender, name...)
 * @summary create user doc in db
 */
export const saveUserById = async (id, data) => {
  try {
    Sentry.setUser({ id, ...data });
    const docRef = doc(db, COLLECTION_NAME, id);
    await setDoc(docRef, data, { merge: true });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method updateUserById
 * @param {string} id - user id
 * @param {object} data - updated user data (gender, name...)
 * @summary update user data
 * @returns {Promise<Object>} new user data
 */
export const updateUserById = async (id, data) => {
  try {
    const userRef = doc(db, COLLECTION_NAME, id);
    await updateDoc(userRef, data);
    const userSnap = await getDoc(userRef);
    return { ...userSnap.data(), uid: userSnap.id };
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const useUpdateUserByIdMutation = () => {
  const [, dispatch] = useGlobalState();

  return useMutation(async ({ id, data }) => {
    const response = await updateUserById(id, data);
    dispatch({ type: 'SET_USER', payload: response });
    return response;
  });
};

/**
 * @method reAuthenticate
 * @param {string} currentPassword - user current password
 * @summary - reauthenticate user to app
 */
const reAuthenticate = async (currentPassword) => {
  const auth = getAuth();
  const cred = EmailAuthProvider.credential(
    auth.currentUser.email,
    currentPassword,
  );
  await reauthenticateWithCredential(auth.currentUser, cred);
};

/**
 * @method updateUserEmail
 * @param {string} email - new user email
 * @param {string} currentPassword - user current password
 * @summary - update user email
 * @returns {Promise<Object>} user with updated email
 */
export const updateUserEmail = async (email, currentPassword) => {
  try {
    const auth = getAuth();
    await reAuthenticate(currentPassword);
    await updateEmail(auth.currentUser, email);
    return auth.currentUser;
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method updateUserPassword
 * @param {string} password - new user password
 * @param {string} currentPassword - user current password
 * @summary update user password
 */
export const updateUserPassword = async (password, currentPassword) => {
  try {
    const auth = getAuth();
    await reAuthenticate(currentPassword);
    await updatePassword(auth.currentUser, password);
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method getOpenWorkshopBookingsByUserId
 * @param {string} user_id - id of user looking for
 * @summary get all open workshopbookings
 * @returns {Promise<Array>} open workshopbookings array or empty array
 */

export const getOpenWorkshopBookingsByUserId = async (user_id) => {
  try {
    const q = query(collection(db, `users/${user_id}/openWorkshopBookings`));
    const openWorkshopBookingsSnap = await getDocs(q);

    const openWorkshopBookings = openWorkshopBookingsSnap.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return openWorkshopBookings;
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method addOpenWorkshopBookingsByUserId
 * @param {string} user_id - id of user looking for
 * @param {string} eventId - id of user looking for
 * @param {array} focusTopics -checked focus topics
 * @summary update open workshopbookings
 * @returns {Promise<Boolean>} bool
 */

export const addOpenWorkshopBookingsByUserId = async (
  user_id,
  eventId,
  focusTopics,
  workshopId,
) => {
  try {
    const bookingsRef = collection(db, `users/${user_id}/openWorkshopBookings`);
    await addDoc(bookingsRef, {
      focusTopics,
      eventId,
      workshopId,
      createdAt: new Date(),
    });
    return true;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};

/**
 * @method deleteEventFromUserById
 * @param {string} user_id - id of user looking for
 * @param {string} eventId - id of user looking for
 * @summary delete open workshopbookings
 * @returns {Promise<Boolean>} bool
 */

export const deleteEventFromUserById = async (user_id, event_id) => {
  try {
    const bookingsRef = collection(db, `users/${user_id}/openWorkshopBookings`);
    const q = query(bookingsRef, where('eventId', '==', event_id));
    const bookings = await getDocs(q);
    bookings.forEach((element) => deleteDoc(element.ref));
    return true;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};

/**
 * @method updateEventFromUserById
 * @param {string} user_id - id of user looking for
 * @param {string} eventId - id of user looking for
 * @param {array} focusTopics -checked focus topics
 * @summary delete open workshopbookings
 * @returns {Promise<Boolean>} bool
 */

export const updateEventFromUserById = async (
  user_id,
  event_id,
  focusTopics,
  workshopId,
) => {
  try {
    const bookingsRef = collection(db, `users/${user_id}/openWorkshopBookings`);
    const q = query(bookingsRef, where('eventId', '==', event_id));
    const bookings = await getDocs(q);

    bookings.forEach((element) => {
      setDoc(
        element.ref,
        { focusTopics, eventId: event_id, workshopId },
        { merge: true },
      );
    });
    return true;
  } catch (err) {
    Sentry.captureException(err);

    return false;
  }
};

/**
 * @function getAllUsers
 * @description fetch all users in the app and return them
 * @returns {Promise<Array>} all users or empty array
 */
export const getAllUsers = async () => {
  try {
    const usersRef = collection(db, COLLECTION_NAME);
    const usersSnap = await getDocs(query(usersRef));
    if (usersSnap.empty) {
      return [];
    }
    return usersSnap.docs.map((user) => ({
      ...user.data(),
      id: user.id,
    }));
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function getAllQuestionnaireUsers
 * @description fetch all questionnaire users in the app and return them
 * @returns {Promise<Array>} all questionnaire users or empty array
 */
export const getAllQuestionnaireUsers = async () => {
  const usersRef = collection(db, 'questionnaireApp');
  const usersSnap = await getDocs(query(usersRef));
  if (usersSnap.empty) {
    return [];
  }
  return usersSnap.docs.map((snap) => ({
    id: snap.id,
    ...snap.data(),
  }));
};

/**
 * @function getAllWorkshopsWithUsers
 * @description fetch all workshops with users as the owners and return them
 * @returns {Promise<Array>} all workshops with users
 */
export const getAllWorkshopsWithUsers = async (id) => {
  try {
    const workshopsRef = collectionGroup(db, 'openWorkshopBookings');
    const workshopsSnap = await getDocs(query(workshopsRef));
    if (workshopsSnap.empty) {
      return [];
    }
    const filteredWorkshops = workshopsSnap.docs.filter(
      (doc) => doc.data().eventId === id,
    );
    if (!filteredWorkshops.length) {
      return [];
    }
    const allUsers = await getAllUsers();
    return filteredWorkshops.map((workshop) => ({
      ...workshop.data(),
      id: workshop.id,
      user: allUsers.find((u) => u.id === workshop.ref.parent.parent.id),
    }));
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const getWorkshopByEventId = async (id) => {
  try {
    const eventsRef = collectionGroup(db, 'events');
    const eventsSnap = await getDocs(query(eventsRef));
    const event = eventsSnap.docs.find((e) => e.id === id);
    const bookingRef = event.ref.parent.parent;
    const booking = await getDoc(bookingRef);
    return booking.data();
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const deleteOpenBooking = async (currentWorkshop) => {
  try {
    const workshopRef = doc(
      db,
      COLLECTION_NAME,
      currentWorkshop.user.id,
      'openWorkshopBookings',
      currentWorkshop.id,
    );
    await deleteDoc(workshopRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};
