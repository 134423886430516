import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import Button from '../../../components/Button';
import FgiModal from '../../../components/FgiModal';
import { useGlobalState } from '../../../hooks';
import MainInfo from '../MainInfo';
import './InfoPartOfModal.scss';

export const InfoPartOfModal = ({
  item,
  nextStep,
  prevStep,
  onHide,
  signOut,
}) => {
  const { text, urlToPdf, pdfUrl, isEditing, dateUntilRegistrationNotice } =
    item;
  const [{ locale }] = useGlobalState();
  const [t] = useTranslation();

  return (
    <FgiModal
      title={t('Angebot')}
      onHide={onHide}
      show
      color="dark-orchid"
      showBackButton
      backClick={prevStep}
    >
      <MainInfo item={item} />
      <hr className="info-part-of-modal-hr" />
      <div className="info-part-of-modal-text">
        <ReactMarkdown>{text[locale]}</ReactMarkdown>
      </div>
      {isEditing ? (
        <div className="info-part-of-modal-buttons-container info-part-of-modal-buttons-container-edit">
          {(urlToPdf || pdfUrl?.[locale]) && (
            <a
              href={urlToPdf || pdfUrl?.[locale]}
              className="info-part-of-modal-link"
              download="newname.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="medium" variant="dark-orchid" arrow="none">
                {t('PDF herunterladen')}
              </Button>
            </a>
          )}
          <div className="container-info-part-of-modal">
            <div className="info-part-of-modal-text-above-button">
              {t('Änderungen möglich bis {{val, datetime}}', {
                val: dateUntilRegistrationNotice,
              })}
            </div>
            <Button
              size="medium"
              variant="light"
              arrow="default"
              onClick={nextStep}
            >
              {t('Themenschwepunkt Wählen')}
            </Button>
          </div>
          <Button
            size="medium"
            variant="light"
            arrow="default"
            onClick={signOut}
          >
            {t('Abmelden')}
          </Button>
        </div>
      ) : (
        <div className="info-part-of-modal-buttons-container">
          <Button
            size="small"
            variant="dark-orchid"
            arrow="default"
            onClick={nextStep}
          >
            {t('Jetzt Anmelden')}
          </Button>
          {(urlToPdf || pdfUrl?.[locale]) && (
            <a
              href={urlToPdf || pdfUrl?.[locale]}
              className="info-part-of-modal-link"
              download="newname.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="small" variant="light" arrow="none">
                {t('PDF herunterladen')}
              </Button>
            </a>
          )}
        </div>
      )}
    </FgiModal>
  );
};

export default InfoPartOfModal;
