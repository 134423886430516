import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';

import BackArrow from '../../assets/svgs/BackArrow.js';
import './FgiModal.scss';

const FgiModal = ({
  children,
  show,
  onHide,
  title,
  description,
  showCloseButton,
  className,
  showBackButton,
  backClick,
  color = 'yellow',
  ...restProps
}) => (
  <Modal
    onHide={onHide}
    show={show}
    className={`fgi-modal ${className}`}
    {...restProps}
  >
    <ModalHeader>
      {showBackButton && (
        <div className="modal-back-arrow back" onClick={backClick}>
          <BackArrow />
        </div>
      )}
      <ModalTitle>
        <span className={`colorBar colorBar__${color}`} />
        <h4 className="title-modal">{title}</h4>
      </ModalTitle>
      {showCloseButton && (
        <div onClick={onHide} className="close">
          <span>×</span>
        </div>
      )}
    </ModalHeader>
    {description && (
      <div className="fgi-modal__description description">{description}</div>
    )}
    <ModalBody>{children}</ModalBody>
    <div className="fgi-modal__bottom-part" />
  </Modal>
);

FgiModal.defaultProps = {
  showCloseButton: true,
  showBackButton: false,
};

FgiModal.propTypes = {
  /**It is an optional string property that represents the color of the modal.  */
  color: PropTypes.string,
  /**required node property that represents the content of the modal.  */
  children: PropTypes.node.isRequired,
  /**required boolean property that determines whether the modal is visible or not.  */
  show: PropTypes.bool.isRequired,
  /** It is a required function property that handles the event when the modal is closed or hidden. */
  onHide: PropTypes.func.isRequired,
  /**It is an optional string property that allows you to add custom CSS classes to the modal */
  className: PropTypes.string,
  /** It is an optional string property that represents the title of the modal. */
  title: PropTypes.string,
  /**t is an optional property that can be either a string or a node */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** It is an optional boolean property that determines whether to show a close button in the modal*/
  showCloseButton: PropTypes.bool,
  /** It is an optional boolean property that determines whether to show a back button in the modal*/
  showBackButton: PropTypes.bool,
};

export default FgiModal;
