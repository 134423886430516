import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { confirmPasswordReset, checkActionCodeOnFirebase } from '../../api';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import LoadingWrapper from '../../components/LoadingWrapper';
import Monitoring from '../../utils/monitoring';
import NewPasswordModalSchema from './NewPasswordModalSchema';

const LOADING = 'loading';
const ERROR = 'error';
const SUCCESS = 'success';

export const NewPasswordModal = ({ show, onHide, oobCode, onSubmit }) => {
  const [t] = useTranslation();
  const [state, setState] = useState(LOADING);

  useEffect(() => {
    checkActionCodeOnFirebase(oobCode)
      .then((result) => {
        setState(SUCCESS);
      })
      .catch((e) => {
        switch (e.code) {
          case 'auth/invalid-action-code':
            setState(ERROR);
            break;
          default:
            Monitoring.captureException(e);
        }
      });
  });

  const initialFormValues = {
    password: '',
    confirmPassword: '',
  };

  const submit = async ({ password }) => {
    try {
      await confirmPasswordReset(oobCode, password);
      onSubmit();
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-action-code':
          setState(ERROR);
          break;
        default:
          Monitoring.captureException(e);
      }
    }
  };

  return (
    <FgiModal
      color="yellow"
      show={show}
      onHide={onHide}
      title={t('Neues Passwort')}
      description={t('Bitte geben Sie Ihr neues Passwort ein')}
    >
      <LoadingWrapper
        isLoading={state === LOADING}
        isError={state === ERROR}
        errorMessage={t('NewPasswordModal.error.invalidActionCode.message')}
      >
        <Formik
          initialValues={initialFormValues}
          validationSchema={NewPasswordModalSchema(t)}
          onSubmit={submit}
        >
          {({ handleSubmit, errors, handleChange, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>{t('Passwort *')}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="on"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>{t('Passwort wiederholen *')}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="on"
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="modal-footer-button">
                <Button variant="main" size="medium" type="submit">
                  {t('Speichern')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </LoadingWrapper>
    </FgiModal>
  );
};

NewPasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  oobCode: PropTypes.string.isRequired,
};

export default NewPasswordModal;
