import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { getCoachProfilePdfURL } from '../../api';
import Button from '../Button';
import './ButtonPdfProfile.scss';

/**
 * Creates a button that links to a Coach Profile
 *
 * @param {coachId} The database ID of the coach of which the button should load the PDF.
 */
const ButtonPdfProfile = ({ coachId }) => {
  const [t] = useTranslation();
  return (
    <Button
      size="small"
      className="review-coaches-modal-coaches-container-item-info-button"
      variant="light"
      onClick={async () => {
        window.open(await getCoachProfilePdfURL(coachId));
      }}
    >
      <Fragment>
        <span className="desktop">{t('Als PDF öffnen')}</span>
        <span className="mobile">{t('PDF')}</span>
      </Fragment>
    </Button>
  );
};

ButtonPdfProfile.propTypes = {
  /** The ID of the coach to whose profile the button should link to */
  coachId: PropTypes.string.isRequired,
};

export default ButtonPdfProfile;
