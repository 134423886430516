import * as yup from 'yup';

import ProjectTypes from '../../../../constants/ProjectTypes';
import { Locales } from '../../../../i18n';

const languageSchema = yup.object(
  Object.keys(Locales).reduce((acc, key) => {
    acc[Locales[key]] = yup
      .string()
      .required('Bitte alle Felder ausfüllen')
      .nullable()
      .test('test', 'Bitte alle Sprachen ausfüllen', function () {
        return Object.keys(Locales).every((otherKey) =>
          otherKey === key ? true : this.parent[Locales[otherKey]],
        );
      });
    return acc;
  }, {}),
);

const adminProductsNewFormSchema = yup.object({
  title: languageSchema,
  type: yup
    .string()
    .oneOf(Object.values(ProjectTypes), 'Ungültige Kategorie')
    .required('Bitte eine Kategorie wählen')
    .nullable(),
  description: languageSchema,
  hourly_rate: yup.mixed().when('type', {
    is: (value) =>
      value === ProjectTypes.COACH || value === ProjectTypes.MODERATOR,
    then: yup.number().typeError('Bitte eine Zahl angeben.'),
    otherwise: yup.mixed().notRequired(),
  }),
  hours_needed: yup.mixed().when('type', {
    is: (value) =>
      value === ProjectTypes.COACH || value === ProjectTypes.MODERATOR,
    then: yup.number().typeError('Bitte eine Zahl angeben.'),
    otherwise: yup.mixed().notRequired(),
  }),
  is_virtual: yup.string().when('type', {
    is: (value) =>
      value === ProjectTypes.COACH || value === ProjectTypes.MODERATOR,
    then: yup.string().required('Bitte alle Felder ausfüllen'),
    otherwise: yup.string().notRequired(),
  }),
  course_id: yup.object().when('type', {
    is: ProjectTypes.ELEARNING,
    then: languageSchema.required(),
    otherwise: yup.object().notRequired(),
  }),
  pdfUrl: yup.mixed(),
  image: yup
    .object({
      name: yup.string().required(),
    })
    .required('Bitte laden Sie ein Bild hoch')
    .nullable(),
});

export default adminProductsNewFormSchema;
