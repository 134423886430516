/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import Excel from 'exceljs';

import { downloadReport } from '.';
import { getAllianzBillingReport } from '../api';

export const getBillingReport = async () => {
  try {
    const { data } = await getAllianzBillingReport();
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(JSON.parse(data).data);
    const buffer = await workbook.xlsx.writeBuffer();

    downloadReport(buffer, 'AllianzBillingReport');
  } catch (err) {
    Sentry.captureException(err);
  }
};
