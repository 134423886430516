import * as Sentry from '@sentry/react';
import {
  checkActionCode,
  applyActionCode,
  confirmPasswordReset as confirmPassReset,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

import { auth, functions } from '../firebaseConfig';

/**
 * @method getCustomToken
 * @summary create custom token for user
 * @returns {Promise} token
 */
export const getCustomToken = async () => {
  const customToken = httpsCallable(functions, 'createCustomToken');
  const { data } = await customToken();
  return data;
};

/**
 * @method applyActionCodeOnFirebase
 * @summary apply action code
 */
export const applyActionCodeOnFirebase = (oobCode) =>
  applyActionCode(auth, oobCode);

/**
 * @function checkActionCodeOnFirebase
 * @param {String} oobCode - code to be checked
 * @returns {Promise<Object>} - metadata about the code
 */
export const checkActionCodeOnFirebase = (oobCode) =>
  checkActionCode(auth, oobCode);
/**
 * @method getCurrentUser
 * @summary getting current user
 * @returns {object} user
 */
export const getCurrentUser = () => auth.currentUser;

/**
 * Returns true if the current user is signed in using a SAML provider.
 */
export const isSAMLProvider = () => {
  const user = getCurrentUser();

  if (!user) {
    return false;
  }

  const result = !user || user.providerData[0].providerId !== 'password';
  return result;
};

export const confirmPasswordReset = async (oobCode, password) => {
  await confirmPassReset(auth, oobCode, password);
};

export const onAuthStateChanged = (callback) => {
  try {
    return auth.onAuthStateChanged(callback);
  } catch (err) {
    Sentry.captureException(err);
  }
};
