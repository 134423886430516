import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { trigger_resetPassword } from '../../api';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import { useGlobalState } from '../../hooks';
import ResetPasswordModalSchema from './ResetPasswordModalSchema';

export const ResetPasswordModal = ({ show, onHide }) => {
  const [{ locale }] = useGlobalState();
  const [t] = useTranslation();

  const initialFormValues = {
    email: '',
  };

  const submit = async ({ email }) => {
    try {
      await trigger_resetPassword({ email, locale });
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      onHide();
    }
  };

  return (
    <FgiModal
      className="small-modal"
      color="yellow"
      show={show}
      onHide={onHide}
      description={t('Bitte geben Sie ihre E-Mail-Adresse ein. Sie erhalten dann einen Link zum Zurücksetzen des Passworts.')} // prettier-ignore
      title={t('Passwort zurücksetzen')}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={ResetPasswordModalSchema(t)}
        onSubmit={submit}
      >
        {({ handleSubmit, touched, errors, handleChange }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('E-Mail-Adresse *')}</Form.Label>
                <Form.Control
                  as="input"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className="modal-footer-button">
              <Button type="submit" size="medium" variant="main">
                {t('Zurücksetzen')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </FgiModal>
  );
};

ResetPasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ResetPasswordModal;
