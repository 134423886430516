import * as Sentry from '@sentry/react';
import moment from 'moment';

import {
  getAllEventsByCompanyIdAndWorkshopId,
  getAllWorkshopsByCompanyId,
  getOpenWorkshopBookingsByUserId,
} from '.';
import ContentTypes from '../constants/ContentTypes';
import { GET_ALL_EVENTS_BY_COMPANY_ID } from '../hooks';
import { Locales } from '../i18n';

const getEventsList = async ({ workshops, userEvents, company_id }) => {
  const dataToList = [];

  for await (const workshop of workshops) {
    const events = await getAllEventsByCompanyIdAndWorkshopId(
      company_id,
      workshop.id,
    );

    for await (const event of events) {
      const {
        noticePeriodInDays,
        title,
        text,
        imageUrl,
        maxParticipants,
        focusTopics,
        urlToPdf = null,
        pdfUrl = Object.values(Locales).reduce((acc, locale) => {
          acc[locale] = null;
          return acc;
        }, {}),
      } = workshop;
      const { location, id, numBookings } = event;
      const start = event.start.toDate();
      const end = event.end.toDate();
      const dateUntilRegistrationNotice = moment(event.start.toDate())
        .subtract(noticePeriodInDays, 'days')
        .toDate();

      // Skip, if event can't be booked or changed anymore
      if (Date.now() > dateUntilRegistrationNotice) continue;

      const checkedEvent = userEvents?.find((item) => item.eventId === id);
      const isAlreadyBooking = !!checkedEvent;
      let isEditing = false;

      let checkedFocused = [];
      if (!isAlreadyBooking) {
        checkedFocused = focusTopics.map((item) => {
          return {
            name: item,
            isActive: false,
          };
        });
      } else {
        const { focusTopics: userFocusTopics } = checkedEvent;
        isEditing = true;
        checkedFocused = focusTopics.map((item) => {
          if (userFocusTopics.includes(item)) {
            return {
              name: item,
              isActive: true,
            };
          }
          return {
            name: item,
            isActive: false,
          };
        });
      }

      const isAvailable = numBookings < maxParticipants;
      const newItem = {
        id,
        title,
        location,
        start,
        end,
        dateUntilRegistrationNotice,
        text,
        imageUrl,
        isAlreadyBooking,
        isAvailable,
        focusTopics: checkedFocused,
        urlToPdf,
        pdfUrl,
        isEditing,
        workshopId: workshop.id,
      };
      dataToList.push(newItem);
    }
  }
  return dataToList;
};

const getUserEvents = async ({ workshops, userEvents, company_id }) => {
  const dataToList = [];

  for await (const workshop of workshops) {
    const events = await getAllEventsByCompanyIdAndWorkshopId(
      company_id,
      workshop.id,
    );

    for await (const event of events) {
      const {
        noticePeriodInDays,
        title,
        text,
        imageUrl,
        maxParticipants,
        focusTopics,
        urlToPdf = null,
        pdfUrl = Object.values(Locales).reduce((acc, locale) => {
          acc[locale] = null;
          return acc;
        }, {}),
      } = workshop;
      const { location, id, numBookings } = event;
      const start = event.start.toDate();
      const end = event.end.toDate();
      const dateUntilRegistrationNotice = moment(start)
        .subtract(noticePeriodInDays, 'days')
        .toDate();

      const checkedEvent =
        userEvents?.find((item) => item.eventId === id) || {};
      const isAlreadyBooked = checkedEvent.length > 0;
      if (isAlreadyBooked) {
        const { focusTopics: userFocusTopics } = checkedEvent;

        const checkedTopics = focusTopics.map((item) => {
          if (userFocusTopics.includes(item)) {
            return {
              name: item,
              isActive: true,
            };
          }
          return {
            name: item,
            isActive: false,
          };
        });

        const isAvailable = numBookings < maxParticipants;
        const newItem = {
          id,
          title,
          location,
          start,
          end,
          noticePeriodInDays,
          dateUntilRegistrationNotice,
          text,
          imageUrl,
          isAlreadyBooked,
          isAvailable,
          focusTopics: checkedTopics,
          urlToPdf,
          pdfUrl,
          isEditing: true,
          workshopId: workshop.id,
          type: ContentTypes.OPEN_WORKSHOP,
        };
        dataToList.push(newItem);
      }
    }
  }
  return dataToList;
};

const comparator = (a, b) => {
  if (a.start < b.start) {
    return -1;
  }
  if (a.start > b.start) {
    return 1;
  }
  return 0;
};

export const loadEventsList = async ({ company_id, user_id, dispatch }) => {
  try {
    const workshops = await getAllWorkshopsByCompanyId(company_id);
    const userEvents = await getOpenWorkshopBookingsByUserId(user_id);
    const eventsList = await getEventsList({
      workshops,
      userEvents,
      company_id,
    });
    eventsList.sort(comparator);

    dispatch({ type: GET_ALL_EVENTS_BY_COMPANY_ID, payload: eventsList });
    return eventsList;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const loadUserEvents = async ({ company_id, user_id }) => {
  try {
    const workshops = await getAllWorkshopsByCompanyId(company_id);
    const userEvents = await getOpenWorkshopBookingsByUserId(user_id);
    const eventsList = await getUserEvents({
      workshops,
      userEvents,
      company_id,
    });
    eventsList.sort(comparator);

    return eventsList;
  } catch (err) {
    Sentry.captureException(err);
  }
};
