import React from 'react';
import { Navigate } from 'react-router-dom';

import PagePath from '../constants/PagePath';
import ProjectTypes from '../constants/ProjectTypes';
import useAvailableContent from '../hooks/useAvailableContent';
import { useGlobalState } from '../hooks/useGlobalState';
import { getLink } from '../utils';

const withRequiredProjects = (ComposedComponent) => (props) => {
  const [{ company }] = useGlobalState();
  const isAvailable = useAvailableContent();
  if (isAvailable(ProjectTypes.COACH) || isAvailable(ProjectTypes.MODERATOR)) {
    return <ComposedComponent {...props} />;
  }

  return <Navigate to={getLink(company, PagePath.ANMELDEN)} />;
};

export default withRequiredProjects;
