import ContentTypes from '../constants/ContentTypes';
import PagePath from '../constants/PagePath';
import useAvailableContent from './useAvailableContent';

/**
 * @function useAvailablePage
 * @description hook for navigation functionality, where we check the page, the user is able to enter.
 * @returns {Function} getAvailablePage
 */
const useAvailablePage = () => {
  const isAvailable = useAvailableContent();
  /**
   * @function getAvailablePage
   * @description used to get available page based on current global state
   * @param {Object} [state={}] - used to overwrite data in global state
   * @returns {String} page path
   */
  const getAvailablePage = (state = {}) => {
    if (
      isAvailable(ContentTypes.COACH, state) ||
      isAvailable(ContentTypes.MODERATOR, state)
    ) {
      return PagePath.DASHBOARD;
    }
    if (isAvailable(ContentTypes.ELEARNING, state)) {
      return PagePath.ELEARNING;
    }
    if (isAvailable(ContentTypes.OPEN_WORKSHOP, state)) {
      return PagePath.OPEN_WORKSHOPS;
    }
    return PagePath.SETTINGS;
  };
  return getAvailablePage;
};

export default useAvailablePage;
