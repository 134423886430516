import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import NoProjectsIcon from '../../../../assets/icons/NoProjectsIcon.png';

const NoBookingItems = () => {
  const [t] = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xxs: 0, lg: '100px 0' },
      }}
    >
      <img alt={t('bookings.noBooking.title')} src={NoProjectsIcon} />
      <Box
        sx={{
          textAlign: 'center',
          marginTop: 2,
          width: { xxs: '280px', lg: '311px' },
        }}
      >
        <Typography
          sx={{ color: 'grey.light2' }}
          variant="titleProNr"
          paragraph
          gutterBottom
        >
          {t('bookings.noBooking.title')}
        </Typography>
        <Typography variant="primaryText" paragraph>
          {t('bookings.noBooking.text')}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoBookingItems;
