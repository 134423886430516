/* eslint-disable import/prefer-default-export */
import {
  collectionGroup,
  getDocs,
  query,
  collection,
} from '@firebase/firestore';
import * as Sentry from '@sentry/react';
import Excel from 'exceljs';

import { db } from '../firebaseConfig';
import { downloadReport } from './';

const getAllOpenWorkshops = async () => {
  const result = {};
  const openWorkshopRef = collectionGroup(db, 'openWorkshops');
  const openWorkshopSnapshot = await getDocs(query(openWorkshopRef));
  if (openWorkshopSnapshot.empty) {
    return result;
  }
  openWorkshopSnapshot.docs.forEach((doc) => {
    result[doc.id] = doc.data();
  });
  return result;
};

const getAllEvents = async () => {
  const result = {};
  const eventsRef = collectionGroup(db, 'events');
  const eventsSnapshot = await getDocs(query(eventsRef));
  eventsSnapshot.docs.forEach((doc) => {
    result[doc.id] = doc.data();
    [, result[doc.id].workshopdId] = doc.ref.path.match(
      /openWorkshops\/(.*)\/events/i,
    );
  });
  return result;
};

const getAllUsers = async () => {
  const result = {};
  const usersRef = collection(db, 'users');
  const usersSnapshot = await getDocs(query(usersRef));
  usersSnapshot.docs.forEach((doc) => {
    result[doc.id] = doc.data();
  });
  return result;
};

const getAllBookings = async () => {
  const bookingsRef = collectionGroup(db, 'openWorkshopBookings');
  return await getDocs(query(bookingsRef));
};

const getUserIdFromBooking = (doc) => {
  const [, userId] = doc.ref.path.match(
    /users\/(.*)\/openWorkshopBookings\/.*/i,
  );
  return userId;
};

const prepareDataForExcel = async () => {
  const allWorkshops = await getAllOpenWorkshops();
  const allEvents = await getAllEvents();
  const allBookings = await getAllBookings();
  const allUsers = await getAllUsers();

  const result = [];

  allBookings.docs.forEach((booking) => {
    const { eventId, focusTopics } = booking.data();
    if (allEvents[eventId]) {
      const { start, workshopdId } = allEvents[eventId];
      const userId = getUserIdFromBooking(booking);
      const { firstname, lastname, email } = allUsers[userId];

      result.push({
        start: start ? start.toDate() : '-',
        workshopName: allWorkshops[workshopdId].title.en,
        focusTopics: focusTopics.join(', '),
        firstname,
        lastname,
        email,
      });
    }
  });

  return result;
};

const createExcelDocument = async (dataForExcel) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Bookings');

  worksheet.columns = [
    { header: 'Training', key: 'workshopName' },
    { header: 'Datum', key: 'start' },
    { header: 'Schwerpunktthemen', key: 'focusTopics' },
    { header: 'Vorname', key: 'firstname' },
    { header: 'Nachname', key: 'lastname' },
    { header: 'E-Mail-Adresse', key: 'email' },
  ];

  worksheet.columns.forEach((column) => {
    // eslint-disable-next-line no-param-reassign
    column.width = column.header.length < 12 ? 12 : column.header.length;
  });
  worksheet.getRow(1).font = { bold: true };
  worksheet.getColumn(2).numFmt = 'dd.mm.yy hh:mm';
  worksheet.addRows(dataForExcel);

  return workbook;
};

export const getOpenWorkshopsBookings = async () => {
  try {
    const dataForExcel = await prepareDataForExcel();
    const workbook = await createExcelDocument(dataForExcel);
    const buffer = await workbook.xlsx.writeBuffer();
    downloadReport(buffer, 'Training Buchungen');
  } catch (err) {
    Sentry.captureException(err);
  }
};
