/* eslint-disable import/prefer-default-export */

import * as yup from 'yup';

import { Locales } from '../../../../i18n';

const languageSchema = yup.object(
  Object.keys(Locales).reduce((acc, key) => {
    acc[Locales[key]] = yup
      .string()
      .required('Bitte alle Felder ausfüllen')
      .nullable()
      .test('test', 'Bitte alle Sprachen ausfüllen', function () {
        return Object.keys(Locales).every((otherKey) =>
          otherKey === key ? true : this.parent[Locales[otherKey]],
        );
      });
    return acc;
  }, {}),
);

const isValidTime = (value) => {
  const date = new Date(`01.01.2000 ${value ?? ''}`);
  return !isNaN(date.getTime());
};

export const openWorkshopSchema = yup.object({
  imageUrl: yup
    .object({
      name: yup.string().required(),
    })
    .required('Bitte laden Sie ein Bild hoch')
    .nullable(),
  pdfUrl: yup.mixed(),
  title: languageSchema,
  companyId: yup
    .string('Bitte alle Felder ausfüllen')
    .required('Bitte alle Felder ausfüllen')
    .nullable(),
  text: languageSchema,
  maxParticipants: yup
    .number()
    .required('Bitte alle Felder ausfüllen')
    .nullable(),
  noticePeriodInDays: yup
    .number()
    .required('Bitte alle Felder ausfüllen')
    .nullable(),
  events: yup
    .array()
    .of(
      yup.object({
        endDate: yup
          .string()
          .required('Bitte alle Felder ausfüllen')
          .nullable(),
        endTime: yup
          .string()
          .test(
            'isValidTime',
            'Bitte geben Sie nur Stunden und Minuten ein',
            isValidTime,
          )
          .required('Bitte alle Felder ausfüllen')
          .nullable(),
        location: yup
          .string()
          .required('Bitte alle Felder ausfüllen')
          .nullable(),
        startDate: yup
          .string()
          .required('Bitte alle Felder ausfüllen')
          .nullable(),
        startTime: yup
          .string()
          .test(
            'isValidTime',
            'Bitte geben Sie nur Stunden und Minuten ein',
            isValidTime,
          )
          .required('Bitte alle Felder ausfüllen')
          .nullable(),
      }),
    )
    .nullable()
    .transform((events = []) =>
      events.map((event) => ({
        ...event,
        numBookings: 0,
        start: new Date(`${event.startDate} ${event.startTime}`),
        end: new Date(`${event.endDate} ${event.endTime}`),
      })),
    ),
  focusTopics: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required('Bitte alle Felder ausfüllen').nullable(),
      }),
    )
    .max(10, 'Sie können nicht mehr als 10 Themenschwerpunkte anlegen')
    .nullable(),
});
