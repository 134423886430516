import React from 'react';
import { useTranslation } from 'react-i18next';

import './MiddleItem.scss';

export const MiddleItem = () => {
  const [t] = useTranslation();

  return (
    <div className="moderator-finden-middle-item">
      <div className="moderator-finden-middle-item-left-container">
        <div className="title-snippet">
          {t('Workshop-Moderatoren mit Format')}
        </div>
        <div className="description">
          {/* prettier-ignore */}
          <div className="moderator-finden-middle-item-left-container-description">
            {t('Bei der Vor- und Nachbereitung, sowie der Durchführung Ihres Workshops, begleitet Sie ein erfahrener Moderator.')}
          </div>
          {/* prettier-ignore */}
          <div className="moderator-finden-middle-item-left-container-description">
            {t('Die Workshop-Formate werden stets an Ihren individuellen Anforderungen und der spezifischen Ausgangssituation ausgerichtet.')}
          </div>
        </div>
      </div>
      <div className="moderator-finden-middle-item-right-container" />
    </div>
  );
};

export default MiddleItem;
