import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';

import EnterIcon from '../../../assets/svgs/EnterIcon.svg';

/**
 * @param name {string | undefined}
 * @param sx {object | undefined}
 * @return {JSX.Element}
 * @constructor
 */
const AdminFocusTopicInput = ({ name = 'focusTopics', sx }) => {
  const [newFocusTopic, setNewFocusTopic] = useState('');

  const formContext = useFormContext();

  const focusTopicsFieldArrayController = useFieldArray({
    control: formContext.control,
    name: name,
  });

  /**
   * @param e {React.ChangeEvent<HTMLInputElement>}
   */
  const handleOnChangeNewFocusTopic = (e) => {
    setNewFocusTopic(e.target.value);
  };

  const handleOnKeyPressNewFocusTopic = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (newFocusTopic !== '') {
        if (
          focusTopicsFieldArrayController.fields.find(
            (field) => field.name === newFocusTopic,
          )
        ) {
          return;
        }
        setNewFocusTopic('');
        focusTopicsFieldArrayController.append({ name: newFocusTopic });
      }
    }
  };

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <Form.Label>Themenschwerpunkte hinzufügen *</Form.Label>
      <Form.Control
        name="focusTopic"
        as="input"
        onChange={handleOnChangeNewFocusTopic}
        onKeyPress={handleOnKeyPressNewFocusTopic}
        value={newFocusTopic}
        placeholder="Bitte eintragen"
        isInvalid={formContext.formState.errors[name]}
      />
      {!formContext.formState.errors[name] && (
        <Box
          component="img"
          sx={{
            position: 'absolute',
            right: '20px',
            top: '40px',
          }}
          src={EnterIcon}
          alt=""
        />
      )}
      <Form.Control.Feedback type="invalid">
        {formContext.formState.errors[name]?.message}
      </Form.Control.Feedback>
    </Box>
  );
};

export default AdminFocusTopicInput;
