import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback } from 'react';

import { Locales, LocalesFullName } from '../../../i18n';

/**
 * @description This component is used to switch between languages in the admin forms
 * @param selectedLanguage {TLocale}
 * @param handleChangeLanguage {(language: TLocale) => void}
 * @return {JSX.Element}
 * @constructor
 */
const LanguageSwitch = ({ selectedLanguage, handleChangeLanguage }) => {
  /**
   * @param e {React.MouseEvent<HTMLLIElement, MouseEvent>}
   */
  const handleOnClick = useCallback(
    (e) => {
      const selectedLanguage = e.currentTarget.dataset['language'];
      if (selectedLanguage) {
        handleChangeLanguage(selectedLanguage);
      }
    },
    [handleChangeLanguage],
  );

  return (
    <Box>
      <Typography variant="labelText">Sprache</Typography>
      <Ul>
        {Object.values(Locales).map((locale) => (
          <Li
            id={locale}
            key={locale}
            onClick={handleOnClick}
            data-language={locale}
            className={`text-small-1 ${
              locale === selectedLanguage ? 'active' : ''
            }`}
          >
            <span>{LocalesFullName[Locales.DE][locale]}</span>
          </Li>
        ))}
      </Ul>
    </Box>
  );
};

const Ul = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin-top: 15px;
`;

const Li = styled.li`
  position: relative;
  border-bottom: 2px solid #ffffff;
  margin: 0 7.5px;
  cursor: pointer;

  &:hover,
  &.active {
    border-bottom: 2px #f4da55 solid;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:not(:last-of-type) {
    &::after {
      content: '';
      position: absolute;
      height: 17px;
      width: 1px;
      background-color: #e3e3e3ff;
      right: -8px;
    }
  }
`;

export default LanguageSwitch;
