import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AdminCheckbox from '../../../../components/AdminCheckbox';
import ButtonPdfProfile from '../../../../components/ButtonPdfProfile';
import { useGlobalState } from '../../../../hooks';
import { getCoachesTranslatedData } from '../../../../utils';

export const ProjectCandidateTable = ({
  suggested_coaches,
  deleteFromSuggestedCoaches,
}) => {
  const [{ locale }] = useGlobalState();
  const [t] = useTranslation();

  if (suggested_coaches && suggested_coaches.length > 0) {
    return (
      <>
        <div className="project-candidate-title title-section">
          {t('Ausgewählte Kandidaten')}
        </div>
        <table className="project-candidate-table">
          <thead className="secondary-text">
            <tr className="project-candidate-table-head-row">
              <th width="4%" />
              <th width="20%">{t('Name')}</th>
              <th width="20%">{t('E-Mail')}</th>
              <th width="16%">{t('Ort')}</th>
              <th width="20%">{t('Sprache')}</th>
              <th width="20%" />
            </tr>
          </thead>
          <tbody className="primary-text">
            {suggested_coaches.map((coach) => {
              return (
                <tr
                  key={coach.email}
                  className="project-candidate-table-body-row"
                >
                  <td>
                    <AdminCheckbox
                      checked
                      onChange={deleteFromSuggestedCoaches(coach)}
                    />
                  </td>
                  <td>{`${coach.firstname} ${coach.lastname}`}</td>
                  <td>{coach.email}</td>
                  <td>{coach.bio_location}</td>
                  <td>
                    {getCoachesTranslatedData(coach.bio_language, locale)}
                  </td>
                  <td>
                    <ButtonPdfProfile coachId={coach.id} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
  return null;
};

ProjectCandidateTable.propTypes = {
  deleteFromSuggestedCoaches: PropTypes.func.isRequired,
  suggested_coaches: PropTypes.array.isRequired,
};

export default ProjectCandidateTable;
