import { Box, Typography } from '@mui/material';
import moment from 'moment';
import momentLocale from 'moment/locale/de';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NoCoach from '../../../../assets/images/_.png';
import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';
import ContentTypes from '../../../../constants/ContentTypes';
import projectStates from '../../../../constants/ProjectStates';
import ProjectTypes from '../../../../constants/ProjectTypes';
import { useGlobalState } from '../../../../hooks';

moment.updateLocale('de', momentLocale);

const BookingCard = ({
  project,
  index,
  openReviewCoachesModal,
  openProjectDetailsModal,
  openFindWorkshopModal,
}) => {
  const [t] = useTranslation();
  const [{ locale }] = useGlobalState();

  const renderNextStepText = () => {
    const { state, type, start, dateUntilRegistrationNotice, end } = project;
    if (type === ContentTypes.OPEN_WORKSHOP) {
      const currentDate = new Date();
      if (currentDate < dateUntilRegistrationNotice) {
        return t('Sie können Änderungen bis zum {{date}} vornehmen.', {
          date: moment(dateUntilRegistrationNotice).format('DD.MM.yyyy'),
        });
      }
      if (currentDate > dateUntilRegistrationNotice && currentDate < start) {
        return t('Freuen Sie sich auf die Veranstaltung');
      }
      if (currentDate > start && currentDate < end) {
        return t('Diese Veranstaltung läuft gerade');
      }
      if (currentDate > end) {
        return t('Diese Veranstaltung ist beendet');
      }
    }
    switch (state) {
      case projectStates.findCoaches:
        return t('Sie erhalten bald eine E-Mail-Benachrichtigung');
      case projectStates.reviewCoaches:
        return t('Vereinbaren Sie Chemistry Calls mit den Coaches');
      case projectStates.bookCoach:
        if (type === ProjectTypes.COACH) {
          return t('Wählen und buchen Sie Ihren favorisierten Coach');
        }
        return t('Wählen und buchen Sie Ihren favorisierten Moderator');

      case projectStates.projectRunning:
        return t('Keine weiteren Schritte vorgesehen');
      case projectStates.projectFinished:
        return t('Keine weiteren Schritte vorgesehen');
      default:
        return t('Project state undefined');
    }
  };

  const renderState = () => {
    const { state, type } = project;
    if (type === ContentTypes.OPEN_WORKSHOP) {
      return t('Die Veranstaltung ist gebucht');
    }
    switch (state) {
      case projectStates.findCoaches:
        return t('fgi verifiziert Ihre Vorauswahl');
      case projectStates.reviewCoaches:
        return t('fgi hat Ihre Coaches gefunden');
      case projectStates.bookCoach:
        if (type === ProjectTypes.COACH) {
          return t('fgi hat Ihre Coaches gefunden');
        }
        return t('fgi hat Ihre Moderatoren gefunden');
      case projectStates.projectRunning:
        return t('Coaching wird durchgeführt');
      case projectStates.projectFinished:
        return t('Projekt erfolgreich abgeschlossen');
      default:
        return t('Project state undefined');
    }
  };

  const renderCoachImages = () => {
    switch (project.state) {
      case projectStates.findCoaches:
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 0 2px grey.light2',
              borderRadius: '100%',
              width: {
                xxs: '48px',
                lg: '64px',
              },
              height: {
                xxs: '48px',
                lg: '64px',
              },
              minWidth: {
                xxs: '48px',
                lg: '64px',
              },
            }}
          >
            <img src={NoCoach} alt="" />
          </Box>
        );
      case projectStates.reviewCoaches:
      case projectStates.bookCoach:
        const images = project.suggested_coaches.map((coach) => coach.id);
        return (
          <>
            <Avatar
              marginRight="-10px"
              image={images[0]}
              smallSize="48px"
              bigSize="64px"
            />
            {images[1] && (
              <Avatar
                marginRight="-10px"
                image={images[1]}
                smallSize="48px"
                bigSize="64px"
              />
            )}
            {images[2] && (
              <Avatar image={images[2]} smallSize="48px" bigSize="64px" />
            )}
          </>
        );
      case projectStates.projectRunning:
      case projectStates.projectFinished:
        return (
          <Avatar
            margin="0px"
            image={project.selected_coach.id}
            smallSize="48px"
            bigSize="64px"
          />
        );
      default:
        return '';
    }
  };

  const getButtonColorByProject = () => {
    switch (project.type) {
      case ProjectTypes.COACH:
        return 'main';
      case ProjectTypes.MODERATOR:
        return 'green';
      case ContentTypes.OPEN_WORKSHOP:
        return 'dark-orchid';
      default:
        return '';
    }
  };

  const getColorByProject = () => {
    switch (project.type) {
      case ProjectTypes.COACH:
        return 'color-underline-yellow';
      case ProjectTypes.MODERATOR:
        return 'color-underline-green';
      case ContentTypes.OPEN_WORKSHOP:
        return 'color-underline-dark-orchid';
      default:
        return '';
    }
  };

  const renderTimeLabel = () => {
    switch (project.type) {
      case ContentTypes.OPEN_WORKSHOP:
        return t('Workshop am');
      case ProjectTypes.COACH:
      case ProjectTypes.MODERATOR:
        return t('Anfrage vom');
      default:
        return '';
    }
  };

  const renderBookingTitle = () => {
    if (project.type === ContentTypes.OPEN_WORKSHOP) {
      return project.title[locale];
    }
    return project.category;
  };

  const renderTime = () => {
    switch (project.type) {
      case ContentTypes.OPEN_WORKSHOP:
        return moment(project?.start).format('LL');
      case ProjectTypes.COACH:
      case ProjectTypes.MODERATOR:
        return moment(project?.created_at?.toDate()).format('LL');
      default:
        return '';
    }
  };

  return (
    <Box sx={{ padding: '32px', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ position: 'relative', zIndex: '1' }}>
          <span className={`color-underline ${getColorByProject()}`} />
          <Typography variant="titleProNr">#{index + 1}</Typography>
        </Box>
        <Box sx={{ marginLeft: '7px', minWidth: '100px' }}>
          <Typography component="div" variant="titleContent">
            {renderBookingTitle()}
          </Typography>
        </Box>
        {project.type === ContentTypes.OPEN_WORKSHOP ? (
          <hr width="100%" />
        ) : (
          <>
            <hr width="50%" />
            {renderCoachImages()}
            <hr width="3%" />
          </>
        )}
      </Box>
      <Box sx={{ padding: '0 16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xxs: 'column', lg: 'row' },
            alignItems: { xxs: 'flex-start', lg: 'center' },
            div: {
              flex: '1',
              paddingBottom: '5px',
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component="div" variant="titleContent">
              {t('Status')}
            </Typography>
            <Typography component="div" variant="primaryText">
              {renderState()}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component="div" variant="titleContent">
              {t('Ihr Nächster Schritt')}
            </Typography>
            <Typography component="div" variant="primaryText">
              {renderNextStepText()}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '5px 0 10px 0',
          }}
        >
          <Typography variant="titleContent">{renderTimeLabel()}</Typography>
          <Typography variant="secondaryText">
            {renderTime()}
            {project.type !== ContentTypes.OPEN_WORKSHOP && (
              <Box
                component="span"
                onClick={openProjectDetailsModal(project, index++)}
                sx={{
                  textDecoration: 'underline',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
              >
                {t('Projektdetails')}
              </Box>
            )}
          </Typography>
        </Box>
        {(project.state === projectStates.reviewCoaches ||
          project.state === projectStates.bookCoach) && (
          <Button
            size="medium"
            variant={getButtonColorByProject()}
            onClick={openReviewCoachesModal(project.suggested_coaches, project)}
          >
            {t('Auswählen und buchen')}
          </Button>
        )}
        {project.type === ContentTypes.OPEN_WORKSHOP &&
          new Date() < project.dateUntilRegistrationNotice && (
            <Button
              size="medium"
              variant={getButtonColorByProject()}
              onClick={openFindWorkshopModal(project)}
            >
              {t('Buchung bearbeiten')}
            </Button>
          )}
      </Box>
    </Box>
  );
};

BookingCard.propTypes = {
  index: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  openProjectDetailsModal: PropTypes.func,
  openFindWorkshopModal: PropTypes.func,
  openReviewCoachesModal: PropTypes.func,
};

export default BookingCard;
