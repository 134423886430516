import {
  Typography,
  Paper,
  Snackbar,
  Stack,
  CircularProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';

import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import firebaseApp from '../../firebaseConfig';
import AdminLayout from '../../HOC/AdminLayout';

export const AdminConfirmEmailAddresses = () => {
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const functions = getFunctions(firebaseApp, 'europe-west1');
  const confirmEmailAddress = httpsCallable(
    functions,
    'users-confirmEmailAddress',
  );

  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ['allUnverifiedUsers'],
    queryFn: httpsCallable(functions, 'users-getAllUnverified'),
  });

  const submit = async (emailAddress) => {
    try {
      await confirmEmailAddress({ emailAddress });
      setSnackBarMessage(
        `E-Mail-Adresse '${emailAddress}' erfolgreich bestätigt.`,
      );
      refetch();
    } catch (error) {
      setSnackBarMessage(error.message);
    }
  };

  const renderList = () => {
    if (isSuccess && data.data.length === 0) {
      return <Typography>Alle E-Mail-Adressen sind bestätigt.</Typography>;
    } else if (isSuccess && data.data.length > 0) {
      return data.data.map((i) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          spacing={2}
          key={i.uid}
        >
          <Typography>{i.email}</Typography>
          <Button onClick={() => submit(i.email)}>Bestätigen</Button>
        </Stack>
      ));
    } else if (isLoading) {
      return <CircularProgress />;
    }
  };

  return (
    <AdminLayout>
      <Title text="E-Mail-Adressen bestätigen" color="yellow" width="13%" />
      <Paper sx={{ padding: 2, marginTop: 2, width: '75%' }}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {renderList()}
        </Stack>
      </Paper>
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={6000}
        onClose={() => {
          setSnackBarMessage();
        }}
        message={snackBarMessage}
      />
    </AdminLayout>
  );
};

export default AdminConfirmEmailAddresses;
