import blueStripes from '../../assets/images/blue_stripes.png';
import darkOrchidStripes from '../../assets/images/dark-orchid-teaser-stripes.png';
import * as image from '../../assets/images/dashboard';
import greenStripes from '../../assets/images/green_stripes.png';
import yellowStripes from '../../assets/images/yellow_stripes.png';
import ContentTypes from '../../constants/ContentTypes';
import PagePath from '../../constants/PagePath';
import { getLink } from '../../utils';

const getDashboardData = (t, company, locale) => {
  return [
    {
      id: 0,
      name: ContentTypes.COACH,
      backgroundImage: image.coachingImage,
      stripes: yellowStripes,
      btnColor: 'main',
      btnText: t('Coach finden'),
      title: t('Coaching'),
      link: getLink(company, PagePath.COACH_FINDER),
      description: t('Finden Sie Ihren Executive Coach und Consultant'),
    },
    {
      id: 1,
      name: ContentTypes.ELEARNING,
      backgroundImage: image.elearningImage,
      stripes: blueStripes,
      btnColor: 'secondary',
      btnText: t('E-Learning starten'),
      title: t('E-Learning'),
      link: getLink(company, PagePath.ELEARNING),
      description: t('Nutzen Sie E-Learnings zur Selbstreflexion'),
    },
    {
      id: 2,
      name: ContentTypes.MODERATOR,
      backgroundImage: image.moderationImage,
      stripes: greenStripes,
      btnColor: 'green',
      btnText: t('Moderator finden'),
      title: t('Workshop-Moderation'),
      link: getLink(company, PagePath.MODERATOR_FINDEN),
      description: t('Finden Sie Ihren Workshop-Moderator'),
    },
    {
      id: 3,
      name: ContentTypes.OPEN_WORKSHOP,
      backgroundImage: image.leadershipImage,
      stripes: darkOrchidStripes,
      btnColor: 'dark-orchid',
      btnText: t('Training buchen'),
      title: company.openWorkshopDashboardTitle
        ? company.openWorkshopDashboardTitle[locale]
        : t('openWorkshops.dashboard.title'),
      link: getLink(company, PagePath.OPEN_WORKSHOPS),
      description: company.openWorkshopDashboardText
        ? company.openWorkshopDashboardText[locale]
        : t('openWorkshops.dashboard.text'),
    },
  ];
};

export default getDashboardData;
