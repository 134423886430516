import { Box, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

/**
 * A navigation component that adds a vertical list of links with optional sub links to any sidebar. Used mainly in admin pages to navigate
 * to different configuration options.
 */

const AdminNavigation = ({ items }) => {
  const location = useLocation();

  return (
    <Box>
      {items.map(({ label, to, submenu }) => (
        <Box key={label}>
          <Link
            component={RouterLink}
            to={to}
            sx={{
              textTransform: 'uppercase',
              fontSize: 13,
              fontWeight: 500,
              color: 'primary.dark',
              cursor: 'pointer',
              position: 'relative',
              display: 'block',
              textDecoration: 'none',
              marginBottom: '16px',
              '&::after': {
                content: '""',
                position: 'absolute',
                width: '45%',
                height: '4px',
                backgroundColor: 'yellow.main',
                bottom: 0,
                left: 0,
                visibility: location.pathname === to ? 'visible' : 'hidden',
              },
              '&:hover': {
                color: 'primary.dark',
                '&::after': {
                  visibility: 'visible',
                },
              },
            }}
          >
            {label}
          </Link>
          {submenu && (
            <Box sx={{ marginLeft: '20px' }}>
              {submenu.map(({ label, to }) => (
                <Typography variant="body3" key={label}>
                  <Link
                    key={label}
                    component={RouterLink}
                    to={to}
                    sx={{
                      textDecoration: 'none',
                      display: 'block',
                      marginBottom: '8px',
                      '&:hover': {
                        textDecoration: 'none',
                        color: 'inherit',
                      },
                    }}
                  >
                    {label}
                  </Link>
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

AdminNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      submenu: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
};

export default AdminNavigation;
