import { Stack, Box } from '@mui/material';
import PropTypes from 'prop-types';

import { redirectWithToken } from '../../api';
import Button from '../Button';
import InputText from '../InputText';

/**
 * This component is read-only in nature. It is intended to show that a value has been set.
 * It also allows the user to try out the redirect by clicking the button.
 */
const InputAdminCompanyRedirect = ({ label, name, value }) => {
  return (
    <Stack direction="column" spacing={1}>
      <Box>
        <InputText label={label} name={name} value={value} isDisabled />
      </Box>
      <Stack direction="row" justifyContent="end">
        <Button
          onClick={() => redirectWithToken(value)}
          variant="main"
          size="small"
          type="button"
        >
          Öffnen
        </Button>
      </Stack>
    </Stack>
  );
};

InputAdminCompanyRedirect.propTypes = {
  /**
   * The name of the component.
   */
  name: PropTypes.string.isRequired,

  /**
   * The label of the component.
   */
  label: PropTypes.string.isRequired,

  /**
   * The value of the component.
   */
  value: PropTypes.string.isRequired,
};

export default InputAdminCompanyRedirect;
