import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import smallBackground from '../../assets/images/projects-small-background.png';
import bigBackground from '../../assets/images/projects_background.png';
import Hero from '../../components/Hero';
import Title from '../../components/PageTitle';
import Teaser from '../../components/Teaser';
import ProjectTypes from '../../constants/ProjectTypes';
import MainLayout from '../../HOC/MainLayout';
import { useGlobalState } from '../../hooks';
import BookingItem from './components/BookingItem';
import FindCoachItem from './components/FindCoachItem';

export const Bookings = () => {
  const [{ available_products }] = useGlobalState();
  const [t] = useTranslation();

  useEffect(() => {
    sendAmplitudeData('Visit Projects');
  }, []);

  const coachFinderItems = available_products.filter(
    (product) => product.type === ProjectTypes.COACH,
  );
  const moderatorFindenItems = available_products.filter(
    (product) => product.type === ProjectTypes.MODERATOR,
  );

  return (
    <MainLayout>
      <Title width="15%" text={t('Buchungen')} color="orange" />
      <Hero
        paddingBottom="45px"
        bigBackground={bigBackground}
        smallBackground={smallBackground}
      >
        <Teaser
          type="orange"
          title={t('fgi unterstützt Sie bei Ihren Herausforderungen')}
          description={t('Unsere Executive Coaches und Workshop-Moderatoren unterstützen Sie zielgerichtet, individuell und nachhaltig.')} // prettier-ignore
        />
      </Hero>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', lg: 'row' },
        }}
      >
        <BookingItem />
        <FindCoachItem
          coachFinderItems={coachFinderItems}
          moderatorFindenItems={moderatorFindenItems}
        />
      </Box>
    </MainLayout>
  );
};

export default Bookings;
