import React from 'react';

const PhoneSVG = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.8889 19.8889C23.5506 19.8889 22.2202 19.6828 20.9446 19.278C20.5556 19.1668 20.1113 19.2221 19.8336 19.5558L17.3889 21.9999C14.2221 20.3891 11.6668 17.8333 10.0554 14.6665L12.5002 12.2223C12.7779 11.9446 12.8888 11.5003 12.7779 11.1112C12.3332 9.83359 12.1111 8.5 12.1111 7.11109C12.1111 6.50021 11.6111 6 11 6H7.11109C6.49995 6 6 6.50021 6 7.11109C6 17.5554 14.4446 26 24.8889 26C25.4998 26 26 25.4998 26 24.8889V21C26 20.3891 25.4998 19.8889 24.8889 19.8889Z"
      fill={color === 'yellow' ? '#f4da55' : '#CECECE'}
    />
  </svg>
);

export default PhoneSVG;
