import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import set from 'lodash/set';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  uploadProductPdf,
  uploadProductImage,
  createProduct,
  updateProduct,
  getProductById,
  deleteProductPdf,
  deleteProductImage,
} from '../../../../api';
import AdminCheckbox from '../../../../components/AdminCheckbox';
import AdminFormImage from '../../../../components/AdminForms/fields/AdminFormImage';
import AdminFormPdf from '../../../../components/AdminForms/fields/AdminFormPdf';
import AdminFormSelect from '../../../../components/AdminForms/fields/AdminFormSelect';
import AdminFormText from '../../../../components/AdminForms/fields/AdminFormText';
import { useLanguageSwitch } from '../../../../components/AdminForms/LanguageSwitch';
import Button from '../../../../components/Button';
import PagePath from '../../../../constants/PagePath';
import ProjectTypes from '../../../../constants/ProjectTypes';
import { useGlobalState } from '../../../../hooks';
import { Locales } from '../../../../i18n';
import { getLink } from '../../../../utils';
import './AdminProductsNewForm.scss';
import adminProductsNewFormSchema from './adminProductsNewFormSchema';

/**
 * @typedef {object} AdminProductsNewFormObject
 * @property {Record<Locales, string>} title
 * @property {Record<Locales, string>} description
 * @property {Record<Locales, string>} course_id
 * @property {ProjectTypes} type
 * @property {string} hourly_rate
 * @property {string} hours_needed
 * @property {boolean} is_virtual
 */

const typesOptions = [
  {
    value: ProjectTypes.COACH,
    label: 'Coaching',
  },
  {
    value: ProjectTypes.MODERATOR,
    label: 'Moderator',
  },
  {
    value: ProjectTypes.ELEARNING,
    label: 'E-Learning',
  },
];

/**
 * @param {TLocale[]} locales
 * @returns {AdminProductsNewFormObject}
 */
const initialFormValues = (locales) => {
  const values = {
    title: {},
    description: {},
    course_id: {},
    type: null,
    hourly_rate: null,
    hours_needed: null,
    is_virtual: false,
    image: {
      name: null,
    },
    pdfUrl: {},
  };

  locales.forEach((locale) => {
    values.title[locale] = null;
    values.description[locale] = null;
    values.course_id[locale] = null;
    values.pdfUrl[locale] = null;
  });

  // noinspection JSValidateTypes
  return values;
};

export const AdminProductsNewForm = ({ isEdit = false }) => {
  const [{ company }] = useGlobalState();
  const { productId } = useParams();
  const navigate = useNavigate();

  const { selectedLanguage, LanguageSwitchComponent } = useLanguageSwitch();

  const form = useForm({
    resolver: yupResolver(adminProductsNewFormSchema),
    defaultValues: initialFormValues(Object.values(Locales)),
    mode: 'onSubmit',
    criteriaMode: 'all',
  });

  const getProductByIdQuery = useQuery(
    ['product', productId],
    async (context) => {
      const product = await getProductById(context.queryKey[1]);

      const formProduct = {
        ...product,
        is_virtual: !!product.is_virtual,
        image: { name: product.image },
      };

      Object.values(Locales).forEach((locale) => {
        set(
          formProduct,
          `pdfUrl.${locale}`,
          (product.pdfUrl && product.pdfUrl[locale]) ?? null,
        );
      });

      Object.entries(formProduct).forEach(([key, value]) => {
        if (!value) {
          delete formProduct[key];
        }
      });

      return formProduct;
    },
    {
      initialData: initialFormValues(Object.values(Locales)),
      enabled: isEdit,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onSuccess: (payload) => {
        form.reset(payload);
      },
    },
  );

  const submitMutation = useMutation(async (payload) => {
    const newPayload = { ...payload };
    try {
      if (payload.image.name !== getProductByIdQuery.data?.image?.name) {
        newPayload.image = {};
        newPayload.image.name = await uploadProductImage(payload.image);
        if (getProductByIdQuery.data?.image?.name) {
          await deleteProductImage(getProductByIdQuery.data?.image?.name);
        }
      }

      await Promise.all(
        Object.values(Locales).map(async (locale) => {
          const pdfFile = payload.pdfUrl[locale];
          const initialPdfUrl = getProductByIdQuery.data.pdfUrl[locale];

          // Is our file a new file that needs uploading?
          if (pdfFile?.blob) {
            // If yes, we delete the old file (if there was any),
            if (initialPdfUrl) {
              await deleteProductPdf(initialPdfUrl);
            }

            // upload the new one and set it's url as our pdfUrl.
            newPayload.pdfUrl[locale] = await uploadProductPdf(pdfFile);
          } else {
            /**
             * If we don't have a pdfFile now but there was an initialPdfUrl,
             * this means the user has removed the file and hence, we can delete it.
             * */

            if (!pdfFile && initialPdfUrl) {
              await deleteProductPdf(initialPdfUrl);
            }

            newPayload.pdfUrl[locale] = pdfFile;
          }
        }),
      );

      if (payload.type === ProjectTypes.ELEARNING) {
        delete newPayload.hours_needed;
        delete newPayload.hourly_rate;
        delete newPayload.is_virtual;
      } else if (
        [ProjectTypes.COACH, ProjectTypes.MODERATOR].includes(payload.type)
      ) {
        delete newPayload.course_id;
      }

      if (isEdit) {
        await updateProduct(productId, {
          ...newPayload,
          image: newPayload.image.name,
        });
      } else {
        await createProduct({
          ...newPayload,
          image: newPayload.image.name,
        });
      }
      navigate(getLink(company, PagePath.ADMIN_PRODUCTS));
      getProductByIdQuery.remove();
    } catch (err) {
      Sentry.captureException(err);
    }
  });

  const values = form.watch();

  return (
    <form onSubmit={form.handleSubmit(submitMutation.mutate)}>
      <FormProvider {...form}>
        <Box
          className={'admin-products-new-form'}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 3fr',
            gridTemplateAreas: `
          'files inputs'
          `,
          }}
        >
          <Box
            sx={{
              gridArea: 'files',
              display: 'flex',
              gap: '30px',
              flexDirection: 'column',
            }}
          >
            <AdminFormImage label={'Produktbild'} name={'image'} />
            {Object.values(Locales).map((locale) => {
              // TODO: This is a workaround,
              //  because InputFile component is not updating its value when changed.
              return (
                <div
                  key={locale}
                  style={{
                    display: selectedLanguage === locale ? 'block' : 'none',
                  }}
                >
                  <AdminFormPdf label={'PDF'} name={'pdfUrl'} locale={locale} />
                </div>
              );
            })}
            {LanguageSwitchComponent}
          </Box>
          <Box
            sx={{
              gridArea: 'inputs',
              display: 'grid',
              gridTemplateColumns: 'repeat(5, 1fr)',
              gridTemplateAreas: `
          'title title title type type'
          'description description description description description'
          `,
              columnGap: '40px',
              rowGap: '8px',
            }}
          >
            <AdminFormText
              label="Produktname *"
              name="title"
              selectedLanguage={selectedLanguage}
              sx={{
                gridArea: 'title',
              }}
            />
            <AdminFormSelect
              label="Kategorie *"
              name="type"
              options={typesOptions}
              sx={{
                gridArea: 'type',
              }}
            />
            <AdminFormText
              label="Beschreibung *"
              name="description"
              as="textarea"
              selectedLanguage={selectedLanguage}
              className="admin-products-new-form-data-textarea"
              sx={{
                gridArea: 'description',
              }}
            />
            {values.type &&
              [ProjectTypes.MODERATOR, ProjectTypes.COACH].includes(
                values.type,
              ) && (
                <>
                  <AdminFormText
                    label={(() => {
                      switch (values.type) {
                        case ProjectTypes.MODERATOR: {
                          return 'Tagessatz *';
                        }
                        case ProjectTypes.COACH: {
                          return 'Stundensatz *';
                        }
                        default: {
                          throw new Error(`Invalid type: ${values.type}`);
                        }
                      }
                    })()}
                    as="input"
                    type="number"
                    name={'hourly_rate'}
                  />
                  <AdminFormText
                    label={'Zeitaufwand *'}
                    as="input"
                    type="number"
                    name={'hours_needed'}
                  />
                  <Box>
                    <Form.Label>Kosten</Form.Label>
                    <Box
                      className="primary-text"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '38px',
                      }}
                    >
                      {values.hourly_rate && values.hours_needed
                        ? `${values.hourly_rate * values.hours_needed} €`
                        : '-'}
                    </Box>
                  </Box>
                  <AdminCheckbox
                    label={'Virtuelles Produkt'}
                    description={
                      'Bei der Buchung wird der Ort “Virtuell” angezeigt'
                    }
                    checked={values.is_virtual ?? ''}
                    {...form.register(`is_virtual`)}
                    sx={{ gridColumn: 'span 2', marginLeft: '-8px' }}
                  />
                </>
              )}
            {values.type && [ProjectTypes.ELEARNING].includes(values.type) && (
              <AdminFormText
                label={'SCORM-Cloud-Course-ID *'}
                name={`course_id`}
                as={'textarea'}
                selectedLanguage={selectedLanguage}
                className="admin-products-new-form-data-textarea"
                sx={{
                  gridColumn: 'span 5',
                }}
              />
            )}
            <Box sx={{ gridColumn: 'span 5' }}>
              <hr />
              <Box
                className="admin-products-new-form-data-container-button-wrapper"
                sx={{
                  gridColumn: 'span 5',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <Button
                  type="submit"
                  size="medium"
                  variant="main"
                  load={submitMutation.isLoading}
                >
                  {isEdit ? 'Speichern' : 'Produkt neu anlegen'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </form>
  );
};

export default AdminProductsNewForm;
