import { Box } from '@mui/system';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

/**
 * @param name {string}
 * @param label {string}
 * @param selectedLanguage {TLocale}
 * @param options {Array<{ value: string, label: string }>}
 * @param sx {object | undefined}
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const AdminFormSelect = ({ label, name, options, sx, ...props }) => {
  const formContext = useFormContext();

  const value = formContext.watch(name);

  return (
    <Box sx={sx}>
      <Form.Label htmlFor={name}>{label}</Form.Label>
      <Form.Control
        id={name}
        as="select"
        isInvalid={formContext.formState.errors[name]}
        {...props}
        value={value ?? ''}
        {...formContext.register(name)}
      >
        <option disabled hidden value="">
          {'Bitte wählen'}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {formContext.formState.errors[name]?.message}
      </Form.Control.Feedback>
    </Box>
  );
};

export default AdminFormSelect;
