import { createTheme } from '@mui/material/styles';

import defineTypography from './typography';

const theme = createTheme({
  palette: {
    green: {
      main: '#89c959',
    },
    yellow: {
      main: '#f4da55',
      light: '#F8E78F',
      dark: '#EED240',
    },
    blue: {
      main: '#5dc0e0',
      dark: '#0056b3',
    },
    red: { main: '#d95959' },
    violet: { main: '#9046cf' },
    primary: {
      main: '#666666',
      dark: '#2a2d41',
    },
    grey: {
      light: '#f7f7f7',
      light2: '#afafaf',
      light3: '#e3e3e3',
      light4: '#fbfbfa',
      light5: '#F6F6F6',
      light6: '#cecece',
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 480,
      md: 768,
      lg: 992,
    },
  },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          margin: 0,
        },
        displayedRows: {
          margin: 0,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: { titleSection: 'h3', ProductTitle: 'h4' },
      },
    },
  },
});

defineTypography(theme);

export default theme;
