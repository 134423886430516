import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { onAuthStateChanged, useLoadUserAndCompany } from '../../api';
import Loader from '../../components/Loader';
import CompleteMissingInfoModal from '../../modals/CompleteMissingInfoModal';

export const Auth = () => {
  const loadUserAndCompany = useLoadUserAndCompany();
  const [
    completeMissingInfoModalVisibility,
    setCompleteMissingInfoModalVisibility,
  ] = useState(false);
  const [userId, setUserId] = useState('');
  const [isLoaded, setIsLoaded] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(async (authUser) => {
      if (!authUser) {
        setIsLoaded(true);
        return;
      }
      try {
        const { user } = await loadUserAndCompany(authUser);

        if (!user) {
          setIsLoaded(true);
          return;
        }

        setIsLoaded(true);

        if (!user.sex) {
          setCompleteMissingInfoModalVisibility(true);
          setUserId(user.uid);
        }
      } catch (e) {
        setIsLoaded(true);
        return;
      }
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoaded]);

  if (isLoaded) {
    return (
      <>
        <Outlet />
        <CompleteMissingInfoModal
          userId={userId}
          onHide={() => setCompleteMissingInfoModalVisibility(false)}
          show={completeMissingInfoModalVisibility}
        />
      </>
    );
  } else {
    return <Loader show />;
  }
};

export default Auth;
