import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { updateUserById } from '../../api';
import { useGlobalState, SET_USER } from '../../hooks';
import useAvailablePage from '../../hooks/useAvailablePage';
import { getLink } from '../../utils';

/**
 * @function AvailableContentCheck
 * @description component, used to detect 'v' param from URL and handle depends on the user availability:
 * Update user's productVisibility array || store temporary productVisibility in localStorage
 * @returns null
 */
const AvailableContentCheck = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [{ user, company }, dispatch] = useGlobalState();
  const getAvailablePage = useAvailablePage();

  /**
   * @function updateUser
   * @description updates user document in db and in global state, redirects after update
   * @param {String} uid - user id
   * @param {Object} data - productVisibility
   */
  const updateUser = async (uid, data) => {
    const updatedUser = await updateUserById(uid, data);
    dispatch({ type: SET_USER, payload: updatedUser });
    redirectAfterUpdate(updatedUser);
  };

  /**
   * @function redirectAfterUpdate
   * @description check if current page is unavailable for user, if so - redirects user, else - nothing
   * @param {Object} user - updated user
   */
  const redirectAfterUpdate = (user) => {
    const availablePage = getAvailablePage({ user });
    if (!pathname.includes(availablePage)) {
      navigate(getLink(company, availablePage));
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const visibility = params.get('v');

    if (visibility) {
      const productVisibility = visibility.split(',');
      if (user) {
        updateUser(user.uid, { productVisibility });
      } else {
        localStorage.setItem('productVisibility', productVisibility);
      }
    }
  }, []); // eslint-disable-line

  return null;
};

export default AvailableContentCheck;
