import { Typography, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';

const DeleteTrainingModal = ({ show, onHide, onDeleteTraining }) => {
  const [t] = useTranslation();
  return (
    <FgiModal
      color="dark-orchid"
      className="small-modal"
      show={show}
      onHide={onHide}
      title={t('Training löschen?')}
    >
      <Typography
        sx={{ textAlign: 'center' }}
        component="div"
        variant="primaryText"
      >
        {t('Möchten Sie dieses Training löschen?')}
      </Typography>
      <Typography
        sx={{ textAlign: 'center', margin: '24px 0' }}
        component="div"
        variant="primaryText"
      >
        {t(
          'Das Training sowie die Anmeldungen werden aus allen Ansichten und allen Reportings aller Nutzer entfernt. Alle Teilnehmer erhalten eine Storno-E-Mail.',
        )}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button onClick={onHide} size="medium" arrow={false} variant="light">
          {t('Abbrechen')}
        </Button>
        <Button
          onClick={onDeleteTraining}
          size="medium"
          arrow={false}
          variant="dark-orchid"
        >
          {t('Löschen')}
        </Button>
      </Box>
    </FgiModal>
  );
};

export default DeleteTrainingModal;
