import React from 'react';

const BackArrow = () => (
  <svg
    width="14"
    height="24"
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.32149 11.9997L13.2582 3.07002C13.9191 2.40909 13.9191 1.34034 13.2582 0.686429C12.5973 0.0254918 11.5285 0.032523 10.8676 0.686429L0.742587 10.8044C0.102743 11.4442 0.0886808 12.4708 0.693368 13.1317L10.8606 23.32C11.191 23.6505 11.627 23.8122 12.0559 23.8122C12.4848 23.8122 12.9207 23.6505 13.2512 23.32C13.9121 22.6591 13.9121 21.5903 13.2512 20.9364L4.32149 11.9997Z"
      fill="#CECECE"
    />
  </svg>
);

export default BackArrow;
