import React from 'react';

const OkSVG = ({ color = '#F4DA55' }) => (
  <svg
    width="70"
    height="53"
    viewBox="0 0 70 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.6301 6.14463L63.9089 0.382049C63.6813 0.127349 63.3238 0 62.9987 0C62.6411 0 62.316 0.127349 62.0885 0.382049L22.4299 39.5103L7.99674 25.3745C7.73668 25.1198 7.41161 24.9924 7.08654 24.9924C6.76147 24.9924 6.43639 25.1198 6.17634 25.3745L0.390085 31.0415C-0.130028 31.5509 -0.130028 32.3469 0.390085 32.8563L18.594 50.6853C19.7643 51.8314 21.1946 52.5 22.3974 52.5C24.1202 52.5 25.6156 51.2583 26.1682 50.7489H26.2007L69.6626 7.95937C70.1177 7.41813 70.1177 6.62219 69.6301 6.14463Z"
      fill={color}
    />
  </svg>
);

export default OkSVG;
