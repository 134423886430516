import { Alert, AlertTitle, Button, Box } from '@mui/material';
import { Component } from 'react';

import bgWelcome from '../../assets/images/bg-welcome.webp';
import Monitoring from '../../utils/monitoring';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    if (Object.keys(customErrorMessages).includes(error?.message)) {
      return {
        hasError: true,
        error: customErrorMessages[error.message],
      };
    }
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Monitoring.captureException(error, {
      method: 'boundary',
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          className="Container"
          sx={{ backgroundImage: `url(${bgWelcome})`, height: '100%' }}
        >
          <Alert
            severity="error"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => (window.location = '/')}
              >
                Zur Startseite
              </Button>
            }
          >
            <AlertTitle>Error</AlertTitle>
            {this.state.error ??
              'We have already been informed of the error and are working on a solution.'}
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}

export const customErrorMessages = {
  ConfirmRegistrationModeNotSet:
    'No `mode` was passed in the URL. Is the URL correct?',
  AuthInvalidActionCode: 'Invalid action code',
};

export default ErrorBoundary;
