import React from 'react';
import { useTranslation } from 'react-i18next';

import bigBackground from '../../assets/images/find-offers.png';
import smallBackground from '../../assets/images/find-offers.png';
import Button from '../../components/Button';
import Hero from '../../components/Hero';
import Title from '../../components/PageTitle';
import Teaser from '../../components/Teaser';
import MainLayout from '../../HOC/MainLayout';
import './FindWorkshops.scss';
import WorkshopsList from './WorkshopsList';

export const FindWorkshops = () => {
  const [t] = useTranslation();

  return (
    <MainLayout>
      <Title text={t('Training buchen')} color="dark-orchid" width="14%" />
      <Hero
        paddingBottom="35px"
        bigBackground={bigBackground}
        smallBackground={smallBackground}
      >
        <Teaser
          type="dark-orchid"
          title={t('Vernetzen Sie sich mit Ihren Kollegen zu relevanten Themen')} // prettier-ignore
          description={t('rund um eine aktive und engagierte Führung')} // prettier-ignore
        />
        <div className="find-workshops-button-wrapper">
          <a href="#WorkshopsList" className="find-workshops-link">
            <Button size="big" variant="dark-orchid" arrow="down">
              {t('openWorkshops.page.cta')}
            </Button>
          </a>
        </div>
      </Hero>
      <div className="find-workshops-item">
        <div className="find-workshops-item-title">
          <Title
            text={t('openWorkshops.page.headline')}
            color="dark-orchid"
            width="27%"
          />
        </div>
        <div id="WorkshopsList">
          <WorkshopsList />
        </div>
      </div>
    </MainLayout>
  );
};
export default FindWorkshops;
