import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';

export const AuswahlSendenModal = ({ show, onHide, confirmProjectRequest }) => {
  const [t] = useTranslation();

  return (
    <FgiModal
      color="orange"
      className="small-modal"
      show={show}
      onHide={onHide}
      title={t('Auswahl Senden?')}
      description={t('Möchten Sie die Auswahl an Kandidaten an den Kunden senden? Dieser Schritt nicht umkehrbar.')} // prettier-ignore
    >
      <div className="modal-footer-button">
        <Button onClick={onHide} size="small" variant="light" arrow={false}>
          {t('Abbrechen')}
        </Button>
        <Button
          onClick={confirmProjectRequest}
          style={{ marginLeft: '24px' }}
          size="medium"
          variant="main-dark"
          arrow={false}
        >
          {t('Senden')}
        </Button>
      </div>
    </FgiModal>
  );
};

AuswahlSendenModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  confirmProjectRequest: PropTypes.func.isRequired,
};

export default AuswahlSendenModal;
