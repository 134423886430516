import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks';
import { getLink } from '../../utils';
import AdminTrainingsNewForm from './components/AdminTrainingsNewForm';

const AdminTrainingsNew = () => {
  const [{ company }] = useGlobalState();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const redirectToNewTrainings = () => {
    navigate(getLink(company, PagePath.ADMIN_TRAININGS));
  };

  return (
    <AdminLayout>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Title
          text={t('Training hinzufügen')}
          color="dark-orchid"
          width="13%"
        />
        <Button
          className="admin-projects-title-wrapper-button"
          variant="light"
          size="small"
          onClick={redirectToNewTrainings}
          arrow="back"
        >
          {t('Zurück')}
        </Button>
      </Box>
      <AdminTrainingsNewForm />
    </AdminLayout>
  );
};

export default AdminTrainingsNew;
