export const businessSituationOptions = (t) => [
  { text: t('Wachstum'), value: t('Wachstum') },
  { text: t('Start Up'), value: t('Start Up') },
  { text: t('Merger'), value: t('Merger') },
  { text: t('Disruptive Veränderung'), value: t('Disruptive Veränderung') },
  { text: t('Kontinuität'), value: t('Kontinuität') },
];

export const leadershipLevelOptions = (t) => [
  { text: t('Lower Management'), value: t('Lower Management') },
  { text: t('Middle Management'), value: t('Middle Management') },
  { text: t('Top Management'), value: t('Top Management') },
];

export const followOnActionOptions = (t) => [
  { text: t('Ja'), value: t('Ja') },
  { text: t('Nein'), value: t('Nein') },
  { text: t('Vielleicht'), value: t('Vielleicht') },
  { text: t('Keine Angabe'), value: t('Keine Angabe') },
];

export const coachingTopicOptions = (t) => [
  { text: t('Persönliche Entwicklung'), value: t('Persönliche Entwicklung') },
  {
    text: t('Gesprächsführung und Kommunikation'),
    value: t('Gesprächsführung und Kommunikation'),
  },
  { text: t('Konflikte managen'), value: t('Konflikte managen') },
  {
    text: t('Auftritt und persönliche Wirkung'),
    value: t('Auftritt und persönliche Wirkung'),
  },
  {
    text: t('Positionierung und strategische Ausrichtung'),
    value: t('Positionierung und strategische Ausrichtung'),
  },
  {
    text: t('Potential- und Karriereentwicklung'),
    value: t('Potential- und Karriereentwicklung'),
  },
  {
    text: t('Führungswechsel'),
    value: t('Führungswechsel'),
  },
];
