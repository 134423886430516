import { getFunctions, httpsCallable } from 'firebase/functions';

import firebaseApp from '../firebaseConfig';

const functions = getFunctions(firebaseApp, 'europe-west1');

export const trigger_resetPassword = httpsCallable(
  functions,
  'trigger_resetPassword',
);

export const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');

export const trigger_update_email = httpsCallable(
  functions,
  'trigger_update_email',
);

export const getAllianzBillingReport = httpsCallable(
  functions,
  'getAllianzBillingReport',
);
