import * as yup from 'yup';

/** The yup schema used when email and password authentication is in place */
export const EmailPasswordSchema = (t) =>
  yup.object({
    sex: yup.string(),
    firstname: yup.string(),
    lastname: yup.string(),
    email: yup
      .string()
      .email(t('Ihr Unternehmen wurde noch nicht freigeschaltet')),
    password: yup
      .string()
      .min(8, t('Mindestens 8 Zeichen erforderlich'))
      .matches(/(?=.*[A-Z])/, t('Mindestens 1 Großbuchstabe erforderlich'))
      .matches(/(?=.*[a-z])/, t('Mindestens 1 Kleinbuchstabe erforderlich'))
      .matches(
        /(?=.*\d)|(?=.*\W+)/,
        t('Mindestens eine Zahl oder Sonderzeichen erforderlich'),
      )
      .required(t('Bitte geben Sie ein Passwort an')),
  });

/** The yup schema used when SAML authentication is in place */
export const SAMLSchema = (t) =>
  yup.object({
    sex: yup.string(),
  });
