import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { signInWithEmailAndPassword } from '../../api';
import { createUser, updateUserById } from '../../api';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import FgiModal from '../../components/FgiModal';
import GENDER_TYPES from '../../constants/SexTypes';
import { useGlobalState, SET_USER } from '../../hooks';
import { DEFAULT_LOCALE } from '../../i18n';
import { Monitoring } from '../../utils';
import Confirm from '../Confirm';
import './SignUp.scss';
import signUpSchema from './signUpSchema';

const SignUp = ({ onHide, show }) => {
  const [{ locale }, dispatch] = useGlobalState();
  const [t] = useTranslation();

  const [confirmShow, setConfirmShow] = useState(false);
  const [emailAlreadyExistsError, setEmailAlreadyExistsError] = useState(false);
  const [invalidEmailDomainError, setInvalidEmailDomainError] = useState(false);
  const [load, setLoad] = useState(false);

  const initialFormValues = {
    sex: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const handleConfirmClose = () => {
    setConfirmShow(false);
  };

  const setUserProductVisibility = async (user) => {
    if (localStorage.getItem('productVisibility')) {
      const productVisibility = localStorage.getItem('productVisibility');
      const updatedUser = await updateUserById(user.uid, {
        productVisibility,
      });
      dispatch({ type: SET_USER, payload: updatedUser });
      localStorage.removeItem('productVisibility');
    }
  };

  const submit = async ({ firstname, lastname, email, password, sex }) => {
    try {
      setLoad(true);
      Monitoring.trackEvent('Submit Register Form');

      const user = await createUser({
        firstname,
        lastname,
        email,
        sex,
        locale: locale || DEFAULT_LOCALE,
        password,
      });

      await signInWithEmailAndPassword(email, password);

      onHide();

      await setUserProductVisibility(user);
      setConfirmShow(true);
      setLoad(false);
    } catch (err) {
      if (err.code === 'functions/already-exists') {
        setEmailAlreadyExistsError(true);
      } else if (err.code === 'functions/failed-precondition') {
        setInvalidEmailDomainError(true);
      } else {
        Monitoring.captureException(err);
      }
    } finally {
      setLoad(false);
    }
  };

  const handleHide = () => {
    handleConfirmClose();
    onHide();
  };

  return (
    <Formik
      validationSchema={signUpSchema(t)}
      onSubmit={submit}
      initialValues={initialFormValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
      }) => {
        const change = (name) => (e) => {
          e.persist();
          handleChange(e);
          if (name === 'email' && emailAlreadyExistsError) {
            setEmailAlreadyExistsError(false);
          }
          if (name === 'email' && invalidEmailDomainError) {
            setInvalidEmailDomainError(false);
          }
        };

        const setSex = (val) => () => {
          setFieldValue('sex', val);
        };

        return (
          <>
            <FgiModal
              id="signupModalbox"
              show={show}
              onHide={handleHide}
              showCloseButton
              title={t('WILLKOMMEN')}
              description={t(
                'Für Ihren Zugang benötigen wir folgende Informationen:',
              )}
              className="sign-up-modal"
            >
              <Form noValidate onSubmit={handleSubmit}>
                <div className="checkboxLayout">
                  <Checkbox
                    checked={values.sex === GENDER_TYPES.male}
                    onClick={setSex(GENDER_TYPES.male)}
                    isError={!!errors.sex}
                    label={t('Herr')}
                  />
                  <Checkbox
                    checked={values.sex === GENDER_TYPES.female}
                    onClick={setSex(GENDER_TYPES.female)}
                    isError={!!errors.sex}
                    label={t('Frau')}
                  />
                  <Checkbox
                    checked={values.sex === GENDER_TYPES.other}
                    onClick={setSex(GENDER_TYPES.other)}
                    isError={!!errors.sex}
                    label={t('Divers')}
                  />
                </div>

                <div className="modal-row">
                  <Form.Group as={Col} md={6} xs={12}>
                    <Form.Label htmlFor="firstname">
                      {t('Frauirstname')} *
                    </Form.Label>
                    <Form.Control
                      id="firstname"
                      type="text"
                      name="firstname"
                      value={values.firstname}
                      onChange={change('firstname')}
                      isInvalid={touched.firstname && !!errors.firstname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstname}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6} xs={12}>
                    <Form.Label htmlFor="lastname">
                      {t('Nachname')} *
                    </Form.Label>
                    <Form.Control
                      id="lastname"
                      type="text"
                      name="lastname"
                      value={values.lastname}
                      onChange={change('lastname')}
                      isInvalid={touched.lastname && !!errors.lastname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastname}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="modal-row">
                  <Form.Group as={Col} md={12}>
                    <Form.Label htmlFor="email">
                      {t('E-Mail-Adresse')} *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={change('email')}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                    {emailAlreadyExistsError && (
                      <div className="sign-up-modal__invalid-feedback">
                        {t('Diese E-Mail-Adresse wird bereits genutzt')}
                      </div>
                    )}
                    {invalidEmailDomainError && (
                      <div className="sign-up-modal__invalid-feedback">
                        {t(
                          'Diese E-Mail-Adresse wurde noch nicht freigeschaltet.',
                        )}
                      </div>
                    )}
                  </Form.Group>
                </div>
                <div className="modal-row">
                  <Form.Group as={Col} md={6} xs={12}>
                    <Form.Label htmlFor="password">
                      {t('Passwort')} *
                    </Form.Label>
                    <Form.Control
                      id="password"
                      autoComplete="on"
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={change('password')}
                      isInvalid={touched.password && !!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6} xs={12}>
                    <Form.Label htmlFor="confirmPassword">
                      {t('Passwort wiederholen')} *
                    </Form.Label>
                    <Form.Control
                      id="confirmPassword"
                      autoComplete="on"
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={change('confirmPassword')}
                      isInvalid={
                        touched.confirmPassword && !!errors.confirmPassword
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="modal-row">
                  <Form.Group
                    className="sign-up-modal__footer"
                    as={Col}
                    md={12}
                  >
                    <Button
                      className="sign-up-modal-button"
                      variant="main"
                      size="medium"
                      load={load}
                      type="submit"
                      arrow={!load && 'default'}
                    >
                      {t('REGISTRIEREN')}
                    </Button>
                  </Form.Group>
                </div>
              </Form>
            </FgiModal>
            <Confirm
              show={confirmShow}
              onHide={() => handleConfirmClose()}
              email={values.email}
            />
          </>
        );
      }}
    </Formik>
  );
};

SignUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default SignUp;
