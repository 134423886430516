import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { Controller, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useCreateCompanyMutation, useGetAllProducts } from '../../../../api';
import AdminMainContainer from '../../../../components/AdminMainContainer';
import InputAdminCompanCustomPath from '../../../../components/InputAdminCompanCustomPath';
import InputFile from '../../../../components/InputFile';
import InputText from '../../../../components/InputText';
import PagePath from '../../../../constants/PagePath';
import { useGlobalState } from '../../../../hooks';
import { getLink } from '../../../../utils';
import AdminCompanyDomains from '../../../AdminCompany/components/AdminCompanyForm/AdminCompanyDomains';
import AdminCompanyProductsTabs from '../../../AdminCompany/components/AdminCompanyForm/components/AdminCompanyProductsTabs';
import useAdminActions from '../../../AdminCompany/components/AdminCompanyForm/components/useAdminActions';
import useAdminCompanyForm from '../../../AdminCompany/components/AdminCompanyForm/useAdminCompanyForm';

const AdminCompaniesNewForm = () => {
  const { form, handleInvalidateQueries } = useAdminCompanyForm();
  const getAllProductsQuery = useGetAllProducts();
  const [{ locale }] = useGlobalState();
  const { AdminActions } = useAdminActions();
  const navigate = useNavigate();
  const [{ company }] = useGlobalState();

  const createCompanyMutation = useCreateCompanyMutation();

  const submitMutation = useMutation(
    /**
     * @param {AdminCompanyFormFields} payload
     * @return {Promise<void>}
     */
    async (payload) => {
      const companyData = {
        name: payload.name,
        available_products: payload.available_products,
        domain: payload.domains,
        logo: payload.logo,
        custom_path: payload.custom_path,
      };
      await createCompanyMutation.mutateAsync(companyData);
    },
    {
      onSuccess: () => {
        handleInvalidateQueries();
        navigate(getLink(company, PagePath.ADMIN_COMPANIES));
      },
    },
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitMutation.mutate)}>
        <AdminMainContainer
          sidebarElements={[
            <Box>
              <Controller
                name={'logo'}
                render={({ field, fieldState }) => (
                  <InputFile
                    accept={'.png, .jpeg, .bmp, .tiff'}
                    title="Logo"
                    removePicture={() => {
                      field.onChange(null);
                      form.setValue('logo_url', null);
                    }}
                    onSubmitFile={(file) => field.onChange(file)}
                    pictureName={form.watch('logo_url')}
                    content="logo"
                    isFormSubmit={fieldState.invalid}
                  />
                )}
              />
            </Box>,
          ]}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridTemplateAreas: `'name domains'
                                      'customPath domains'
                                      'select_products select_products'`,
              gridTemplateRows: 'auto auto auto',
              gridColumnGap: '32px',
              gridRowGap: '32px',
            }}
          >
            <Box sx={{ gridArea: 'name' }}>
              <Controller
                name={'name'}
                render={({ field, fieldState }) => (
                  <InputText
                    label="Name"
                    name={field.name}
                    onChange={(e) => field.onChange(e.target.value)}
                    value={field.value}
                    isInvalid={fieldState.invalid}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Box sx={{ gridArea: 'customPath' }}>
              <Controller
                name={'custom_path'}
                render={({ field, fieldState }) => (
                  <InputAdminCompanCustomPath
                    value={field.value}
                    name={field.name}
                    onChange={(e) => field.onChange(e.target.value)}
                    errorMessage={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Box sx={{ gridArea: 'domains' }}>
              <Controller
                name={'domains'}
                render={({ field, fieldState }) => (
                  <AdminCompanyDomains
                    domains={field.value}
                    invalid={fieldState.invalid}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={{ gridArea: 'select_products' }}>
              <Typography variant="titleSection" sx={{ marginBottom: 2 }}>
                Produkte zuordnen
              </Typography>
              <Controller
                name="available_products"
                render={({ field }) => (
                  <AdminCompanyProductsTabs
                    allProducts={getAllProductsQuery.data}
                    locale={locale}
                    selectedProducts={field.value}
                    isLoading={getAllProductsQuery.isLoading}
                    onChange={(v) => field.onChange(v)}
                  />
                )}
              />
            </Box>
          </Box>
          <AdminActions
            isSaving={submitMutation.isLoading}
            buttonLabel="Hinzufügen"
            buttonHasArrow
          />
        </AdminMainContainer>
      </form>
    </FormProvider>
  );
};

export default AdminCompaniesNewForm;
