import {
  TableHead,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TablePagination,
  Box,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getTrainingsData } from '../../api';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks';
import { getLink, getOpenWorkshopsBookings } from '../../utils';
import AdminTrainingsRow from './components/AdminTrainingsRow';

const AdminTrainings = () => {
  const [{ company }] = useGlobalState();
  const navigate = useNavigate();
  const [allWorkshops, setAllWorkshops] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const redirectToNewTrainings = () => {
    navigate(getLink(company, PagePath.ADMIN_TRAININGS_NEW));
  };

  const downloadReport = async () => {
    try {
      setIsLoading(true);
      await getOpenWorkshopsBookings();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await getTrainingsData();
      setAllWorkshops(res);
    };
    fetch();
  }, []);

  useEffect(() => {
    let workshopsToRender;
    if (rowsPerPage === -1) {
      workshopsToRender = allWorkshops;
    } else {
      workshopsToRender = allWorkshops.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
      );
    }
    setWorkshops(workshopsToRender);
  }, [page, rowsPerPage, allWorkshops]);

  const tableHeads = [
    {
      label: 'Firma',
      width: '25%',
    },
    {
      label: 'Produktname',
      width: '24%',
    },
    {
      label: 'Termine',
      width: '10%',
    },
    {
      label: 'Frist',
      width: '10%',
    },
    {
      label: 'Teilnehmer',
      width: '10%',
    },
  ];

  return (
    <AdminLayout>
      <Loader show={isLoading} />
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Title text="Trainings" color="dark-orchid" width="13%" />
        <Box sx={{ position: 'absolute', right: '0', top: '0', zIndex: '2' }}>
          <Box
            component={Button}
            sx={{ marginRight: '24px' }}
            arrow={false}
            onClick={downloadReport}
            size="small"
            variant="light"
          >
            Exportieren
          </Box>
          <Button variant="light" onClick={redirectToNewTrainings} size="small">
            Hinzufügen
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'common.white',
          padding: '37px 40px',
          width: '100%',
          minHeight: '788px',
          marginTop: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ '>th': { padding: '16px 0' } }}>
              <TableCell width="5%" />
              {tableHeads.map((head, index) => (
                <TableCell width={head.width} key={index}>
                  <Typography
                    sx={{ color: 'primary.main' }}
                    variant="secondaryText"
                  >
                    {head.label}
                  </Typography>
                </TableCell>
              ))}
              <TableCell width="16%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {workshops.map((workshop) => (
              <AdminTrainingsRow key={workshop.id} workshop={workshop} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, { value: -1, label: 'Alle anzeigen' }]}
          component="div"
          count={workshops.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} von ${count !== -1 ? count : `more than ${to}`}`
          }
        />
      </Box>
    </AdminLayout>
  );
};

export default AdminTrainings;
