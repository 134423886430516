import { Box } from '@mui/material';
import React from 'react';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';

import AvatarSVG from '../../assets/svgs/avatar';
import NavItem from '../../components/NavItem';
import PagePath from '../../constants/PagePath';
import { useGlobalState } from '../../hooks';
import { getLink, getCompanyLogo } from '../../utils';
import './AdminHeader.scss';

const { Collapse, Brand, Toggle } = Navbar;

const AdminHeader = () => {
  const [{ company }] = useGlobalState();

  const navList = [
    {
      text: 'Projekte',
      to: getLink(company, PagePath.ADMIN_PROJECTS),
      color: 'dark-yellow',
    },
    {
      text: 'Produkte',
      to: getLink(company, PagePath.ADMIN_PRODUCTS),
      color: 'green',
    },
    {
      text: 'Trainings',
      to: getLink(company, PagePath.ADMIN_TRAININGS),
      color: 'dark-orchid',
    },
    {
      text: 'Coaches',
      to: getLink(company, PagePath.ADMIN_COACHES),
      color: 'yellow',
    },
    {
      text: 'Unternehmen',
      to: getLink(company, PagePath.ADMIN_COMPANIES),
      color: 'yellow',
    },
    {
      text: 'Berichte',
      to: getLink(company, PagePath.ADMIN_REPORTS),
      color: 'yellow',
    },
  ];

  const Avatar = () => (
    <Box
      sx={{
        width: '32px',
        height: '32px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#edefec',
        borderRadius: '50%',
      }}
    >
      <AvatarSVG />
    </Box>
  );

  return (
    <Navbar className="admin-navbar" bg="light" expand="lg">
      <Container>
        <Brand>
          <img src={getCompanyLogo(company)} alt="fgidigitalsolutions_trans" />
        </Brand>
        <Toggle aria-controls="responsive-navbar-nav" />
        <Collapse id="basic-navbar-nav">
          <div className="admin-navbar-wrapper">
            <Nav className="admin-navbar-menu" activeKey="/home">
              {navList.map((item, index) => (
                <NavItem
                  key={index}
                  to={item.to}
                  text={item.text}
                  selectedColor={item.color}
                />
              ))}
              <NavDropdown
                className="hidden-on-mobile-menu"
                title={<Avatar />}
                id="basic-nav-dropdown"
              >
                <NavItem
                  to={getLink(company, PagePath.DASHBOARD)}
                  text="Zurück zum Start"
                />
                <NavItem
                  text="Abmelden"
                  to={getLink(company, PagePath.SIGNOUT)}
                />
              </NavDropdown>
            </Nav>
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminHeader;
