import { generatePath } from 'react-router';
/**
 * Some companies should have company-specific logos, users of some companies should be redirected after login
 * or some companies use SSO to sign in their users. In order to set up the app correctly, we need to identify the
 * company even when the user is not signed in. To accomplish this, we have added a `custom_path` field to the company
 * object. This is a special URL path that only this specific company has.
 *
 * This method creates this special link that has the required `company_path` added to it,
 * if it was set for the company.
 *
 * @param {*} company A company object of the company that needs to be added.
 * @param {String} link The actual link target.
 * @returns {String} The link target with added `company_path`
 */
export const getLink = (company, link) =>
  generatePath(link, { customPath: company?.custom_path });

/**
 * Some users of a company are redirected immediately after they have signed in. The redirect target is
 * another [app of ours](https://gitlab.com/pielaco/fgi/fgi-questionnaire) that uses the same
 * Firebase Project for authentication, data storage, etc. In order to immediately sign in the user to that
 * other app, we are appending a custom token to that url.
 *
 * This method simply adds a token to a URL.
 *
 * @param {String} token A token that should be added
 * @param {String} url The original url that the token should be added to.
 *
 * @returns {String} The final url with the custom token
 */
export const getRedirectToLink = (token, url) => `${url}?token=${token}`;
