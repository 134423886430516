import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks/useGlobalState';
import { getLink } from '../../utils';
import AdminProductsNewForm from '../AdminProductsNew/components/AdminProductsNewForm';
import './AdminProduct.scss';

export const AdminProduct = () => {
  const [{ company }] = useGlobalState();
  const navigate = useNavigate();

  return (
    <AdminLayout>
      <div className="admin-product-new-title-wrapper">
        <Title text="Produktdetails" color="green" width="13%" />
        <Button
          className="admin-product-new-title-wrapper-button"
          variant="light"
          size="small"
          onClick={() => navigate(getLink(company, PagePath.ADMIN_PRODUCTS))}
          arrow="back"
        >
          Zurück
        </Button>
      </div>
      <AdminProductsNewForm isEdit />
    </AdminLayout>
  );
};

export default AdminProduct;
