import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Typography, Tooltip, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';

import EnterIcon from '../../../../assets/svgs/EnterIcon.svg';
import AdminChip from '../../../../components/AdminChip';
import { Keys } from '../../../../constants/KeyCodes';

/**
 * @property {string[]} domains
 * @property {(domains: string[]) => void} onChange
 * @property {boolean} invalid
 * @return {JSX.Element}
 */
const AdminCompanyDomains = forwardRef(
  ({ domains = [], onChange, invalid = false }, ref) => {
    const [newDomain, setNewDomain] = useState('');

    const handleAddDomain = (event) => {
      if (event.key === Keys.ENTER) {
        event.preventDefault();
        const newDomain = event.target.value;
        if (!domains.includes(newDomain)) {
          onChange([...domains, newDomain]);
        }
      }
    };

    /**
     * @type {React.MouseEventHandler<HTMLDivElement>}
     */
    const handleRemoveDomain = useCallback(
      (index) => {
        onChange(domains.filter((_, i) => i !== index));
      },
      [domains, onChange],
    );

    return (
      <>
        <Box
          sx={{
            position: 'relative',
            '>img': { position: 'absolute', top: '40px', right: '20px' },
          }}
        >
          <Form.Label>
            Freigeschaltete E-Mail-Domain hinzufügen *{' '}
            <Tooltip title="Die E-Mail-Domain ist der Teil nach dem @ einer E-Mail-Adresse. Alle Nutzer einer freigeschalteten E-Mail-Domain können sich anmelden. Mit ENTER fügen Sie eine neue Domain der Liste hinzu.">
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          </Form.Label>
          <Form.Control
            name="domain"
            as="input"
            onChange={(e) => setNewDomain(e.target.value)}
            onKeyPress={handleAddDomain}
            value={newDomain}
            placeholder="firma.de"
            ref={ref}
          />
          <img
            className="admin-companies-new-form-container-data-input-icon"
            src={EnterIcon}
            alt="ENTER fügt die Domain zur Liste hinzu"
          />
        </Box>
        {invalid && (
          <Typography variant="formError">
            Bitte eine E-Mail-Domain hinzufügen
          </Typography>
        )}
        <Box sx={{ marginTop: 1 }}>
          {domains.map((domain, index) => (
            <AdminChip
              label={domain}
              onClick={() => handleRemoveDomain(index)}
            />
          ))}
        </Box>
      </>
    );
  },
);

AdminCompanyDomains.propTypes = {
  /**
   * The function that is called when the list of domains changes
   */
  onChange: PropTypes.func,

  /**
   * The list of domains that the component displays.
   */
  domains: PropTypes.array.isRequired,

  /**
   * Whether the component is deemed invalid or not. Displays an error message when true.
   */
  invalid: PropTypes.bool,
};

export default AdminCompanyDomains;
