import { Box } from '@mui/system';
import get from 'lodash/get';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { Locales, LocalesFullName } from '../../../i18n';

/**
 * @param name {string}
 * @param label {string | undefined | null}
 * @param selectedLanguage {TLocale | undefined | null}
 * @param sx {object | undefined}
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const AdminFormText = ({ label, selectedLanguage, name, sx, ...props }) => {
  const formContext = useFormContext();

  const field = selectedLanguage ? `${name}.${selectedLanguage}` : name;

  const value = formContext.watch(field);

  return (
    <Box sx={sx}>
      {label && <Form.Label htmlFor={name}>{label}</Form.Label>}
      {selectedLanguage && (
        <span className="text-small-1" style={{ marginLeft: '10px' }}>
          {LocalesFullName[Locales.DE][selectedLanguage]}
        </span>
      )}
      <Form.Control
        id={name}
        as="input"
        type="text"
        lang={selectedLanguage}
        isInvalid={get(formContext.formState.errors, name)}
        {...props}
        value={value ?? ''}
        {...formContext.register(field)}
      />
      <Form.Control.Feedback type="invalid">
        {get(formContext.formState.errors, name)?.message}
        {formContext.formState.errors[name]?.[selectedLanguage]?.message}
      </Form.Control.Feedback>
    </Box>
  );
};

export default AdminFormText;
