import { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import MainLayout from '../../HOC/MainLayout';
import { useGlobalState } from '../../hooks';
import useAvailableContent from '../../hooks/useAvailableContent';
import { Monitoring } from '../../utils';
import DashboardItem from './components/DashboardItem';
import './Dashboard.scss';
import getDashboardData from './getDashboardData';

export const Dashboard = () => {
  const isAvailable = useAvailableContent();
  const [{ company, locale }] = useGlobalState();
  const [t] = useTranslation();

  Monitoring.trackEvent('Visit Dashboard');

  const availableTabs = useMemo(() => {
    const filteredData = getDashboardData(t, company, locale).filter((el) =>
      isAvailable(el.name),
    );
    return filteredData.reduce((acc, item, idx) => {
      idx % 2 === 0
        ? acc.push(new Array(item))
        : acc[acc.length - 1].push(item);
      return acc;
    }, []);
  }, [company, isAvailable, locale, t]);

  return (
    <MainLayout>
      {availableTabs?.map((row, index) => (
        <Row key={index}>
          {row.map((col, index) => (
            <Col key={col.title + index}>
              <DashboardItem
                backgroundImage={col.backgroundImage}
                stripes={col.stripes}
                btnColor={col.btnColor}
                btnText={col.btnText}
                title={col.title}
                link={col.link}
                description={col.description}
              />
            </Col>
          ))}
        </Row>
      ))}
    </MainLayout>
  );
};

export default Dashboard;
