import {
  Typography,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Box,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { getAllWorkshopsWithUsers, deleteOpenBooking } from '../../api';
import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks';
import CancelBookingModal from '../../modals/CancelBookingModal';
import { getLink } from '../../utils';

const AdminTrainingParticipants = () => {
  const navigate = useNavigate();
  const [{ company }] = useGlobalState();
  const { id } = useParams();

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [currentWorkshop, setCurrentWorkshop] = useState({});
  const [allWorkshops, setAllWorkshops] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const navigateToTrainings = () => {
    navigate(getLink(company, PagePath.ADMIN_TRAININGS));
  };

  const openCancelModal = (workshop) => () => {
    setCurrentWorkshop(workshop);
    setIsOpenCancelModal(true);
  };

  const closeCancelModal = () => {
    setIsOpenCancelModal(false);
  };

  const handleCancelBooking = async () => {
    await deleteOpenBooking(currentWorkshop);
    const index = allWorkshops.findIndex((w) => w.id === currentWorkshop.id);
    const newWorkshops = [...allWorkshops];
    newWorkshops.splice(index, 1);
    setAllWorkshops(newWorkshops);
    setIsOpenCancelModal(false);
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllWorkshopsWithUsers(id);
      setAllWorkshops(data);
    };
    fetch();
  }, [id]);

  useEffect(() => {
    let workshopsToRender;
    if (rowsPerPage === -1) {
      workshopsToRender = allWorkshops;
    } else {
      workshopsToRender = allWorkshops.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
      );
    }
    setWorkshops(workshopsToRender);
  }, [page, rowsPerPage, allWorkshops]);

  const tableHeaders = [
    {
      title: 'Datum',
      width: '10%',
    },
    {
      title: 'Name',
      width: '17%',
    },
    {
      title: 'E-Mail-Adresse',
      width: '20%',
    },
    {
      title: 'Schwerpunkte',
      width: '20%',
    },
    {
      width: '10%',
    },
  ];

  return (
    <AdminLayout>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Title text="Teilnehmerliste" color="dark-orchid" width="13%" />
        <Button
          className="admin-projects-title-wrapper-button"
          variant="light"
          arrow="back"
          onClick={navigateToTrainings}
          size="small"
        >
          Zurück
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: 'common.white',
          padding: '37px 40px',
          width: '100%',
          minHeight: '788px',
          marginTop: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(({ title, width }, index) => (
                <TableCell key={index} sx={{ width, padding: '0 0 13px 0' }}>
                  <Typography variant="secondaryText">{title}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {workshops.map((workshop) => (
              <TableRow
                sx={{
                  borderBottom: '1px solid grey.light6',
                  td: { padding: '10px 0' },
                }}
                key={workshop.id}
              >
                <TableCell>
                  <Typography variant="primaryText">
                    {workshop.createdAt?.seconds
                      ? moment.unix(workshop.createdAt.seconds).format('L')
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="primaryText">
                    {workshop.user.firstname} {workshop.user.lastname}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="primaryText">
                    {workshop.user.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  {workshop.focusTopics?.map((topic, index) => (
                    <Typography
                      component="div"
                      key={index}
                      variant="primaryText"
                    >
                      {topic}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={openCancelModal(workshop)}
                    variant="dark-orchid"
                    size="medium"
                  >
                    Stornieren
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, { value: -1, label: 'Alle anzeigen' }]}
          component="div"
          count={workshops.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Zeilen pro Seite:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} von ${count !== -1 ? count : `more than ${to}`}`
          }
        />
      </Box>
      <CancelBookingModal
        onCancelBooking={handleCancelBooking}
        show={isOpenCancelModal}
        onHide={closeCancelModal}
      />
    </AdminLayout>
  );
};

export default AdminTrainingParticipants;
