import React from 'react';
import { useTranslation } from 'react-i18next';

import FgiModal from '../../../components/FgiModal';
import './SignOffDialogModal.scss';

export const SignOffDialogModal = ({ onHide, signOut, prevStep }) => {
  const [t] = useTranslation();

  return (
    <FgiModal
      title={t('Angebot abmeldung')}
      onHide={onHide}
      show
      color="dark-orchid"
      showCloseButton={false}
      className="small-modal"
    >
      <div className="sign-off-dialog-modal-text">
        {t('Wollen Sie sich wirklich abmelden?')}
      </div>

      <div className="sign-off-dialog-modal-button-container">
        <button className="sign-off-dialog-modal-button" onClick={prevStep}>
          {' '}
          {t('Nein')}
        </button>
        <button
          className="sign-off-dialog-modal-button sign-off-dialog-modal-button-grey"
          onClick={signOut}
        >
          {' '}
          {t('Ja')}
        </button>
      </div>
    </FgiModal>
  );
};
export default SignOffDialogModal;
