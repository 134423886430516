import { Stack, Divider } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import MailSVG from '../../assets/svgs/mail';
import PhoneSVG from '../../assets/svgs/phone';
import FgiInfo from '../../constants/FgiInfo';
import PagePath from '../../constants/PagePath';
import LanguageSwitcher from '../LanguageSwitcher';
import './Footer.scss';

const openEmail = () => {
  window.location.href = `mailto:${FgiInfo.email}`;
};

export const Footer = () => {
  const [t] = useTranslation();
  return (
    <Container className="footer">
      <Row className="align-items-center footer-wrapper">
        <Col className="hidden">
          <Row>
            <Col>
              <div className="footer-item flex-row align-items-center">
                <MailSVG />
                <div onClick={openEmail} className="primary-text">
                  {FgiInfo.email}
                </div>
              </div>
            </Col>
            <Col>
              <div className="footer-item flex-row align-items-center">
                <PhoneSVG />
                <div className="primary-text">{FgiInfo.phone}</div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <LanguageSwitcher />
            <Link
              to={PagePath.IMPRINT}
              target="_blank"
              className="secondary-text"
            >
              {t('Impressum')}
            </Link>
            <Link
              to={PagePath.PRIVACY_POLICY}
              target="_blank"
              className="secondary-text"
            >
              {t('Footer.privacyPolicy')}
            </Link>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
