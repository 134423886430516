const defineTypography = (theme) => {
  theme.typography.secondaryText = {
    fontFamily: 'ClanOT-News, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.grey.light2,
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  };

  theme.typography.primaryText = {
    fontFamily: 'ClanOT-News, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  };

  theme.typography.primaryTextDark = {
    ...theme.typography.primaryText,
    color: theme.palette.primary.dark,
  };
  theme.typography.secondaryTextMedium = {
    fontFamily: 'ClanOT-Medium, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  };
  theme.typography.body1 = theme.typography.primaryText;
  theme.typography.body2 = theme.typography.secondaryText;
  theme.typography.body3 = theme.typography.secondaryTextMedium;

  theme.typography.primaryTextMedium = {
    fontFamily: 'ClanOT-Medium, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  };

  theme.typography.titleProNr = {
    fontFamily: 'ClanOT-Medium, sans-serif',
    color: theme.palette.primary.dark,
    fontSize: '24px',
    lineHeight: '30px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
      lineHeight: '20px',
    },
  };

  theme.typography.titleSection = {
    fontFamily: 'ClanOT-News, sans-serif',
    color: theme.palette.primary.dark,
    fontSize: '20px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      lineHeight: '16px',
    },
  };

  theme.typography.ProductTitle = {
    fontFamily: 'ClanOT-News, sans-serif',
    color: theme.palette.primary.dark,
    fontSize: '16px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      lineHeight: '13px',
    },
  };

  theme.typography.titleSnippet = {
    ...theme.typography.titleSection,
    textTransform: 'none',
  };

  theme.typography.titleContent = {
    fontFamily: 'ClanOT-Medium, sans-serif',
    color: theme.palette.primary.dark,
    fontSize: '12px',
    lineHeight: '15px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '10px',
    },
  };

  theme.typography.labelText = {
    fontFamily: 'ClanOT-Medium, sans-serif',
    fontSize: '13px',
    color: theme.palette.primary.dark,
    lineHeight: '16px',
  };

  theme.typography.textSmall = {
    fontFamily: 'ClanOT-News, sans-serif',
    color: theme.palette.grey.light2,
    fontSize: '11px',
    lineHeight: '11px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '9px',
      lineHeight: '9px',
    },
  };

  theme.typography.formLabel = {
    fontFamily: 'ClanOT-Medium, sans-serif',
    fontSize: '13px',
    lineHeight: '16px',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
      lineHeight: '13px',
    },
  };

  theme.typography.formError = {
    fontFamily: 'ClanOT-News, sans-serif',
    fontSize: '80%',
    color: theme.palette.red.main,
  };
};

export default defineTypography;
