import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import { createNewProject } from '../../api/projects';
import greenArrow from '../../assets/svgs/select-arrow-green.svg';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import LanguageInput from '../../components/LanguageInput';
import ProjectTypes from '../../constants/ProjectTypes';
import { useGlobalState } from '../../hooks/useGlobalState';
import { getProductTranslatedData } from '../../utils';
import { leadershipLevelOptions } from '../NewCoachingModal/newCoachingFormOptions';
import {
  FosterCollaborationModalSchema,
  FosterCollaborationModalSchemaWithoutLocation,
} from './FosterCollaborationModalSchema';

const initialFormValues = {
  numParticipants: '',
  language: '',
  location: '',
  date: '',
  leadership_level: '',
  comments: '',
};

export const FosterCollaborationModal = ({
  show,
  onHide,
  product,
  onSubmit,
}) => {
  const [{ user, locale }] = useGlobalState();
  const productVirtual = product && product.is_virtual;
  const [t] = useTranslation();

  const submit = (formData) => {
    try {
      sendAmplitudeData('New Project – Submit Request', {
        type: ProjectTypes.MODERATOR,
        category: getProductTranslatedData(product.title, locale),
      });
      const projectData = {
        ...formData,
        type: ProjectTypes.MODERATOR,
        category: getProductTranslatedData(product.title, locale),
        user_id: user.uid,
        product_id: product.id,
        suggested_scope: product.hours_needed,
        suggested_dayrate: product.hourly_rate,
      };
      createNewProject(projectData);
      onSubmit();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const checkFormSchema = () => {
    if (productVirtual) {
      return FosterCollaborationModalSchemaWithoutLocation(t);
    }
    return FosterCollaborationModalSchema(t);
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={submit}
      validationSchema={checkFormSchema()}
    >
      {({ handleSubmit, values, touched, errors, handleChange }) => (
        <FgiModal
          show={show}
          onHide={onHide}
          title={t('Moderator finden')}
          description={t('Mit folgenden Infos helfen Sie uns dabei, den besten Workshop-Moderator für Sie zu finden:')} // prettier-ignore
          color="green"
        >
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Teilnehmerzahl *')}</Form.Label>
                <Form.Control
                  as="input"
                  name="numParticipants"
                  value={values.numParticipants}
                  onChange={handleChange}
                  isInvalid={
                    !!errors.numParticipants && touched.numParticipants
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numParticipants}
                </Form.Control.Feedback>
              </Form.Group>
              <LanguageInput
                label={t('Bevorzugte Sprache der Coachings')}
                color="green"
                name="language"
                value={values.language}
                onChange={handleChange}
                isInvalid={!!errors.language && touched.language}
                errorMessage={errors.language}
              />
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Ort des Workshops *')}</Form.Label>
                <Form.Control
                  as="input"
                  name="location"
                  value={productVirtual ? t('Virtuell') : values.location}
                  onChange={handleChange}
                  disabled={productVirtual}
                  isInvalid={!!errors.location && touched.location}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.location}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('Startdatum *')}</Form.Label>
                <Form.Control
                  as="input"
                  name="date"
                  type="date"
                  value={values.date}
                  onChange={handleChange}
                  min={moment(Date.now() + 1209600000).format('YYYY-MM-DD')}
                  placeholder="TT.MM.JJJJ"
                  isInvalid={!!errors.date && touched.date}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.date}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Führungsebene *')}</Form.Label>
                <Form.Control
                  style={{ background: `url(${greenArrow}) no-repeat 10px` }}
                  as="select"
                  name="leadership_level"
                  value={values.leadership_level}
                  onChange={handleChange}
                  isInvalid={
                    !!errors.leadership_level && touched.leadership_level
                  }
                >
                  <option disabled hidden value="">
                    {t('Bitte wählen')}
                  </option>
                  {leadershipLevelOptions(t).map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.leadership_level}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}></Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Anmerkungen (optional)')}</Form.Label>
                <Form.Control
                  as="textarea"
                  className="modal-textarea"
                  name="comments"
                  value={values.comments}
                  onChange={handleChange}
                  isInvalid={!!errors.comments && touched.comments}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.comments}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className="modal-footer-button">
              <Button variant="green" size="medium" type="submit">
                {t('Suche starten')}
              </Button>
            </div>
          </Form>
        </FgiModal>
      )}
    </Formik>
  );
};

FosterCollaborationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  product: PropTypes.object,
};

export default FosterCollaborationModal;
