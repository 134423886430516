import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import AdminChip from '../../AdminChip';

const AdminFocusTopicsArray = ({ name = 'focusTopics', sx }) => {
  const formContext = useFormContext();

  const focusTopics = formContext.watch(name)?.map((field) => field.name) ?? [];

  /**
   * @param e {React.MouseEvent<HTMLImageElement, MouseEvent>}
   */
  const handleOnClickFocusTopic = (e) => {
    const focusTopic = e.currentTarget.dataset['id'];
    if (focusTopic) {
      formContext.setValue(
        name,
        formContext
          .getValues(name)
          .filter((field) => field.name !== focusTopic),
      );
    }
  };

  return (
    <Box sx={{ marginTop: 1, minHeight: 6, ...sx }}>
      {focusTopics.map((focusTopic) => (
        <AdminChip label={focusTopic} onClick={handleOnClickFocusTopic} />
      ))}
    </Box>
  );
};

export default AdminFocusTopicsArray;
