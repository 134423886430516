import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const UserInfo = ({ user, project, userCompany }) => {
  const [t] = useTranslation();

  return (
    <Form.Group as={Col}>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Nutzer')}</Form.Label>
          <div className="primary-text">
            {user
              ? `${user.firstname} ${user.lastname} (${userCompany.name})`
              : t('user deleted')}
          </div>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{t('Thema')}</Form.Label>
          <div className="primary-text">{project.category}</div>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{t('Anfragedatum')}</Form.Label>
          <div className="primary-text">
            {project.created_at &&
              moment.unix(project.created_at.seconds).format('L')}
          </div>
        </Form.Group>
      </Row>
      {user && (
        <Row>
          <Form.Group as={Col}>
            <Form.Label>{t('Sprache des Nutzers')}</Form.Label>
            <div className="project-main-container-language primary-text">
              {t(user.language)}
            </div>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>{t('Nationalität')}</Form.Label>
            <div className="project-main-container-nationality primary-text">
              {t(user.nationality)}
            </div>
          </Form.Group>
        </Row>
      )}
    </Form.Group>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  userCompany: PropTypes.object.isRequired,
};

export default UserInfo;
