import * as Sentry from '@sentry/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  deleteCompanyById,
  deleteCompanyLogo,
  useGetCompanies,
} from '../../api';
import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks';
import { getLink } from '../../utils';
import './AdminCompanies.scss';

export const AdminCompanies = () => {
  const [{ company }] = useGlobalState();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const companies = useGetCompanies();

  const openNewCompanyModal = () => {
    navigate(getLink(company, PagePath.ADMIN_COMPANIES_NEW));
  };

  const deleteCompany = (company) => async () => {
    try {
      await deleteCompanyById(company.id);
      if (company.logo_url) {
        await deleteCompanyLogo(company.logo_url);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const redirectToCompany = (id) => () => {
    navigate(`${getLink(company, PagePath.ADMIN_COMPANIES)}/${id}`);
  };

  return (
    <AdminLayout>
      <div className="admin-companies-title-wrapper">
        <Title width="14%" text={t('Unternehmen')} color="yellow" />
        <Button
          className="admin-companies-title-wrapper-button"
          variant="light"
          size="small"
          onClick={openNewCompanyModal}
        >
          {t('Hinzufügen')}
        </Button>
      </div>
      <div className="main-container">
        <div className="admin-companies-companies-list">
          <table className="admin-companies-table">
            <thead className="secondary-text">
              <tr className="admin-companies-table-head-row">
                <th width="29%">{t('Name')}</th>
                <th width="29%">{t('Domain')}</th>
                <th width="14%">{t('Registrierte Nutzer')}</th>
                <th width="13%" />
                <th width="15%" />
              </tr>
            </thead>
            <tbody className="primary-text">
              {companies.map((company) => (
                <tr key={company.id} className="admin-companies-table-body-row">
                  <td>{company.name}</td>
                  <td>
                    {company.domain.map((domain) => (
                      <div key={domain}>{domain}</div>
                    ))}
                  </td>
                  <td>{company.users_count}</td>
                  <td>
                    {company.users_count === 0 && (
                      <Button
                        onClick={deleteCompany(company)}
                        arrow={false}
                        size="small"
                        variant="light"
                      >
                        {t('Entfernen')}
                      </Button>
                    )}
                  </td>
                  <td>
                    <Button
                      onClick={redirectToCompany(company.id)}
                      size="medium"
                      variant="main"
                    >
                      {t('Bearbeiten')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminCompanies;
