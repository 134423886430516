import React from 'react';
import { useTranslation } from 'react-i18next';

import checkedImg from '../../../assets/svgs/checked.svg';
import FgiModal from '../../../components/FgiModal';
import './SuccessModal.scss';

export const SuccessModal = ({ onHide, title, subtitle }) => {
  const [t] = useTranslation();

  return (
    <FgiModal
      title={t(title)}
      onHide={onHide}
      show
      color="dark-orchid"
      showCloseButton={false}
      className="small-modal"
    >
      <div className="success-modal-container">
        <img className="success-modal-check" alt="img" src={checkedImg} />
        <div className="success-modal-text">{t(subtitle)}</div>
        <button className="success-modal-button" onClick={onHide}>
          {t('Fertig')}
        </button>
      </div>
    </FgiModal>
  );
};
export default SuccessModal;
