import { collection, getDocs, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { db, functions } from '../firebaseConfig';
import Monitoring from '../utils/monitoring';

const COLLECTION_NAME = 'elearnings';

/**
 * @method getCourseInfo
 * @param {string} user_id - user id
 * @param {string} course_id - course id
 * @summary get info about user course
 * @returns {Promise<Array>} company data or null
 */
export const getCourseInfo = async (user_id, course_id) => {
  const courseRef = collection(db, COLLECTION_NAME);
  const q = query(
    courseRef,
    where('user_id', '==', user_id),
    where('course_id', '==', course_id),
  );
  const courseSnap = await getDocs(q);
  if (courseSnap.empty) {
    return null;
  }
  const [doc] = courseSnap.docs;
  return doc.data();
};

/**
 * @method registerCourse *
 * @param {string} course_id - course id
 * @summary register user for course
 */
export const registerCourse = httpsCallable(functions, 'registerCourse');

/**
 * @method redirectToCourse
 * @param {string} registration_id - id of registration
 * @summary redirect user to course by registration id
 */
export const redirectToCourse = httpsCallable(functions, 'redirectToCourse');

/**
 * @function getAllElearnings
 * @description fetch all elearnings
 * @returns {Promise<Array>} list of elearnings
 */
export const getAllElearnings = async () => {
  try {
    const elearningsRef = collection(db, COLLECTION_NAME);
    const elearningsSnap = await getDocs(query(elearningsRef));
    if (elearningsSnap.empty) {
      return [];
    }
    return elearningsSnap.docs.map((snap) => ({
      id: snap.id,
      ...snap.data(),
    }));
  } catch (err) {
    Monitoring.captureException(err);
  }
};
