import React from 'react';

const ArrowSVG = ({ color, rotate = false, rotateDeg = 180 }) => (
  <svg
    style={
      rotate
        ? {
            transform: `rotate(${rotateDeg}deg)`,
            marginRight: rotateDeg === 180 ? '0px' : '15px',
            left: rotateDeg === 180 ? '-10px' : '15px',
            top: rotateDeg === 180 ? '-1px' : '0px',
          }
        : {}
    }
    width="10"
    height="15"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.93763 9.00444L0.483331 15.4537C0.00598826 15.931 0.00598819 16.7029 0.483331 17.1751C0.960674 17.6525 1.73255 17.6474 2.20989 17.1751L9.52239 9.86772C9.9845 9.40561 9.99466 8.66421 9.55794 8.18686L2.21497 0.828658C1.9763 0.589986 1.66146 0.47319 1.35169 0.47319C1.04193 0.47319 0.727083 0.589986 0.488411 0.828658C0.0110679 1.306 0.0110678 2.07788 0.488411 2.55014L6.93763 9.00444Z"
      fill={
        color === 'button--main' || color === 'button--light'
          ? '#666666'
          : 'white'
      }
    />
  </svg>
);

export default ArrowSVG;
