import { Typography, Box } from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import {
  updateUserById,
  updateUserEmail,
  getCompanyByDomain,
  isSAMLProvider,
} from '../../api';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import FgiModal from '../../components/FgiModal';
import GENDER_TYPES from '../../constants/SexTypes';
import Confirm from '../../containers/Confirm';
import { SET_USER, useGlobalState } from '../../hooks/useGlobalState';
import {
  EmailPasswordSchema,
  SAMLSchema,
} from './ProfileMainDataUpdateModalSchema';

export const ProfileMainDataUpdateModal = ({ show = false, onHide }) => {
  const [{ user, company }, dispatch] = useGlobalState();
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [invalidDomain, setInvalidDomain] = useState(false);
  const [userWithNewData, setUserWithNewData] = useState({});
  const [t] = useTranslation();

  const initialFormValues = {
    sex: user.sex,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    password: '',
  };

  const isUserSignedInWithSAML = isSAMLProvider();

  const submit = async ({ email, firstname, lastname, sex, password }) => {
    try {
      let newEmail = user.email;
      if (user.email !== email) {
        const domain = email
          .substring(email.indexOf('@') + 1, email.length)
          .toLowerCase();
        const company = await getCompanyByDomain(domain);
        if (!company.data) {
          setInvalidDomain(true);
          return;
        }
        sendAmplitudeData('Update Email Address');
        const newUser = await updateUserEmail(email, password);
        newEmail = newUser.email;
      }

      const formData = {
        email: newEmail,
        firstname,
        lastname,
        sex,
      };

      onHide();
      sendAmplitudeData('Update Profile Data');
      const updatedUser = await updateUserById(user.uid, formData);
      if (user.email !== email) {
        setConfirmShow(true);
        setUserWithNewData(updatedUser);
      } else {
        dispatch({ type: SET_USER, payload: updatedUser });
      }
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        setErrorDialog(true);
      }
    }
  };

  const handleConfirmClose = () => {
    dispatch({ type: SET_USER, payload: userWithNewData });
    setConfirmShow(false);
  };

  const handleConfirm = () => {
    dispatch({
      type: SET_USER,
      payload: { ...userWithNewData, emailVerified: true },
    });
  };

  return (
    <Formik
      validationSchema={
        isUserSignedInWithSAML ? SAMLSchema : EmailPasswordSchema(t)
      }
      onSubmit={submit}
      initialValues={initialFormValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
      }) => {
        const change = (name) => (e) => {
          e.persist();
          handleChange(e);
          if (name === 'email' && errorDialog) {
            setErrorDialog(false);
          }
          if (name === 'email' && invalidDomain) {
            setInvalidDomain(false);
          }
        };

        const setSex = (val) => () => {
          setFieldValue('sex', val);
        };

        return (
          <>
            <FgiModal
              title={t('Daten Ändern')}
              color="yellow"
              show={show}
              onHide={onHide}
            >
              <Form noValidate onSubmit={handleSubmit}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Checkbox
                    checked={values.sex === GENDER_TYPES.male}
                    onClick={setSex(GENDER_TYPES.male)}
                    label={t('Herr')}
                  />
                  <Checkbox
                    checked={values.sex === GENDER_TYPES.female}
                    onClick={setSex(GENDER_TYPES.female)}
                    label={t('Frau')}
                  />
                  <Checkbox
                    checked={values.sex === GENDER_TYPES.other}
                    onClick={setSex(GENDER_TYPES.other)}
                    label={t('Divers')}
                  />
                </Box>
                <Row>
                  <Form.Group as={Col} md={6} xs={12}>
                    <Form.Label>{t('Vorname')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      value={values.firstname}
                      onChange={change('firstname')}
                      isInvalid={touched.firstname && !!errors.firstname}
                      disabled={isUserSignedInWithSAML}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstname}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6} xs={12}>
                    <Form.Label>{t('Nachname')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      value={values.lastname}
                      onChange={change('lastname')}
                      isInvalid={touched.lastname && !!errors.lastname}
                      disabled={isUserSignedInWithSAML}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastname}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>{t('E-Mail-Adresse')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={change('email')}
                      isInvalid={touched.email && !!errors.email}
                      disabled={isUserSignedInWithSAML}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                    {errorDialog && (
                      <Typography variant="formError">
                        {t('Diese E-Mail-Adresse wird bereits genutzt')}
                      </Typography>
                    )}
                    {invalidDomain && (
                      <Typography variant="formError">
                        {t(
                          'Ihre E-Mail-Adresse wurde noch nicht freigeschaltet.',
                        )}
                      </Typography>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>{t('Passwort *')}</Form.Label>
                    <Form.Control
                      as="input"
                      autoComplete="on"
                      type="password"
                      name="password"
                      onChange={change('password')}
                      disabled={isUserSignedInWithSAML}
                      isInvalid={touched.password && !!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                {isUserSignedInWithSAML && (
                  <Box
                    component={Row}
                    sx={{ justifyContent: 'center', paddingY: 1, paddingX: 2 }}
                  >
                    <Typography variant="textSmall" textAlign="center">
                      {t('ProfileMainDataUpdateModal.SAML-notice', {
                        company,
                      })}
                    </Typography>
                  </Box>
                )}
                <Row>
                  <Form.Group className="modal-footer-button" as={Col} md={12}>
                    <Button variant="main" size="medium" type="submit">
                      {t('Speichern')}
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </FgiModal>
            <Confirm
              show={confirmShow}
              onHide={handleConfirmClose}
              onConfirm={handleConfirm}
              email={values.email}
              password={values.password}
            />
          </>
        );
      }}
    </Formik>
  );
};

ProfileMainDataUpdateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ProfileMainDataUpdateModal;
