import React, { useState } from 'react';

import { loadEventsList } from '../../api';
import { deleteEventFromUserById } from '../../api';
import { useGlobalState } from '../../hooks';
import BookOfferPartOfModal from './BookOfferPartOfModal';
import ChooseTopicPartOfModal from './ChooseTopicPartOfModal';
import './FindWorkshopsModal.scss';
import InfoPartOfModal from './InfoPartOfModal';
import SignOffDialogModal from './SignOffDialogModal';
import SuccessModal from './SuccessModal';

export const FindWorkshopsModal = ({ show, onHide, item }) => {
  const [focusTopicsState, setFocusTopics] = useState(item.focusTopics ?? []);
  // TODO: Instead of numbers use enum. Create enum using correct modal names
  const [numberModal, setNumberModal] = useState(1);

  const [
    {
      user: { uid, company_id },
    },
    dispatch,
  ] = useGlobalState();

  const handleChangeFocusTopics = (newButtons) => {
    setFocusTopics(newButtons);
  };

  const onHideHandle = () => {
    setNumberModal(1);
    onHide();
  };

  const signOutHandle = async () => {
    const isDeleting = await deleteEventFromUserById(uid, item.id);
    if (isDeleting) {
      loadEventsList({ company_id, user_id: uid, dispatch });
      setNumberModal(6);
    }
  };

  /**
   * @param number
   * @return {function(...[*]=)}
   */
  const handleModalStep = (number) => () => {
    let modalToOpen = number;
    if (number === 2) {
      // When no focus topics then skip step 2
      if (
        typeof focusTopicsState?.length !== 'number' ||
        focusTopicsState?.length === 0
      ) {
        if (numberModal === 1) {
          modalToOpen = 3;
        }
        if (numberModal === 3) {
          modalToOpen = 1;
        }
      }
    }
    setNumberModal(modalToOpen);
  };

  if (!show) return null;

  switch (numberModal) {
    case 1:
      return (
        <InfoPartOfModal
          item={item}
          nextStep={handleModalStep(2)}
          prevStep={onHideHandle}
          onHide={onHideHandle}
          signOut={handleModalStep(5)}
        />
      );
    case 2:
      return (
        <ChooseTopicPartOfModal
          item={item}
          focusTopics={focusTopicsState}
          changeFocusTopics={handleChangeFocusTopics}
          nextStep={handleModalStep(3)}
          prevStep={handleModalStep(1)}
          onHide={onHideHandle}
        />
      );
    case 3: {
      return (
        <BookOfferPartOfModal
          item={item}
          focusTopics={focusTopicsState}
          prevStep={handleModalStep(2)}
          nextStep={handleModalStep(4)}
          onHide={onHideHandle}
        />
      );
    }
    case 4: {
      return (
        <SuccessModal
          onHide={onHideHandle}
          title="Anmeldung erfolgreich"
          subtitle="Sie erhalten in Kürze eine Bestätigung ihrer Buchung per E-Mail."
        />
      );
    }
    case 5: {
      return (
        <SignOffDialogModal
          onHide={onHideHandle}
          signOut={signOutHandle}
          prevStep={handleModalStep(1)}
        />
      );
    }
    case 6: {
      return (
        <SuccessModal
          onHide={onHideHandle}
          title="Erfolgreich Abgemeldet"
          subtitle="Sie erhalten in Kürze eine Bestätigung ihrer Abmeldung per E-Mail."
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default FindWorkshopsModal;
