/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import Excel from 'exceljs';

import { getAllUsers, getAllQuestionnaireUsers } from '../api';
import { downloadReport } from './';

export const getAllianzUserReport = async () => {
  try {
    const userRecords = await getAllianzUserReportData();
    const workbook = createAllianzUserWorkbook(userRecords);
    const buffer = await workbook.xlsx.writeBuffer();
    downloadReport(buffer, 'AllianzUserReport');
  } catch (err) {
    Sentry.captureException(err);
  }
};

const getAllianzUserReportData = async () => {
  const questionnaireUsers = await getAllQuestionnaireUsers();
  const users = await getAllUsers();

  return questionnaireUsers.map((user) => ({
    email: users.find((u) => u.id === user.id)?.email,
    unit: user.unit,
    cluster: user.cluster,
    answersCompleted: hasFilledInQuestionnaire(user) ? 'Ja' : 'Nein',
    detailsCompleted: hasFilledInQuestionnaireDetails(user) ? 'Ja' : 'Nein',
  }));
};

const hasFilledInQuestionnaire = (userData) => {
  return userData?.answers?.length;
};

const hasFilledInQuestionnaireDetails = (userData) => {
  const hasGoals = userData?.goals?.length;
  const hasSavedOffers = userData?.savedOffers?.length;
  const hasContextNotes = userData?.contextNotes?.length;
  const hasTeamNotes = userData?.teamNotes?.length;
  const hasSelfNotes = userData?.selfNotes?.length;
  const hasTeamIdeas = userData?.teamIdeas?.length;
  const hasSelfIdeas = userData?.selfIdeas?.length;

  return (
    hasGoals ||
    hasSavedOffers ||
    hasContextNotes ||
    hasTeamNotes ||
    hasSelfNotes ||
    hasTeamIdeas ||
    hasSelfIdeas
  );
};

const createAllianzUserWorkbook = (userRecords) => {
  const workbook = new Excel.Workbook();

  const worksheet = workbook.addWorksheet('Nutzer');

  // Add headers
  worksheet.columns = [
    { header: 'E-Mail-Adresse', key: 'email' },
    { header: 'Bereich', key: 'unit' },
    { header: 'Cluster', key: 'cluster' },
    { header: 'Standortbestimmung ausgefüllt?', key: 'answersCompleted' },
    { header: 'Details bearbeitet?', key: 'detailsCompleted' },
  ];

  // force the columns to be at least as long as their header row.
  // Have to take this approach because ExcelJS doesn't have an autofit property.
  worksheet.columns.forEach((column) => {
    column.width = column.header.length < 12 ? 12 : column.header.length;
  });

  // Make the header bold.
  // Note: in Excel the rows are 1 based, meaning the first row is 1 instead of 0.
  worksheet.getRow(1).font = { bold: true };
  worksheet.addRows(userRecords);

  return workbook;
};
