import i18n from 'i18next';
import { createContext, useReducer, useContext } from 'react';

import { i18nChangeLocale, getLanguagePartFromCode } from '../i18n';
import { Monitoring } from '../utils';

const StateContext = createContext({});

// Actions
export const SET_USER = 'SET_USER';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_AVAILABLE_PRODUCTS = 'SET_AVAILABLE_PRODUCTS';
export const LOGOUT = 'LOGOUT';
export const GET_ALL_EVENTS_BY_COMPANY_ID = 'GET_ALL_EVENTS_BY_COMPANY_ID';

export const GlobalStateProvider = ({
  children,
  reducer: _reducer = reducer,
}) => (
  <StateContext.Provider
    value={useReducer(_reducer, {
      /**
       * We need to only get the language part (`en` of `en-US`),
       * because our system only uses the language part to provide localized
       * strings. E.g. our Firestore DB uses objects / maps with locales as keys.
       * It usually uses `en` instead of `en-US`.
       */
      locale: getLanguagePartFromCode(i18n.language),
    })}
  >
    {children}
  </StateContext.Provider>
);

export const useGlobalState = () => {
  return useContext(StateContext);
};

export const reducer = (state, { payload, type }) => {
  switch (type) {
    case SET_USER: {
      const user = payload;
      const isAdmin = payload && !!payload.admin;
      Monitoring.setUser({ ...payload });
      if (payload?.hasOwnProperty('emailVerified')) {
        return {
          ...state,
          user,
          isAdmin,
          emailVerified: user.emailVerified,
        };
      }
      return {
        ...state,
        user,
        isAdmin,
      };
    }
    case EMAIL_VERIFIED:
      return {
        ...state,
        emailVerified: payload,
      };
    case GET_ALL_PROJECTS:
      return {
        ...state,
        projects: payload,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: payload,
      };
    case SET_LOCALE:
      i18nChangeLocale(payload);
      return {
        ...state,
        locale: getLanguagePartFromCode(payload),
      };
    case SET_AVAILABLE_PRODUCTS:
      return {
        ...state,
        available_products: payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        company: null,
        available_products: [],
        projects: [],
        emailVerified: false,
        events: [],
      };
    case GET_ALL_EVENTS_BY_COMPANY_ID:
      return {
        ...state,
        events: payload,
      };
    default:
      return state;
  }
};
