import { Unstable_Grid2 as Grid, Paper } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import bigBackground from '../../assets/images/coach-finder-background.png';
import smallBackground from '../../assets/images/coach-finder-small-background.png';
import Button from '../../components/Button';
import Hero from '../../components/Hero';
import PageTitle from '../../components/PageTitle';
import ProductItem from '../../components/ProductItem';
import Teaser from '../../components/Teaser';
import ProjectTypes from '../../constants/ProjectTypes';
import MainLayout from '../../HOC/MainLayout';
import { useGlobalState } from '../../hooks/useGlobalState';
import FirstCoachingModal from '../../modals/FirstCoachingModal';
import NeuesCoachingModal from '../../modals/NeuesCoachingModal';
import NewCoachingModal from '../../modals/NewCoachingModal';
import NewCoachingSuccessModal from '../../modals/NewCoachingSuccessModal';
import { getProductTranslatedData, Monitoring } from '../../utils';
import './CoachFinder.scss';
import CoachItem from './components/CoachItem';

export const CoachFinder = () => {
  const [{ locale, user, available_products }] = useGlobalState();
  const [t] = useTranslation();

  useEffect(() => {
    Monitoring.trackEvent('Visit Coach Finder');
  }, []);

  const [product, setProduct] = useState(null);
  const [showFirstCoachingModal, setShowFirstCoachingModal] = useState(false);
  const [showNeuesCoachingModal, setShowNeuesCoachingModal] = useState(false);
  const [showNewCoachingModal, setShowNewCoachingModal] = useState(false);
  const [showNewCoachingSuccessModal, setShowNewCoachingSuccessModal] =
    useState(false);

  const handleFindCoach = () => {
    const {
      // eslint-disable-next-line camelcase
      date_of_birth,
      nationality,
      language,
      location,
    } = user;
    // eslint-disable-next-line camelcase
    Monitoring.trackEvent('New Project – Start', { type: ProjectTypes.COACH });
    if (!date_of_birth || !nationality || !language || !location) {
      setShowFirstCoachingModal(true);
    } else {
      setShowNeuesCoachingModal(true);
    }
  };

  const handleHideFirstCoachingModal = () => {
    setShowFirstCoachingModal(false);
  };

  const handleHideNewCoachingModal = () => {
    setProduct(null);
    setShowNewCoachingModal(false);
  };

  const handleHideNewCoachingSuccessModal = () => {
    setShowNewCoachingSuccessModal(false);
  };

  const handleHideNeuesCoachingModal = () => {
    setShowNeuesCoachingModal(false);
  };

  const onCategoryClick = (product) => () => {
    Monitoring.trackEvent('New Project – Select Category', {
      category: getProductTranslatedData(product.title, locale),
      type: ProjectTypes.COACH,
    });
    setProduct(product);
    setShowNeuesCoachingModal(false);
    setShowNewCoachingModal(true);
  };

  const handleSubmitFirstCoaching = () => {
    setShowFirstCoachingModal(false);
    setShowNeuesCoachingModal(true);
  };

  const handleSubmitNewCoaching = () => {
    setShowNewCoachingModal(false);
    setProduct(null);
    setShowNewCoachingSuccessModal(true);
  };

  const handleFindCoachBottomItem = (product) => {
    const {
      // eslint-disable-next-line camelcase
      date_of_birth,
      nationality,
      language,
      location,
    } = user;
    // eslint-disable-next-line camelcase
    Monitoring.trackEvent('New Project – Start', { type: ProjectTypes.COACH });
    if (!date_of_birth || !nationality || !language || !location) {
      setShowFirstCoachingModal(true);
    } else {
      onCategoryClick(product)();
    }
  };

  const coachFinderItems = available_products.filter(
    (product) => product.type === ProjectTypes.COACH,
  );

  return (
    <MainLayout>
      <FirstCoachingModal
        onSubmit={handleSubmitFirstCoaching}
        onHide={handleHideFirstCoachingModal}
        show={showFirstCoachingModal}
      />
      <NeuesCoachingModal
        coachFinderItems={coachFinderItems}
        show={showNeuesCoachingModal}
        onHide={handleHideNeuesCoachingModal}
        onCategoryClick={onCategoryClick}
      />
      <NewCoachingModal
        onSubmit={handleSubmitNewCoaching}
        onHide={handleHideNewCoachingModal}
        show={showNewCoachingModal}
        product={product}
      />
      <NewCoachingSuccessModal
        onHide={handleHideNewCoachingSuccessModal}
        show={showNewCoachingSuccessModal}
      />
      <div className="coach-finder">
        <PageTitle width="15%" text={t('Coach finden')} color="yellow" />
        <Hero
          paddingBottom="35px"
          bigBackground={bigBackground}
          smallBackground={smallBackground}
        >
          <Teaser
            type="yellow"
            title={t('Executive Coaches unterstützen Sie bei Ihren Führungsthemen')} // prettier-ignore
            description={t('Arbeiten Sie an Ihren individuellen Führungsthemen mit erfahrenen Executive Coaches – virtuell oder persönlich.')} // prettier-ignore
          />
          <div className="coach-finder-button-wrapper">
            <Button size="big" variant="main" onClick={handleFindCoach}>
              {t('Finden Sie Ihren Coach')}
            </Button>
          </div>
        </Hero>
        <CoachItem />
        <Paper elevation={0} square sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              sx={{ marginBottom: 7 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <PageTitle
                text={t('Coaching Angebote')}
                color="yellow"
                width="40%"
              />
            </Grid>

            {coachFinderItems.map((item) => (
              <Grid xs={6}>
                <ProductItem
                  onClick={() => handleFindCoachBottomItem(item)}
                  key={item.id}
                  description={item.description[locale]}
                  image={item.image}
                  pdfUrl={item.pdfUrl ? item.pdfUrl[locale] : ''}
                  title={item.title[locale]}
                  color="yellow"
                  buttonLabel={t('ihren coach finden')}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </div>
    </MainLayout>
  );
};

export default CoachFinder;
