import { DE_LOCALE, EN_LOCALE } from '../containers/Translation/constant';
/* eslint-disable import/prefer-default-export */

export const getCoachesTranslatedData = (data, locale) => {
  if (!data) {
    return '';
  }
  switch (locale) {
    case DE_LOCALE:
      return data[DE_LOCALE];
    case EN_LOCALE:
      if (data[EN_LOCALE]) {
        return data[EN_LOCALE];
      }
      return data[DE_LOCALE];
    default:
      return data[DE_LOCALE];
  }
};
