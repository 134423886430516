import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProjectStates from '../../../../constants/ProjectStates';

const AdminProjectsTabs = ({
  setActiveTab,
  currentProjectState,
  projectsCount,
}) => {
  const [t] = useTranslation();

  const tabs = [
    {
      type: ProjectStates.findCoaches,
      title: t('Offene Anfragen'),
      count: projectsCount[ProjectStates.findCoaches] ?? 0,
    },
    {
      type: ProjectStates.bookCoach,
      title: t('Wartet auf Buchung'),
      count: projectsCount[ProjectStates.bookCoach] ?? 0,
    },
    {
      type: ProjectStates.projectRunning,
      title: t('Laufende Projekte'),
      count: projectsCount[ProjectStates.projectRunning] ?? 0,
    },
  ];

  const getActiveTab = (productState) => {
    const tabStyle = {
      padding: '9px 23px',
      border: '1px solid yellow.main',
      borderBottom: 'none',
      backgroundColor: 'yellow.light',
      cursor: 'pointer',
    };
    switch (productState) {
      case currentProjectState:
        return { ...tabStyle, backgroundColor: 'yellow.main' };
      default:
        return tabStyle;
    }
  };

  return (
    <Box sx={{ display: 'flex', borderBottom: '1px solid yellow.main' }}>
      {tabs.map((tab) => (
        <Box
          key={tab.type}
          onClick={setActiveTab(tab.type)}
          sx={getActiveTab(tab.type)}
        >
          <Typography variant="primaryText">
            {tab.title} ({tab.count})
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

AdminProjectsTabs.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
  currentProjectState: PropTypes.string.isRequired,
  projectsCount: PropTypes.object.isRequired,
};

export default AdminProjectsTabs;
