const PagePath = {
  ADMIN_TRAINING: '/:customPath?/admin/trainings/:id',
  ADMIN_TRAININGS: '/:customPath?/admin/trainings',
  ADMIN_TRAININGS_NEW: '/:customPath?/admin/trainings/new',
  ADMIN_TRAININGS_PARTICIPANTS: '/:customPath?/admin/trainings/participants',
  ADMIN_TRAININGS_PARTICIPANTS_ID:
    '/:customPath?/admin/trainings/participants/:id',
  ADMIN_COACHES: '/:customPath?/admin/coaches',
  ADMIN_COMPANIES: '/:customPath?/admin/companies',
  ADMIN_COMPANIES_NEW: '/:customPath?/admin/companies/new',
  ADMIN_COMPANY: '/:customPath?/admin/companies/:companyId',
  ADMIN_COMPANY_PRODUCTS:
    '/:customPath?/admin/companies/:companyId/products/:groupId?',
  ADMIN_COMPANY_REDIRECT: '/:customPath?/admin/companies/:companyId/redirect',
  ADMIN_COMPANY_GROUPS: '/admin/companies/:companyId/groups',
  ADMIN_PROJECT: '/:customPath?/admin/projects/:id',
  ADMIN_PROJECTS: '/:customPath?/admin/projects',
  ADMIN_PRODUCT: '/:customPath?/admin/products/:productId',
  ADMIN_PRODUCTS: '/:customPath?/admin/products',
  ADMIN_PRODUCTS_NEW: '/:customPath?/admin/products/new',
  ADMIN_REPORTS: '/:customPath?/admin/reports',
  ADMIN_VERIFY_EMAIL_ADDRESS: '/:customPath?/admin/confirmEmailAddresses',
  ANMELDEN: '/:customPath?/',
  BOOKINGS: '/:customPath?/bookings',
  COACH_FINDER: '/:customPath?/coach-finder',
  CONFIRM: '/:customPath?/confirm',
  CONFIRM_REGISTRATION: '/:customPath?/confirm_registration',
  DASHBOARD: '/:customPath?/dashboard',
  IMPRINT: '/imprint',
  LOGIN: '/:customPath?/login',
  MODERATOR_FINDEN: '/:customPath?/moderator-finder',
  PRIVACY_POLICY: '/privacy-policy',
  SETTINGS: '/:customPath?/settings',
  SIGNOUT: '/:customPath?/signout',
  OPEN_WORKSHOPS: '/:customPath?/offers',
  ELEARNING: '/:customPath?/elearning',
};

export default PagePath;
