import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { sendAmplitudeData } from '../../amplitude';
import { updateUserById } from '../../api';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import FgiModal from '../../components/FgiModal';
import GENDER_TYPES from '../../constants/SexTypes';

const missingSchema = () =>
  yup.object({
    sex: yup.string().required(),
  });

const CompleteMissingInfoModal = ({ userId, show, onHide }) => {
  const [t] = useTranslation();
  const handleSubmit = async (formData) => {
    try {
      await updateUserById(userId, formData);
      onHide();
      sendAmplitudeData('Update Profile Data');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <FgiModal
      show={show}
      onHide={onHide}
      title={t('CompleteMissingInfoModal.title')}
      showCloseButton={false}
      test-id="CompleteMissingInfoModal"
    >
      <Formik
        initialValues={{ sex: undefined }}
        onSubmit={handleSubmit}
        validationSchema={missingSchema}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit} role="form">
            <Typography variant="textSmall" textAlign="center" mb={3}>
              {t('CompleteMissingInfoModal.greeting')}
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Checkbox
                checked={values.sex === GENDER_TYPES.male}
                onClick={() => handleChange('sex')(GENDER_TYPES.male)}
                isError={!!errors.sex}
                label={t('generic.salutation.mr')}
              />
              <Checkbox
                checked={values.sex === GENDER_TYPES.female}
                onClick={() => handleChange('sex')(GENDER_TYPES.female)}
                isError={!!errors.sex}
                label={t('generic.salutation.ms')}
              />
              <Checkbox
                checked={values.sex === GENDER_TYPES.other}
                onClick={() => handleChange('sex')(GENDER_TYPES.other)}
                isError={!!errors.sex}
                label={t('generic.salutation.other')}
              />
            </Box>
            <Button
              className="modal-footer-button"
              variant="main"
              size="small"
              type="submit"
              arrow="default"
              load={isSubmitting}
              disabled={Object.keys(errors).length > 0}
            >
              {t('CompleteMissingInfoModal.confirm')}
            </Button>
          </Form>
        )}
      </Formik>
    </FgiModal>
  );
};

CompleteMissingInfoModal.propTypes = {
  userId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default CompleteMissingInfoModal;
