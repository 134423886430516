import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import { updateUserById } from '../../api';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import LanguageInput from '../../components/LanguageInput';
import { useGlobalState, SET_USER } from '../../hooks/useGlobalState';
import useTranslatedCountries from '../../hooks/useTranslatedCountries';
import ProfileSecondaryDataUpdateModalSchema from './ProfileSecondaryDataUpdateModalSchema';

export const ProfileSecondaryDataUpdateModal = ({ show, onHide }) => {
  const [{ user }, dispatch] = useGlobalState();
  const [t] = useTranslation();
  const countries = useTranslatedCountries();
  const initialFormValues = {
    date_of_birth: user.date_of_birth || '',
    nationality: user.nationality || '',
    language: user.language || '',
    location: user.location || '',
  };

  const submit = async (data) => {
    try {
      sendAmplitudeData('Update Profile Data');
      const updatedUser = await updateUserById(user.uid, data);
      dispatch({ type: SET_USER, payload: updatedUser });
    } catch (err) {
      Sentry.captureException(err);
    }
    onHide();
  };

  return (
    <FgiModal
      color="yellow"
      title={t('Daten Ändern')}
      onHide={onHide}
      show={show}
    >
      <Formik
        validationSchema={ProfileSecondaryDataUpdateModalSchema}
        onSubmit={submit}
        initialValues={initialFormValues}
      >
        {({ handleSubmit, values, touched, errors, handleChange }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Geburtsdatum')}</Form.Label>
                <Form.Control
                  as="input"
                  type="date"
                  name="date_of_birth"
                  value={values.date_of_birth}
                  onChange={handleChange}
                  placeholder="TT.MM.JJJJ"
                  isInvalid={!!errors.date_of_birth && touched.date_of_birth}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.date_of_birth}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('Nationalität')}</Form.Label>
                <Form.Control
                  as="select"
                  name="nationality"
                  value={values.nationality}
                  onChange={handleChange}
                  isInvalid={!!errors.nationality && touched.nationality}
                >
                  {Object.entries(countries)
                    .sort(([aK, aV], [bK, bV]) => {
                      if (aV < bV) {
                        return -1;
                      }
                      if (aV > bV) {
                        return 1;
                      }
                      return 0;
                    })
                    .map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.nationality}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <LanguageInput
                label={t('Bevorzugte Sprache der Coachings')}
                color="yellow"
                name="language"
                value={values.language}
                onChange={handleChange}
                isInvalid={!!errors.language && touched.language}
                errorMessage={errors.language}
                allowNoResponse
              />
              <Form.Group as={Col}>
                <Form.Label>{t('Standort')}</Form.Label>
                <Form.Control
                  as="input"
                  type="text"
                  name="location"
                  value={values.location}
                  onChange={handleChange}
                  isInvalid={!!errors.location && touched.location}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.location}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className="modal-footer-button">
              <Button variant="main" size="medium" type="submit">
                {t('Speichern')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </FgiModal>
  );
};

ProfileSecondaryDataUpdateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ProfileSecondaryDataUpdateModal;
