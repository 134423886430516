import { Box, Unstable_Grid2 as Grid, Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../Button';

/**
 * This component renders a product item.
 * It can be used to display a product as part of a grid.
 * It automatically adds a button to download a PDF, if a URL is provided.
 */

const ProductItem = ({
  image,
  title,
  description,
  pdfUrl,
  color,
  onClick,
  buttonLabel,
}) => {
  const [t] = useTranslation();

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.setAttribute('href', pdfUrl);
    link.setAttribute('download', 'Product.pdf');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Box component="img" src={image} alt={title} sx={{ width: '100%' }} />
      </Grid>
      <Grid xs={12}>
        <Typography variant="ProductTitle">{title}</Typography>
      </Grid>
      <Grid xs={12} md={6}>
        <Typography variant="body1">{description}</Typography>
      </Grid>
      <Grid xs={12} md={6}>
        <Stack>
          <Button onClick={onClick} size="medium" variant={color}>
            {buttonLabel}
          </Button>
          {pdfUrl && (
            <Box
              component={Button}
              sx={{ marginTop: '8px' }}
              arrow={false}
              onClick={downloadPdf}
              size="medium"
              variant="light"
            >
              {t('ProductItem.button.downloadPDF')}
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

ProductItem.propTypes = {
  /** The product's lead image */
  image: PropTypes.string.isRequired,

  /** The product's title */
  title: PropTypes.string.isRequired,

  /** The product's description */
  description: PropTypes.string.isRequired,

  /** An optional URL to a pdf file */
  pdfUrl: PropTypes.string,
  /** The color of the main button */
  color: PropTypes.string.isRequired,

  /** Called when the user clicks the main button */
  onClick: PropTypes.func.isRequired,

  /** The label of the main button */
  buttonLabel: PropTypes.string.isRequired,
};

export default ProductItem;
