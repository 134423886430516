import { Alert, AlertTitle, Button, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  applyActionCodeOnFirebase,
  checkActionCodeOnFirebase,
  trigger_update_email,
} from '../../api';
import bgWelcome from '../../assets/images/bg-welcome.webp';
import PagePath from '../../constants/PagePath';
import { getLink, Monitoring } from '../../utils';

const ConfirmRegistration = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [isInvalidEmailVerificationURL, setIsInvalidEmailVerificationURL] =
    useState(false);

  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    const applyActionCodeRecoverEmail = async () => {
      try {
        const {
          data: { fromEmail, email },
        } = await checkActionCodeOnFirebase(oobCode);
        await applyActionCodeOnFirebase(oobCode);
        await trigger_update_email({ fromEmail, email });
      } catch (err) {
        Monitoring.captureException(err);
      } finally {
        navigate(getLink(undefined, PagePath.ANMELDEN));
      }
    };

    switch (mode) {
      case 'verifyEmail':
        Monitoring.trackEvent('Confirm Email Address');
        applyActionCodeOnFirebase(oobCode)
          .then(() => {
            window.location.assign(getLink(undefined, PagePath.ANMELDEN));
          })
          .catch((e) => {
            switch (e.code) {
              case 'auth/invalid-action-code':
                setIsInvalidEmailVerificationURL(true);
                break;
              default:
                Monitoring.captureException(e);
            }
          });
        break;
      case 'resetPassword':
        navigate(getLink(undefined, `${PagePath.ANMELDEN}?oobCode=${oobCode}`));
        break;
      case 'recoverEmail':
        applyActionCodeRecoverEmail();
        break;
      default:
        throw new Error('ConfirmRegistrationModeNotSet');
    }
  }, []); // eslint-disable-line

  if (isInvalidEmailVerificationURL) {
    return (
      <Box
        className="Container"
        sx={{ backgroundImage: `url(${bgWelcome})`, height: '100%' }}
      >
        <Alert
          severity="info"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => (window.location = '/')}
            >
              {t('ConfirmRegistration.invalidEmailVerificationURL.button')}
            </Button>
          }
        >
          <AlertTitle>
            {t('ConfirmRegistration.invalidEmailVerificationURL.title')}
          </AlertTitle>
          {t('ConfirmRegistration.invalidEmailVerificationURL.message')}
        </Alert>
      </Box>
    );
  } else {
    return <div />;
  }
};

export default ConfirmRegistration;
