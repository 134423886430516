import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import PagePath from '../../constants/PagePath';
import ProjectStates from '../../constants/ProjectStates';
import { useGlobalState } from '../../hooks/useGlobalState';
import { getLink } from '../../utils';
import './NavItem.scss';

const NavItem = ({
  icon,
  text,
  onclick,
  to,
  selectedColor,
  selected = true,
}) => {
  const [{ company, projects }] = useGlobalState();
  const renderProjectsCount = () => {
    if (!!projects && to === getLink(company, PagePath.BOOKINGS)) {
      const projectsInAppropriateState = projects.filter(
        (project) =>
          project.state === ProjectStates.bookCoach ||
          project.state === ProjectStates.reviewCoaches,
      );
      return (
        projectsInAppropriateState.length > 0 && (
          <div className="nav-item-counter title-content">
            {projectsInAppropriateState.length}
          </div>
        )
      );
    }
  };

  return (
    <NavLink
      to={to}
      className={(navData) =>
        `nav-link ${selectedColor} ${
          navData.isActive && selected && 'nav-link__selected'
        }`
      }
      onClick={onclick}
    >
      <div className="custom-nav-item flex-row align-self-center">
        {icon}
        <div className="nav-item-text text-nav">{text}</div>
        {renderProjectsCount()}
      </div>
    </NavLink>
  );
};

NavItem.defaultProps = {
  to: '',
};

NavItem.propTypes = {
  /** It is an optional node property that represents the icon element */
  icon: PropTypes.node,
  /**It is a required node property that represents the text */
  text: PropTypes.node.isRequired,
  /** It is an optional function property that handles the event*/
  onclick: PropTypes.func,
  /** It is an optional string property that specifies the destination URL */
  to: PropTypes.string,
  /** It is an optional string property that represents the color*/
  selectedColor: PropTypes.string,
  /**It is an optional boolean property that determines whether the navigation is selected */
  selected: PropTypes.bool,
};

export default NavItem;
