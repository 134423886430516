import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import './PageTitle.scss';

const getColorClass = (color) => {
  return `page-color-bar-${color}`;
};

/**
 * Renders a page title that is typically used as the main title of every page.
 */

const PageTitle = ({
  text,
  width,
  color = 'yellow',
  mobileWidth,
  textAlign = 'left',
}) => {
  const [isMobile, setIsMobile] = useState(true);
  const changeWindowSize = () => {
    setIsMobile(window.innerWidth <= 992);
  };

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);
    changeWindowSize();
    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);
  return (
    <div
      className="page-title"
      style={{ width: isMobile ? mobileWidth : 'auto' }}
    >
      <span
        style={width && { width }}
        className={`page-color-bar ${getColorClass(color)}`}
      />
      <h4 className="page-text-wrapper title-page" style={{ textAlign }}>
        {text}
      </h4>
    </div>
  );
};

PageTitle.propTypes = {
  /** The width of the title object */
  width: PropTypes.string,

  /** The title text to be displayed */
  text: PropTypes.string.isRequired,

  /** The color of the bar below the title text */
  color: PropTypes.oneOf(['yellow', 'blue', 'orange', 'green', 'dark-orchid']),

  /** The width on mobile devices */
  mobileWidth: PropTypes.string,

  /** How should the text be aligned */
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

export default PageTitle;
