import React from 'react';
import { useTranslation } from 'react-i18next';

import { updateEventFromUserById } from '../../../api';
import Button from '../../../components/Button';
import FgiModal from '../../../components/FgiModal';
import { useGlobalState } from '../../../hooks';
import './ChooseTopicPartOfModal.scss';

export const ChooseTopicPartOfModal = ({
  item,
  focusTopics,
  changeFocusTopics,
  nextStep,
  prevStep,
  onHide,
}) => {
  const [{ locale, user }] = useGlobalState();
  const { uid } = user;
  const [t] = useTranslation();

  const { imageUrl, title, isEditing, id, workshopId } = item;

  const handleChange = (status) => (e) => {
    const newFocusTopics = focusTopics.map((item) => {
      if (item.name === e.target.name) item.isActive = !status;
      return item;
    });
    changeFocusTopics(newFocusTopics);
  };

  const updateHandle = async () => {
    const newFocus = [];
    focusTopics.forEach((item) => {
      if (item.isActive) {
        newFocus.push(item.name);
      }
    });
    const isUpdated = await updateEventFromUserById(
      uid,
      id,
      newFocus,
      workshopId,
    );
    if (isUpdated) {
      onHide();
    }
  };

  const isValid = focusTopics.some((item) => item.isActive);
  return (
    <FgiModal
      title={t('Angebot')}
      onHide={onHide}
      show
      color="dark-orchid"
      showBackButton
      backClick={prevStep}
    >
      <div className="choose-topic-container">
        <div className="choose-topic-title">{title[locale]}</div>
        <div className="choose-topic-image-container">
          <hr className="choose-topic-hr" />
          <img src={imageUrl} alt="img" className="choose-topic-image" />
          <hr className="choose-topic-hr" />
        </div>
        <div className="choose-topic-subtitle">
          {t('openWorkshops.modals.chooseTopics')}
        </div>
        <div className="choose-topic-buttons-container">
          {focusTopics.map((item) => (
            <button
              name={item.name}
              key={item.name}
              className={`choose-topic-button ${
                item.isActive ? 'choose-topic-button-active' : ''
              }`}
              onClick={handleChange(item.isActive)}
            >
              {item.name}
            </button>
          ))}
        </div>
        <div className="choose-topic-bottom-button-container">
          {isValid && !isEditing && (
            <Button
              size="small"
              variant="dark-orchid"
              arrow="default"
              onClick={nextStep}
            >
              {t('Weiter')}
            </Button>
          )}

          {isValid && isEditing && (
            <Button
              size="small"
              variant="dark-orchid"
              arrow="default"
              onClick={updateHandle}
            >
              {t('Fertig')}
            </Button>
          )}
          <Button size="medium" variant="light" arrow="back" onClick={prevStep}>
            {t('Zurück')}
          </Button>
        </div>
      </div>
    </FgiModal>
  );
};

export default ChooseTopicPartOfModal;
