import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';

const AdminCompanyGroups = ({ onChange, value = [] }) => {
  const valueToGrid = (value) => {
    // First, we simply transform our format into the ReactDataSheet format
    const result = value.map((item) => [
      { value: item.id },
      { value: item.name },
    ]);

    // We're adding a header row
    result.unshift([
      { readOnly: true, value: 'ID' },
      { readOnly: true, value: 'Name' },
    ]);

    // And add an empty new row so a user can enter new data
    result.push([{ value: '' }, { value: '' }]);

    // The results go back to the ReactDataSheet
    return result;
  };

  const handleCellsChange = (changes, additions) => {
    // Creates a copy of our value array
    let newValue = value.slice();

    // Copy of our changes array, because we might need to add additions
    let fullChanges = changes.slice();

    // If the user pasted content that exceeded size of the table,
    //we get additional data in `additions` and need to add it to our changes
    if (additions) {
      fullChanges = fullChanges.concat(additions);
    }

    fullChanges.forEach(({ row, col, value }) => {
      // If the row number exceeds the size of our array, we need to add an empty object.
      if (newValue[row - 1] === undefined) {
        newValue[row - 1] = {};
      }

      // We assign our new value to the correct element in our output variable
      newValue[row - 1][col === 0 ? 'id' : 'name'] = value;
    });

    // We don't want any empty rows in our result
    const noEmptyRows = newValue.filter(({ id, name }) => id || name);

    // Return the new values via our onChange function
    onChange(noEmptyRows);
  };

  return (
    <Box
      sx={{
        display: 'block',
        width: '100%',
        '& .data-grid': {
          width: '100%',
          textAlign: 'center',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 475,
          lineHeight: '22px',
          color: 'primary.main',
          '.cell': {
            textAlign: 'start',
            '.value-viewer': {
              minHeight: '22px',
            },
          },
          '& .cell > input': {
            width: '100% !important',
            height: '100% !important',
            color: 'primary.main',
            textAlign: 'inherit',
          },
        },
      }}
      mb={3.2}
    >
      <ReactDataSheet
        data={valueToGrid(value)}
        valueRenderer={(cell) => cell.value}
        onCellsChanged={handleCellsChange}
      />
    </Box>
  );
};

AdminCompanyGroups.propTypes = {
  /** Called on every change of values and returns the contents of the grid */
  onChange: PropTypes.func.isRequired,

  /** Receives the current value */
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
};

export default AdminCompanyGroups;
