import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import { updateProjectStateToProjectRunning } from '../../api/projects';
import BackArrow from '../../assets/svgs/BackArrow.svg';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import ProjectTypes from '../../constants/ProjectTypes';
import './CoachModeratorBuchenModal.scss';

export const CoachModeratorBuchenModal = ({
  show,
  onHide,
  project,
  coach,
  onBackToPreviousClick,
  onSubmitClick,
}) => {
  const [t] = useTranslation();

  const getColorByProjectType = (type) => {
    switch (type) {
      case ProjectTypes.MODERATOR:
        return 'green';
      case ProjectTypes.COACH:
        return 'yellow';
      default:
        return '';
    }
  };

  const getTitleByProjectType = (type) => {
    switch (type) {
      case ProjectTypes.MODERATOR:
        return t('Moderator buchen');
      case ProjectTypes.COACH:
        return t('Coach buchen');
      default:
        return '';
    }
  };

  const getDescriptionByProject = (project) => {
    switch (project.type) {
      case ProjectTypes.MODERATOR:
        return t('freut sich darauf, Ihren Workshop zu moderieren.');
      case ProjectTypes.COACH:
        return `${t('freut sich darauf, Sie bei dem Thema')} ${
          project.coaching_topic
        } ${t('zu unterstützen.')}`;
      default:
        return '';
    }
  };

  const renderScope = () => {
    if (project.type === ProjectTypes.COACH) {
      return `${project.suggested_scope} ${t('Stunden')}`;
    }
    return `${project.suggested_scope / 8} ${t('Tage')}`;
  };

  const renderBottomDescription = () => {
    return (
      <div className="coach-moderator-buchen-modal-coach-moderator-description primary-text">
        {coach.firstname} {coach.lastname} {getDescriptionByProject(project)}
      </div>
    );
  };

  const bookCoachOrModerator = () => {
    const { id, type, category } = project;
    sendAmplitudeData('Confirm Booking', {
      project_id: id,
      type,
      category,
      coach_name: `${coach.firstname} ${coach.lastname}`,
    });
    updateProjectStateToProjectRunning(project, coach);
    onSubmitClick();
  };

  return (
    <FgiModal
      title={getTitleByProjectType(project.type)}
      color={getColorByProjectType(project.type)}
      show={show}
      onHide={onHide}
    >
      <img
        alt=""
        className="modal-back-arrow"
        src={BackArrow}
        onClick={onBackToPreviousClick}
      />
      <div className="coach-moderator-buchen-modal-coach-moderator-main-info">
        <Avatar bigSize="104px" smallSize="72px" image={coach.id} />
        <div className="coach-moderator-buchen-modal-coach-moderator-main-info-text">
          <div className="primary-text">
            {project.category} {t('mit')}
          </div>
          <div className="primary-text-medium">
            {`${coach.firstname} ${coach.lastname}`}
          </div>
        </div>
      </div>
      <div className="coach-moderator-buchen-modal-coach-moderator-middle-container">
        <div className="coach-moderator-buchen-modal-coach-moderator-middle-container-item">
          <div className="title-content-2">{t('UMFANG')}</div>
          <div className="coach-moderator-buchen-modal-coach-moderator-middle-container-item-description description">
            {renderScope()}
          </div>
        </div>
        <div className="coach-moderator-buchen-modal-coach-moderator-middle-container-item">
          <div className="title-content-2">{t('KOSTEN')}</div>
          <div className="coach-moderator-buchen-modal-coach-moderator-middle-container-item-description description">
            {project.suggested_scope * project.suggested_dayrate} EUR
          </div>
        </div>
      </div>
      {renderBottomDescription()}
      <div className="coach-moderator-buchen-modal-coach-moderator-bottom-container">
        <Button
          onClick={bookCoachOrModerator}
          variant={getColorByProjectType(project.type)}
          size="medium"
        >
          {t('verbindlich buchen')}
        </Button>
      </div>
    </FgiModal>
  );
};

CoachModeratorBuchenModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  coach: PropTypes.object.isRequired,
  onBackToPreviousClick: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
};

export default CoachModeratorBuchenModal;
