import React from 'react';
import { useFormContext } from 'react-hook-form';

import InputFile from '../../InputFile';

const AdminFormImage = ({ label, name }) => {
  const formContext = useFormContext();

  const handleFileInputChange =
    (field) =>
    (file = null) => {
      formContext.setValue(field, file);
    };

  const value = formContext.watch(`${name}`);

  return (
    <InputFile
      accept={'.png, .jpeg, .bmp, .tiff'}
      pictureName={value?.name}
      content={'product'}
      title={label}
      name={name}
      onSubmitFile={handleFileInputChange(name)}
      removePicture={() => handleFileInputChange(name)(null)}
      isFormSubmit={!!formContext.formState.errors[name]}
    />
  );
};

export default AdminFormImage;
