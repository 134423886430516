import * as yup from 'yup';

const NewPasswordModalSchema = (t) =>
  yup.object({
    password: yup
      .string()
      .min(8, t('Mindestens 8 Zeichen erforderlich'))
      .matches(/(?=.*[A-Z])/, t('Mindestens 1 Großbuchstabe erforderlich'))
      .matches(/(?=.*[a-z])/, t('Mindestens 1 Kleinbuchstabe erforderlich'))
      .matches(
        /(?=.*\d)|(?=.*\W+)/,
        t('Mindestens eine Zahl oder Sonderzeichen erforderlich'),
      )
      .required(t('Bitte geben Sie ein Passwort an')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('Die Passwörter stimmen nicht überein'))
      .test('test', t('Bitte geben Sie ein Passwort an'), function (value) {
        if (!value && !this.parent.password) {
          return false;
        }
        return true;
      })
      .required(t('Bitte wiederholen Sie das Passwort')),
  });

export default NewPasswordModalSchema;
