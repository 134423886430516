/**
 * @deprecated use the constants from the i18n module instead
 * @see \src\i18n.js
 */
export const DE_LOCALE = 'de';

/**
 * @deprecated use the constants from the i18n module instead
 * @see \src\i18n.js
 */
export const EN_LOCALE = 'en';
