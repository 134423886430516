import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { signInWithEmailAndPassword, getCurrentUser } from '../../api';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import { KeyCodes } from '../../constants/KeyCodes';
import { Monitoring } from '../../utils';
import LoginError from '../LoginError';
import './Login.scss';
import loginSchema from './loginSchema';

const Login = ({ onHide, show, openResetPasswordModal }) => {
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorEmailDialog, setErrorEmailDialog] = useState(false);
  const [load, setLoad] = useState(false);
  const [t] = useTranslation();
  const initialFormValues = {
    email: '',
    password: '',
  };

  const onEnterPressOpenModal = (event) => {
    if (event.keyCode === KeyCodes.ENTER) {
      openResetPasswordModal();
    }
  };

  const submit = async ({ email, password }) => {
    try {
      setLoad(true);
      Monitoring.trackEvent('Submit Login Form');
      await signInWithEmailAndPassword(email, password);

      const { emailVerified } = getCurrentUser();
      if (!emailVerified) {
        Monitoring.trackEvent('Login declined', {
          reason: 'email not confirmed',
        });
        setErrorEmailDialog(true);
        setLoad(false);
        return;
      }

      onHide();
      setLoad(false);
    } catch (err) {
      Monitoring.trackEvent('Login declined', { reason: 'wrong credentials' });
      setErrorDialog(true);
      setLoad(false);
    }
  };

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  const handleErrorEmailDialogClose = () => {
    setErrorEmailDialog(false);
  };

  const formik = useFormik({
    validationSchema: loginSchema(t),
    onSubmit: submit,
    initialValues: initialFormValues,
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    formik;
  return (
    <>
      <FgiModal
        id="loginModalbox"
        show={show}
        onHide={onHide}
        dialogClassName="loginForm"
        title={t('Login.title')}
        description={t('Bitte geben Sie Ihre Zugangsdaten ein')}
        className="login-modal small-modal"
      >
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group as={Col} md="12">
              <Form.Label>{t('E-Mail-Adresse *')}</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12">
              <Form.Label>{t('Passwort *')}</Form.Label>
              <Form.Control
                autoComplete="on"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && !!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="login-modal__button-wrapper"
              as={Col}
              md="12"
            >
              <Button
                arrow={!load && 'default'}
                className="login-modal-button"
                variant="main"
                size="medium"
                load={load}
                type="submit"
              >
                {t('Login.submit')}
              </Button>
            </Form.Group>

            <div className="loginBottomPart">
              <p className="primaty-text">
                {t('Sie haben Ihr')}
                &nbsp;
                <span
                  tabIndex="0"
                  onKeyDown={onEnterPressOpenModal}
                  className="loginBottomPart-reset-password"
                  onClick={openResetPasswordModal}
                >
                  {t('Passwort vergessen')}
                </span>
                &nbsp;
                {t('oder andere Probleme mit dem Login?')}
              </p>
              <p className="primaty-text">
                {t(
                  'Wir sind gerne für Sie da. Unsere Kontaktdaten finden Sie unter Support & Hilfe',
                )}
              </p>
            </div>
          </Form>
        </>
      </FgiModal>
      <LoginError
        description={t('Ihre E-Mail-Adresse oder Ihr Passwort sind nicht korrekt. Schreiben Sie uns, wenn wir weiterhelfen können.')} // prettier-ignore
        title={t('Prüfen Sie Ihre Zugangsdaten')}
        show={errorDialog}
        onHide={handleErrorDialogClose}
      />
      <LoginError
        description={t('Bitte klicken Sie auf den Link in der E-Mail, die Sie erhalten haben.')} // prettier-ignore
        title={t('Bitte bestätigen Sie Ihre E-Mail-Adresse')}
        show={errorEmailDialog}
        onHide={handleErrorEmailDialogClose}
      />
    </>
  );
};

Login.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  openResetPasswordModal: PropTypes.func.isRequired,
};

export default Login;
