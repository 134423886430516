import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Icon from '../../../../assets/icons/find-coach-item-icon.png';
import Button from '../../../../components/Button';
import ContentTypes from '../../../../constants/ContentTypes';
import PagePath from '../../../../constants/PagePath';
import ProjectTypes from '../../../../constants/ProjectTypes';
import useAvailableContent from '../../../../hooks/useAvailableContent';
import { useGlobalState } from '../../../../hooks/useGlobalState';
import FirstCoachingModal from '../../../../modals/FirstCoachingModal';
import FosterCollaborationModal from '../../../../modals/FosterCollaborationModal';
import FosterCollaborationSuccessModal from '../../../../modals/FosterCollaborationSuccessModal';
import ModeratorFindenModal from '../../../../modals/ModeratorFindenModal';
import NeuesCoachingModal from '../../../../modals/NeuesCoachingModal';
import NewCoachingModal from '../../../../modals/NewCoachingModal';
import NewCoachingSuccessModal from '../../../../modals/NewCoachingSuccessModal';
import {
  getLink,
  getProductTranslatedData,
  Monitoring,
} from '../../../../utils';

export const FindCoachItem = ({ moderatorFindenItems, coachFinderItems }) => {
  const [{ user, locale, company }] = useGlobalState();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const isAvailable = useAvailableContent();

  const [product, setProduct] = useState(null);
  const [showFirstCoachingModal, setShowFirstCoachingModal] = useState(false);
  const [showNeuesCoachingModal, setShowNeuesCoachingModal] = useState(false);
  const [showNewCoachingModal, setShowNewCoachingModal] = useState(false);
  const [showNewCoachingSuccessModal, setShowNewCoachingSuccessModal] =
    useState(false);
  const [moderatorModalShow, setModeratorModalShow] = useState(false);
  const [fosterCollaborationShow, setFosterCollaborationShow] = useState(false);
  const [fosterCollaborationSuccessShow, setFosterCollaborationSuccessShow] =
    useState(false);

  const handleFindCoach = () => {
    Monitoring.trackEvent('New Project – Start', { type: ProjectTypes.COACH });
    const { date_of_birth, nationality, language, location } = user;
    if (!date_of_birth || !nationality || !language || !location) {
      setShowFirstCoachingModal(true);
    } else {
      setShowNeuesCoachingModal(true);
    }
  };

  const openModeratorModalShow = () => {
    Monitoring.trackEvent('New Project – Start', {
      type: ProjectTypes.MODERATOR,
    });
    setModeratorModalShow(true);
  };
  const closeModeratorModal = () => {
    setModeratorModalShow(false);
  };
  const onCategoriesClick = (product) => () => {
    Monitoring.trackEvent('New Project – Select Category', {
      category: getProductTranslatedData(product.title, locale),
      type: ProjectTypes.MODERATOR,
    });
    setProduct(product);
    setModeratorModalShow(false);
    setFosterCollaborationShow(true);
  };
  const onSubmit = () => {
    setProduct(null);
    setFosterCollaborationShow(false);
    setFosterCollaborationSuccessShow(true);
  };
  const closeFosterCollaborationModal = () => {
    setFosterCollaborationShow(false);
    setProduct(null);
  };
  const closeFosterCollaborationSuccessModal = () => {
    setFosterCollaborationSuccessShow(false);
  };

  const handleHideFirstCoachingModal = () => {
    setShowFirstCoachingModal(false);
  };

  const handleHideNewCoachingModal = () => {
    setProduct(null);
    setShowNewCoachingModal(false);
  };

  const handleHideNewCoachingSuccessModal = () => {
    setShowNewCoachingSuccessModal(false);
  };

  const handleHideNeuesCoachingModal = () => {
    setShowNeuesCoachingModal(false);
  };

  const onCategoryClick = (product) => () => {
    Monitoring.trackEvent('New Project – Select Category', {
      category: getProductTranslatedData(product.title, locale),
      type: ProjectTypes.COACH,
    });
    setProduct(product);
    setShowNeuesCoachingModal(false);
    setShowNewCoachingModal(true);
  };

  const handleSubmitFirstCoaching = () => {
    setShowFirstCoachingModal(false);
    setShowNeuesCoachingModal(true);
  };

  const handleSubmitNewCoaching = () => {
    setShowNewCoachingModal(false);
    setProduct(null);
    setShowNewCoachingSuccessModal(true);
  };

  const redirectToOpenWorkshops = () => {
    navigate(getLink(company, PagePath.OPEN_WORKSHOPS));
  };

  return (
    <Box
      component="div"
      sx={{
        flex: '1',
        backgroundColor: 'grey.light4',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '46px 0',
      }}
    >
      <Box
        component="div"
        sx={{ display: 'flex', alignItems: 'center', paddingBottom: '31px' }}
      >
        <Box component="img" src={Icon} alt="" sx={{ marginRight: '10px' }} />
        <Typography component="div" variant="titleSection">
          {t('coaching.new.title')}
        </Typography>
      </Box>
      <Stack direction="column" spacing={2}>
        {coachFinderItems.length > 0 && (
          <Button onClick={handleFindCoach} variant="main">
            {t('Finden Sie Ihren Coach')}
          </Button>
        )}
        {moderatorFindenItems.length > 0 && (
          <Button onClick={openModeratorModalShow} variant="green">
            {t('Moderator finden')}
          </Button>
        )}
        {isAvailable(ContentTypes.OPEN_WORKSHOP) && (
          <Button onClick={redirectToOpenWorkshops} variant="dark-orchid">
            {t('bookings.actions.bookTraining')}
          </Button>
        )}
      </Stack>
      <FirstCoachingModal
        onSubmit={handleSubmitFirstCoaching}
        onHide={handleHideFirstCoachingModal}
        show={showFirstCoachingModal}
      />
      <NeuesCoachingModal
        coachFinderItems={coachFinderItems}
        show={showNeuesCoachingModal}
        onHide={handleHideNeuesCoachingModal}
        onCategoryClick={onCategoryClick}
      />
      <NewCoachingModal
        onSubmit={handleSubmitNewCoaching}
        onHide={handleHideNewCoachingModal}
        show={showNewCoachingModal}
        product={product}
      />
      <NewCoachingSuccessModal
        onHide={handleHideNewCoachingSuccessModal}
        show={showNewCoachingSuccessModal}
      />
      <ModeratorFindenModal
        moderatorFindenItems={moderatorFindenItems}
        onHide={closeModeratorModal}
        show={moderatorModalShow}
        onCategoryClick={onCategoriesClick}
      />
      <FosterCollaborationModal
        onHide={closeFosterCollaborationModal}
        show={fosterCollaborationShow}
        product={product}
        onSubmit={onSubmit}
      />
      <FosterCollaborationSuccessModal
        show={fosterCollaborationSuccessShow}
        onHide={closeFosterCollaborationSuccessModal}
      />
    </Box>
  );
};

FindCoachItem.propTypes = {
  coachFinderItems: PropTypes.array.isRequired,
  moderatorFindenItems: PropTypes.array.isRequired,
};

export default FindCoachItem;
