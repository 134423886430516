import {
  Typography,
  TableBody,
  Table,
  TableCell,
  TableRow,
  Box,
  TablePagination,
} from '@mui/material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getAllUsers,
  getAllCompanies,
  onProjectsUpdate,
  onProjectsByStateUpdate,
  getCoachesByRef,
} from '../../api';
import Button from '../../components/Button';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import ProjectStates from '../../constants/ProjectStates';
import ProjectTypes from '../../constants/ProjectTypes';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks';
import { getLink, getComparator } from '../../utils';
import './AdminProjects.scss';
import AdminProjectsNoItems from './components/AdminProjectsNoItems';
import AdminProjectsPDFButton from './components/AdminProjectsPDFButton/AdminProjectsPDFButton';
import AdminProjectsTableHead from './components/AdminProjectsTableHead/AdminProjectsTableHead';
import AdminProjectsTabs from './components/AdminProjectsTabs';

let unsubscribe;
let unsubscribe2;

export const AdminProjects = () => {
  const [{ company }] = useGlobalState();
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [currentStateProjects, setCurrentStateProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [projectsCount, setProjectsCount] = useState({});
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [currentProjectState, setCurrentProjectState] = useState(
    ProjectStates.findCoaches,
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  // fetch all users
  useEffect(() => {
    const fetch = async () => {
      const users = await getAllUsers();
      setUsers(users);
    };
    fetch();
  }, []);

  // fetch all companies
  useEffect(() => {
    const fetch = async () => {
      const companies = await getAllCompanies();
      setCompanies(companies);
    };
    fetch();
  }, []);

  // fetch projects and split them by state
  useEffect(() => {
    const callback = (projects) => {
      if (!projects.length) {
        return;
      }
      const findCoachesProjects = projects.filter(
        (p) => p.state === ProjectStates.findCoaches,
      );
      const bookCoachesProjects = projects.filter(
        (p) => p.state === ProjectStates.bookCoach,
      );
      const projectRunningProjects = projects.filter(
        (p) => p.state === ProjectStates.projectRunning,
      );
      setAllProjects(projects);
      setProjectsCount({
        [ProjectStates.findCoaches]: findCoachesProjects.length,
        [ProjectStates.bookCoach]: bookCoachesProjects.length,
        [ProjectStates.projectRunning]: projectRunningProjects.length,
      });
    };
    unsubscribe2 = onProjectsUpdate(callback);
    return () => {
      unsubscribe2();
    };
  }, []);

  // fetch projects by currentProjectState
  useEffect(() => {
    let isSubscribe = true;
    const callback = async (projects) => {
      if (!isSubscribe) {
        return;
      }
      if (!projects.length) {
        setCurrentStateProjects([]);
        return;
      }
      // fetch projects
      const projectsWithCoaches = await Promise.all(
        projects.map(async (project) => {
          if (
            project.suggested_coaches &&
            currentProjectState !== ProjectStates.projectRunning
          ) {
            const coaches = await getCoachesByRef(project.suggested_coaches);
            return {
              ...project,
              coaches,
            };
          }
          if (
            project.selected_coach &&
            currentProjectState === ProjectStates.projectRunning
          ) {
            const coaches = await getCoachesByRef([project.selected_coach]);
            return {
              ...project,
              coaches,
            };
          }
          return project;
        }),
      );
      setCurrentStateProjects(projectsWithCoaches.reverse());
    };
    unsubscribe = onProjectsByStateUpdate(currentProjectState, callback);
    return () => {
      unsubscribe();
      isSubscribe = false;
    };
  }, [currentProjectState]);

  // filter projects for render
  useEffect(() => {
    let projectsToRender;
    if (rowsPerPage === -1) {
      projectsToRender = currentStateProjects;
    } else {
      projectsToRender = currentStateProjects.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
      );
    }
    setProjects(projectsToRender);
  }, [page, rowsPerPage, currentStateProjects]);

  const renderUser = (user_id) => {
    if (users.length > 0 && companies.length > 0) {
      const user = users.find((u) => user_id === u.id) || {};
      const company = companies.find((c) => user.company_id === c.id) || {};
      return (
        <>
          {company.name ? (
            <Typography component="div" variant="primaryTextMedium">
              {company.name}
            </Typography>
          ) : (
            <Typography component="div" variant="primaryText">
              Unternehmen gelöscht
            </Typography>
          )}
          <Typography component="div" variant="primaryText">
            {user.firstname} {user.lastname}
          </Typography>
        </>
      );
    }
  };

  const renderCoaches = (coaches) => {
    if (coaches) {
      return coaches.map((coach) => (
        <Typography
          component="div"
          variant="primaryText"
          key={coach?.phone + coach?.email}
        >
          {coach?.firstname} {coach?.lastname}
        </Typography>
      ));
    }
    return '-';
  };

  const getColorByProjectsType = (type) => {
    switch (type) {
      case ProjectTypes.COACH:
        return 'main';
      case ProjectTypes.MODERATOR:
        return 'green';
      default:
        return 'main';
    }
  };

  const renderType = (type) => {
    switch (type) {
      case ProjectTypes.COACH:
        return 'Coaching';
      case ProjectTypes.MODERATOR:
        return 'Workshop';
      default:
    }
  };

  const setActiveTab = (state) => () => {
    setPage(0);
    setCurrentProjectState(state);
  };

  const handleEditButton = (id) => () => {
    navigate(getLink(company, `${PagePath.ADMIN_PROJECTS}/${id}`));
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <AdminLayout>
      <Box sx={{ position: 'relative' }}>
        <Title color="orange" text="Projekte" width="14%" />
        <AdminProjectsPDFButton projects={allProjects} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          minHeight: '788px',
          marginTop: '24px',
        }}
      >
        <AdminProjectsTabs
          currentProjectState={currentProjectState}
          projectsCount={projectsCount}
          setActiveTab={setActiveTab}
        />
        {projects?.length ? (
          <Box
            sx={{
              backgroundColor: 'common.white',
              padding: '37px 40px',
              width: '100%',
            }}
          >
            <Table>
              <AdminProjectsTableHead
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {projects.sort(getComparator(order, orderBy)).map((project) => (
                  <TableRow
                    key={project.id}
                    sx={{
                      borderBottom: '1px solid grey.light6',
                      td: { padding: '10px 0' },
                    }}
                  >
                    <TableCell>
                      <Typography variant="primaryText">
                        {moment.unix(project.created_at.seconds).format('L')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="primaryText">
                        {renderType(project.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="primaryText">
                        {project.category}
                      </Typography>
                    </TableCell>
                    <TableCell>{renderCoaches(project.coaches)}</TableCell>
                    <TableCell>{renderUser(project.user_id)}</TableCell>
                    <TableCell>
                      {currentProjectState === ProjectStates.findCoaches && (
                        <Button
                          onClick={handleEditButton(project.id)}
                          variant={getColorByProjectsType(project.type)}
                          size="medium"
                        >
                          Bearbeiten
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[
                10,
                25,
                { value: -1, label: 'Alle anzeigen' },
              ]}
              component="div"
              count={projectsCount[currentProjectState]}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Zeilen pro Seite:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}–${to} von ${count !== -1 ? count : `more than ${to}`}`
              }
            />
          </Box>
        ) : (
          <AdminProjectsNoItems />
        )}
      </Box>
    </AdminLayout>
  );
};

export default AdminProjects;
