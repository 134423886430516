import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';

import App from './App';
import { GlobalStateProvider, reducer } from './hooks';
import './i18n';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import Monitoring from './utils/monitoring';

Monitoring.init();

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => {
        Monitoring.captureMessage(error);
      },
    },
    queries: {
      onError: (error) => {
        Monitoring.captureMessage(error);
      },
      retry: (failureCount, error) => {
        if (error?.status && [400, 404, 500].includes(error.status)) {
          return false;
        }
        return failureCount < 2;
      },
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStateProvider reducer={reducer}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GlobalStateProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
