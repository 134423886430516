import { Typography } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { addOpenWorkshopBookingsByUserId } from '../../../api';
import { loadEventsList } from '../../../api';
import Button from '../../../components/Button';
import FgiModal from '../../../components/FgiModal';
import { useGlobalState } from '../../../hooks';
import './BookOfferPartOfModal.scss';

export const BookOfferPartOfModal = ({
  item,
  prevStep,
  nextStep,
  focusTopics = [],
  onHide,
}) => {
  const [{ locale, user }, dispatch] = useGlobalState();
  const { imageUrl, title, location, start, end, id, workshopId } = item;
  const [t] = useTranslation();

  const activeTopics = useMemo(() => {
    return focusTopics.filter((item) => item.isActive);
  }, [focusTopics]);

  const bookHandle = async () => {
    const focusTopics = activeTopics.map((item) => item.name);
    const isAdding = await addOpenWorkshopBookingsByUserId(
      user.uid,
      id,
      focusTopics,
      workshopId,
    );
    if (isAdding) {
      await loadEventsList({
        company_id: user.company_id,
        user_id: user.uid,
        dispatch,
      });
      nextStep();
    } else {
      onHide();
    }
  };

  return (
    <FgiModal
      title={t('Angebot buchen')}
      onHide={onHide}
      show
      color="dark-orchid"
      showBackButton
      backClick={prevStep}
    >
      <div>
        <div className="book-offer-image-info-container">
          <img src={imageUrl} alt="img" className="book-offer-image" />
          <div>
            <div className="book-offer-title">{t('Angebot zum Thema')}</div>
            <div className="book-offer-title book-offer-title-2">
              {title[locale]}
            </div>
          </div>
        </div>
        <hr className="book-offer-hr" />
        <div className="book-offer-container">
          <div className="book-offer-info-container">
            <div className="book-offer-name">{t('Standort')}</div>
            <div className="book-offer-value">{location}</div>
          </div>
          <div className="book-offer-info-container">
            <div className="book-offer-name">{t('Termin')}</div>
            <div className="book-offer-value">
              <span>{moment(start).format('Do MMMM YYYY')}</span>
              <br />
              <span>{`${moment(start).format('HH:mm')} ${t('bis')} ${moment(
                end,
              ).format('HH:mm')} ${t('Uhr')}`}</span>
            </div>
          </div>
          {activeTopics.length > 0 && (
            <div
              className="book-offer-info-container"
              style={{ gridColumn: 'span 2' }}
            >
              <div className="book-offer-name">
                {t('openWorkshops.modals.summary.focusTopicsHeadline', {
                  count: activeTopics.length,
                })}
              </div>
              <Typography align="center" color="primary.main">
                {activeTopics.map((i) => i.name).join(', ')}
              </Typography>
            </div>
          )}
        </div>
        <div className="book-offer-buttons-container">
          <button className="book-offer-button" onClick={bookHandle}>
            {t('verbindlich buchen')}
          </button>
          <div className="book-offer-back-button">
            <Button
              size="medium"
              variant="light"
              arrow="back"
              onClick={prevStep}
            >
              {t('Zurück')}
            </Button>
          </div>
        </div>
      </div>
    </FgiModal>
  );
};

export default BookOfferPartOfModal;
