import React from 'react';

import './Loader.scss';

const Loader = ({ show }) => {
  if (show) {
    return (
      <div id="loader_con">
        <div id="loader">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
  return null;
};

export default Loader;
