import ProjectTypes from './ProjectTypes';

/**
 * ContentTypes contains of all available types of content in application
 * we can expand this variable with new type of content in future
 */
const ContentTypes = {
  ...ProjectTypes,
  OPEN_WORKSHOP: 'openWorkshop',
};

export default ContentTypes;
