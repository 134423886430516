import React from 'react';
import { useFormContext } from 'react-hook-form';

import InputFile from '../../InputFile';

const AdminFormPdf = ({ label, name, locale }) => {
  const formContext = useFormContext();

  const handleFileInputChange =
    (field) =>
    (file = null) => {
      formContext.setValue(field, file);
    };

  const value = formContext.watch(`${name}.${locale}`);

  return (
    <InputFile
      accept={'.pdf'}
      title={label}
      content="pdf"
      isFormSubmit={!!formContext.formState.errors[name]?.[locale]}
      skipModalStep
      pictureName={value}
      onSubmitFile={handleFileInputChange(`${name}.${locale}`)}
      removePicture={() => handleFileInputChange(`${name}.${locale}`)(null)}
      language={locale}
      isRequired={false}
    />
  );
};

export default AdminFormPdf;
