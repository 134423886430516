import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import greenArrow from '../../assets/svgs/select-arrow-green.svg';
import yellowArrow from '../../assets/svgs/select-arrow.svg';

const LanguageInput = ({
  name,
  value,
  label,
  onChange,
  isInvalid = false,
  errorMessage,
  color = 'yellow',
  allowNoResponse = false,
}) => {
  const [t] = useTranslation();

  let backgroundUrl = '';

  switch (color) {
    case 'green':
      backgroundUrl = greenArrow;
      break;
    default:
      backgroundUrl = yellowArrow;
  }

  const getLanguageOptions = (t) =>
    [
      { text: t('Deutsch'), value: t('Deutsch') },
      { text: t('Englisch'), value: t('Englisch') },
      { text: t('Französisch'), value: t('Französisch') },
      { text: t('Niederländisch'), value: t('Niederländisch') },
      { text: t('Bulgarisch'), value: t('Bulgarisch') },
      { text: t('Ungarisch'), value: t('Ungarisch') },
      { text: t('Italienisch'), value: t('Italienisch') },
      { text: t('Mandarin Chinesisch'), value: t('Mandarin Chinesisch') },
      { text: t('Polnisch'), value: t('Polnisch') },
      { text: t('Portugiesisch'), value: t('Portugiesisch') },
      { text: t('Rumänisch'), value: t('Rumänisch') },
      { text: t('Russisch'), value: t('Russisch') },
      { text: t('Spanisch'), value: t('Spanisch') },
      { text: t('Schwedisch'), value: t('Schwedisch') },
      { text: t('Türkisch'), value: t('Türkisch') },
    ].sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });

  return (
    <Form.Group as={Col}>
      <Form.Label>{label} *</Form.Label>
      <Form.Control
        style={{ background: `url(${backgroundUrl}) no-repeat 10px` }}
        as="select"
        name={name}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
      >
        <option disabled hidden value="">
          {t('Bitte wählen')}
        </option>
        {allowNoResponse && (
          <option value={t('Keine Angabe')}>{t('Keine Angabe')}</option>
        )}
        {getLanguageOptions(t).map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

LanguageInput.propTypes = {
  /** The name of the input element */
  name: PropTypes.string.isRequired,

  /** Sets a value on first load */
  value: PropTypes.string,

  /** Sets a custom label */
  label: PropTypes.string.isRequired,

  /** Triggered when the drop down value has changed */
  onChange: PropTypes.func.isRequired,

  /** Sets the input element to invalid and renders the error message */
  isInvalid: PropTypes.bool,

  /** Sets a custom error message. Please also set `isInvalid = true` */
  errorMessage: PropTypes.string,

  /** Sets the color of the dropdown arrow */
  color: PropTypes.oneOf(['yellow', 'green']).isRequired,

  /** Renders a _Not specified_ option in case a user does not want to enter a value.  */
  allowNoResponse: PropTypes.bool,
};

export default LanguageInput;
