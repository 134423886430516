import React from 'react';
import { useTranslation } from 'react-i18next';

import PrivacyPolicyDE from './PrivacyPolicyDe';
import PrivacyPolicyEN from './PrivacyPolicyEn';
import PrivacyPolicyES from './PrivacyPolicyEs';
import PrivacyPolicyFR from './PrivacyPolicyFr';

export const PrivacyPolicy = () => {
  const [, i18n] = useTranslation();

  switch (i18n.language) {
    case 'de':
      return <PrivacyPolicyDE />;
    case 'en':
      return <PrivacyPolicyEN />;
    case 'fr':
      return <PrivacyPolicyFR />;
    case 'es':
      return <PrivacyPolicyES />;
    default:
      return <PrivacyPolicyDE />;
  }
};

export default PrivacyPolicy;
