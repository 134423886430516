import PropTypes from 'prop-types';
import React from 'react';

import blueStripes from '../../assets/images/blue-teaser-stripes.png';
import darkOrchid from '../../assets/images/dark-orchid-teaser-stripes.png';
import greenStripes from '../../assets/images/green-teaser-stripes.png';
import orangeStripes from '../../assets/images/orange-teaser-stripes.png';
import yellowStripes from '../../assets/images/yellow-teaser-stripes.png';
import './Teaser.scss';

const getStripes = (type) => {
  switch (type) {
    case 'yellow':
      return yellowStripes;
    case 'blue':
      return blueStripes;
    case 'green':
      return greenStripes;
    case 'orange':
      return orangeStripes;
    case 'dark-orchid':
      return darkOrchid;
    default:
      return yellowStripes;
  }
};

const Teaser = ({ type = 'yellow', title, description, style }) => (
  <div
    style={{ ...style, backgroundImage: `url(${getStripes(type)})` }}
    className="teaser"
  >
    <div className="teaser__main">
      <div className="teaser__title title-snippet">{title}</div>
      <div className="description">{description}</div>
    </div>
  </div>
);

Teaser.propTypes = {
  /** It is an optional string property that represents the type */
  type: PropTypes.string,
  /**It is a required string property that represents the title */
  title: PropTypes.string.isRequired,
  /**It is a required string property that represents the description */
  description: PropTypes.string.isRequired,
  /** It is an optional object property that allows you to add custom styles*/
  style: PropTypes.object,
};

export default Teaser;
