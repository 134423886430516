import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AdminCheckbox from '../../../../components/AdminCheckbox';
import ButtonPdfProfile from '../../../../components/ButtonPdfProfile';
import ProjectTypes from '../../../../constants/ProjectTypes';
import { useGlobalState } from '../../../../hooks';
import { getCoachesTranslatedData } from '../../../../utils';

export const ProjectCoachesTable = ({
  project,
  coaches,
  addToSuggestedCoaches,
}) => {
  const [{ locale }] = useGlobalState();
  const [t] = useTranslation();

  return (
    <>
      <div className="project-coaches-title title-section">
        {project.type === ProjectTypes.MODERATOR
          ? t('Moderatorenpool')
          : t('Coachpool')}
      </div>
      <table className="project-coaches-table">
        <thead className="secondary-text">
          <tr className="project-coaches-table-head-row">
            <th width="4%" />
            <th width="20%">{t('Name')}</th>
            <th width="20%">{t('E-Mail')}</th>
            <th width="16%">{t('Ort')}</th>
            <th width="20%">{t('Sprache')}</th>
            <th width="20%" />
          </tr>
        </thead>
        <tbody className="primary-text">
          {coaches.map((coach) => {
            return (
              <tr key={coach.email} className="project-coaches-table-body-row">
                <td>
                  <AdminCheckbox
                    checked={false}
                    onChange={addToSuggestedCoaches(coach)}
                  />
                </td>
                <td>{`${coach.firstname} ${coach.lastname}`}</td>
                <td>{coach.email}</td>
                <td>{coach.bio_location}</td>
                <td>{getCoachesTranslatedData(coach.bio_language, locale)}</td>
                <td>
                  <ButtonPdfProfile coachId={coach.id} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

ProjectCoachesTable.propTypes = {
  coaches: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  addToSuggestedCoaches: PropTypes.func.isRequired,
};

export default ProjectCoachesTable;
