import React from 'react';

const AvatarSVG = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9881 23.8392C23.5381 21.8516 20.9692 20.8828 20.0816 20.5703C19.1066 20.2266 17.719 20.1453 16.8252 19.9453C16.3127 19.8328 15.5689 19.5515 15.3189 19.2515C15.0689 18.9515 15.2189 16.1701 15.2189 16.1701C15.2189 16.1701 15.6814 15.4513 15.9314 14.8138C16.1815 14.1825 16.4565 12.4449 16.4565 12.4449C16.4565 12.4449 16.969 12.4449 17.1502 11.5449C17.344 10.5636 17.6503 10.1823 17.6128 9.45103C17.5753 8.73224 17.1815 8.75099 17.1815 8.75099C17.1815 8.75099 17.5628 7.70719 17.6065 5.54459C17.6628 2.97571 15.6502 0.444336 12.0062 0.444336C8.31231 0.444336 6.34347 2.97571 6.39972 5.54459C6.44972 7.70719 6.81849 8.75099 6.81849 8.75099C6.81849 8.75099 6.42472 8.73224 6.38722 9.45103C6.34972 10.1823 6.65598 10.5636 6.84974 11.5449C7.02475 12.4449 7.54353 12.4449 7.54353 12.4449C7.54353 12.4449 7.81854 14.1825 8.06855 14.8138C8.31856 15.4513 8.78109 16.1701 8.78109 16.1701C8.78109 16.1701 8.93109 18.9515 8.68108 19.2515C8.43107 19.5515 7.68728 19.8328 7.17476 19.9453C6.28096 20.1453 4.8934 20.2266 3.91835 20.5703C3.0308 20.8828 0.461928 21.8516 0.0119054 23.8392C-0.056848 24.1517 0.180664 24.4455 0.50568 24.4455H23.5006C23.8193 24.4455 24.0568 24.1517 23.9881 23.8392Z"
      fill="#CECECE"
    />
  </svg>
);

export default AvatarSVG;
