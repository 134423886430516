import { Stack, Typography } from '@mui/material';
import { useState } from 'react';

import Button from '../../../../../../components/Button';

/**
 * Use this hook to render action buttons to submit a form. Calling the `onSaveSuccess()` function displays
 * a small _saved_ label next to the submit button.
 */

const useAdminActions = () => {
  const [savedLabelVisible, setSavedLabelVisibilty] = useState(false);

  return {
    /** Call this function to display a small  _saved_ label next to the submit button */
    onSaveSuccess: () => {
      setSavedLabelVisibilty(true);
      setTimeout(() => setSavedLabelVisibilty(false), 3000);
    },

    /** The actual action component */
    AdminActions: ({
      isSaving,
      buttonLabel = 'Speichern',
      buttonHasArrow = false,
    }) => {
      return (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          {savedLabelVisible && (
            <Typography color="grey.light2">Gespeichert</Typography>
          )}
          <Button
            type="submit"
            size="medium"
            variant="main"
            arrow={buttonHasArrow ? 'default' : false}
            load={isSaving}
          >
            {buttonLabel}
          </Button>
        </Stack>
      );
    },
  };
};

export default useAdminActions;
