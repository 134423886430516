import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import checkedImg from '../../../assets/svgs/checked.svg';
import { useGlobalState } from '../../../hooks';
import './Description.scss';

const Description = ({ isActive, item }) => {
  const { location, title, text, start } = item;
  const [{ locale }] = useGlobalState();
  const [t] = useTranslation();

  // TODO: Replace `isMobile` and useEffect below with css media query
  const [isMobile, setIsMobile] = useState(false);

  const changeSize = () => {
    setIsMobile(window.innerWidth <= 992);
  };

  useEffect(() => {
    window.addEventListener('resize', changeSize);
    changeSize();
    return () => {
      window.removeEventListener('resize', changeSize);
    };
  }, []);

  const startDay = moment(start, 'MMMM Do YYYY, h').format('dddd');
  const startTime = moment(start, 'MMMM Do YYYY, h').format('h');
  const startDayTime = `${startDay} ${t('um')} ${startTime} ${t('Uhr')}`;

  const activeIcon = () => {
    if (!isActive) return <></>;
    return (
      <div className="description-status-container">
        <img className="description-check " alt="img" src={checkedImg} />
        <div className="description-title-place-or-status description-text-status">
          {t('Gebucht')}
        </div>
      </div>
    );
  };

  const desktopLayout = () => {
    return (
      <div className="description-container">
        <div className="description-title-container">
          <div className="description-title-place-container">
            <div className="description-title-place-or-status">{location}</div>
            <div className="description-title">{title[locale]}</div>
          </div>
          {activeIcon()}
        </div>
        <div className="description-text">
          <p>{text[locale]}</p>
        </div>
      </div>
    );
  };

  const mobileLayout = () => {
    return (
      <div className="description-container">
        <div className="description-title-container">
          <div className="description-title-place-container">
            <div className="description-place-and-icon">
              <div className="description-title-place-or-status">
                {location}
              </div>
              {activeIcon()}
            </div>
            <div className="description-title">{title[locale]}</div>
            <div className="description-title-place-or-status">
              {startDayTime}
            </div>
          </div>
        </div>
        <div className="description-text">
          <p>{text[locale]}</p>
        </div>
      </div>
    );
  };

  return <>{isMobile ? mobileLayout() : desktopLayout()}</>;
};
export default Description;
