import PageContainer from '../../components/PageContainer';
import { Title, Headline, Text, UL } from './components';

const PrivacyPolicyEs = () => {
  return (
    <PageContainer>
      <Title text="Política de Privacidad" />

      <Headline>1. Introducción</Headline>
      <Text>
        En fgi Fischer Group International, tomamos muy en serio la protección
        de sus datos personales. Esta política de privacidad explica cómo
        recopilamos, utilizamos, almacenamos y protegemos sus datos personales.
      </Text>

      <Headline>2. Responsable del Tratamiento</Headline>
      <Text>
        El responsable del tratamiento es: fgi Fischer Group International GmbH
        <br />
        Brodschrangen 3-5
        <br />
        20457 Hamburgo
        <br />
        Alemania
        <br />
        Correo Electrónico: digital.solutions@fgi-mail.com
        <br />
        Internet:{' '}
        <a href="http://www.fgi-digitalsolutions.de">
          www.fgi-digitalsolutions.de
        </a>
      </Text>

      <Headline>3. Declaración de Privacidad</Headline>
      <Text>
        Los datos personales solo se recopilan en esta página web en la medida
        técnica necesaria. En ningún caso se venderán los datos recopilados. La
        siguiente declaración ofrece una visión general de cómo fgi Fischer
        Group International GmbH, en adelante fgi, garantiza esta protección y
        qué tipo de datos se recopilan para qué propósito.
      </Text>

      <Headline>4. Recopilación y Uso de Datos</Headline>
      <Headline>4.1. Qué Datos Recopilamos</Headline>
      <Text>
        Recopilamos diferentes tipos de datos personales necesarios para la
        prestación de nuestros servicios. Estos incluyen, en particular:
        <UL
          items={[
            <>
              <strong>Información del Usuario:</strong> Nombre, Apellido,
              Dirección de correo electrónico, Fecha de nacimiento,
              Nacionalidad, Ubicación, Dirección IP, Idioma preferido en
              proyectos.
            </>,
            <>
              <strong>Información del Coach:</strong> Nombre, Apellido,
              Dirección de correo electrónico, Ubicación, Número de teléfono,
              Idiomas, Foto, Biografía.
            </>,
            <>
              <strong>Datos del Proyecto:</strong> Categoría, Producto, Centro
              de costos, Situación empresarial, Asunto del coaching, Situación
              de liderazgo, Nivel de liderazgo, Medidas de seguimiento, Número
              de participantes, Fecha, Lugar, Idioma, Expectativas, Comentarios.
            </>,
            <>
              <strong>E-Learnings:</strong> Fecha del primer inicio de un
              módulo, Progreso dentro del módulo, Datos guardados del usuario,
              Resultado/Éxito/Finalización de un módulo.
            </>,
          ]}
        />
      </Text>

      <Headline>5. Base Legal del Tratamiento</Headline>
      <Text>
        El tratamiento de sus datos se basa en las siguientes bases legales:
        <UL
          items={[
            'Consentimiento (Art. 6 párr. 1 lit. a RGPD)',
            'Cumplimiento de contrato (Art. 6 párr. 1 lit. b RGPD)',
            'Cumplimiento de obligaciones legales (Art. 6 párr. 1 lit. c RGPD)',
            'Intereses legítimos (Art. 6 párr. 1 lit. f RGPD)',
          ]}
        />
      </Text>

      <Headline>6. Transferencia de Datos</Headline>
      <Headline>6.1. Proveedores de Servicios</Headline>
      <Text>
        Solo compartimos sus datos personales en los siguientes casos:
        <UL
          items={[
            'Con su consentimiento',
            <>
              Con proveedores de servicios necesarios para la prestación de
              nuestros servicios. Estos incluyen:
              <UL
                items={[
                  <>
                    <strong>Google Cloud EMEA Limited</strong>
                    <br />
                    Sitio web:{' '}
                    <a href="https://cloud.google.com/">
                      https://cloud.google.com/
                    </a>
                    <br />
                    Privacidad:{' '}
                    <a href="https://policies.google.com/privacy">
                      https://policies.google.com/privacy
                    </a>
                    <br />
                    Propósito: Alojamiento IT e infraestructura
                    <br />
                    Necesario para la operación: Sí
                    <br />
                    Protección de datos: Sujeta a la legislación de protección
                    de datos europea (RGPD)
                  </>,
                  <>
                    <strong>Rustici Software LLC</strong>
                    <br />
                    Sitio web:{' '}
                    <a href="https://scorm.com/">https://scorm.com/</a>
                    <br />
                    Privacidad:{' '}
                    <a href="https://ltgplc.com/privacy-notice/">
                      https://ltgplc.com/privacy-notice/
                    </a>
                    <br />
                    Propósito: Provisión de infraestructura de e-learning
                    <br />
                    Necesario para la operación: Sí
                    <br />
                    Protección de datos: Sujeta a la legislación de protección
                    de datos europea (RGPD)
                  </>,
                  <>
                    <strong>Mailgun Technologies, Inc.</strong>
                    <br />
                    Sitio web:{' '}
                    <a href="https://www.mailgun.com/">
                      https://www.mailgun.com/
                    </a>
                    <br />
                    Privacidad:{' '}
                    <a href="https://www.mailgun.com/gdpr/">
                      https://www.mailgun.com/gdpr/
                    </a>
                    <br />
                    Propósito: Envío de correos electrónicos
                    <br />
                    Necesario para la operación: Sí
                    <br />
                    Protección de datos: Sujeta a la legislación de protección
                    de datos europea (RGPD)
                  </>,
                  <>
                    <strong>Amplitude, Inc.</strong>
                    <br />
                    Sitio web:{' '}
                    <a href="https://amplitude.com/">https://amplitude.com/</a>
                    <br />
                    Privacidad:{' '}
                    <a href="https://amplitude.com/privacy">
                      https://amplitude.com/privacy
                    </a>
                    <br />
                    Propósito: Seguimiento del comportamiento de los usuarios
                    <br />
                    Necesario para la operación: No
                    <br />
                    Protección de datos: Sujeta a la legislación de protección
                    de datos europea (RGPD)
                  </>,
                ]}
              />
            </>,
            'Cuando sea requerido por ley o para hacer cumplir nuestros términos de uso.',
          ]}
        />
      </Text>

      <Headline>7. Transferencia de Datos a Terceros Países</Headline>
      <Text>
        La transferencia de sus datos a terceros países (fuera de la UE/EEA)
        solo ocurrirá si es legalmente permitido o si usted ha consentido la
        transferencia de datos. Nos aseguramos de que los proveedores de
        servicios estén sujetos a los estándares europeos de protección de
        datos.
      </Text>

      <Headline>8. Almacenamiento y Protección de Datos</Headline>
      <Headline>8.1. Almacenamiento de Datos</Headline>
      <Text>
        Solo almacenamos sus datos personales durante el tiempo que sea
        necesario para los fines para los que se recopilaron o según lo exijan
        las leyes. En general, los datos se eliminan al finalizar la
        colaboración, a menos que existan obligaciones legales de retención.
      </Text>

      <Headline>8.2. Seguridad de Datos</Headline>
      <Text>
        Implementamos medidas técnicas y organizativas adecuadas para proteger
        sus datos personales contra el acceso no autorizado, la pérdida o la
        destrucción.
      </Text>

      <Headline>9. Sus Derechos</Headline>
      <Text>
        Usted tiene el derecho de:
        <UL
          items={[
            'Solicitar información sobre los datos que tenemos sobre usted',
            'Solicitar la corrección de datos incorrectos',
            'Solicitar la eliminación de sus datos, salvo que existan obligaciones legales de retención',
            'Solicitar la restricción del tratamiento de sus datos',
            'Oponerse al tratamiento de sus datos',
            'Solicitar la portabilidad de datos',
          ]}
        />
      </Text>

      <Headline>9.1. Derecho de Información</Headline>
      <Text>
        Los visitantes tienen derecho en todo momento a obtener información
        sobre los datos almacenados sobre su persona, su origen y destinatarios,
        así como el propósito del tratamiento de datos. La solicitud de
        información debe enviarse a la dirección indicada en el aviso legal del
        sitio web o puede dirigirse a las siguientes direcciones:
        <UL
          items={[
            'Correo: digital.solutions@fgi-mail.com',
            'Teléfono: +49 (0)40 65 68 90 0',
            'Fax: +49 (0)40 65 68 90 80',
          ]}
        />
      </Text>

      <Headline>9.2. Eliminación</Headline>
      <Text>
        La eliminación de los datos personales almacenados se llevará a cabo si
        el visitante de la aplicación revoca el consentimiento para el
        almacenamiento, si el conocimiento es ya no necesario para cumplir con
        el propósito perseguido con el almacenamiento, o si el almacenamiento es
        de otro modo ilegal por razones legales. Los datos para fines de
        facturación y contables no se ven afectados por una solicitud de
        eliminación.
      </Text>

      <Headline>10. Aviso de Exención de Responsabilidad</Headline>
      <Text>
        Todos los contenidos en nuestro sitio web han sido cuidadosamente
        recopilados y revisados. A pesar de todos los cuidados, no se puede
        asumir responsabilidad o garantía por la exactitud, integridad y
        actualidad de los datos. Esto también se aplica a todos los demás sitios
        web a los que se haga referencia mediante hipervínculos. No somos
        responsables del contenido de los sitios web alcanzados a través de
        dicho enlace. Nos reservamos el derecho de realizar actualizaciones,
        cambios o adiciones a la información y datos proporcionados.
      </Text>

      <Headline>11. Cambios en Esta Política de Privacidad</Headline>
      <Text>
        Podemos actualizar esta política de privacidad de vez en cuando. La
        versión actual siempre estará disponible en nuestro sitio web. Le
        informaremos sobre los cambios significativos por correo electrónico.
      </Text>

      <Headline>12. Contacto</Headline>
      <Text>
        Si tiene alguna pregunta sobre esta política de privacidad o desea
        ejercer sus derechos, contáctenos por favor en:
        <br />
        fgi Fischer Group International GmbH
        <br />
        Brodschrangen 3-5
        <br />
        20457 Hamburgo
        <br />
        Alemania
        <br />
        Correo Electrónico: digital.solutions@fgi-mail.com
        <br />
        Internet:{' '}
        <a href="http://www.fgi-digitalsolutions.de">
          www.fgi-digitalsolutions.de
        </a>
      </Text>
    </PageContainer>
  );
};

export default PrivacyPolicyEs;
