import { Paper } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Use this component as the white background container that is used on almost all frontend pages.
 *
 * It automatically sets the correct color, padding, size and top margin.
 */
const PageContainer = ({ children }) => {
  return (
    <Paper elevation={0} square sx={{ padding: 3 }}>
      {children}
    </Paper>
  );
};

PageContainer.propTypes = {
  /** The element that should be displayed in the main content area. */
  children: PropTypes.node.isRequired,
};

export default PageContainer;
