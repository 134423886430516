import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

/** This component renders a small chip that can be used to display tags etc. */
const AdminChip = ({ label, onClick }) => {
  return (
    <Typography
      key={label}
      variant="formLabel"
      sx={{
        paddingLeft: '14px',
        backgroundColor: 'yellow.main',
        marginTop: 0,
        marginRight: 2,
        marginBottom: 2,
        marginLeft: 0,
        display: 'inline-block',
      }}
    >
      {label}
      <CloseIcon
        sx={{
          marginLeft: '14px',
          cursor: 'pointer',
          height: '28px',
          width: '28px',
        }}
        onClick={onClick}
        data-id={label}
        fontSize="small"
      />
    </Typography>
  );
};

AdminChip.propTypes = {
  /** The text that should be displayed on the chip */
  label: PropTypes.string.isRequired,

  /** The function that is called once the user clicks the X */
  onClick: PropTypes.func.isRequired,
};

export default AdminChip;
