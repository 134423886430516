import { Unstable_Grid2 as Grid, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import { getCourseInfo, redirectToCourse, registerCourse } from '../../api';
import Loader from '../../components/Loader';
import PageContainer from '../../components/PageContainer';
import PageTitle from '../../components/PageTitle';
import ProductItem from '../../components/ProductItem';
import ProjectTypes from '../../constants/ProjectTypes';
import MainLayout from '../../HOC/MainLayout';
import { useGlobalState } from '../../hooks/useGlobalState';
import Monitoring from '../../utils/monitoring';
import MiddleItem from './components/MiddleItem';
import TopItem from './components/TopItem';
import './Leadership.scss';

export const Leadership = () => {
  const [{ user, available_products, locale }] = useGlobalState();
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    sendAmplitudeData('Visit E-Learning');
  }, []);

  const goToCourse = (course_id, title) => async () => {
    try {
      setLoading(true);
      const info = await getCourseInfo(user.uid, course_id);
      let registration_id =
        info && info.registration_id ? info.registration_id : '';

      if (!info) {
        const { data } = await registerCourse({ course_id, title });
        registration_id = data.registration_id;
      }
      const response = await redirectToCourse(registration_id);
      if (!response.data) {
        return;
      }
      window.open(response.data.launchLink, '_blank');
      sendAmplitudeData('Start E-Learning Course', { course_id, title });
      setLoading(false);
    } catch (err) {
      Monitoring.captureException(err, { tags: { user, course_id } });
      setErrorMessage(t('Leadership.error.could_not_be_started'));
      setLoading(false);
    }
  };

  const eLearningItems = available_products
    .filter((product) => product.type === ProjectTypes.ELEARNING)
    .sort(
      (a, b) =>
        a.title[locale] && a.title[locale].localeCompare(b.title[locale]),
    );
  return (
    <MainLayout>
      <PageTitle text={t('E-Learning')} color="blue" width="14%" />
      <TopItem goToCourse={goToCourse} eLearningItems={eLearningItems} />
      <MiddleItem />
      <PageContainer>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            sx={{ marginBottom: 7 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <PageTitle
              text={t('Unsere Auswahl an Themen')}
              color="blue"
              width="14%"
            />
          </Grid>
          {eLearningItems.map((item) => (
            <Grid xs={6}>
              <ProductItem
                onClick={goToCourse(item.course_id[locale], item.title[locale])}
                key={item.id}
                description={item.description[locale]}
                image={item.image}
                pdfUrl={item.pdfUrl ? item.pdfUrl[locale] : ''}
                title={item.title[locale]}
                color="secondary"
                buttonLabel={t('Zum E-Learning')}
              />
            </Grid>
          ))}
        </Grid>
      </PageContainer>
      <Loader show={loading} />
      <Snackbar
        open={!!errorMessage}
        message={errorMessage}
        onClose={() => setErrorMessage(undefined)}
        autoHideDuration={5000}
      />
    </MainLayout>
  );
};

export default Leadership;
