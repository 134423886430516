import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';

const getClassNameByType = (type) => {
  switch (type) {
    case 'large':
      return 'dashboard-item-large flex-col';
    case 'hidden':
      return 'dashboard-item-hidden flex-col';
    default:
      return 'dashboard-item flex-col';
  }
};

const DashboardItem = ({
  backgroundImage,
  stripes,
  btnColor,
  btnText,
  title,
  link,
  description,
  type,
}) => {
  const renderButton = () => {
    if (link.match(/^mailto|^http/)) {
      return (
        <a
          href={link}
          id="dashboard-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button arrow={false} variant={btnColor}>
            {btnText}
          </Button>
        </a>
      );
    }
    return (
      <Link to={link} id="dashboard-btn">
        <Button variant={btnColor}>{btnText}</Button>
      </Link>
    );
  };
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
      }}
      className={getClassNameByType(type)}
    >
      <div
        style={{ backgroundImage: `url(${stripes})` }}
        className="dashboard-item-content-wrapper"
      >
        <div className="dashboard-item-content">
          <div className="dashboard-item-title big-title">{title}</div>
          <div className="dashboard-item-description description">
            {description}
          </div>
        </div>
      </div>
      {renderButton()}
    </div>
  );
};

DashboardItem.propTypes = {
  backgroundImage: PropTypes.node.isRequired,
  stripes: PropTypes.node.isRequired,
  btnColor: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default DashboardItem;
