import { Typography, Stack, Divider } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { useUpdateUserByIdMutation } from '../../api';
import { SET_LOCALE, useGlobalState } from '../../hooks';
import { Locales } from '../../i18n';

export const LanguageSwitcher = () => {
  const [{ locale: currentLocale, user }, dispatch] = useGlobalState();
  const updateUserByIdMutation = useUpdateUserByIdMutation();

  const switchLanguage = useCallback(
    /**
     * @param {React.MouseEvent<HTMLSpanElement, MouseEvent>} e
     */
    async (e) => {
      const selectedLocale = e.currentTarget.dataset.key;
      if (selectedLocale === currentLocale) {
        return;
      }
      dispatch({ type: SET_LOCALE, payload: selectedLocale });

      if (user) {
        updateUserByIdMutation.mutate({
          id: user.uid,
          data: { locale: selectedLocale },
        });
      }
    },
    [currentLocale, dispatch, updateUserByIdMutation, user],
  );

  return useMemo(() => {
    return (
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {Object.values(Locales).map((locale) => (
          <Typography
            variant={'secondaryText'}
            key={locale}
            data-key={locale}
            data-active={locale === currentLocale}
            onClick={switchLanguage}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: '#0056b3',
              },
              '&[data-active="true"]': {
                color: '#666666',
              },
            }}
          >
            {localeToText[locale]}
          </Typography>
        ))}
      </Stack>
    );
  }, [currentLocale, switchLanguage]);
};

/**
 * @type {Record<Locales, string>}
 */
export const localeToText = {
  [Locales.DE]: 'Deutsch',
  [Locales.FR]: 'Français',
  [Locales.EN]: 'English',
  [Locales.ES]: 'Español',
};

export default LanguageSwitcher;
