import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';

import AdminHeader from '../components/AdminHeader';

const AdminLayout = ({ children }) => {
  return (
    <>
      <AdminHeader />
      <Container className="container-with-margins">{children}</Container>
    </>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminLayout;
