import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import bigBackground from '../../../../assets/images/elearning-background.png';
import smallBackground from '../../../../assets/images/elearning-small-background.png';
import Button from '../../../../components/Button';
import Hero from '../../../../components/Hero';
import Teaser from '../../../../components/Teaser';
import ELearningModal from '../../../../modals/ELearningModal';
import './TopItem.scss';

export const TopItem = ({ goToCourse, eLearningItems }) => {
  const [showELearningModal, setShowELearningModal] = useState(false);
  const [t] = useTranslation();

  const openELearningModal = () => {
    setShowELearningModal(true);
  };

  const closeELearningModal = () => {
    setShowELearningModal(false);
  };

  return (
    <>
      <ELearningModal
        eLearningItems={eLearningItems}
        onHide={closeELearningModal}
        show={showELearningModal}
        goToCourse={goToCourse}
      />
      <Hero
        paddingBottom="45px"
        bigBackground={bigBackground}
        smallBackground={smallBackground}
      >
        <Teaser
          type="blue"
          title={t('E-Learnings unterstützen Sie bei der Selbstreflexion Ihrer Führungsthemen')} // prettier-ignore
          description={t('Analysieren Sie regelmäßig Ihre persönliche Situation mit interaktiven Tools und bewährten Coachingfragen.')} // prettier-ignore
        />
        <div className="leadership-top-item-lto-btn-wrapper">
          <Button size="big" onClick={openELearningModal} variant="secondary">
            {t('Zum E-Learning')}
          </Button>
        </div>
      </Hero>
    </>
  );
};

TopItem.propTypes = {
  goToCourse: PropTypes.func.isRequired,
  eLearningItems: PropTypes.array.isRequired,
};

export default TopItem;
