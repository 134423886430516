import * as yup from 'yup';

export const FosterCollaborationModalSchema = (t) =>
  yup.object({
    numParticipants: yup
      .number()
      .typeError(t('Bitte geben Sie eine Zahl ein'))
      .required(t('Bitte die Teilnehmerzahl angeben')),
    language: yup.string().required(t('Bitte eine bevorzugte Sprache wählen')),
    location: yup.string().required(t('Bitte einen Ort des Workshops angeben')),
    date: yup.string().required(t('Bitte ein Startdatum wählen')),
    leadership_level: yup
      .string()
      .required(t('Bitte wählen Sie eine Führungsebene')),
    comments: yup.string(),
  });

export const FosterCollaborationModalSchemaWithoutLocation = (t) =>
  yup.object({
    numParticipants: yup
      .number()
      .required(t('Bitte die Teilnehmerzahl angeben')),
    language: yup.string().required(t('Bitte eine bevorzugte Sprache wählen')),
    date: yup.string().required(t('Bitte ein Startdatum wählen')),
    leadership_level: yup
      .string()
      .required(t('Bitte wählen Sie eine Führungsebene')),
    comments: yup.string(),
  });
