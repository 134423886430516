import * as Sentry from '@sentry/react';

import { setAmplitudeUserId, sendAmplitudeData } from '../amplitude';

const IS_DEBUG = process.env.NODE_ENV === 'development';

class Monitoring {
  init() {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      /**
       * Performance tracing
       * https://docs.sentry.io/platforms/javascript/performance/
       */
      tracesSampleRate: 1.0,

      /**
       * Session Replay
       * https://docs.sentry.io/platforms/javascript/session-replay/
       */

      /**
       * This sets the sample rate at 10%. It's off for development to save free replay quota.
       */
      replaysSessionSampleRate: IS_DEBUG ? 0 : 0.1,

      /**
       * If you're not already sampling the entire session,
       * change the sample rate to 100% when sampling sessions where errors occur.
       */
      replaysOnErrorSampleRate: 1.0,

      /**
       * Set this boolean to true to add stack local variables to stack traces.
       * https://docs.sentry.io/platforms/node/configuration/options/#include-local-variables
       */
      includeLocalVariables: true,

      // Called for message and error events
      beforeSend(event, hint) {
        if (IS_DEBUG) {
          // eslint-disable-next-line no-console
          console.error(
            "Sentry cought this but did not collect it because we're in development:\n",
            hint.originalException || hint.syntheticException,
          );
          return null; // this drops the event and nothing will be sent to sentry
        }
        return event;
      },
    });
  }

  /**
   * Captures an exception event and sends it to Sentry.
   *
   * @param exception The exception to capture.
   * @param hint Optional additional data to attach to the Sentry event.
   * @returns the id of the captured Sentry event.
   */
  captureException(error, hint) {
    Sentry.captureException(error, hint);
  }

  /**
   * Captures a message event and sends it to Sentry.
   *
   * @param exception The exception to capture.
   * @param captureContext Define the level of the message or pass in additional data to attach to the message.
   * @returns the id of the captured message.
   */
  captureMessage(exception, captureContext) {
    Sentry.captureMessage(exception, captureContext);
  }

  trackEvent(event, context) {
    // https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/#manual-breadcrumbs
    Sentry.addBreadcrumb({
      type: 'event',
      message: event,

      /**
       * You can choose from the following breadcrumb log levels:
       * "fatal", "critical", "error", "warning", "log", "info", and "debug".
       * */
      level: 'info',
    });
    sendAmplitudeData(event, context);
  }

  setUser(user) {
    try {
      // Anonymize user data
      user.email = user.email.replace(
        /(\w{3})[\w.-]+@(\w{1})\w+/,
        '$1***@$2***',
      );
      user.lastname = '***';

      const sentryUserObject = {
        id: user.uid,
        ...user,
      };

      if (IS_DEBUG) {
        // eslint-disable-next-line no-console
        console.debug('Setting monitoring user', sentryUserObject);
      }

      setAmplitudeUserId(user.uid);
      Sentry.setUser(sentryUserObject);
    } catch (e) {
      this.captureException(e, { user });
    }
  }
}

export default new Monitoring();
