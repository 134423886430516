import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  collection,
  getDocs,
  query,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  collectionGroup,
  onSnapshot,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';

import { db, functions } from '../firebaseConfig';
import { getRedirectToLink } from '../utils';
import { getCustomToken } from './auth';
import { useUploadCompanyLogoMutation } from './storage';

export const COLLECTION_NAME = 'companies';

/**
 * @typedef {Object} Company
 */

/**
 * Redirects the user and appends a custom token to the url that
 * the target app can use to authenticate the user.
 *
 * @param {String} link The target to where the user should be redirected.
 */
export const redirectWithToken = async (link) => {
  const token = await getCustomToken();
  window.open(getRedirectToLink(token, link), '_blank');
};

/**
 * @method deleteCompanyById
 * @param {string} company_id - company id
 * @summary delete company from db
 * @returns nothing
 */
export const deleteCompanyById = async (company_id) => {
  try {
    const companyRef = doc(db, COLLECTION_NAME, company_id);
    await deleteDoc(companyRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method createCompany
 * @param {object} data - new company data
 * @summary create a new company
 * @returns nothing
 */
export const createCompany = async (data) => {
  const docRef = collection(db, COLLECTION_NAME);
  await addDoc(docRef, { ...data, users_count: 0 });
};

/**
 * @function useCreateCompanyMutation
 * @description - react-query hook for creating a company
 * @returns {import('@tanstack/react-query').UseMutationResult<void, unknown, { logo: File }, unknown>}
 */
export const useCreateCompanyMutation = () => {
  const uploadCompanyLogoMutation = useUploadCompanyLogoMutation();

  return useMutation(async ({ logo, ...companyData }) => {
    const newCompanyData = { ...companyData };
    if (logo) {
      newCompanyData.logo_url = await uploadCompanyLogoMutation.mutateAsync(
        logo,
      );
    }

    // firestore does not accept undefined values
    for (const key of Object.keys(newCompanyData)) {
      if (newCompanyData[key] === undefined) {
        newCompanyData[key] = null;
      }
    }

    return createCompany(newCompanyData);
  });
};

/**
 * @function updateCompany
 * @description - updates company by id with provided data
 * @param {String} id - id of an updated company
 * @param {Object} data - data of an updated company
 */
export const updateCompany = async ({ data, id }) => {
  const companyRef = doc(db, COLLECTION_NAME, id);
  await updateDoc(companyRef, data);
};

/**
 * @function useUpdateCompanyMutation
 * @description - react-query hook for updating a company
 * @return {import('@tanstack/react-query').UseMutationResult<void, unknown, { id: string, data: Object }, unknown>}
 */
export const useUpdateCompanyMutation = () => {
  const uploadCompanyLogoMutation = useUploadCompanyLogoMutation();

  return useMutation(async (companyData) => {
    const { logo, ...data } = companyData.data;
    const newCompanyData = { ...data };
    if (logo) {
      newCompanyData.logo_url = await uploadCompanyLogoMutation.mutateAsync(
        logo,
      );
    }

    // firestore does not accept undefined values
    for (const key of Object.keys(newCompanyData)) {
      if (newCompanyData[key] === undefined) {
        newCompanyData[key] = null;
      }
    }

    return updateCompany({ id: companyData.id, data: newCompanyData });
  });
};

/**
 * @method getCompanyById
 * @param {string} id - id of a company looking for
 * @summary find company by id
 * @returns {Promise<Object>} company object
 */
export const getCompanyById = async (id) => {
  try {
    const companyRef = doc(db, COLLECTION_NAME, id);
    const companySnap = await getDoc(companyRef);
    if (!companySnap.exists()) {
      return null;
    }
    return companySnap.data();
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function useGetCompanyById
 * @description - react-query hook for getting a company by id
 * @param {string | null} id - id of a company looking for
 * @param {import('@tanstack/react-query').UseQueryOptions<Company | null, unknown>} options - react-query options
 * @return {import('@tanstack/react-query').UseQueryResult<Company | null, unknown>}
 */
export const useGetCompanyById = (id, options = undefined) => {
  return useQuery(
    ['company', id],
    (context) => {
      const _id = context.queryKey[1];
      if (!_id) {
        return null;
      }
      return getCompanyById(_id);
    },
    options,
  );
};

/**
 * @method getAllWorkshops
 * @param {string} company_id - id of company looking for
 * @summary get all workshops
 * @returns {Promise<Array>} workshops array or empty array
 */
export const getAllWorkshopsByCompanyId = async (company_id) => {
  try {
    const workshopsRef = collection(
      db,
      `companies/${company_id}/openWorkshops`,
    );
    const workshopsSnap = await getDocs(query(workshopsRef));
    const workshops = workshopsSnap.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return workshops;
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @method getAllWorkshops
 * @param {string} company_id - id of company looking for
 * @param {string} workshop_id - id of company looking for
 * @summary get all events
 * @returns {Promise<Array>} events array or empty array
 */
export const getAllEventsByCompanyIdAndWorkshopId = async (
  company_id,
  workshop_id,
) => {
  try {
    const eventsRef = collection(
      db,
      `companies/${company_id}/openWorkshops/${workshop_id}/events`,
    );
    const eventsSnap = await getDocs(query(eventsRef));
    if (eventsSnap.empty) {
      return [];
    }
    return eventsSnap.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const getCompanyByDomain = httpsCallable(
  functions,
  'trigger_getCompanyByDomain',
);

export const getPublicDataByCustomPath = httpsCallable(
  functions,
  'companies-getPublicDataByCustomPath',
);

export const useGetPublicDataByCustomPathQuery = (
  customPath,
  options = undefined,
) => {
  return useQuery({
    queryKey: ['getPublicDataByCustomPath', customPath],
    queryFn: async () => {
      const { data } = await getPublicDataByCustomPath(customPath);
      return data;
    },
    ...options,
  });
};

/**
 * @deprecated use useGetAllCompaniesQuery instead
 * @function getAllCompanies
 * @description fetch all companies in the app and return it
 * @returns {Promise<Array>} all companies or empty array
 */
export const getAllCompanies = async () => {
  try {
    const companiesRef = collection(db, COLLECTION_NAME);
    const companiesSnap = await getDocs(query(companiesRef));
    if (companiesSnap.empty) {
      return [];
    }
    return companiesSnap.docs.map((company) => ({
      ...company.data(),
      id: company.id,
    }));
  } catch (err) {
    // TODO: Remove try/catch when getAllCompanies will no longer be used in the app without the hook below
    Sentry.captureException(err);
    throw err;
  }
};

export const useGetAllCompaniesQuery = (options = undefined) => {
  return useQuery(['getAllCompanies'], getAllCompanies, {
    initialData: [],
    ...options,
  });
};

/**
 * @function getAllOpenWorkshops
 * @description fetch all open workshops in the app
 * @returns {Promise<Array>} list of all open workshops
 */
export const getAllOpenWorkshops = async () => {
  try {
    const workshopsRef = collectionGroup(db, 'openWorkshops');
    const workshopsSnap = await getDocs(query(workshopsRef));
    if (workshopsSnap.empty) {
      return [];
    }
    return workshopsSnap.docs.map((workshop) => ({
      ...workshop.data(),
      id: workshop.id,
      companyId: workshop.ref.parent.parent.id,
    }));
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function getAllEvents
 * @description fetch all events in the app
 * @returns {Promise<Array>} list of all events
 */
export const getAllEvents = async () => {
  try {
    const eventsRef = collectionGroup(db, 'events');
    const eventsSnap = await getDocs(query(eventsRef));
    if (eventsSnap.empty) {
      return [];
    }
    return eventsSnap.docs.map((event) => ({
      ...event.data(),
      id: event.id,
      openWorkshopId: event.ref.parent.parent.id,
    }));
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function getTrainingsData
 * @description return list of companies, events and workshop
 * @returns {Promise<Array>}
 */
export const getTrainingsData = async () => {
  try {
    const allOpenWorkshops = await getAllOpenWorkshops();
    const allEvents = await getAllEvents();
    const allCompanies = await getAllCompanies();
    return allOpenWorkshops.map((workshop) => {
      const events =
        allEvents.filter((e) => e.openWorkshopId === workshop.id) || [];
      const company =
        allCompanies.find((c) => c.id === workshop.companyId) || {};
      return {
        company,
        events,
        ...workshop,
      };
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @typedef {Object} Company
 * @property {string} id - id of company
 * @property {string} name - name of company
 * @property {Array<string>} domain - domain of a company
 * @property {number} users_count - number of users in a company
 * @property {Array} available_products - list of available products
 */

/**
 * @desc function to get all companies
 * @function onCompaniesUpdate
 * @param {(companies: Company[]) => void} callback - function, that we will call on update
 * @returns {Function} unsubscribe function
 */
export const onCompaniesUpdate = (callback) => {
  try {
    const companiesRef = collection(db, COLLECTION_NAME);
    const q = query(companiesRef);
    return onSnapshot(q, (companiesSnap) => {
      if (companiesSnap.empty) {
        callback([]);
        return;
      }
      const companies = companiesSnap.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      callback(companies);
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @function useGetCompanies
 * @desc function to get all companies
 * @return {Company[]} companies
 */
export const useGetCompanies = () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    let isSubscribe = true;
    const callback = (companies) => {
      if (isSubscribe) {
        setCompanies(companies);
      }
    };
    const unsubscribe = onCompaniesUpdate(callback);
    return () => {
      unsubscribe();
      isSubscribe = false;
    };
  }, []);

  return companies;
};

export const createOpenWorkshop = async (openWorkshopData) => {
  const { companyId, events, ...rest } = openWorkshopData;
  const openWorkshopsRef = collection(
    db,
    `${COLLECTION_NAME}/${companyId}/openWorkshops`,
  );
  const openWorkshopRef = await addDoc(openWorkshopsRef, rest);
  const openWorkshopEventsRef = collection(
    db,
    `${COLLECTION_NAME}/${companyId}/openWorkshops/${openWorkshopRef.id}/events`,
  );
  await Promise.all(
    events.map(async (event) => {
      await addDoc(openWorkshopEventsRef, event);
    }),
  );
};

export const useCreateOpenWorkshopMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createOpenWorkshop, {
    onSuccess: () => {
      queryClient.invalidateQueries('getAllOpenWorkshops');
    },
  });
};

export const getWorkshopById = async (id) => {
  try {
    const workshopsRef = collectionGroup(db, 'openWorkshops');
    const workshopsSnap = await getDocs(query(workshopsRef));
    if (workshopsSnap.empty) {
      return {};
    }
    const workshop = workshopsSnap.docs.find((doc) => doc.id === id);
    const eventsRef = collection(db, `${workshop.ref.path}/events`);
    const eventsSnap = await getDocs(eventsRef);
    let events = [];
    if (!eventsSnap.empty) {
      events = eventsSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    }
    return {
      ...workshop.data(),
      id,
      companyId: workshop.ref.parent.parent.id,
      events,
    };
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const useGetWorkshopByIdQuery = (id, options) => {
  return useQuery(
    ['getWorkshopById', id],
    (context) => {
      return getWorkshopById(context.queryKey[1]);
    },
    options,
  );
};

export const deleteWorkshopById = async (companyId, id) => {
  try {
    const openWorkshopRef = doc(
      db,
      `${COLLECTION_NAME}/${companyId}/openWorkshops/${id}`,
    );
    const eventsRef = collection(db, `${openWorkshopRef.path}/events`);
    const eventsSnap = await getDocs(query(eventsRef));
    if (!eventsSnap.empty) {
      const workshopsBookingsRef = collectionGroup(db, 'openWorkshopBookings');
      await Promise.all(
        eventsSnap.docs.map(async (event) => {
          const workshopsBookingsSnap = await getDocs(
            query(workshopsBookingsRef),
          );
          if (!workshopsBookingsSnap.empty) {
            const matchedBookings = workshopsBookingsSnap.docs.filter(
              (doc) => doc.data().eventId === event.id,
            );
            await Promise.all(
              matchedBookings.map(async (doc) => {
                await deleteDoc(doc.ref);
              }),
            );
          }

          await deleteDoc(event.ref);
        }),
      );
    }
    await deleteDoc(openWorkshopRef);
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * Retrieves the IDs of existing events in a Firestore collection
 *
 * @param {firebase.firestore.CollectionReference} eventsRef - Reference to a Firestore events collection
 * @returns {Promise<Set<string>>} A promise that resolves to a Set of event IDs
 */
export const getExistingEventIds = async (eventsRef) => {
  const currentEventsSnap = await getDocs(query(eventsRef));
  return new Set(currentEventsSnap.docs.map((eventDoc) => eventDoc.id));
};

/**
 * Adds or updates an event in the Firestore collection
 *
 * @param {firebase.firestore.CollectionReference} eventsRef - Reference to a Firestore events collection
 * @param {Object} event - The event object to add or update
 * @param {Set<string>} existingEventIds - A Set of existing event IDs
 * @returns {Promise<void>}
 */
export const updateEvent = async (eventsRef, event, existingEventIds) => {
  if (event.id && existingEventIds.has(event.id)) {
    const eventRef = doc(eventsRef, event.id);
    await updateDoc(eventRef, event);
    existingEventIds.delete(event.id);
  } else {
    await addDoc(eventsRef, event);
  }
};

/**
 * Deletes an event from the Firestore collection
 *
 * @param {firebase.firestore.CollectionReference} eventsRef - Reference to a Firestore events collection
 * @param {string} eventId - The ID of the event to delete
 * @returns {Promise<void>}
 */
export const deleteEvent = async (eventsRef, eventId) => {
  const eventRef = doc(eventsRef, eventId);
  await deleteDoc(eventRef);
};

/**
 * @typedef {Object} Event
 * @property {string} id - The unique identifier of the event.
 * @property {number} numBookings - The number of bookings for the event.
 * @property {string} location - The location of the event.
 * @property {string} start - The start time of the event.
 * @property {string} end - The end time of the event.
 */

/**
 * @typedef {Object} Translation
 * @property {string} de - The German translation.
 * @property {string} en - The English translation.
 */

/**
 * @typedef {Object} Training
 * @property {string[]} focusTopics - The list of focus topics.
 * @property {number} maxParticipants - The maximum number of participants.
 * @property {number} noticePeriodInDays - The notice period in days.
 * @property {Translation} title - The title of the training in different languages.
 * @property {Translation} text - The text description of the training in different languages.
 * @property {string} companyId - The ID of the associated company.
 * @property {Event[]} events - The list of associated events.
 * @property {string} id - The unique identifier of the training.
 */

/**
 * Updates a given workshop.
 *
 * @param {Training} workshop - The workshop object to be updated.
 * @returns {Promise} - A promise that resolves when the workshop is updated.
 */
export const updateWorkshop = async (workshop) => {
  const { id, companyId, events, ...rest } = workshop;
  const openWorkshopRef = doc(
    db,
    `${COLLECTION_NAME}/${companyId}/openWorkshops/${id}`,
  );
  await updateDoc(openWorkshopRef, rest);

  const eventsRef = collection(db, `${openWorkshopRef.path}/events`);
  const existingEventIds = await getExistingEventIds(eventsRef);

  await Promise.all(
    events.map((event) => updateEvent(eventsRef, event, existingEventIds)),
  );
  await Promise.all(
    Array.from(existingEventIds).map((eventId) =>
      deleteEvent(eventsRef, eventId),
    ),
  );
};

/**
 * @param {import('@tanstack/react-query').MutationOptions} options
 */
export const useUpdateWorkshopMutation = (options = undefined) => {
  return useMutation(updateWorkshop, options);
};
