import { Box, Typography, CircularProgress } from '@mui/material';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { getProductRef } from '../../../../../../api';
import Button from '../../../../../../components/Button';
import ProjectTypes from '../../../../../../constants/ProjectTypes';

const AdminCompanyProductsTabs = ({
  allProducts,
  locale,
  selectedProducts = [],
  onChange,
  isLoading = false,
}) => {
  const [activeTab, setActiveTab] = useState(ProjectTypes.MODERATOR);

  if (isLoading) {
    return <CircularProgress />;
  }

  const tabs = [
    {
      type: ProjectTypes.MODERATOR,
      label: 'Moderator',
    },
    {
      type: ProjectTypes.COACH,
      label: 'Coachings',
    },
    {
      type: ProjectTypes.ELEARNING,
      label: 'E-Learning',
    },
  ];

  const allProductsGroupedByType = groupBy(allProducts, 'type');

  Object.values(ProjectTypes).forEach((key) => {
    if (!allProductsGroupedByType[key]) {
      allProductsGroupedByType[key] = [];
    }
  });

  const isSelectedProduct = (id) =>
    selectedProducts?.find((item) => item.id === id) !== undefined;

  const removeFromSelectedProducts = (id) =>
    onChange(selectedProducts.filter((item) => item.id !== id));

  const addToSelectedProducts = (id) =>
    onChange(selectedProducts.concat([getProductRef(id)]));

  const countSelectedProductsByType = (type) =>
    allProductsGroupedByType[type].filter((product) =>
      isSelectedProduct(product.id),
    ).length;

  const companyButtonVariantPerType = {
    [ProjectTypes.MODERATOR]: 'green',
    [ProjectTypes.COACH]: 'main',
    [ProjectTypes.ELEARNING]: 'secondary',
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          borderBottom: 1,
          borderBottomColor: 'yellow.dark',
          marginBottom: 4,
        }}
      >
        {tabs.map(({ type, label }) => (
          <Box
            sx={{
              padding: '9px 23px',
              border: '1px solid yellow.dark',
              borderBottom: 'none',
              backgroundColor:
                type === activeTab ? 'yellow.dark' : 'yellow.main',
              cursor: 'pointer',
              '&:hover': { backgroundColor: 'yellow.dark' },
            }}
            key={type}
            onClick={(e) => setActiveTab(type)}
          >
            {`${label} (${countSelectedProductsByType(type)}/${
              allProductsGroupedByType[type]?.length ?? 0
            })`}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '150px 1fr auto',
          gridColumnGap: '32px',
          gridRowGap: '32px',
          alignItems: 'center',
        }}
      >
        {allProductsGroupedByType[activeTab].map(
          ({ id, title, description, image }) => {
            const active = isSelectedProduct(id);
            return (
              <>
                <Box
                  component="img"
                  sx={{
                    width: 150,
                  }}
                  src={image}
                  alt=""
                />
                <Box sx={{ minHeight: '90px' }}>
                  <Typography>{title[locale]}</Typography>
                  <Typography variant="secondaryText">
                    {description[locale]}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    type="button"
                    variant={
                      active ? companyButtonVariantPerType[activeTab] : 'light'
                    }
                    onClick={(v) =>
                      active
                        ? removeFromSelectedProducts(id)
                        : addToSelectedProducts(id)
                    }
                    arrow={false}
                    size="medium"
                  >
                    {active ? 'Hinzugefügt' : 'Hinzufügen'}
                  </Button>
                </Box>
              </>
            );
          },
        )}
      </Box>
    </>
  );
};

AdminCompanyProductsTabs.propTypes = {
  /** All possible products from which the admin can assign products to the company.  */
  allProducts: PropTypes.array.isRequired,

  /** The language in which details of the products should be displayed. */
  locale: PropTypes.oneOf(['en', 'fr', 'de']).isRequired,

  /** The list of products the user has selected. This is an array of Firestore docs. Is is the same as is stored in the database. */
  selectedProducts: PropTypes.array,

  /** This function is called when a user assigns a product to this company. Use this to update the list of selected products to reflect the change in the UI.*/
  onChange: PropTypes.func.isRequired,

  /** Set to true if the component should dispaly a loading state */
  isLoading: PropTypes.bool,
};

export default AdminCompanyProductsTabs;
