import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './DateComponent.scss';

export const DateComponent = ({ start }) => {
  // TODO: Replace `isMobile` and useEffect below with css media query
  const [isMobile, setIsMobile] = useState(false);
  const [t] = useTranslation();

  const changeSize = () => {
    setIsMobile(window.innerWidth <= 992);
  };

  useEffect(() => {
    window.addEventListener('resize', changeSize);
    changeSize();
    return () => {
      window.removeEventListener('resize', changeSize);
    };
  }, []);

  return (
    <div className="date-component">
      <div className="date-component-text date-component-day">
        {t('{{val, datetime(day: numeric)}}', { val: start })}
      </div>
      <div className="date-component-text date-component-date">
        {isMobile
          ? t('openWorkshops.list.item.date.month', { val: start })
          : t('openWorkshops.list.item.date.monthAndYear', { val: start })}
      </div>
      <div className="date-component-text date-component-week">
        {t('{{val, datetime(day: numeric)}}OfWeekAndTime', { val: start })}
      </div>
    </div>
  );
};

export default DateComponent;
