import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalState } from '../../../hooks';
import './MainInfo.scss';

export const MainInfo = ({ item }) => {
  const [{ locale }] = useGlobalState();
  const { imageUrl, start, end, location, title } = item;
  const [t] = useTranslation();

  const infoValue = [
    {
      name: t('Standort'),
      value: location,
    },
    {
      name: t('Start'),
      value: t('{{val, datetime(day: numeric; month: long; year: numeric;)}}', {
        val: start,
      }),
    },
    {
      name: t('Ende'),
      value: t('{{val, datetime(day: numeric; month: long; year: numeric;)}}', {
        val: end,
      }),
    },
  ];

  return (
    <div className="main-info-container">
      <div>
        <img src={imageUrl} alt="img" className="main-info-image" />
      </div>
      <div className="main-info-title-table">
        <div className="main-info-title">{title[locale]}</div>
        <table>
          <tbody>
            {infoValue.map((item, index) => (
              <tr key={index}>
                <td className="main-info-first-column">{`${item.name}:`}</td>
                <td className="main-info-second-column">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default MainInfo;
