import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import { updateUserPassword } from '../../api';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import ProfilePasswordUpdateModalSchema from './ProfilePasswordUpdateModalSchema';

export const ProfilePasswordUpdateModal = ({ show, onHide }) => {
  const initialFormValues = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  };
  const [t] = useTranslation();

  const submit = async ({ password, currentPassword }) => {
    try {
      sendAmplitudeData('Update Password');
      await updateUserPassword(password, currentPassword);
    } catch (err) {
      Sentry.captureException(err);
    }
    onHide();
  };

  return (
    <FgiModal
      show={show}
      onHide={onHide}
      title={t('Passwort Ändern')}
      color="yellow"
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={ProfilePasswordUpdateModalSchema(t)}
        onSubmit={submit}
      >
        {({ handleSubmit, errors, handleChange, touched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Aktuelles Passwort')}</Form.Label>
                <Form.Control
                  as="input"
                  autoComplete="on"
                  type="password"
                  name="currentPassword"
                  onChange={handleChange}
                  isInvalid={
                    touched.currentPassword && !!errors.currentPassword
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.currentPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('Neues Passwort *')}</Form.Label>
                <Form.Control
                  as="input"
                  autoComplete="on"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} />
              <Form.Group as={Col}>
                <Form.Label>{t('Neues Passwort bestätigen *')}</Form.Label>
                <Form.Control
                  as="input"
                  autoComplete="on"
                  type="password"
                  name="confirmPassword"
                  onChange={handleChange}
                  isInvalid={
                    touched.confirmPassword && !!errors.confirmPassword
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className="modal-footer-button">
              <Button variant="main" size="medium" type="submit">
                {t('Speichern')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </FgiModal>
  );
};

ProfilePasswordUpdateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ProfilePasswordUpdateModal;
