import {
  Typography,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import PropTypes from 'prop-types';

const AdminProjectsTableHead = ({ onRequestSort, order, orderBy }) => {
  const tableHeaders = [
    {
      id: 'created_at',
      width: '10%',
      title: 'Datum',
      sortEnabled: true,
    },
    {
      id: 'type',
      width: '10%',
      title: 'Typ',
      sortEnabled: true,
    },
    {
      id: 'category',
      width: '23%',
      title: 'Thema',
      sortEnabled: true,
    },
    {
      width: '15%',
      title: 'Coaches/Moderatoren',
    },
    {
      width: '27%',
      title: 'Nutzer',
    },
    {
      width: '15%',
    },
  ];

  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow sx={{ borderBottom: '1px solid grey.light6' }}>
        {tableHeaders.map((headCell, index) => (
          <TableCell
            key={index}
            sx={{ width: headCell.width, padding: '0 0 13px 0' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortEnabled ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="secondaryText">
                  {headCell.title}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="secondaryText">{headCell.title}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

AdminProjectsTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default AdminProjectsTableHead;
