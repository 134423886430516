import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getProjectById,
  getUserById,
  getCompanyById,
  getAllCoaches,
  confirmProjectRequest,
  updateSuggestedCoaches,
  deleteProject,
  getCoachesByRef,
} from '../../api';
import Title from '../../components/PageTitle';
import PagePath from '../../constants/PagePath';
import ProjectTypes from '../../constants/ProjectTypes';
import AdminLayout from '../../HOC/AdminLayout';
import { useGlobalState } from '../../hooks';
import AuswahlSendenModal from '../../modals/AuswahlSendenModal';
import DeleteProjectModal from '../../modals/DeleteProjectModal';
import { getLink } from '../../utils';
import './AdminProject.scss';
import Footer from './components/Footer';
import ProjectCandidateTable from './components/ProjectCandidateTable';
import ProjectCoachesTable from './components/ProjectCoachesTable';
import ProjectInfo from './components/ProjectInfo';
import UserInfo from './components/UserInfo';

export const AdminProject = () => {
  const [{ company }] = useGlobalState();
  const navigate = useNavigate();
  const { id } = useParams();

  const [project, setProject] = useState({});
  const [user, setUser] = useState({});
  const [userCompany, setCompany] = useState({});
  const [suggested_coaches, setSuggestedCoaches] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [showAuswahlSendenModal, setShowAuswahlSendenModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const project = await getProjectById(id);
      setProject(project);

      const coaches = await getAllCoaches();
      setCoaches(coaches);

      if (project.suggested_coaches) {
        const suggested_coaches = await getCoachesByRef(
          project.suggested_coaches,
        );
        const filteredCoaches = _.differenceBy(
          coaches,
          suggested_coaches,
          'id',
        );

        setSuggestedCoaches(suggested_coaches);
        setCoaches(filteredCoaches);
      }

      const user = await getUserById(project.user_id);
      setUser(user);

      if (user) {
        const company = await getCompanyById(user.company_id);
        setCompany(company);
      }
    };
    fetch();
  }, [id]);

  const renderTitle = (project) => {
    const coachesLength = project.suggested_coaches
      ? project.suggested_coaches.length
      : 0;
    const text =
      project.type === ProjectTypes.MODERATOR
        ? `Workshop-Anfrage – ${coachesLength}/3`
        : `Coach-Anfrage – ${coachesLength}/3`;
    const color = project.type === ProjectTypes.MODERATOR ? 'green' : 'yellow';
    return <Title width="15%" color={color} text={text} />;
  };

  const submitSuggestedCoaches = () => {
    updateSuggestedCoaches(project.id, suggested_coaches);
    navigate(getLink(company, PagePath.ADMIN_PROJECTS));
  };

  const onSendenClick = () => {
    confirmProjectRequest(project.id, suggested_coaches);
    closeAuswahlSendenModal();
    navigate(getLink(company, PagePath.ADMIN_PROJECTS));
  };

  const openAuswahlSendenModal = () => setShowAuswahlSendenModal(true);

  const closeAuswahlSendenModal = () => setShowAuswahlSendenModal(false);

  const addToSuggestedCoaches = (coach) => () => {
    const newSuggestedCoachesArray = [...suggested_coaches];
    newSuggestedCoachesArray.push(coach);
    setSuggestedCoaches(newSuggestedCoachesArray);
    const newCoachesArray = coaches.filter((c) => c.id !== coach.id);
    setCoaches(newCoachesArray);
  };

  const deleteFromSuggestedCoaches = (coach) => () => {
    const newSuggestedCoachesArray = suggested_coaches.filter(
      (c) => c.id !== coach.id,
    );
    setSuggestedCoaches(newSuggestedCoachesArray);
    const newCoachesArray = [...coaches];
    newCoachesArray.push(coach);
    setCoaches(newCoachesArray);
  };

  const openDeleteProjectModal = () => setShowDeleteProjectModal(true);
  const closeDeleteProjectModal = () => setShowDeleteProjectModal(false);

  const onDeleteClick = async () => {
    closeDeleteProjectModal();
    navigate(getLink(company, PagePath.ADMIN_PROJECTS));
    await deleteProject(id);
  };
  return (
    <AdminLayout>
      {renderTitle(project)}
      <div className="admin-project-main-container">
        <Row>
          <UserInfo user={user} project={project} userCompany={userCompany} />
          <ProjectInfo project={project} />
        </Row>
        <ProjectCandidateTable
          deleteFromSuggestedCoaches={deleteFromSuggestedCoaches}
          suggested_coaches={suggested_coaches}
        />
        <ProjectCoachesTable
          coaches={coaches}
          addToSuggestedCoaches={addToSuggestedCoaches}
          project={project}
        />
      </div>
      <Footer
        submitSuggestedCoaches={submitSuggestedCoaches}
        openAuswahlSendenModal={openAuswahlSendenModal}
        openDeleteProjectModal={openDeleteProjectModal}
      />
      <AuswahlSendenModal
        show={showAuswahlSendenModal}
        onHide={closeAuswahlSendenModal}
        confirmProjectRequest={onSendenClick}
      />
      <DeleteProjectModal
        show={showDeleteProjectModal}
        onHide={closeDeleteProjectModal}
        confirmDeleteProject={onDeleteClick}
      />
    </AdminLayout>
  );
};

export default AdminProject;
