import React from 'react';
import { Navigate } from 'react-router-dom';

import PagePath from '../constants/PagePath';
import { useGlobalState } from '../hooks/useGlobalState';
import { getLink } from '../utils';

const withRequiredAdmin = (ComposedComponent) => (props) => {
  const [{ company, emailVerified, isAdmin }] = useGlobalState();

  if (!emailVerified || !isAdmin) {
    return <Navigate to={getLink(company, PagePath.ANMELDEN)} />;
  }

  return <ComposedComponent {...props} />;
};

export default withRequiredAdmin;
