import { Paper, Unstable_Grid2 as Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import Title from '../../components/PageTitle';
import PageTitle from '../../components/PageTitle';
import ProductItem from '../../components/ProductItem';
import ProjectTypes from '../../constants/ProjectTypes';
import MainLayout from '../../HOC/MainLayout';
import { useGlobalState } from '../../hooks';
import FosterCollaborationModal from '../../modals/FosterCollaborationModal';
import FosterCollaborationSuccessModal from '../../modals/FosterCollaborationSuccessModal';
import { getProductTranslatedData } from '../../utils';
import MiddleItem from './components/MiddleItem';
import TopItem from './components/TopItem';
import './ModeratorFinder.scss';

export const ModeratorFinder = () => {
  const [{ available_products, locale }] = useGlobalState();
  const [t] = useTranslation();

  const [product, setProduct] = useState(null);
  const [showFosterCollaborationModal, setShowFosterCollaborationModal] =
    useState(false);
  const [
    showFosterCollaborationSuccessModal,
    setShowFosterCollaborationSuccessModal,
  ] = useState(false);

  const openFosterCollaborationModal = (product) => {
    setProduct(product);
    sendAmplitudeData('New Project – Select Category', {
      category: getProductTranslatedData(product.title, locale),
      type: ProjectTypes.MODERATOR,
    });
    setShowFosterCollaborationModal(true);
  };
  const onSubmit = () => {
    setProduct(null);
    setShowFosterCollaborationModal(false);
    setShowFosterCollaborationSuccessModal(true);
  };
  const hideFosterCollaborationModal = () => {
    setShowFosterCollaborationModal(false);
    setProduct(null);
  };
  const hideFosterCollaborationSuccessModal = () => {
    setShowFosterCollaborationSuccessModal(false);
  };

  useEffect(() => {
    sendAmplitudeData('Visit Moderator Finder');
  }, []);

  const moderatorFindenItems = available_products.filter(
    (product) => product.type === ProjectTypes.MODERATOR,
  );

  return (
    <MainLayout>
      <Title text={t('Moderator finden')} width="14%" color="green" />
      <TopItem
        openFosterCollaborationModal={openFosterCollaborationModal}
        moderatorFindenItems={moderatorFindenItems}
      />
      <MiddleItem />
      <Paper elevation={0} square sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            sx={{ marginBottom: 7 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <PageTitle
              color="green"
              text={t('Unsere Auswahl an Workshop-Themen')}
            />
          </Grid>
          {moderatorFindenItems.map((item) => (
            <Grid xs={6}>
              <ProductItem
                onClick={() => openFosterCollaborationModal(item)}
                key={item.id}
                description={item.description[locale]}
                image={item.image}
                pdfUrl={item.pdfUrl ? item.pdfUrl[locale] : ''}
                title={item.title[locale]}
                color="green"
                buttonLabel={t('Moderator finden')}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
      <FosterCollaborationModal
        show={showFosterCollaborationModal}
        onHide={hideFosterCollaborationModal}
        product={product}
        onSubmit={onSubmit}
      />
      <FosterCollaborationSuccessModal
        show={showFosterCollaborationSuccessModal}
        onHide={hideFosterCollaborationSuccessModal}
      />
    </MainLayout>
  );
};

export default ModeratorFinder;
