import { useTranslation } from 'react-i18next';

const useTranslatedCountries = () => {
  const [t] = useTranslation();

  return {
    AF: t('countries.AF'),
    EG: t('countries.EG'),
    AX: t('countries.AX'),
    AL: t('countries.AL'),
    DZ: t('countries.DZ'),
    AS: t('countries.AS'),
    VI: t('countries.VI'),
    UM: t('countries.UM'),
    AD: t('countries.AD'),
    AO: t('countries.AO'),
    AI: t('countries.AI'),
    AQ: t('countries.AQ'),
    AG: t('countries.AG'),
    GQ: t('countries.GQ'),
    AR: t('countries.AR'),
    AM: t('countries.AM'),
    AW: t('countries.AW'),
    AC: t('countries.AC'),
    AZ: t('countries.AZ'),
    ET: t('countries.ET'),
    AU: t('countries.AU'),
    BS: t('countries.BS'),
    BH: t('countries.BH'),
    BD: t('countries.BD'),
    BB: t('countries.BB'),
    BY: t('countries.BY'),
    BE: t('countries.BE'),
    BZ: t('countries.BZ'),
    BJ: t('countries.BJ'),
    BM: t('countries.BM'),
    BT: t('countries.BT'),
    BO: t('countries.BO'),
    BQ: t('countries.BQ'),
    BA: t('countries.BA'),
    BW: t('countries.BW'),
    BR: t('countries.BR'),
    VG: t('countries.VG'),
    IO: t('countries.IO'),
    BN: t('countries.BN'),
    BG: t('countries.BG'),
    BF: t('countries.BF'),
    BI: t('countries.BI'),
    CV: t('countries.CV'),
    EA: t('countries.EA'),
    CL: t('countries.CL'),
    CN: t('countries.CN'),
    CK: t('countries.CK'),
    CR: t('countries.CR'),
    CI: t('countries.CI'),
    CW: t('countries.CW'),
    DK: t('countries.DK'),
    DE: t('countries.DE'),
    DG: t('countries.DG'),
    DM: t('countries.DM'),
    DO: t('countries.DO'),
    DJ: t('countries.DJ'),
    EC: t('countries.EC'),
    SV: t('countries.SV'),
    ER: t('countries.ER'),
    EE: t('countries.EE'),
    FK: t('countries.FK'),
    FO: t('countries.FO'),
    FJ: t('countries.FJ'),
    FI: t('countries.FI'),
    FR: t('countries.FR'),
    GF: t('countries.GF'),
    PF: t('countries.PF'),
    TF: t('countries.TF'),
    GA: t('countries.GA'),
    GM: t('countries.GM'),
    GE: t('countries.GE'),
    GH: t('countries.GH'),
    GI: t('countries.GI'),
    GD: t('countries.GD'),
    GR: t('countries.GR'),
    GL: t('countries.GL'),
    GP: t('countries.GP'),
    GU: t('countries.GU'),
    GT: t('countries.GT'),
    GG: t('countries.GG'),
    GN: t('countries.GN'),
    GW: t('countries.GW'),
    GY: t('countries.GY'),
    HT: t('countries.HT'),
    HN: t('countries.HN'),
    IN: t('countries.IN'),
    ID: t('countries.ID'),
    IQ: t('countries.IQ'),
    IR: t('countries.IR'),
    IE: t('countries.IE'),
    IS: t('countries.IS'),
    IM: t('countries.IM'),
    IL: t('countries.IL'),
    IT: t('countries.IT'),
    JM: t('countries.JM'),
    JP: t('countries.JP'),
    YE: t('countries.YE'),
    JE: t('countries.JE'),
    JO: t('countries.JO'),
    KY: t('countries.KY'),
    KH: t('countries.KH'),
    CM: t('countries.CM'),
    CA: t('countries.CA'),
    IC: t('countries.IC'),
    KZ: t('countries.KZ'),
    QA: t('countries.QA'),
    KE: t('countries.KE'),
    KG: t('countries.KG'),
    KI: t('countries.KI'),
    CC: t('countries.CC'),
    CO: t('countries.CO'),
    KM: t('countries.KM'),
    CG: t('countries.CG'),
    CD: t('countries.CD'),
    XK: t('countries.XK'),
    HR: t('countries.HR'),
    CU: t('countries.CU'),
    KW: t('countries.KW'),
    LA: t('countries.LA'),
    LS: t('countries.LS'),
    LV: t('countries.LV'),
    LB: t('countries.LB'),
    LR: t('countries.LR'),
    LY: t('countries.LY'),
    LI: t('countries.LI'),
    LT: t('countries.LT'),
    LU: t('countries.LU'),
    MG: t('countries.MG'),
    MW: t('countries.MW'),
    MY: t('countries.MY'),
    MV: t('countries.MV'),
    ML: t('countries.ML'),
    MT: t('countries.MT'),
    MA: t('countries.MA'),
    MH: t('countries.MH'),
    MQ: t('countries.MQ'),
    MR: t('countries.MR'),
    MU: t('countries.MU'),
    YT: t('countries.YT'),
    MX: t('countries.MX'),
    FM: t('countries.FM'),
    MC: t('countries.MC'),
    MN: t('countries.MN'),
    ME: t('countries.ME'),
    MS: t('countries.MS'),
    MZ: t('countries.MZ'),
    MM: t('countries.MM'),
    NA: t('countries.NA'),
    NR: t('countries.NR'),
    NP: t('countries.NP'),
    NC: t('countries.NC'),
    NZ: t('countries.NZ'),
    NI: t('countries.NI'),
    NL: t('countries.NL'),
    NE: t('countries.NE'),
    NG: t('countries.NG'),
    NU: t('countries.NU'),
    KP: t('countries.KP'),
    MP: t('countries.MP'),
    MK: t('countries.MK'),
    NF: t('countries.NF'),
    NO: t('countries.NO'),
    OM: t('countries.OM'),
    AT: t('countries.AT'),
    PK: t('countries.PK'),
    PS: t('countries.PS'),
    PW: t('countries.PW'),
    PA: t('countries.PA'),
    PG: t('countries.PG'),
    PY: t('countries.PY'),
    PE: t('countries.PE'),
    PH: t('countries.PH'),
    PN: t('countries.PN'),
    PL: t('countries.PL'),
    PT: t('countries.PT'),
    XA: t('countries.XA'),
    XB: t('countries.XB'),
    PR: t('countries.PR'),
    MD: t('countries.MD'),
    RE: t('countries.RE'),
    RW: t('countries.RW'),
    RO: t('countries.RO'),
    RU: t('countries.RU'),
    SB: t('countries.SB'),
    ZM: t('countries.ZM'),
    WS: t('countries.WS'),
    SM: t('countries.SM'),
    ST: t('countries.ST'),
    SA: t('countries.SA'),
    SE: t('countries.SE'),
    CH: t('countries.CH'),
    SN: t('countries.SN'),
    RS: t('countries.RS'),
    SC: t('countries.SC'),
    SL: t('countries.SL'),
    ZW: t('countries.ZW'),
    SG: t('countries.SG'),
    SX: t('countries.SX'),
    SK: t('countries.SK'),
    SI: t('countries.SI'),
    SO: t('countries.SO'),
    HK: t('countries.HK'),
    MO: t('countries.MO'),
    ES: t('countries.ES'),
    SJ: t('countries.SJ'),
    LK: t('countries.LK'),
    BL: t('countries.BL'),
    SH: t('countries.SH'),
    KN: t('countries.KN'),
    LC: t('countries.LC'),
    MF: t('countries.MF'),
    PM: t('countries.PM'),
    VC: t('countries.VC'),
    ZA: t('countries.ZA'),
    SD: t('countries.SD'),
    GS: t('countries.GS'),
    KR: t('countries.KR'),
    SS: t('countries.SS'),
    SR: t('countries.SR'),
    SZ: t('countries.SZ'),
    SY: t('countries.SY'),
    TJ: t('countries.TJ'),
    TW: t('countries.TW'),
    TZ: t('countries.TZ'),
    TH: t('countries.TH'),
    TL: t('countries.TL'),
    TG: t('countries.TG'),
    TK: t('countries.TK'),
    TO: t('countries.TO'),
    TT: t('countries.TT'),
    TA: t('countries.TA'),
    TD: t('countries.TD'),
    CZ: t('countries.CZ'),
    TN: t('countries.TN'),
    TR: t('countries.TR'),
    TM: t('countries.TM'),
    TC: t('countries.TC'),
    TV: t('countries.TV'),
    UG: t('countries.UG'),
    UA: t('countries.UA'),
    HU: t('countries.HU'),
    UY: t('countries.UY'),
    UZ: t('countries.UZ'),
    VU: t('countries.VU'),
    VA: t('countries.VA'),
    VE: t('countries.VE'),
    AE: t('countries.AE'),
    US: t('countries.US'),
    GB: t('countries.GB'),
    VN: t('countries.VN'),
    WF: t('countries.WF'),
    CX: t('countries.CX'),
    EH: t('countries.EH'),
    CF: t('countries.CF'),
    CY: t('countries.CY'),
  };
};
export default useTranslatedCountries;
