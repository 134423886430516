import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * This is a non-responsive side bar, that is mainly used in admin pages.
 */

const AdminSidebar = ({ elements }) => (
  <Box width={300} minHeight={'100%'} bgcolor="grey.light5" padding={4}>
    <Stack direction={'column'} spacing={2}>
      {elements.map((Element, index) => (
        <Box key={index}>{Element}</Box>
      ))}
    </Stack>
  </Box>
);

AdminSidebar.propTypes = {
  /** An array of individual components that should be shown in the Sidebar. */
  elements: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default AdminSidebar;
