import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FindWorkshopsModal from '../../../modals/FindWorkshopsModal';
import DateComponent from '../DateComponent';
import Description from '../Description';
import './WorkshopItem.scss';

export const WorkshopItem = ({ item }) => {
  const {
    imageUrl,
    dateUntilRegistrationNotice,
    start,
    isAlreadyBooking,
    isAvailable,
  } = item;

  const [isShow, setShow] = useState(false);
  const [t] = useTranslation();

  const openFindWorkshopModal = () => {
    setShow(true);
  };

  const closeFindWorkshopModal = () => {
    setShow(false);
  };

  const buttonRender = () => {
    if (isAlreadyBooking) {
      return (
        <button
          className="workshop-item-button"
          onClick={openFindWorkshopModal}
        >
          {t('Mehr Infos')}
        </button>
      );
    }
    if (isAvailable) {
      return (
        <button
          className="workshop-item-button workshop-item-button-grey"
          onClick={openFindWorkshopModal}
        >
          {t('Anmelden')}
        </button>
      );
    }
    return (
      <button
        className="workshop-item-button workshop-item-button-grey workshop-item-button-disabled"
        disabled
      >
        {t('Ausgebucht')}
      </button>
    );
  };

  return (
    <div className="workshop-item-container">
      <img className="workshop-item-image" alt="img" src={imageUrl} />
      <FindWorkshopsModal
        show={isShow}
        onHide={closeFindWorkshopModal}
        item={item}
      />

      <DateComponent start={start} />
      <Description isActive={isAlreadyBooking} item={item} />

      <div className="workshop-item-button-container">
        {isAvailable && !isAlreadyBooking && (
          <div className="workshop-item-text-above-button">
            {t('Anmeldung bis {{val, datetime}}', {
              val: dateUntilRegistrationNotice,
            })}
          </div>
        )}
        {buttonRender()}
      </div>
    </div>
  );
};

WorkshopItem.propTypes = {
  item: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    dateUntilRegistrationNotice: PropTypes.instanceOf(Date).isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    isAlreadyBooking: PropTypes.bool.isRequired,
    isAvailable: PropTypes.bool.isRequired,
  }).isRequired,
};

export default WorkshopItem;
