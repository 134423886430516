import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from '../../components/PageTitle';
import FgiInfo from '../../constants/FgiInfo';
import './Imprint.scss';

export const Imprint = () => {
  const [t] = useTranslation();

  return (
    <div className="impressum">
      <div className="impressum-title-wrapper">
        <Title width="30%" text={t('imprint.title')} color="yellow" />
      </div>
      <div className="impressum-main-container">
        <div className="impressum-main-container-text primary-text">
          <div className="primary-text-medium">
            {t('imprint.company.title')}:
          </div>
          <br />
          <br />
          fgi Digital Solutions GmbH
          <br />
          Brodschrangen 3-5
          <br />
          20457 Hamburg
          <br />
          Germany
          <br />
          <br />
          <span className="primary-text-medium">
            {t('imprint.company.email')}:&nbsp;
          </span>
          {FgiInfo.email}
          <br />
          <span className="primary-text-medium">
            {t('imprint.company.website')}:&nbsp;
          </span>
          www.fgi-digitalsolutions.de
          <br />
          <br />
          <span className="primary-text-medium">
            {t('imprint.company.managingDirector')}
            :&nbsp;
          </span>
          Carlo Fischer <br />
          <span className="primary-text-medium">
            {t('imprint.company.register')}
            :&nbsp;
          </span>
          Amtsgericht Hamburg <br />
          <span className="primary-text-medium">
            {t('imprint.company.registerNo')}
            :&nbsp;
          </span>
          HRB 145814 <br />
          <span className="primary-text-medium">
            {t('imprint.company.vatId')}: &nbsp;
          </span>
          DE 183047450 <br />
        </div>
        <div className="impressum-main-container-text primary-text">
          <div className="primary-text-medium">
            {t('imprint.disclaimer.title')}:
          </div>
          <br />
          <Typography gutterBottom>{t('imprint.disclaimer.1')}</Typography>
          <Typography gutterBottom>{t('imprint.disclaimer.2')}</Typography>
          {t('imprint.conceptAndDesign')}
          :&nbsp;
          <a
            href="https://pielaco.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Piela & Co. Digital Consultants
          </a>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
