import * as Sentry from '@sentry/react';
import { signOut } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PagePath from '../../constants/PagePath';
import { auth } from '../../firebaseConfig';
import { LOGOUT } from '../../hooks';
import { useGlobalState } from '../../hooks/useGlobalState';
import { getLink, Monitoring } from '../../utils';

export const SignOut = () => {
  const [{ company }, dispatch] = useGlobalState();
  const navigate = useNavigate();

  useEffect(() => {
    signOut(auth)
      .then(() => {
        dispatch({ type: LOGOUT });
        navigate(getLink(company, PagePath.ANMELDEN));
        Monitoring.trackEvent('Logout');
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, [navigate, dispatch]); // eslint-disable-line
  return <></>;
};

export default SignOut;
