import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../../../amplitude';
import {
  loadUserEvents,
  getAllCoaches,
  onUserProjectsUpdate,
} from '../../../../api';
import Icon from '../../../../assets/icons/projects-item-icon.png';
import { useGlobalState } from '../../../../hooks';
import CoachModeratorBuchenModal from '../../../../modals/CoachModeratorBuchenModal';
import CoachModeratorBuchenSuccessModal from '../../../../modals/CoachModeratorBuchenSuccessModal';
import FindWorkshopsModal from '../../../../modals/FindWorkshopsModal';
import ProjectDetailsModal from '../../../../modals/ProjectDetailsModal';
import ReviewCoachesModal from '../../../../modals/ReviewCoachesModal';
import BookingCard from '../BookingCard';
import NoBookingItems from '../NoBookingItems';

let unsubscribe;

const BookingItem = () => {
  const [t] = useTranslation();
  const [{ user }] = useGlobalState();
  const [isSubscribe, setIsSubscribe] = useState(true);
  const [userEvents, setUserEvents] = useState([]);
  const [isFindModalShow, setIsFindModalShow] = useState(false);

  const initialState = {
    reviewCoachesModalShow: false,
    projectDetailsModalShow: false,
    coachModeratorBuchenModalShow: false,
    coaches: [],
    coach: {},
    project: {},
    projects: [],
    index: 0,
  };

  const [state, setState] = useState(initialState);
  const [
    coachModeratorBuchenSuccessModalShow,
    setCoachModeratorBuchenSuccessModalShow,
  ] = useState(false);

  const openReviewCoachesModal = (coachesRef, project) => async () => {
    const coachesIds = coachesRef.map((coach) => coach.id);
    const allCoaches = await getAllCoaches();
    const coaches = allCoaches.filter((coach) =>
      coachesIds.some((id) => id === coach.id),
    );
    sendAmplitudeData('Open Selected Coaches Dialog', {
      type: project.type,
      category: project.category,
      project_id: project.id,
    });
    setState((state) => ({
      ...state,
      reviewCoachesModalShow: true,
      coaches,
      project,
    }));
  };

  const closeReviewCoachesModal = () => {
    setState((state) => ({ ...state, reviewCoachesModalShow: false }));
  };

  const openProjectDetailsModal = (project, index) => () => {
    setState((state) => ({
      ...state,
      project,
      index,
      projectDetailsModalShow: true,
    }));
  };

  const closeProjectDetailsModal = () => {
    setState((state) => ({
      ...state,
      index: 0,
      projectDetailsModalShow: false,
    }));
  };

  const openCoachModeratorBuchenModal =
    (coach, { id, type, category }) =>
    () => {
      sendAmplitudeData('Start Booking', {
        project_id: id,
        type,
        category,
        coach_name: `${coach.firstname} ${coach.lastname}`,
      });
      setState((state) => ({
        ...state,
        coachModeratorBuchenModalShow: true,
        coach,
      }));
      closeReviewCoachesModal();
    };

  const closeCoachModeratorBuchenModal = () => {
    setState((state) => ({
      ...state,
      coachModeratorBuchenModalShow: false,
    }));
  };

  const openReviewModalCloseBuchenModal = () => {
    setState((state) => ({
      ...state,
      reviewCoachesModalShow: true,
      coachModeratorBuchenModalShow: false,
    }));
  };

  const openCoachModeratorBuchenSuccessModal = () => {
    setState((state) => ({
      ...state,
      coachModeratorBuchenModalShow: false,
    }));
    setCoachModeratorBuchenSuccessModalShow(true);
  };

  const closeCoachModeratorBuchenSuccessModal = () => {
    setCoachModeratorBuchenSuccessModalShow(false);
  };

  const openFindWorkshopModal = (project) => () => {
    setIsFindModalShow(true);
    setState((currentState) => ({ ...currentState, project }));
  };

  const closeFindWorkshopModal = () => {
    setIsFindModalShow(false);
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await loadUserEvents({
        company_id: user.company_id,
        user_id: user.uid,
      });
      setUserEvents(data);
    };
    if (!isFindModalShow) {
      fetch();
    }
  }, [user, isFindModalShow]);

  useEffect(() => {
    const callback = (projects) => {
      if (isSubscribe) {
        setState({ ...state, projects });
      }
    };
    unsubscribe = onUserProjectsUpdate(user.uid, callback);
    return () => {
      unsubscribe();
      setIsSubscribe(false);
    };
  }, []); // eslint-disable-line

  const {
    reviewCoachesModalShow,
    coaches,
    coach,
    projectDetailsModalShow,
    project,
    coachModeratorBuchenModalShow,
    index,
    projects,
  } = state;

  return (
    <Box
      sx={{
        flex: '2',
        backgroundColor: 'common.white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '46px 0',
      }}
    >
      {projects?.length || userEvents?.length ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '31px',
            }}
          >
            <Box
              src={Icon}
              alt=""
              component="img"
              sx={{ marginRight: '10px' }}
            />
            <Typography variant="titleSection">{t('IHRE PROJEKTE')}</Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {projects.map((project, index) => (
              <BookingCard
                project={project}
                index={index}
                key={index}
                openProjectDetailsModal={openProjectDetailsModal}
                openReviewCoachesModal={openReviewCoachesModal}
              />
            ))}
            {userEvents.map((event, index) => (
              <BookingCard
                project={event}
                index={projects.length + index}
                key={index}
                openFindWorkshopModal={openFindWorkshopModal}
              />
            ))}
          </Box>
          <ReviewCoachesModal
            project={project}
            show={reviewCoachesModalShow}
            onHide={closeReviewCoachesModal}
            coaches={coaches}
            onSofortBuchenClick={openCoachModeratorBuchenModal}
          />
          <CoachModeratorBuchenModal
            show={coachModeratorBuchenModalShow}
            onHide={closeCoachModeratorBuchenModal}
            onBackToPreviousClick={openReviewModalCloseBuchenModal}
            project={project}
            coach={coach}
            onSubmitClick={openCoachModeratorBuchenSuccessModal}
          />
          <CoachModeratorBuchenSuccessModal
            show={coachModeratorBuchenSuccessModalShow}
            project={project}
            coach={coach}
            onHide={closeCoachModeratorBuchenSuccessModal}
          />
          <ProjectDetailsModal
            show={projectDetailsModalShow}
            project={project}
            onHide={closeProjectDetailsModal}
            index={index}
          />
          {isFindModalShow && (
            <FindWorkshopsModal
              show={isFindModalShow}
              onHide={closeFindWorkshopModal}
              item={project}
            />
          )}
        </>
      ) : (
        <NoBookingItems />
      )}
    </Box>
  );
};

export default BookingItem;
