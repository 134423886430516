import React, { useMemo, useState } from 'react';

import { Locales } from '../../../i18n';
import LanguageSwitch from './LanguageSwitch';

/**
 * @param defaultLanguage {TLocale | undefined}
 * @return {{selectedLanguage: TLocale, LanguageSwitchComponent: JSX.Element, setSelectedLanguage: React.Dispatch<React.SetStateAction<TLocale>>}}
 */
const useLanguageSwitch = (defaultLanguage = Locales.DE) => {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const LanguageSwitchComponent = useMemo(() => {
    return (
      <LanguageSwitch
        selectedLanguage={selectedLanguage}
        handleChangeLanguage={setSelectedLanguage}
      />
    );
  }, [selectedLanguage, setSelectedLanguage]);

  return { selectedLanguage, setSelectedLanguage, LanguageSwitchComponent };
};

export default useLanguageSwitch;
