import { Box } from '@mui/material';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import AvatarSVG from '../../assets/svgs/avatar';
import ContentTypes from '../../constants/ContentTypes';
import PagePath from '../../constants/PagePath';
import ProjectTypes from '../../constants/ProjectTypes';
import useAvailableContent from '../../hooks/useAvailableContent';
import { useGlobalState } from '../../hooks/useGlobalState';
import { getLink, getCompanyLogo } from '../../utils';
import NavItem from '../NavItem/NavItem';
import './Header.scss';

const { Collapse, Brand, Toggle } = Navbar;

export const Header = () => {
  const [{ user, company }] = useGlobalState();
  const isAvailable = useAvailableContent();

  const [t] = useTranslation();

  const navList = [
    {
      text: t('Start'),
      to: getLink(company, PagePath.DASHBOARD),
      visible:
        isAvailable(ProjectTypes.MODERATOR) || isAvailable(ProjectTypes.COACH),
      color: 'yellow',
    },
    {
      text: t('Coach finden'),
      to: getLink(company, PagePath.COACH_FINDER),
      visible: isAvailable(ProjectTypes.COACH),
      color: 'yellow',
    },
    {
      text: t('E-Learning'),
      to: getLink(company, PagePath.ELEARNING),
      visible: isAvailable(ProjectTypes.ELEARNING),
      color: 'blue',
    },
    {
      text: t('Moderator finden'),
      to: getLink(company, PagePath.MODERATOR_FINDEN),
      visible: isAvailable(ProjectTypes.MODERATOR),
      color: 'green',
    },
    {
      text: t('Training buchen'),
      to: getLink(company, PagePath.OPEN_WORKSHOPS),
      visible: isAvailable(ContentTypes.OPEN_WORKSHOP),
      color: 'dark-orchid',
    },
    {
      text: t('Buchungen'),
      to: getLink(company, PagePath.BOOKINGS),
      visible:
        isAvailable(ProjectTypes.MODERATOR) || isAvailable(ProjectTypes.COACH),
      color: 'dark-yellow',
    },
  ];

  const Avatar = () => (
    <Box
      sx={{
        width: '32px',
        height: '32px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#edefec',
        borderRadius: '50%',
      }}
    >
      <AvatarSVG />
    </Box>
  );

  return (
    <Navbar className="custom-navbar" bg="light" expand="lg">
      <Container>
        <Brand>
          <img src={getCompanyLogo(company)} alt="fgidigitalsolutions_trans" />
        </Brand>
        <Toggle aria-controls="responsive-navbar-nav" />
        <Collapse id="basic-navbar-nav">
          <div className="custom-navbar-wrapper">
            <Nav className="custom-navbar-menu" activeKey="/home">
              {navList.map(
                (item, index) =>
                  item.visible && (
                    <NavItem
                      key={index}
                      to={item.to}
                      text={item.text}
                      selectedColor={item.color}
                    />
                  ),
              )}
              <NavDropdown
                className="hidden-on-mobile-menu"
                title={<Avatar />}
                id="basic-nav-dropdown"
              >
                {!!user?.admin && (
                  <NavItem
                    to={getLink(company, PagePath.ADMIN_PROJECTS)}
                    text="Admin"
                  />
                )}
                <NavItem
                  to={getLink(company, PagePath.SETTINGS)}
                  text={t('Einstellungen')}
                />
                <NavItem
                  text={t('Abmelden')}
                  to={generatePath(PagePath.SIGNOUT, {
                    customPath: company.custom_path,
                  })}
                />
              </NavDropdown>
              <div className="hidden-on-desktop-menu">
                {!!user?.admin && (
                  <NavItem
                    to={getLink(company, PagePath.ADMIN_PROJECTS)}
                    text={t('Admin')}
                  />
                )}
              </div>
              <div className="hidden-on-desktop-menu">
                <NavItem
                  to={getLink(company, PagePath.SETTINGS)}
                  text={t('Einstellungen')}
                />
              </div>
              <div className="hidden-on-desktop-menu">
                <NavItem
                  text={t('Abmelden')}
                  to={generatePath(PagePath.SIGNOUT, {
                    customPath: company.custom_path,
                  })}
                />
              </div>
            </Nav>
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
