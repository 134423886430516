import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import InputText from '../InputText';

const InputAdminCompanCustomPath = ({
  name,
  onChange,
  value,
  isInvalid = false,
  errorMessage = '',
}) => {
  return (
    <>
      <Form.Label>Eigene Anmelde-Adresse</Form.Label>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          alignItems: 'center',
        }}
      >
        <Typography variant="primaryText">
          https://fgi-digitalsolutions.de/
        </Typography>
        <InputText
          name={name}
          as="input"
          onChange={onChange}
          value={value}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
      </Box>
    </>
  );
};

InputAdminCompanCustomPath.propTypes = {
  /**
   * The function that is called when the value changes.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The name of the component.
   */
  name: PropTypes.string.isRequired,

  /**
   * The value of the component.
   */
  value: PropTypes.string.isRequired,

  /**
   * Whether the component should display an invalid state.
   */
  isInvalid: PropTypes.bool,

  /**
   * If set, the component will display an error message.
   */
  errorMessage: PropTypes.string,
};

export default InputAdminCompanCustomPath;
