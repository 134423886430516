import PageContainer from '../../components/PageContainer';
import { Title, Headline, Text, UL } from './components';

const PrivacyPolicyEn = () => {
  return (
    <PageContainer>
      <Title text="Privacy Policy" />

      <Headline>1. Introduction</Headline>
      <Text>
        At fgi Fischer Group International, we take the protection of your
        personal data very seriously. This privacy policy explains how we
        collect, use, store, and protect your personal data.
      </Text>

      <Headline>2. Responsible Entity</Headline>
      <Text>
        The entity responsible for data processing is: fgi Fischer Group
        International GmbH
        <br />
        Brodschrangen 3-5
        <br />
        20457 Hamburg
        <br />
        Germany
        <br />
        Email: digital.solutions@fgi-mail.com
        <br />
        Website:{' '}
        <a href="http://www.fgi-digitalsolutions.de">
          www.fgi-digitalsolutions.de
        </a>
      </Text>

      <Headline>3. Privacy Policy</Headline>
      <Text>
        Personal data is collected on this website only to the extent
        technically necessary. Under no circumstances will the collected data be
        sold. The following statement provides an overview of how fgi Fischer
        Group International GmbH, hereafter referred to as fgi, ensures this
        protection and what kind of data is collected for what purpose.
      </Text>

      <Headline>4. Data Collection and Use</Headline>
      <Headline>4.1. What Data We Collect</Headline>
      <Text>
        We collect various types of personal data that are necessary for
        providing our services. This includes, in particular:
        <UL
          items={[
            <>
              <strong>User Information:</strong> First name, last name, email
              address, date of birth, nationality, location, IP address,
              preferred language in projects.
            </>,
            <>
              <strong>Coach Information:</strong> First name, last name, email
              address, location, phone number, languages, photo, biography.
            </>,
            <>
              <strong>Project-Related Data:</strong> Category, product, cost
              center, business situation, coaching concern/topic, leadership
              situation, leadership level, follow-up measures, number of
              participants, date, location, language, expectations, notes.
            </>,
            <>
              <strong>E-Learnings:</strong> Date of first module start, progress
              within the module, user-stored data, result/success/completion of
              a module.
            </>,
          ]}
        />
      </Text>

      <Headline>5. Legal Basis for Processing</Headline>
      <Text>
        The processing of your data is based on the following legal bases:
        <UL
          items={[
            'Consent (Art. 6 para. 1 lit. a GDPR)',
            'Performance of a contract (Art. 6 para. 1 lit. b GDPR)',
            'Compliance with legal obligations (Art. 6 para. 1 lit. c GDPR)',
            'Legitimate interests (Art. 6 para. 1 lit. f GDPR)',
          ]}
        />
      </Text>

      <Headline>6. Data Sharing</Headline>
      <Headline>6.1. Service Providers</Headline>
      <Text>
        We only share your personal data in the following cases:
        <UL
          items={[
            'With your consent',
            <>
              With service providers that are necessary to provide our services.
              These include:
              <UL
                items={[
                  <>
                    <strong>Google Cloud EMEA Limited</strong>
                    <br />
                    Website:{' '}
                    <a href="https://cloud.google.com/">
                      https://cloud.google.com/
                    </a>
                    <br />
                    Privacy:{' '}
                    <a href="https://policies.google.com/privacy">
                      https://policies.google.com/privacy
                    </a>
                    <br />
                    Purpose: IT hosting and infrastructure
                    <br />
                    Necessary for operation: Yes
                    <br />
                    Privacy: Subject to European data protection law (GDPR)
                  </>,
                  <>
                    <strong>Rustici Software LLC</strong>
                    <br />
                    Website: <a href="https://scorm.com/">https://scorm.com/</a>
                    <br />
                    Privacy:{' '}
                    <a href="https://ltgplc.com/privacy-notice/">
                      https://ltgplc.com/privacy-notice/
                    </a>
                    <br />
                    Purpose: Provision of e-learning infrastructure
                    <br />
                    Necessary for operation: Yes
                    <br />
                    Privacy: Subject to European data protection law (GDPR)
                  </>,
                  <>
                    <strong>Mailgun Technologies, Inc.</strong>
                    <br />
                    Website:{' '}
                    <a href="https://www.mailgun.com/">
                      https://www.mailgun.com/
                    </a>
                    <br />
                    Privacy:{' '}
                    <a href="https://www.mailgun.com/gdpr/">
                      https://www.mailgun.com/gdpr/
                    </a>
                    <br />
                    Purpose: Sending emails
                    <br />
                    Necessary for operation: Yes
                    <br />
                    Privacy: Subject to European data protection law (GDPR)
                  </>,
                  <>
                    <strong>Amplitude, Inc.</strong>
                    <br />
                    Website:{' '}
                    <a href="https://amplitude.com/">https://amplitude.com/</a>
                    <br />
                    Privacy:{' '}
                    <a href="https://amplitude.com/privacy">
                      https://amplitude.com/privacy
                    </a>
                    <br />
                    Purpose: Tracking user behavior
                    <br />
                    Necessary for operation: No
                    <br />
                    Privacy: Subject to European data protection law (GDPR)
                  </>,
                ]}
              />
            </>,
            'When required by law or to enforce our terms of use.',
          ]}
        />
      </Text>

      <Headline>7. Data Transfer to Third Countries</Headline>
      <Text>
        A transfer of your data to third countries (outside the EU/EEA) only
        takes place if it is legally permitted or you have consented to the data
        transfer. In doing so, we ensure that the respective service providers
        comply with European data protection standards.
      </Text>

      <Headline>8. Data Storage and Protection</Headline>
      <Headline>8.1. Data Storage</Headline>
      <Text>
        We store your personal data only as long as necessary for the purposes
        for which they were collected or as required by law. In general, the
        data will be deleted upon termination of the collaboration unless there
        are legal retention obligations.
      </Text>

      <Headline>8.2. Data Security</Headline>
      <Text>
        We implement appropriate technical and organizational measures to
        protect your personal data from unauthorized access, loss, or
        destruction.
      </Text>

      <Headline>9. Your Rights</Headline>
      <Text>
        You have the right to:
        <UL
          items={[
            'Request information about the data we store about you',
            'Request correction of inaccurate data',
            'Request deletion of your data, provided there are no legal retention obligations',
            'Request restriction of the processing of your data',
            'Object to the processing of your data',
            'Request data portability',
          ]}
        />
      </Text>

      <Headline>9.1. Right to Information</Headline>
      <Text>
        Visitors have the right at any time to obtain information about the data
        stored about them, their origin and recipients, and the purpose of data
        processing. The request for information should be directed to the
        address provided in our website's imprint or can be directed to the
        following addresses:
        <UL
          items={[
            'Mail: digital.solutions@fgi-mail.com',
            'Phone: +49 (0)40 65 68 90 0',
            'Fax: +49 (0)40 65 68 90 80',
          ]}
        />
      </Text>

      <Headline>9.2. Deletion</Headline>
      <Text>
        The deletion of stored personal data occurs when the visitor to the
        application withdraws consent to storage, when their knowledge is no
        longer necessary for the purpose of storage, or when their storage is
        otherwise unlawful. Data for billing and accounting purposes are not
        affected by a deletion request.
      </Text>

      <Headline>10. Disclaimer</Headline>
      <Text>
        All content on our website has been carefully compiled and checked.
        Despite all diligence, liability or guarantee for the accuracy,
        completeness, and timeliness of the data cannot be assumed. This also
        applies to all other websites referred to by hyperlinks. We are not
        responsible for the content of the websites that can be reached via such
        a connection. We reserve the right to make updates, changes, or
        additions to the information and data provided.
      </Text>

      <Headline>11. Changes to This Privacy Policy</Headline>
      <Text>
        We may update this privacy policy from time to time. The current version
        will always be available on our website. We will notify you of
        significant changes by email.
      </Text>

      <Headline>12. Contact</Headline>
      <Text>
        If you have any questions about this privacy policy or wish to exercise
        your rights, please contact us at:
        <br />
        fgi Fischer Group International GmbH
        <br />
        Brodschrangen 3-5
        <br />
        20457 Hamburg
        <br />
        Germany
        <br />
        Email: digital.solutions@fgi-mail.com
        <br />
        Website:{' '}
        <a href="http://www.fgi-digitalsolutions.de">
          www.fgi-digitalsolutions.de
        </a>
      </Text>
    </PageContainer>
  );
};

export default PrivacyPolicyEn;
