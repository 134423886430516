import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { sendAmplitudeData } from '../../amplitude';
import { createNewProject } from '../../api/projects';
import Button from '../../components/Button';
import FgiModal from '../../components/FgiModal';
import ProjectTypes from '../../constants/ProjectTypes';
import { useGlobalState } from '../../hooks/useGlobalState';
import { getProductTranslatedData } from '../../utils';
import {
  NewCoachingFormSchema,
  NewCoachingFormSchemaWithoutLocation,
} from './newCoachingFormCoachingSchema';
import {
  businessSituationOptions,
  followOnActionOptions,
  leadershipLevelOptions,
  coachingTopicOptions,
} from './newCoachingFormOptions';

const initialFormValues = {
  business_situation: '',
  leadership_level: '',
  follow_on_action: '',
  coaching_topic: '',
  location: '',
  expectations: '',
  comments: '',
};

export const NewCoachingModal = ({ show, onHide, onSubmit, product }) => {
  const [{ locale, user }] = useGlobalState();
  const [t] = useTranslation();

  const productVirtual = product && product.is_virtual;

  const submit = async (formData) => {
    try {
      sendAmplitudeData('New Project – Submit Request', {
        type: ProjectTypes.COACH,
        category: getProductTranslatedData(product.title, locale),
      });
      const projectData = {
        ...formData,
        type: ProjectTypes.COACH,
        category: getProductTranslatedData(product.title, locale),
        user_id: user.uid,
        product_id: product.id,
        suggested_scope: product.hours_needed,
        suggested_dayrate: product.hourly_rate,
      };
      await createNewProject(projectData);
      onSubmit();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const checkFormSchema = () => {
    if (productVirtual) {
      return NewCoachingFormSchemaWithoutLocation(t);
    }
    return NewCoachingFormSchema(t);
  };

  const renderCoachingTopic = (values, errors, handleChange, touched) => {
    return (
      <Form.Group as={Col}>
        <Form.Label>{t('Coachinganliegen/Thema *')}</Form.Label>
        <Form.Control
          as="select"
          name="coaching_topic"
          value={values.coaching_topic}
          onChange={handleChange}
          isInvalid={!!errors.coaching_topic && touched.coaching_topic}
        >
          <option disabled hidden value="">
            {t('Bitte wählen')}
          </option>
          {coachingTopicOptions(t).map(({ text, value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.coaching_topic}
        </Form.Control.Feedback>
      </Form.Group>
    );
  };
  return (
    <FgiModal
      title={t('coaching.new.title')}
      description={t('Wobei dürfen wir Sie unterstützen?')}
      onHide={onHide}
      show={show}
    >
      <Formik
        validationSchema={checkFormSchema()}
        onSubmit={submit}
        initialValues={initialFormValues}
      >
        {({ handleSubmit, values, touched, errors, handleChange }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Businesssituation *')}</Form.Label>
                <Form.Control
                  as="select"
                  name="business_situation"
                  value={values.business_situation}
                  onChange={handleChange}
                  isInvalid={
                    !!errors.business_situation && touched.business_situation
                  }
                >
                  <option disabled hidden value="">
                    {t('Bitte wählen')}
                  </option>
                  {businessSituationOptions(t).map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.business_situation}
                </Form.Control.Feedback>
              </Form.Group>
              {renderCoachingTopic(values, errors, handleChange, touched)}
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Führungsebene *')}</Form.Label>
                <Form.Control
                  as="select"
                  name="leadership_level"
                  value={values.leadership_level}
                  onChange={handleChange}
                  isInvalid={
                    !!errors.leadership_level && touched.leadership_level
                  }
                >
                  <option disabled hidden value="">
                    {t('Bitte wählen')}
                  </option>
                  {leadershipLevelOptions(t).map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.leadership_level}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('Anschlussmaßnahmen *')}</Form.Label>
                <Form.Control
                  as="select"
                  name="follow_on_action"
                  value={values.follow_on_action}
                  onChange={handleChange}
                  isInvalid={
                    !!errors.follow_on_action && touched.follow_on_action
                  }
                >
                  <option disabled hidden value="">
                    {t('Bitte wählen')}
                  </option>
                  {followOnActionOptions(t).map(({ text, value }) => (
                    <option key={text} value={value}>
                      {text}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.follow_on_action}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>{t('Ort des Coachings *')}</Form.Label>
                <Form.Control
                  as="input"
                  type="text"
                  name="location"
                  value={productVirtual ? t('Virtuell') : values.location}
                  onChange={handleChange}
                  disabled={product && product.is_virtual}
                  isInvalid={!!errors.location && touched.location}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.location}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Erwartungen *')}</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  className="modal-textarea"
                  name="expectations"
                  value={values.expectations}
                  onChange={handleChange}
                  isInvalid={!!errors.expectations && touched.expectations}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.expectations}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>{t('Anmerkungen (optional)')}</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  className="modal-textarea"
                  name="comments"
                  value={values.comments}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <div className="coach-finder__modal-footer">
              <Button
                size="medium"
                style={{ minWidth: '190px' }}
                type="submit"
                variant="main"
              >
                {t('Suche starten')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </FgiModal>
  );
};

NewCoachingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  product: PropTypes.object,
};

export default NewCoachingModal;
