import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';

import { Locales, LocalesFullName } from '../../i18n';
import Button from '../Button';
import FgiModal from '../FgiModal';
import './InputFile.scss';

/**
 * TODO: Add "name" to represent the name of the file included in the input field
 * @param onSubmitFile
 * @param title
 * @param content
 * @param isFormSubmit
 * @param pictureName
 * @param removePicture - Deprecated: use onSubmitFile instead with null value
 * @param accept
 * @param skipModalStep
 * @param cropWidth
 * @param cropHeight
 * @param language
 * @param isRequired
 * @return {JSX.Element}
 * @constructor
 */
export const InputFile = ({
  onSubmitFile,
  title,
  content,
  isFormSubmit,
  pictureName,
  removePicture,
  accept,
  skipModalStep,
  cropWidth,
  cropHeight,
  language,
  isRequired,
}) => {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const [t] = useTranslation();

  const [file, setFile] = useState(null);
  const [border, setBorder] = useState('');
  const [scale, setScale] = useState(1);
  const [image, setImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState('');

  useEffect(() => {
    if (isFormSubmit && !image && isRequired) {
      setBorder('red');
    }
  }, [isFormSubmit, image, isRequired]);

  useEffect(() => {
    if (pictureName && !image) {
      if (skipModalStep) {
        setPdfFileUrl(pictureName);
      } else {
        setImage(pictureName);
      }
    }
  }, [pictureName, image, skipModalStep]);

  const handleFile = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      setFile(file);

      if (skipModalStep) {
        onSubmitFile({
          blob: file,
          name: `${Date.now()}${file.name}`,
          type: file.type,
        });
        const url = URL.createObjectURL(file);
        setPdfFileUrl(url);
      } else {
        setShowModal(true);
      }
    }
  };

  const handleEditedFile = async () => {
    const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
    const blob = await fetch(canvas).then((res) => res.blob());
    const url = URL.createObjectURL(blob);
    setImage(url);
    onSubmitFile({
      blob,
      name: `${Date.now()}${file.name}`,
      type: file.type,
    });
    setShowModal(false);
    setBorder('none');
  };

  const handleScale = (event) => {
    setScale(Number(event.target.value));
  };

  const closeModal = () => {
    inputRef.current.value = null;
    setShowModal(false);
  };

  const removeImage = () => {
    setFile(null);
    setImage(null);
    onSubmitFile(null);
    removePicture();
    if (skipModalStep) {
      setPdfFileUrl('');
    }
    inputRef.current.value = null;
  };

  const handleBorder = (color) => () => {
    setBorder(color);
  };

  const getInputSize = () => {
    switch (content) {
      case 'product':
        return 'file-input-container-product';
      case 'logo':
        return 'file-input-container-logo';
      case 'pdf':
        return 'file-input-container-pdf';
      default:
        return 'file-input-container-product';
    }
  };

  return (
    <>
      <div
        className={`file-input-container ${getInputSize()} file-input-container-${border}`}
      >
        <input
          type="file"
          className="file-input"
          accept={accept}
          onChange={handleFile}
          onDragOver={handleBorder('green')}
          onDragLeave={handleBorder('none')}
          onDrop={handleBorder('none')}
          ref={inputRef}
        />
        <div className="file-input-icon" />
        <div className="file-input-text">
          <div className="title-section">{title}</div>
          {language ? (
            <div className="text-small-1">
              Drag & Drop | {LocalesFullName[Locales.DE][language]}
            </div>
          ) : (
            <div className="text-small-1">Drag & Drop</div>
          )}
        </div>
        {(image || pdfFileUrl) && (
          <div className="file-input-image-container">
            <img className="file-input-image" src={image} alt="" />
            {pdfFileUrl && (
              <div
                onClick={() => window.open(pdfFileUrl, 'blank')}
                className="file-input-pdf-open title-section"
              >
                PDF Öffnen
              </div>
            )}
            <CloseIcon
              onClick={removeImage}
              sx={{
                backgroundColor: 'yellow.main',
                top: 0,
                right: 0,
                position: 'absolute',
                zIndex: 3,
                cursor: 'pointer',
              }}
              alt=""
            />
          </div>
        )}
      </div>
      <FgiModal
        color="none"
        className="file-input-modal"
        onHide={closeModal}
        show={showModal}
      >
        <AvatarEditor
          ref={editorRef}
          image={file}
          width={cropWidth}
          height={cropHeight}
          border={50}
          color={[0, 0, 0, 0.75]}
          scale={scale}
          rotate={0}
        />
        <div className="file-input-modal-container">
          <input
            type="range"
            value={scale}
            min={1}
            max={3}
            step={0.1}
            onChange={handleScale}
          />
          <Button size="medium" variant="main" onClick={handleEditedFile}>
            {t('speichern')}
          </Button>
        </div>
      </FgiModal>
    </>
  );
};

InputFile.defaultProps = {
  removePicture: () => {},
  skipModalStep: false,
  cropWidth: 539,
  cropHeight: 160,
  language: '',
  isRequired: true,
};

InputFile.propTypes = {
  /** It is a required function property that handles the event when a file is submitted */
  onSubmitFile: PropTypes.func.isRequired,
  /** It is a required string property that represents the title or label for the input file component */
  title: PropTypes.string.isRequired,
  /**It is a required string property that represents the content or description of the input file component  */
  content: PropTypes.string.isRequired,
  /**  It is an optional string property that represents the name of the picture file */
  pictureName: PropTypes.string,
  /**It is an optional function property that handles the event when the picture is removed  */
  removePicture: PropTypes.func,
  /** It is an optional string property that specifies the file types or formats that are accepted by the input file component */
  accept: PropTypes.string,
  /**It is an optional boolean property that determines whether to skip a modal step in the process */
  skipModalStep: PropTypes.bool,
  /** It is an optional string property that represents the specific language for the component */
  language: PropTypes.string,
  /** It is an optional boolean property that indicates whether the input file component is required or not */
  isRequired: PropTypes.bool,
};

export default InputFile;
