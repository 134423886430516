import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import './Footer.scss';

const Footer = ({
  submitSuggestedCoaches,
  openAuswahlSendenModal,
  openDeleteProjectModal,
}) => {
  const [t] = useTranslation();
  return (
    <div className="admin-footer">
      <Container className="admin-footer-buttons-container">
        <Button
          onClick={openDeleteProjectModal}
          variant="light"
          arrow={false}
          size="small"
        >
          {t('Löschen')}
        </Button>
        <Button
          onClick={submitSuggestedCoaches}
          variant="light"
          arrow={false}
          style={{ marginLeft: '24px' }}
          size="small"
        >
          {t('Speichern')}
        </Button>
        <Button
          onClick={openAuswahlSendenModal}
          style={{ marginLeft: '24px' }}
          size="small"
          arrow={false}
          variant="main-dark"
        >
          {t('Auswahl Senden')}
        </Button>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  submitSuggestedCoaches: PropTypes.func.isRequired,
  openAuswahlSendenModal: PropTypes.func.isRequired,
};

export default Footer;
