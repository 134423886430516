import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  getUserById,
  getCompanyById,
  getCoachByRef,
  getCoachesByRef,
} from '../../../../api';
import Button from '../../../../components/Button';
import ProjectStates from '../../../../constants/ProjectStates';
import GENDER_TYPES from '../../../../constants/SexTypes';

const AdminProjectsPDFButton = ({ projects }) => {
  const [t] = useTranslation();
  const downloadPDFFile = async () => {
    const rows = [
      [
        'Datum, Status, Firma, Anrede, Vorname, Nachname, E-Mail-Adresse, Sprache, Nationalität, Ort, Projekt, Produkt, Teilnehmerzahl, Bevorzugte Sprache, Ort des Workshops, Startdatum, Businesssituation, Coachinganliegen/Thema, Führungsebene, Anschlussmaßnahmen, Ort des Coachings, Erwartungen, Anmerkungen, Coach Vorschlag 1, Coach Vorschlag 2, Coach Vorschlag 3, Gebuchter Coach, Tagessatz, Aufwand in Stunden, Kosten',
      ],
    ];

    const projectsRows = await Promise.all(
      projects.map(async (project) => {
        const state =
          project.state === ProjectStates.findCoaches
            ? 'Offene Anfragen'
            : project.state === ProjectStates.bookCoach
            ? 'Wartet auf Buchung'
            : 'Laufende Projekte';
        const user = await getUserById(project.user_id);
        let companyName = 'company not exist';
        let sex = 'Benutzer gelöscht';
        let firstName = 'Benutzer gelöscht';
        let lastName = 'Benutzer gelöscht';
        let email = 'Benutzer gelöscht';
        let language = 'Benutzer gelöscht';
        let nationality = 'Benutzer gelöscht';
        let location = 'Benutzer gelöscht';
        let suggested_coaches = [
          { firstname: '', lastname: '' },
          { firstname: '', lastname: '' },
          { firstname: '', lastname: '' },
        ];
        let selected_coach = { firstname: '', lastname: '' };
        if (project.selected_coach) {
          const coach = await getCoachByRef(project.selected_coach);
          selected_coach = {
            firstname: coach.firstname,
            lastname: coach.lastname,
          };
        }
        if (project.suggested_coaches) {
          const suggestedCoaches = await getCoachesByRef(
            project.suggested_coaches,
          );
          suggested_coaches = suggested_coaches.map((coach, index) => {
            if (suggestedCoaches[index]) {
              return suggestedCoaches[index];
            }
            return coach;
          });
        }
        if (user) {
          const company = await getCompanyById(user.company_id);
          if (company) {
            companyName = company.name;
          }
          firstName = user.firstname;
          lastName = user.lastname;
          email = user.email;
          language = user.language;
          nationality = user.nationality;
          location = user.location;
          sex =
            user.sex === GENDER_TYPES.male
              ? 'Herr'
              : user.sex === GENDER_TYPES.female
              ? 'Frau'
              : 'Divers';
        }

        return [
          moment.unix(project.created_at?.seconds).format('L'),
          `"${state}"`,
          `"${companyName}"`,
          `"${sex}"`,
          `"${firstName}"`,
          `"${lastName}"`,
          `"${email}"`,
          `"${language}"`,
          `"${nationality}"`,
          `"${location}"`,
          `"${project.type}"`,
          `"${project.category}"`,
          `"${project.numParticipants}"`,
          `"${project.language}"`,
          `"${project.location}"`,
          moment(project.date).format('DD.MM.YYYY'),
          `"${project.business_situation}"`,
          `"${project.coaching_topic}"`,
          `"${project.leadership_situation}"`,
          `"${project.follow_on_action}"`,
          `"${location}"`,
          `"${project.expectations}"`,
          `"${project.comments}"`,
          `${suggested_coaches[0].firstname} ${suggested_coaches[0].lastname}`,
          `${suggested_coaches[1].firstname} ${suggested_coaches[1].lastname}`,
          `${suggested_coaches[2].firstname} ${suggested_coaches[2].lastname}`,
          `${selected_coach.firstname} ${selected_coach.lastname}`,
          `${project.suggested_dayrate} €`,
          project.suggested_scope,
          project.suggested_scope * project.suggested_dayrate,
        ];
      }),
    );
    rows.push(...projectsRows);

    const csvContent = `data:text/csv,${rows
      .map((e) => e.join(','))
      .join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'FGI-projects.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <Button
      className="admin-projects-title-wrapper-button"
      variant="light"
      arrow="false"
      size="medium"
      onClick={downloadPDFFile}
    >
      {t('Export')}
    </Button>
  );
};

AdminProjectsPDFButton.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default AdminProjectsPDFButton;
