import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationDE from './locales/de.json';
import translationEN from './locales/en.json';
import translationES from './locales/es.json';
import translationFR from './locales/fr.json';

/**
 * @typedef {'de' | 'en' | 'fr' | 'es'} TLocale
 */

/**
 * @enum {TLocale}
 */
export const Locales = {
  DE: 'de',
  FR: 'fr',
  EN: 'en',
  ES: 'es',
};

/**
 * @type {Record<TLocale, Record<TLocale, string>>}
 * @example To get the full name of the English language in German:
 * LocalesFullName[Locales.DE][Locales.EN]
 */
export const LocalesFullName = {
  // TODO: Add full names of languages for other locales when needed
  [Locales.DE]: {
    [Locales.DE]: 'Deutsch',
    [Locales.FR]: 'Französisch',
    [Locales.EN]: 'Englisch',
    [Locales.ES]: 'Spanisch',
  },
};

/**
 * @type {TLocale}
 *
 * We need to use German as our default locale for as long as we have German strings as language keys without values.
 * The reason: I18n falls back to the key of the default language if no translation was found.
 * Since the actual German string is our key, it displays the correct string.
 */
export const DEFAULT_LOCALE = Locales.DE;

export const resources = {
  [Locales.DE]: {
    translation: translationDE,
  },
  [Locales.EN]: {
    translation: translationEN,
  },
  [Locales.FR]: {
    translation: translationFR,
  },
  [Locales.ES]: {
    translation: translationES,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: DEFAULT_LOCALE,

    nsSeparator: false,
    keySeparator: false,
    returnEmptyString: false,

    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV === 'development',
  });

/**
 * @param {TLocale} locale
 * @returns {Promise<void>}
 */
export const i18nChangeLocale = (locale) => {
  return i18n.changeLanguage(locale);
};

/**
 * Fixes code (locale). If present, it replaces any `_` with `-`.
 * In contrast to the original, this also turns code into all lower case.
 *
 * Taken from [i18next](https://github.com/i18next/i18next/blob/4685beae6be252f74d5b1002f93e731b5f46220d/src/utils.js#L253-L256).
 * @param {*} code
 * @returns
 */
export const getCleanedCode = (code) => {
  code = code.toLowerCase();
  if (code && code.indexOf('_') > 0) return code.replace('_', '-');
  return code;
};

/**
 * Strips out any part after `-` to only get the language part of the code (locale).
 * E.g. `de-DE` is turned into `de`
 *
 * Taken from [i18next](https://github.com/i18next/i18next/blob/4685beae6be252f74d5b1002f93e731b5f46220d/src/LanguageUtils.js#L27C3-L33C4).
 * @param {String} code The language code (locale).
 * @returns Returns only the first part before any `-`.
 */
export const getLanguagePartFromCode = (code) => {
  code = getCleanedCode(code);
  if (!code || code.indexOf('-') < 0) return code;

  const p = code.split('-');
  return p[0];
};
