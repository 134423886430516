/* eslint-disable import/prefer-default-export */

export const downloadReport = (buffer, fileName) => {
  var blob = new Blob([buffer]);
  const blobUrl = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

  window.URL.revokeObjectURL(blobUrl);
};
